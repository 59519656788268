import { yupResolver } from '@hookform/resolvers/yup';
import { IonInput, IonList } from '@ionic/react';
import HookInput from 'components/baseElements/formControls/HookInput';
import Form from 'components/baseElements/formControls/StyledForm';
import { useAutoInputFocus } from 'hooks/forms/inputFocus';
import React, { createRef, FunctionComponent, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Flight } from 'store/wines';
import { handleFormDataReset, handleServerHookForm } from 'utils/formValidation';

interface FlightFormProperties {
  submitAction: (data: Flight) => void;
  serverValidation?: any;
  validationSchema: any;
  flight?: any;
}

const FlightForm: FunctionComponent<FlightFormProperties> = (
  props: FlightFormProperties,
) => {
  const {
    submitAction,
    serverValidation,
    validationSchema,
    flight,
  } = props;

  const form = useForm({
    defaultValues: {
      name: '',
    },
    resolver: yupResolver(validationSchema),
  });

  // Focus on the input element when rendered the first time
  const inputRef = createRef<HTMLIonInputElement>();
  useAutoInputFocus(inputRef);

  useEffect(() => {
    handleServerHookForm(serverValidation, form.setError);
  }, [serverValidation, form.setError]);

  useEffect(() => {
    handleFormDataReset(flight, form.reset);
  }, [flight, form.reset]);

  return (
    <Form id="flightForm" onSubmit={form.handleSubmit(submitAction)}>
      <HookInput
        form={form}
        name="id"
        hidden={true}
      />
      <HookInput
        form={form}
        name="tastingId"
        hidden={true}
      />
      <IonList lines="full">
        <HookInput
          form={form}
          isRequired
          labelText="flightForm.labels.name"
          name="name"
        >
          <IonInput ref={inputRef} data-cy="flightName"/>
        </HookInput>
      </IonList>
    </Form>
  );
};

export default FlightForm;
