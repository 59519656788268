import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { IonHeader } from '@ionic/react';
import { useSelector } from 'react-redux';
import { Heading, Paragraph } from 'components/baseElements/typography';
import { Flex } from 'components/baseElements/grid';
import { RootState } from 'store';
import { FormattedWine } from 'utils/dataFormat/FormattedWine';
import WineAvatar from 'components/wine/WineAvatar';

interface StepHeaderProps {
  modalId: string;
  stepNumber: number;
  stepName: string;
  totalSteps: number;
}

const StepHeader: FunctionComponent<StepHeaderProps> = (
  props: StepHeaderProps,
) => {
  const {
    modalId,
    stepNumber,
    totalSteps
  } = props;

  const modalProps = useSelector((state: RootState) =>
    state.gui.modals.find(e => e.id === modalId),
  );

  return (
    <IonHeader>
      <Flex
        alignItems="center"
        borderBottom="1px solid var(--ion-color-light)"
        justifyContent="space-between"
        p={3}
        width={1}
      >
        <WineAvatar
          useIonAvatar={false}
          wine={modalProps?.params?.wine}
        />
        <Heading
          ellipsis={2}
          headingSize="xs"
          m={0}
          px={2}
        >
          <FormattedWine wine={modalProps?.params?.wine} />
        </Heading>
        <Flex flexDirection="column">
          <Paragraph m={0} textAlign="center">
            <FormattedMessage id="scoreWine.general.stepTitle.labelStart" />
          </Paragraph>
          <Paragraph
            m={0}
            textAlign="center"
            whiteSpace="nowrap"
          >
            {stepNumber}
            <FormattedMessage id="scoreWine.general.stepTitle.labelPreposition" />
            {totalSteps}
          </Paragraph>
        </Flex>
      </Flex>
    </IonHeader>
  );
}

export default StepHeader;