import { ImageOptions, CameraSource } from '@capacitor/camera';
import { faCamera } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  IonDatetime,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonRadio,
  IonRadioGroup,
  IonTextarea,
  isPlatform,
} from '@ionic/react';
import { Divider } from 'components/baseElements/Divider';
import HookInput from 'components/baseElements/formControls/HookInput';
import Form from 'components/baseElements/formControls/StyledForm';
import { TextareaStyles } from 'components/baseElements/formControls/TextareaStyles';
import { Box, Flex } from 'components/baseElements/grid';
import { Hideable } from 'components/baseElements/hideable';
import Image from 'components/baseElements/Image';
import { Paragraph } from 'components/baseElements/typography';
import { IonToggle } from 'components/ionicComponents/IonToggle';
import { GrapeUploadImageButton } from 'napa-react-core/dist/lib/components/ionicComponents/GrapeUploadImageButton';
import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { CameraApiSettings } from 'store/appSettings';
import { ETastingLocationType, Tasting } from 'store/tastings';
import { toNextHour } from 'utils/dataFormat/date';
import { getImageUrl } from 'utils/dataFormat/strings';
import { handleFormDataReset, handleServerHookForm, LoadingStatus } from 'utils/formValidation';

interface TastingFormProperties {
  allowPublicEventCreation?: boolean;
  apiBaseUrl?: string;
  cameraApiSettings?: CameraApiSettings;
  createTastingLoadingStatus?: LoadingStatus;
  currentSelectedFileId?: string;
  editTastingLoadingStatus?: LoadingStatus;
  fetchTastingLoadingStatus?: LoadingStatus;
  onImageSelect?: (id: string) => void;
  onUploadError?: () => void;
  onUploadSuccess?: (ids: Array<string>) => void;
  publicImageFileIds?: Array<string>;
  serverValidation?: any;
  submitAction: (data: Tasting) => void;
  tasting?: Tasting;
  uploadUrl?: string;
  uploadedFileId?: string;
  validationSchema: any;
}

const ImageLabel = (): JSX.Element => (
  <IonLabel color="medium" position="stacked" style={{ whiteSpace: 'normal' }}>
    <FormattedMessage id="tastingForm.labels.imageHelperText"/>
  </IonLabel>
);

interface RadioButtonLabelProperties {
  caption?: string | number;
  color?: string;
  id?: string | number;
}

const RadioButtonLabel = (props: RadioButtonLabelProperties): JSX.Element => {
  const {
    caption,
    color = 'var(--ion-color-medium)',
    id,
  } = props;

  return (
    <IonLabel>
      <Box>
        <Paragraph m={0}>
          <FormattedMessage id={id}/>
        </Paragraph>
        <Hideable hide={!caption}>
          <Paragraph
            color={color}
            fontSize="small !important"
            m={0}
            whiteSpace="normal"
          >
            <FormattedMessage id={caption} />
          </Paragraph>
        </Hideable>
      </Box>
    </IonLabel>
  )
};

const CreateTastingForm: FunctionComponent<TastingFormProperties> = (
  props: TastingFormProperties,
) => {
  const {
    allowPublicEventCreation,
    apiBaseUrl,
    cameraApiSettings,
    currentSelectedFileId = '',
    onImageSelect,
    onUploadError,
    onUploadSuccess,
    publicImageFileIds,
    serverValidation,
    submitAction,
    tasting,
    uploadUrl = '',
    uploadedFileId = '',
    validationSchema,
  } = props;
  const defaultDatetime = toNextHour(new Date()).toString();
  const intl = useIntl();
  const form = useForm<Tasting>({
    defaultValues: {
      address1: '',
      address2: '',
      city: '',
      name: '',
      dateTime: defaultDatetime,
      time: defaultDatetime,
      description: '',
      maxAttendeesCount: null,
      privacy: 'false',
      roomDescription: '',
      showAttendees: true,
      canAttendeesManageWine: false,
      ownerId: null,
      location: '',
      locationType: ETastingLocationType.Virtual,
      stateProvince: '',
      zipCode: '',
    },
    resolver: yupResolver(validationSchema),
  });
  const { setValue } = form;

  const handleUploadSuccess = (ids: Array<string>): void => {
    if (onUploadSuccess) {
      onUploadSuccess(ids);
    }
  };
  useEffect(() => {
    handleServerHookForm(serverValidation, form.setError);
  }, [serverValidation, form.setError]);

  useEffect(() => {
    if (tasting) {
      handleFormDataReset(allowPublicEventCreation ? tasting : { ...tasting, privacy: 'false' }, form.reset);
    }
  }, [allowPublicEventCreation, tasting, form.reset]);

  const formValues = form.getValues();
  const isVirtual = form.watch('locationType') === ETastingLocationType.Virtual;
  return (
    <Form
      id="tastingForm"
      onSubmit={form.handleSubmit(submitAction)}
      noValidate
    >
      <IonList lines="full">
        <HookInput
          form={form}
          isRequired
          labelText="tastingForm.labels.name"
          name="name"
        >
          <IonInput data-cy="tastingName"/>
        </HookInput>
        <HookInput
          form={form}
          labelPosition="fixed"
          labelText="tastingForm.labels.dateTime"
          name="dateTime"
        >
          <IonDatetime
            displayFormat="MMM D, YYYY"
            data-cy="tastingDate"
            max="2050"
            min="2000"
          />
        </HookInput>
        <HookInput
          form={form}
          labelPosition="fixed"
          labelText="tastingForm.labels.time"
          name="time"
        >
          <IonDatetime
            displayFormat="h:mm a"
            minuteValues="0,15,30,45"
            data-cy="tastingTime"
          />
        </HookInput>
        <HookInput
          form={form}
          labelPosition="stacked"
          labelText="tastingForm.labels.privacy"
          name="privacy"
        >
          <IonRadioGroup
            style={{
              marginTop: isPlatform('android') ? '6px' : '0', // This is here to make the "Location" label not cut off
              maxWidth: '100%'
            }}
          >
            <Box ml={-2}>
              <IonItem lines="none">
                <RadioButtonLabel
                  caption="tastingForm.labels.privateCaption"
                  id="tastingForm.labels.private"
                />
                <IonRadio
                  slot="start"
                  value="false"
                />
              </IonItem>
              <IonItem lines="none">
                <RadioButtonLabel
                  caption={allowPublicEventCreation ? 'tastingForm.labels.publicCaption' : 'tastingForm.labels.publicCaptionNotAllowed'}
                  color={!allowPublicEventCreation ? 'var(--ion-color-dark)' :  'var(--ion-color-medium)'}
                  id="tastingForm.labels.public"
                />
                <IonRadio
                  disabled={!allowPublicEventCreation}
                  slot="start"
                  value="true"
                />
              </IonItem>
            </Box>
          </IonRadioGroup>
        </HookInput>
        <HookInput
          form={form}
          labelPosition="stacked"
          labelText="tastingForm.labels.locationType"
          name="locationType"
        >
          <IonRadioGroup
            style={{
              marginTop: isPlatform('android') ? '6px' : '0', // This is here to make the "Location" label not cut off
            }}
          >
            <Box ml={-2}>
              <IonItem lines="none">
                <IonLabel>
                  <Flex
                    alignItems="center"
                    data-cy="virtualTastingLocation"
                  >
                    <Paragraph pl={2}>
                      <FormattedMessage id="tastingForm.labels.virtualTasting"/>
                    </Paragraph>
                  </Flex>
                </IonLabel>
                <IonRadio
                  slot="start"
                  value="Virtual"
                />
              </IonItem>
              <IonItem lines="none">
                <IonLabel>
                  <Flex
                    alignItems="center"
                    data-cy="inPersonTastingLocation"
                  >
                    <Paragraph pl={2}>
                      <FormattedMessage id="tastingForm.labels.inPersonTasting"/>
                    </Paragraph>
                  </Flex>
                </IonLabel>
                <IonRadio
                  slot="start"
                  value="InPerson"
                />
              </IonItem>
            </Box>
          </IonRadioGroup>
        </HookInput>
        <Hideable hide={isVirtual}>
          <IonItem>
            <TextareaStyles isTextarea>
              <HookInput form={form} labelPosition="stacked" labelText="tastingForm.labels.addressLine1" lines="none" name="address1">
                <IonInput
                  data-cy="address1"
                  placeholder={intl.formatMessage({ id: 'tastingForm.labels.addressLine1' })}
                />
              </HookInput>
              <HookInput form={form} labelPosition="stacked" labelText="tastingForm.labels.addressLine2" lines="none" name="address2">
                <IonInput
                  data-cy="address2"
                  placeholder={intl.formatMessage({ id: 'tastingForm.labels.addressLine2' })}
                />
              </HookInput>
              <HookInput form={form} labelPosition="stacked" labelText="tastingForm.labels.city" lines="none" name="city">
                <IonInput
                  data-cy="city"
                  placeholder={intl.formatMessage({ id: 'tastingForm.labels.city' })}
                />
              </HookInput>
              <HookInput form={form} labelPosition="stacked" labelText="tastingForm.labels.state" lines="none" name="stateProvince">
                <IonInput
                  data-cy="stateProvince"
                  placeholder={intl.formatMessage({ id: 'tastingForm.labels.state' })}
                />
              </HookInput>
              <HookInput form={form} labelPosition="stacked" labelText="tastingForm.labels.zipCode" lines="none" name="zipCode">
                <IonInput
                  data-cy="zipCode"
                  placeholder={intl.formatMessage({ id: 'tastingForm.labels.zipCode' })}
                />
              </HookInput>
            </TextareaStyles>
          </IonItem>
        </Hideable>
        <HookInput
          form={form}
          labelPosition="fixed"
          labelStyle={{ minWidth: '140px' }}
          labelText="tastingForm.labels.maxAttendeesCount"
          name="maxAttendeesCount"
          placeholder={intl.formatMessage({ id: 'tastingForm.placeholders.maxAttendeesCount' })}
        >
          <IonInput data-cy="maxAttendeesCount" type="number" />
        </HookInput>
        <HookInput
          form={form}
          labelPosition="default"
          labelText="tastingForm.labels.attendeeVisibility"
          name="showAttendees"
        >
          <IonToggle
            checked={formValues?.showAttendees}
            onClick={(): void => setValue('showAttendees', !formValues.showAttendees)}
          />
        </HookInput>
        <HookInput
          form={form}
          labelPosition="default"
          labelText="tastingForm.labels.attendeeManageWine"
          name="canAttendeesManageWine"
        >
          <IonToggle
            checked={formValues?.canAttendeesManageWine}
            onClick={(): void => setValue('canAttendeesManageWine', !formValues.canAttendeesManageWine)}
          />
        </HookInput>
        <IonItem className={isPlatform('ios') ? 'ion-item-no-min-height' : ''} lines="none">
          <IonLabel>
            <FormattedMessage id="tastingForm.labels.imageHeader"/>
            <Box mb={isPlatform('android') && 2} mt={isPlatform('android') && -2}>
              <ImageLabel/>
            </Box>
          </IonLabel>
        </IonItem>
        <Flex overflow="auto" p={1}>
          {useMemo(() => publicImageFileIds && publicImageFileIds.map(publicFileId =>
            <Box
              backgroundImage={`url(${getImageUrl(apiBaseUrl || '', publicFileId)})`}
              backgroundSize="cover"
              borderRadius={4}
              boxShadow={publicFileId === currentSelectedFileId && `
                -3px -3px 0 var(--ion-color-primary),
                3px 3px 0 var(--ion-color-primary),
                3px -3px 0 var(--ion-color-primary),
                -3px 3px 0 var(--ion-color-primary)
              `}
              key={`public-file-id-${publicFileId}`}
              height="15.75vw"
              mx={1}
              onClick={(): void => {
                onImageSelect && onImageSelect(publicFileId);
              }}
              width="28.57vw"
            />
          ), [apiBaseUrl, currentSelectedFileId, onImageSelect, publicImageFileIds])}
        </Flex>
        <Divider/>
        <IonItem>
          <Flex
            alignItems="center"
            justifyContent="center"
            mb={1}
            width={1}
          >
            <Hideable
              hide={!uploadedFileId || !!(publicImageFileIds && publicImageFileIds.filter(id => id === uploadedFileId).length > 0)}>
              <Image
                alt="this is descriptive text"
                border={uploadedFileId === currentSelectedFileId ? '4px solid var(--ion-color-primary)' : ''}
                borderRadius={4}
                height="22.5vw"
                mx={1}
                objectFit="cover"
                onClick={(): void => {
                  onImageSelect && onImageSelect(uploadedFileId);
                }}
                src={getImageUrl(apiBaseUrl || '', uploadedFileId || '')}
                width={2 / 5}
              />
            </Hideable>
            <GrapeUploadImageButton
              imageName="tastingImage"
              onUploadError={onUploadError}
              onUploadSuccess={handleUploadSuccess}
              options={{
                quality: cameraApiSettings?.imageUploadQuality,
                source: CameraSource.Prompt,
                width: cameraApiSettings?.uploadImageTargetWidth,
              } as ImageOptions}
              uploadUrl={uploadUrl}
            >
              <>
                <Box mr={1}>
                  <FontAwesomeIcon icon={faCamera}/>
                </Box>
                <FormattedMessage id={`general.buttons.${uploadedFileId ? 'changePhoto' : 'uploadPhoto'}`}/>
              </>
            </GrapeUploadImageButton>
          </Flex>
        </IonItem>
        <HookInput
          form={form}
          isTextarea
          labelText="tastingForm.labels.description"
          name="description"
        >
          <IonTextarea
            data-cy="tastingDescription"
          />
        </HookInput>
        <HookInput
          form={form}
          isTextarea
          labelText="tastingForm.labels.roomDescription"
          name="roomDescription"
        >
          <IonTextarea
            data-cy="tastingRoomDescription"
          />
        </HookInput>
      </IonList>
    </Form>
  );
};

export default CreateTastingForm;
