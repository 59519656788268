import * as yup from 'yup';

export const schema = yup.object().shape({
  newPassword: yup.string()
    .required('general.assistiveText.required')
    .test(
      'passwordsMustMatch',
      'Passwords do not match.',
      function (): boolean {
        return this.parent.newPassword === this.parent.passwordConfirm;
      }
    ),
  passwordConfirm: yup.string()
    .required('general.assistiveText.required')
    .test(
      'passwordsMustMatch',
      'Passwords do not match.',
      function (): boolean {
        return this.parent.newPassword === this.parent.passwordConfirm;
      }
    )
});
