import { ApiCall, CustomHookProps, useBasicMutation } from 'napa-react-core';
import { UseMutationResult } from 'react-query/types/react/types';
import { TastingAttendeeStatus } from '../../store/tastingAttendees';

export default function usePostTastingAttendeeStatus(
  tastingId: string | undefined,
  props?: CustomHookProps<TastingAttendeeStatus>,
): UseMutationResult<TastingAttendeeStatus, ApiCall<TastingAttendeeStatus>> {
  return useBasicMutation<TastingAttendeeStatus>({
    apiRoute: `tastings/${tastingId}/tastingAttendeeStatus`,
    httpMethod: 'POST',
    isFormData: false,
    params: props,
  });
}
