import { IonTitle as IonTitleBase } from '@ionic/react';
import styled from 'styled-components';
import {
  space,
  typography
} from 'styled-system';

const IonTitle = styled(IonTitleBase)`
  ${space}
  ${typography}
`;

IonTitle.defaultProps = {
  fontFamily: 'var(--title-font-family)',
};

export { IonTitle };
