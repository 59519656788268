import { IonCheckbox, IonItem } from '@ionic/react';
import { Heading } from 'components/baseElements/typography';
import { IonLabel } from 'components/ionicComponents';
import React, { useState } from 'react';
import { Box } from '../../baseElements/grid';
import { Hideable } from '../../baseElements/hideable';
import { Paragraph } from '../../baseElements/typography';

interface AttendeeSearchProps {
  displayName?: string;
  emailAddress?: string;
  organizationName?: string;
  updateInvite: (invited: boolean) => void;
}

export default function PhoneContact(props: AttendeeSearchProps): JSX.Element {
  const {
    displayName,
    emailAddress,
    organizationName,
    updateInvite,
  } = props;
  const [checked, setChecked] = useState(false);
  const handleInvite = (nextChecked: boolean): void => {
    setChecked(nextChecked);
    updateInvite(nextChecked);
  };
  return (
    <IonItem onClick={(): void => handleInvite(!checked)}>
      <Box slot="start">
        <IonCheckbox checked={checked}/>
      </Box>
      <IonLabel>
        <Paragraph>
          {displayName}<Hideable hide={!organizationName}>&nbsp;|&nbsp;{organizationName}</Hideable>
        </Paragraph>
        <Heading
          fontFamily="var(--ion-font-family)"
          headingSize="lg">{emailAddress}
        </Heading>
      </IonLabel>
    </IonItem>
  );
}
