import { ApiCall, CustomHookProps, useBasicQuery } from 'napa-react-core';
import { QueryObserverResult } from 'react-query';
import { TastingWithAttendee } from '../../store/tastings';

export default function useTastingWithAttendee(
  tastingId: string | undefined,
  props?: CustomHookProps<TastingWithAttendee>,
): QueryObserverResult<TastingWithAttendee, ApiCall<TastingWithAttendee>> {
  return useBasicQuery<TastingWithAttendee>({
    apiRoute: `tastings/${tastingId}`,
    httpMethod: 'GET',
    isFormData: false,
    body: undefined,
    queryId: 'useTasting',
    params: props,
  });
}
