import { IonLabel as IonLabelBase } from '@ionic/react';
import styled from 'styled-components';
import {
  layout,
  space,
  system,
  typography,
} from 'styled-system';
import { ellipsis, transient } from 'utils/styledHelpers';

const IonLabel = styled(IonLabelBase)`
  ${transient(layout)}
  ${transient(space)}
  ${transient(typography)}
  ${ellipsis}
  ${system({ whiteSpace: true })}
`;

export { IonLabel };
