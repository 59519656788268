import { Tasting } from 'store/tastings';
import { WineScore } from 'store/wineScores';
import { WineRank } from '../wineRanks';

export enum WineStatus {
  Approved = 'Approved',
  Proposed = 'Proposed',
  Rejected = 'Rejected',
}

export interface Flight {
  areRanksPublished?: boolean;
  id?: string | null;
  createdAt?: string;
  name: string;
  sequence: number;
  wines?: Array<Wine>;
}

export interface WineRanking {
  userRank: WineRank;
  totalPoints?: number;
  overallRank?: number;
  flightRank?: number;
}

export interface WineScoring {
  userScore: WineScore;
  averageRating?: number;
}

export interface Wine {
  id?: string;
  approvalStatus: WineStatus;
  contributedBy?: string;
  bottleSize?: string;
  createdAt?: string;
  designation?: string;
  name: string;
  vintage: string;
  producer: string;
  flightId?: string | null;
  flight?: any;
  ranking: WineRanking;
  sequence: number;
  tasting?: Tasting;
  tastingId?: string;
  scoring: WineScoring;
  areScoresPublished: boolean;
  areRanksPublished: boolean;
  imageFileId: string;
  masterWineId?: string;
  wineFlightRanking: WineRanking;
}

export type WineTastingsState = any;
