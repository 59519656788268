import { IonIcon, IonItem, IonReorder, IonToggle } from '@ionic/react';
import { Box, Flex } from 'components/baseElements/grid';
import { Hideable } from 'components/baseElements/hideable';
import { Paragraph } from 'components/baseElements/typography';
import { IonButton, IonLabel, IonText } from 'components/ionicComponents';
import { TastingResultViewState } from 'containers/tastings/ViewTastingResultsContainer';
import dayjs from 'dayjs';
import { ellipsisVertical, trash } from 'ionicons/icons';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Wine } from 'store/wines';
import { getWinePoints } from 'store/wines/utils';
import { FormattedWine } from 'utils/dataFormat/FormattedWine';
import { MasterWine } from '../../../store/masterWines';
import { getUserWineScoreRating } from '../../../store/wineScores';
import RankingIcon from '../../baseElements/Ranking/RankingIcon';
import {
  getWineRankColorFromPoints,
  getWineRankColorFromPointsForResults,
  getWineRankFromPoints,
  getWineRankFromPointsForResults,
} from '../../baseElements/Ranking/utils';
import WineAvatar from '../WineAvatar';
import WineRankingButton from '../WineRankingButton';
import WineRating, { WineRatingProperties } from '../WineRating';
import WineThumbnail from '../WineThumbnail';
import {
  getPointsText,
  getWineNameEllipsis,
  hideWineRatingLabel,
  showWineListItemIonAvatar,
  showWineListItemPublishButton,
  showWineListItemRanks,
  showWineListItemScoreAverages,
  showWineListItemScores,
} from './utils';

export enum WineListItemType {
  Default,
  ActionMenu,
  DragOption,
  RankWinesModal,
  TastingResultsWineRankAsAttendee,
  TastingResultsWineRankAsHost,
  TastingResultsWineScoreAsAttendee,
  TastingResultsWineScoreAsHost,
  TastingRoom,
  UserRating,
}

export interface WineListItemProps {
  flightId?: string;
  flightRank?: number;
  overallRank?: number;
  onEllipsisWineClick?: (wine: Wine | MasterWine) => void;
  onPublishWineClick?: (wine: Wine | MasterWine, publish: boolean) => void;
  onRankWineClick?: (wine: Wine | MasterWine, points: number) => void;
  onRateWineClick?: (wine: Wine | MasterWine) => void;
  onScoreWineClick?: (wine: Wine | MasterWine) => void;
  onWineClick?: (wine: Wine | MasterWine) => void;
  onWineRankAllocation?: (wine: Wine, point: number) => number;
  onWineRemoveClick?: (wine: Wine | MasterWine) => void;
  type: WineListItemType;
  wine: Wine | MasterWine;
  // TODO: See if we need below
  avgWineRatingProps?: WineRatingProperties;
  userWineRatingProps?: WineRatingProperties;
  wineRatingProps?: WineRatingProperties;
  tastingResultViewState?: TastingResultViewState;
  showWineEditEllipsis?: boolean;
}

/**
 * Wine List Item
 * --------------
 *
 * <IonItem>
 *   <LeftSide slot="start" />
 *   <IonLabel>
 *     <Flex flexDirection="column" width={1}>
 *       <IonText fontWeight="bold">
 *         <PrimaryText />
 *       </IonText>
 *       <SecondaryComponent />
 *       <TertiaryComponent />
 *     </Flex>
 *   </IonLabel>
 *   <RightSide slot="end" />
 * </IonItem>
 *
 * @param props
 * @constructor
 */
export function WineListItem(props: WineListItemProps): JSX.Element {
  const {
    avgWineRatingProps,
    flightId,
    flightRank,
    overallRank,
    onEllipsisWineClick,
    onRankWineClick,
    onRateWineClick,
    onPublishWineClick,
    onScoreWineClick,
    onWineClick,
    onWineRemoveClick,
    onWineRankAllocation,
    showWineEditEllipsis,
    type = WineListItemType.Default,
    tastingResultViewState,
    userWineRatingProps,
    wineRatingProps,
    wine,
  } = props;
  const hasDesignation = !!wine?.designation;
  const flightRanking = (wine as Wine)?.wineFlightRanking?.userRank?.points;
  const flightRankTotal = (wine as Wine)?.wineFlightRanking?.totalPoints;
  const flightRankVisible = (wine as Wine)?.flight?.areRanksPublished || type === WineListItemType.TastingResultsWineRankAsHost;
  const overallRanking = (wine as Wine)?.ranking?.userRank?.points;
  const overallRankTotal = (wine as Wine)?.ranking?.totalPoints;
  const overallRankVisible = (wine as Wine)?.tasting?.areRanksPublished || type === WineListItemType.TastingResultsWineRankAsHost;
  const hasRanking = !!flightRanking || !!overallRanking;
  return (
    <IonItem lines="inset">
      {/* Left Side Start */}
      <Hideable hide={!showWineListItemIonAvatar(type)}>
        <WineAvatar wine={wine} onClick={(): void => onWineClick && onWineClick(wine)} />
      </Hideable>
      <Hideable hide={showWineListItemIonAvatar(type)}>
        <WineThumbnail wine={wine} onClick={(): void => onWineClick && onWineClick(wine)} />
      </Hideable>
      {/* Left Side End */}
      {/* Center Side Start */}
      <IonLabel>
        <Flex flexDirection="column" width={1} pr={3}>
          {/* Top Center Start */}
          <IonText
            ellipsis={getWineNameEllipsis(type, hasDesignation, hasRanking)}
            fontWeight="bold"
            onClick={(): void => onWineClick && onWineClick(wine)}>
            <FormattedWine wine={wine} />
          </IonText>
          {/* Top Center End */}
          {/* Bottom Center Start */}
          <Hideable hide={!hasDesignation}>
            <IonText ellipsis onClick={(): void => onWineClick && onWineClick(wine)}>
              {wine.designation}
            </IonText>
          </Hideable>
          <Hideable hide={!showWineListItemPublishButton(type)}>
            <Flex alignItems="center">
              <Box pr={2}>
                <FormattedMessage id="wineListItem.text.publish" />
              </Box>
              <IonToggle
                checked={!!(wine as Wine)?.areScoresPublished}
                color="success"
                onIonChange={(e: any): void => {
                  onPublishWineClick && onPublishWineClick(wine, e.detail.checked);
                }}
              />
            </Flex>
          </Hideable>
          <Hideable hide={type !== WineListItemType.TastingRoom || !hasRanking}>
            <Flex flexDirection="row" width={1} mt={2} style={{ lineHeight: '30px' }}
              onClick={(): void => onWineClick && onWineClick(wine)}>
              <Hideable hide={!flightRanking}>
                <FormattedMessage id="wineListItem.text.flight" />&nbsp;
                <RankingIcon
                  color={getWineRankColorFromPoints(flightRanking)}
                  rank={getWineRankFromPoints(flightRanking)}
                />&nbsp;&nbsp;&nbsp;
              </Hideable>
              <Hideable hide={!overallRanking}>
                <FormattedMessage id="wineListItem.text.overall" />&nbsp;
                <RankingIcon
                  color={getWineRankColorFromPoints(overallRanking)}
                  rank={getWineRankFromPoints(overallRanking)}
                />
              </Hideable>
            </Flex>
          </Hideable>
          <Hideable hide={type !== WineListItemType.UserRating}>
            <Paragraph onClick={(): void => onWineClick && onWineClick(wine)}>
              <IonText>
                <FormattedMessage id="wineListItem.text.ratedOn" />&nbsp;
                {dayjs((wine as Wine)?.scoring?.userScore?.updatedAt).format('M/D/YY')}
              </IonText>
            </Paragraph>
          </Hideable >
          {/* Bottom Center End */}
        </Flex >
      </IonLabel >
      {/* Center Side End */}
      {/* Right Side Start */}
      <Hideable hide={type !== WineListItemType.UserRating && type !== WineListItemType.ActionMenu}>
        <IonButton
          fill="clear"
          onClick={(): void => onWineRemoveClick && onWineRemoveClick(wine)}
          slot="end"
        >
          <IonIcon color="dark" icon={trash}/>
        </IonButton>
      </Hideable>
      <Hideable hide={!showWineEditEllipsis || type !== WineListItemType.ActionMenu}>
        <IonButton
          fill="clear"
          onClick={(): void => onEllipsisWineClick && onEllipsisWineClick(wine)}
          slot="end"
        >
          <IonIcon color="dark" icon={ellipsisVertical}/>
        </IonButton>
      </Hideable>
      <Hideable hide={type !== WineListItemType.DragOption}>
        <IonReorder slot="end" />
      </Hideable>
      <Hideable hide={type !== WineListItemType.RankWinesModal}>
        <Flex onClick={(): void => onRateWineClick && onRateWineClick(wine)}>
          <WineRankingButton
            points={getWinePoints((wine as Wine), flightId)}
            pointChange={(points: number): void => onRankWineClick && onRankWineClick(wine, points)}
            onWineRankAllocation={onWineRankAllocation}
            wine={wine as Wine}
          />
        </Flex>
      </Hideable>
      <Hideable hide={!showWineListItemScores(type)}>
        <Flex marginRight="8px" onClick={(): void => onScoreWineClick && onScoreWineClick(wine)}>
          <Hideable hide={!showWineListItemScoreAverages(type)}>
            <WineRating
              color="secondary"
              mr={2}
              hideLabel={hideWineRatingLabel(type)}
              score={(wine as Wine)?.scoring?.averageRating}
              {...wineRatingProps}
              {...avgWineRatingProps}
            >
              <FormattedMessage id="wineTastingList.text.avgScore" />
            </WineRating>
          </Hideable>
          <WineRating
            hideLabel={hideWineRatingLabel(type)}
            score={getUserWineScoreRating(wine as Wine)}
            {...wineRatingProps}
            {...userWineRatingProps}
          />
        </Flex>
      </Hideable >
      <Hideable hide={!showWineListItemRanks(type)}>
        <Flex marginRight="8px" onClick={(): void => onScoreWineClick && onScoreWineClick(wine)}>
          <Flex flexDirection="row" width={1} mt={2} style={{ lineHeight: '30px' }}>
            <Hideable hide={tastingResultViewState !== TastingResultViewState.FlightRanking}>
              <Flex flexDirection="column" textAlign="center" width="50px">
                <Box marginLeft="9px">
                  <RankingIcon
                    color={getWineRankColorFromPointsForResults(flightRank, flightRankTotal, flightRankVisible)}
                    rank={getWineRankFromPointsForResults(flightRank, flightRankTotal, flightRankVisible)}
                  />
                </Box>
                {getPointsText(flightRankTotal, flightRankVisible)}
              </Flex>
              <RankingIcon
                color={getWineRankColorFromPoints(flightRanking)}
                rank={getWineRankFromPoints(flightRanking)}
              />
            </Hideable>
            <Hideable hide={tastingResultViewState !== TastingResultViewState.OverallRanking}>
              <Flex flexDirection="column" textAlign="center" width="50px">
                <Box marginLeft="9px">
                  <RankingIcon
                    color={getWineRankColorFromPointsForResults(overallRank, overallRankTotal, overallRankVisible)}
                    rank={getWineRankFromPointsForResults(overallRank, overallRankTotal, overallRankVisible)}
                  />
                </Box>
                {getPointsText(overallRankTotal, overallRankVisible)}
              </Flex>
              <RankingIcon
                color={getWineRankColorFromPoints(overallRanking)}
                rank={getWineRankFromPoints(overallRanking)}
              />
            </Hideable>
          </Flex>
        </Flex>
      </Hideable>
      {/* Right Side End */}
    </IonItem >
  );
}
