import { ApiCall, CustomHookProps, useBasicMutation } from 'napa-react-core';
import { UseMutationResult } from 'react-query/types/react/types';
import { User } from '../../store/users';

export default function usePostUser(
  props?: CustomHookProps<User>,
): UseMutationResult<User, ApiCall<User>> {
  return useBasicMutation<User>({
    apiRoute: 'users',
    httpMethod: 'POST',
    isFormData: false,
    params: props,
  });
}
