import { ApiCall, CustomHookProps, useBasicMutation } from 'napa-react-core';
import { UseMutationResult } from 'react-query/types/react/types';
import { GeoLocation } from '../../store/geoLocation';

export default function usePostMyGeoCoordinate(
  props?: CustomHookProps<GeoLocation>,
): UseMutationResult<GeoLocation, ApiCall<GeoLocation>> {
  return useBasicMutation<GeoLocation>({
    apiRoute: 'tastings/myLocation',
    httpMethod: 'POST',
    isFormData: false,
    params: props,
  });
}
