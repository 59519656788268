import { IonPage } from '@ionic/react';
import ViewAttendeeListContainer from 'containers/attendees/ViewAttendeeListContainer';
import React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ViewAttendeesPage = (props: any): any => {
  const { tastingId } = props.match.params;

  return (
    <IonPage>
      <ViewAttendeeListContainer tastingId={tastingId}/>
    </IonPage>
  );
}

export default ViewAttendeesPage;
