import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryStringHelper from 'query-string';
import jwtDecode from 'jwt-decode';
import { clearApiCallStatus, GetLoadingStatus } from 'utils/api';
import { RootState } from 'store';
import { makeApiCall } from 'utils/api';
import { schema } from './validation';
import { toRequestInsertTastingDto, toRequestInsertTastingAttendeeDto } from 'store/tastings';
import TastingForm from 'components/tastings/TastingForm';
import { TastingAttendee } from 'store/tastingAttendees';
import { LoadingStatus } from 'utils/formValidation';
import { guiStateUpdateToast } from 'store/gui/guiStateUpdateToast';
import { BrowseFilesResponseData } from 'store/files';
import { AppSettingsState } from 'store/appSettings';

interface CreateTastingContainerProps {
  loadingStatus?: LoadingStatus;
  setLoadingStatus?: (status: LoadingStatus) => void;
  successHandler?: (tastingId: string) => any;
}

const CreateTastingContainer: React.FC<CreateTastingContainerProps> = (props: CreateTastingContainerProps) => {
  const {
    loadingStatus,
    setLoadingStatus,
    successHandler,
  } = props;
  // CONSTANT DECLARATION
  const BROWSE_PUBLIC_IMAGE_FILES_KEY = 'browsePublicImageFiles';
  const CREATE_TASTING_KEY = 'createTasting';
  const CREATE_TASTING_ATTENDEE_KEY = 'createTastingAttendee';
  const dispatch = useDispatch();

  // GLOBAL (REDUX) STATE SETUP
  const auth = useSelector((state: RootState) => state.authorization);
  const tokenData = jwtDecode(auth.token) as any;
  const allowPublicEventCreation = tokenData['claim/AllowPublicEventCreation'] === 'true';
  const { apiBaseUrl, cameraApiSettings } = useSelector((state: RootState): AppSettingsState => state.appSettings);
  const createTastingApiCall = useSelector((state: RootState) =>
    state.api.callStatuses.find(e => e.id === CREATE_TASTING_KEY),
  );
  const [fileId, setFileId] = useState<string>('');
  const [uploadedFileId, setUploadedFileId] = useState<string>('');
  const [publicImageFileIds, setPublicImageFileIds] = useState<Array<string>>([]);

  // LOCAL FUNCTIONS
  const fetchPublicImageFiles = useCallback(async (): Promise<void> => {
    try {
      const queryParams = queryStringHelper.stringify({
        isFilePublic: true,
      })
      const result = await makeApiCall({
        authToken: auth.token,
        dispatch: dispatch,
        callId: BROWSE_PUBLIC_IMAGE_FILES_KEY,
        request: {
          url: `${apiBaseUrl}/files/?${queryParams}`,
          httpMethod: 'GET',
        },
      }) as BrowseFilesResponseData;
      if (result?.items) {
        setPublicImageFileIds(result?.items);
        if (result?.items.length > 0) {
          setFileId(result?.items[0]);
        }
      }
    }
    catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [apiBaseUrl, auth.token, dispatch]);

  const handleTastingAttendeeSubmitAction = useCallback(async (data: any): Promise<void> => {
    const tastingId = data.id;
    const tastingAttendee = {} as TastingAttendee;
    try {
      await makeApiCall({
        authToken: auth.token,
        dispatch: dispatch,
        callId: CREATE_TASTING_ATTENDEE_KEY,
        request: {
          url: `${apiBaseUrl}/tastings/${tastingId}/tastingAttendee`,
          httpMethod: 'POST',
          body: toRequestInsertTastingAttendeeDto(tastingAttendee, auth, true)
        }
      });
      if (successHandler) {
        successHandler(tastingId);
      }
    }
    catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [apiBaseUrl, auth, dispatch, successHandler]);

  const handleTastingSubmit = useCallback(async (data: any): Promise<void> => {
    try {
      if (!data || loadingStatus === LoadingStatus.Loading) {
        return;
      }
      if (setLoadingStatus) {
        setLoadingStatus(LoadingStatus.Loading);
      }
      const tastingResponse = await makeApiCall({
        authToken: auth.token,
        dispatch: dispatch,
        callId: CREATE_TASTING_KEY,
        showSuccessMessage: false,
        request: {
          url: `${apiBaseUrl}/tastings`,
          httpMethod: 'POST',
          body: toRequestInsertTastingDto({ ...data, tastingImageFileId: fileId }, auth),
        },
      });
      await handleTastingAttendeeSubmitAction(tastingResponse)
    }
    catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    if (setLoadingStatus) {
      setLoadingStatus(LoadingStatus.Complete);
    }
  }, [apiBaseUrl, auth, dispatch, fileId, handleTastingAttendeeSubmitAction, loadingStatus, setLoadingStatus]);

  const handleImageSelect = (id: string): void => {
    setFileId(id);
  }

  const onUploadError = (): void => {
    dispatch(
      guiStateUpdateToast({
        message: 'api.defaults.failureMessage',
      })
    );
  };
  const onUploadSuccess = (ids: Array<string>): void => {
    setFileId(ids[0]);
    setUploadedFileId(ids[0]);
  }

  // EFFECTS SETUP
  useEffect(() => {
    clearApiCallStatus(dispatch, CREATE_TASTING_KEY);
    clearApiCallStatus(dispatch, CREATE_TASTING_ATTENDEE_KEY);
    clearApiCallStatus(dispatch, BROWSE_PUBLIC_IMAGE_FILES_KEY);
    fetchPublicImageFiles();
  }, [dispatch, fetchPublicImageFiles]);

  return (
    <TastingForm
      allowPublicEventCreation={allowPublicEventCreation}
      apiBaseUrl={apiBaseUrl}
      createTastingLoadingStatus={GetLoadingStatus(createTastingApiCall?.status)}
      currentSelectedFileId={fileId}
      cameraApiSettings={cameraApiSettings}
      onImageSelect={handleImageSelect}
      onUploadError={onUploadError}
      onUploadSuccess={onUploadSuccess}
      publicImageFileIds={publicImageFileIds}
      submitAction={handleTastingSubmit}
      serverValidation={createTastingApiCall?.validationErrors}
      uploadedFileId={uploadedFileId}
      uploadUrl={`${apiBaseUrl}/files/upload`}
      validationSchema={schema}
    />
  );
}

export default CreateTastingContainer;
