import * as React from 'react';
import { IonSpinner } from '@ionic/react';
import { IonButton } from 'components/ionicComponents';
import { Loadable } from 'components/baseElements/loadable';
import { Box, Flex } from 'components/baseElements/grid';

interface SubmitButtonProps {
  children?: React.ReactNode;
  disabled?: boolean;
  expand?: 'block' | 'full' | null;
  isDisabledOnLoad?: boolean;
  isLoading: boolean;
  loadingElement?: any;
  type?: 'button' | 'reset' | 'submit';
}

const SubmitButton: any = (props: SubmitButtonProps) => {
  const {
    children,
    disabled,
    expand = 'block',
    isDisabledOnLoad = true,
    isLoading,
    loadingElement = (
      <Box mr={2}>
        <IonSpinner />
      </Box>
    ),
    type = 'submit',
  } = props;

  return (
    <IonButton
      disabled={isDisabledOnLoad ? isLoading : disabled}
      expand={expand}
      type={type}
      {...props}
    >
      <Flex alignItems="center">
        <Loadable isLoading={isLoading} loadingElement={loadingElement} />
        {children}
      </Flex>
    </IonButton>
  );
};

export { SubmitButton };
