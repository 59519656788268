import React from 'react';
import { Flex } from 'components/baseElements/grid';
import { IonLabel, IonRange } from 'components/ionicComponents';
import { IonItem } from '@ionic/react';
import HookInput from 'components/baseElements/formControls/HookInput';
import { FormattedMessage } from 'react-intl';
import WineRating from '../WineRating';

interface WineRatingFormProperties {
  form: any;
  rating: number;
  setRating: (number) => any;
}

export default function WineRatingForm(props: WineRatingFormProperties): JSX.Element {
  const {
    form,
    rating,
    setRating
  } = props;

  return (
    <>
      <IonItem lines="none">
        <IonLabel>
          <FormattedMessage id="scoreWine.ratingStep.labels.rating" />
        </IonLabel>
      </IonItem>
      <Flex
        alignItems="center"  
        justifyContent="center"
        px={4}
      >
        <WineRating
          hideLabel
          score={rating}
          size={56}
        />
      </Flex>
      <HookInput
        form={form}
        lines="none"
        name="rating"
      >
        <IonRange
          className="rating-range"
          color="primary"
          max={100}
          min={50}
          onIonChange={(e: any): void => setRating(e.detail.value as number)}
          snaps={true}
          step={1}
          ticks={false}
        >
          <IonLabel slot="start">
            <FormattedMessage id="scoreWine.ratingStep.labels.ratingStart" />
          </IonLabel>
          <IonLabel slot="end">
            <FormattedMessage id="scoreWine.ratingStep.labels.ratingEnd" />
          </IonLabel>
        </IonRange>
      </HookInput>
    </>
  )
}