import styled from 'styled-components';
import {
  layout,
  space,
  system,
} from 'styled-system';
import { ReactComponent as WineGlasses } from './wine-glasses.svg';
import { transient } from 'utils/styledHelpers';

const EmptyStateImage = styled(WineGlasses)`
${system({ opacity: true })}
${transient(layout)}
  ${transient(space)}
  .gold,
  .gold-glass,
  .purple,
  .purple-glass {
    fill: var(--ion-text-color);
  }
`;

EmptyStateImage.defaultProps = {
  $display: 'block',
  mx: 'auto',
  opacity: 0.21,
  width: 63,
};

export { EmptyStateImage };
