import { AuthorizationState } from './types';
import { createSubReducer, Reducer } from 'utils/store';
import {
  AUTHORIZATION_SET_TOKEN,
  AuthorizationSetTokenReducer,
} from './actions/authorizationSetToken';

const initialState = {
  token: undefined,
  tokenData: undefined,
  tokenServerExpiration: undefined,
  tokenServerExpirationRefreshThreshold: undefined,
  tokenClientExpiration: undefined,
  tokenClientExpirationWarningThreshold: undefined,
};

const reducers: Array<Reducer<AuthorizationState>> = [
  {
    type: AUTHORIZATION_SET_TOKEN,
    reducer: AuthorizationSetTokenReducer,
  },
];

export const authorizationReducer = createSubReducer<AuthorizationState>(
  initialState,
  reducers,
);
