import { ApiCall, CustomHookProps, PaginatedData, useBasicQuery } from 'napa-react-core';
import { QueryObserverResult } from 'react-query';
import { Flight } from '../../store/wines';

export default function useTastingFlights(
  tastingId: string | undefined,
  props?: CustomHookProps<PaginatedData<Flight>>,
): QueryObserverResult<PaginatedData<Flight>, ApiCall<Flight[]>> {
  return useBasicQuery<PaginatedData<Flight>>({
    apiRoute: `tastings/${tastingId}/flights`,
    httpMethod: 'GET',
    isFormData: false,
    body: undefined,
    queryId: 'useTastingFlights',
    params: props
  });
}
