import { Flex } from 'components/baseElements/grid';
import { IonText } from 'components/ionicComponents';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Hideable } from '../../baseElements/hideable';

export interface WineRatingProperties {
  children?: string | React.ReactNode;
  color?: 'primary' | 'secondary';
  hideLabel?: boolean;
  mr?: number | string;
  score?: number;
  size?: number;
  scoreClickHandler?: (scoreStep: number) => any;
  scoreStep?: number;
}

export default function WineRating(props: WineRatingProperties): JSX.Element {
  const {
    children,
    color = 'primary',
    hideLabel,
    mr,
    score,
    size = 35,
    scoreClickHandler,
    scoreStep,
  } = props;

  const borderWidthMath = Math.round(size / 24);
  const borderWidth = borderWidthMath > 1 ? borderWidthMath : 1;

  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      mr={mr}
      my={1}
      onClick={(): void => scoreClickHandler && scoreStep ? scoreClickHandler(scoreStep) : null}
    >
      <Flex
        alignItems="center"
        backgroundColor={score ? `var(--ion-color-${color})` : 'transparent'}
        border={`${borderWidth}px solid var(--ion-color-${color})`}
        borderRadius={4}
        fontFamily="'Open Sans', sans-serif"
        fontSize={score === undefined || score < 100 ? size * .6 : size * .5}
        justifyContent="center"
        mb={!hideLabel && 2}
        position="relative"
        size={size}
      >
        <IonText color={score ? 'light' : 'primary'}>
          {score ? Math.floor(score) : '-'}
        </IonText>
      </Flex>
      <Hideable hide={!!hideLabel}>
        {children}
      </Hideable>
      <Hideable hide={!!hideLabel || !!children}>
        <IonText color="primary" fontWeight="bold">
          <FormattedMessage id="wineTastingList.text.yourScore"/>
        </IonText>
      </Hideable>
    </Flex>
  );
}
