import { Share } from '@capacitor/share';
import { faShareSquare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonFabButton } from '@ionic/react';
import TastingBg from 'assets/images/tastingbg-01.jpg';
import { Box, Flex } from 'components/baseElements/grid';
import dayjs from 'dayjs';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { ETastingLocationType, TastingWithAttendee, toMyTastingVm } from 'store/tastings';
import { getImageUrl } from 'utils/dataFormat/strings';

interface TastingBannerProps {
  children?: React.ReactNode;
  tasting?: TastingWithAttendee;
}

export default function TastingBanner(props: TastingBannerProps): JSX.Element {
  const {
    children,
    tasting,
  } = props;

  const intl = useIntl();
  const apiBaseUrl = useSelector((state: RootState): string => state.appSettings.apiBaseUrl);
  const baseUrl: string = useSelector(
    (state: RootState): string => state.appSettings.baseUrl,
  );
  const numberOfHoursUntilEndOfTasting = useSelector((state: RootState): number => state.appSettings.numberOfHoursUntilEndOfTasting);
  const vm = tasting ? toMyTastingVm(tasting, numberOfHoursUntilEndOfTasting) : tasting;
  const datetimeFormatted = dayjs(vm?.tasting.dateTime).format('MMM D, YYYY h:mm A');

  const onShareClick = async (): Promise<void> => {
    const shareSubject = vm?.tasting?.name || '';
    // Sharing will not work locally from localhost because the backend origin is different.
    const shareUrl = `${baseUrl || window.location.origin}/api/mvc/tastings/${vm?.tasting.id}`;
    const zone = new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2];
    const shareBody = `${intl.formatMessage({ id: 'tastingActionBar.shareEmail.bodyStart' })}
    ${vm?.tasting.name}
    ${datetimeFormatted} ${zone}
    ${intl.formatMessage({ id: vm?.tasting.locationType === ETastingLocationType.Virtual ? 'tastingForm.labels.virtualTasting' : 'tastingForm.labels.inPersonTasting' })}
    ${shareUrl}
    ${intl.formatMessage({ id: 'tastingActionBar.shareEmail.bodyEnd' })}`;
    try {
      await Share.share({
        title: shareSubject,
        text: shareBody,
        dialogTitle: intl.formatMessage({ id: 'tastingActionBar.shareEmail.title' }),
      });
    } catch (ignore) {
      window.open(`mailto:?to=&subject=${encodeURIComponent(shareSubject)}&body=${shareBody}`, '_system');
    }
  };

  const getTastingImageUrl = getImageUrl(apiBaseUrl, tasting?.tasting?.tastingImageFileId, TastingBg);

  return (
    <Flex
      alignItems="flex-end"
      backgroundImage={`url(${getTastingImageUrl})`}
      backgroundPosition="center"
      backgroundSize="cover"
      height="56.25vw"
      justifyContent="flex-end"
      maxHeight="50vh"
      p={3}
      width="100vw"
    >
      {children}
      <Box alignSelf="right">
        <IonFabButton
          color="light"
          onClick={onShareClick}
          size="small"
        >
          <FontAwesomeIcon icon={faShareSquare} />
        </IonFabButton>
      </Box>
    </Flex>
  );
}
