import { IonItem, IonList } from '@ionic/react';
import { EmptyStateImage } from 'components/baseElements/EmptyStateImage';
import { Box, Flex } from 'components/baseElements/grid';
import { Hideable } from 'components/baseElements/hideable';
import { Paragraph } from 'components/baseElements/typography';
import { IonButton, IonListHeader, IonText } from 'components/ionicComponents';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { WineRank } from 'store/wineRanks';
import { Flight, Wine } from 'store/wines';
import { GetFlightsInOrder, GetSortedWinesInFlight } from 'store/wines/utils';
import { WineListItem, WineListItemType } from '../WineListItem';
import { WineRatingProperties } from '../WineRating';
import { RankingsValidation, rankingsValidator, updateUserRankings } from './utils';

interface WineListForRankingProps {
  avgWineRatingProps?: WineRatingProperties;
  flightId?: string;
  flights?: Array<Flight>;
  onClearWineRanks?: (wineRanks: WineRank[]) => void;
  onChangeIsValid: (isValid: boolean) => void;
  onChangeWineRankings: (wineRanks: WineRank[], invalid?: boolean) => void;
  tastingId?: string;
  userId: string;
  userRankings: WineRank[];
  userWineRatingProps?: WineRatingProperties;
  wineClickHandler?: (wine: Wine) => any;
  wines?: Array<Wine>;
  wineRatingProps?: WineRatingProperties;
}

export default function WineListForRanking(props: WineListForRankingProps): JSX.Element {
  const {
    avgWineRatingProps,
    flightId,
    flights = [],
    onClearWineRanks,
    onChangeIsValid,
    onChangeWineRankings,
    tastingId,
    userId,
    userRankings,
    userWineRatingProps,
    wines = [],
    wineClickHandler,
    wineRatingProps,
  } = props;
  // LOCAL (CONTAINER) STATE SETUP
  const [nextUserRankings, setNextUserRankings] = useState<WineRank[]>([]);
  const [rankingsValidation, setRankingsValidation] = useState<RankingsValidation | undefined>();
  // LOCAL FUNCTIONS
  const isValid = (): boolean => !rankingsValidation || rankingsValidation.isValid;
  // CALLBACK FUNCTIONS
  const handleWineRankAllocation = (wine: Wine, point: number): number => {
    // get rankings
    const points = {
      [3]: true,
      [2]: true,
      [1]: true,
      [0]: true,
    };
    nextUserRankings.filter(ranking => (flightId ? ranking.flightId === flightId : !ranking.flightId))
      .forEach(ranking => points[ranking.points] = false);
    const avail = Object.keys(points).filter(p => points[p]).map(p => Number(p));

    const nextAvail = avail.filter(a => a < point);
    const next = point === 0 ? avail[avail.length - 1] : nextAvail[nextAvail.length - 1];
    return next;
  }
  const handleUserRankingUpdate = (wine: Wine, points: number): void => {
    const updatedUserRankings = updateUserRankings(points, nextUserRankings, userId, wine, tastingId, flightId);

    const validation = rankingsValidator(updatedUserRankings, flightId);
    // Update local state
    setNextUserRankings(updatedUserRankings);
    setRankingsValidation(validation);
    // Notify the parent of changes
    onChangeWineRankings(updatedUserRankings);
    onChangeIsValid(validation.isValid);
  };
  useEffect(() => {
    setNextUserRankings(userRankings);
    const validation = rankingsValidator(userRankings, flightId);
    setRankingsValidation(validation);
  }, [flightId, userRankings]);
  const filteredFlights = flightId ? flights.filter((f: Flight) => f.id === flightId) : flights;
  const filteredWines = flightId ? wines.filter((w: Wine) => !!w.flightId) : wines;
  const clearAllRankings = (): void => {
    // Restore logic if state here needs to be updated and preserved
    const updatedUserRankings = flightId
      // Here we are removing ranks that match the flight only and ensuring overall wine ranks remain
      ?
      nextUserRankings.filter((w: WineRank) => !w.flightId || w.flightId !== flightId) :
      // Here we are removing overall ranks only
      nextUserRankings.filter((w: WineRank) => !!w.flightId);

    const validation = rankingsValidator(updatedUserRankings, flightId);
    // Update local state
    setNextUserRankings(updatedUserRankings);
    setRankingsValidation(validation);

    // Notify the parent of changes
    if (onClearWineRanks) {
      onClearWineRanks(updatedUserRankings);
    }
    // onChangeWineRankings(updatedUserRankings);
    // onChangeIsValid(validation.isValid);
  }
  return (
    <>
      <Hideable hide={(!filteredWines || !filteredWines.length) && (!filteredFlights || !filteredFlights.length)}>
        <IonList>
          <IonItem>
            <Flex $alignItems="center" $flexDirection="column" $mb={3} $mt={1} $width="100%">
              <Box
                $borderRadius="8px"
                $flex={1}
                $maxWidth={300}
                $px={1}
                $py={2}
                $textAlign="center"
              >
                <IonText>
                  <FormattedMessage id={'rankWinesContainer.text.' + (isValid() ? 'instructions' : 'validationWarning')} />
                </IonText>
              </Box>
              <Hideable hide={false}>
                <IonButton fill="outline" onClick={clearAllRankings} size="md">
                  <FormattedMessage id="rankWinesContainer.button.clearAll" />
                </IonButton>
              </Hideable>
            </Flex>
          </IonItem>
          {filteredFlights && GetFlightsInOrder(filteredFlights).map((flight: Flight, index: any) => (
            <React.Fragment key={index}>
              <Hideable hide={!flight?.name || !!flightId}>
                <IonListHeader fontSize="initial">
                  <IonText ellipsis>
                    {flight.name}
                  </IonText>
                </IonListHeader>
              </Hideable>
              {GetSortedWinesInFlight(filteredWines, flight.id).map((wine: Wine, key: any) => (
                <WineListItem
                  avgWineRatingProps={avgWineRatingProps}
                  flightId={flightId}
                  key={key}
                  wine={wine}
                  type={WineListItemType.RankWinesModal}
                  onWineClick={(): void => wineClickHandler && wineClickHandler(wine)}
                  onRankWineClick={(_, points: number): void => handleUserRankingUpdate(wine, points)}
                  onWineRankAllocation={handleWineRankAllocation}
                  userWineRatingProps={userWineRatingProps}
                  wineRatingProps={wineRatingProps}
                />
              ))}
            </React.Fragment>
          ))}
        </IonList>
      </Hideable>
      <Hideable
        hide={(filteredWines !== undefined && filteredWines?.length > 0) || (filteredFlights !== undefined && filteredFlights?.length > 0)}>
        <Box py={4}>
          <EmptyStateImage />
          <Paragraph
            data-cy="wineListEmptyStateMsg"
            maxWidth={210}
            mx="auto"
            textAlign="center"
            width={2 / 3}
          >
            <FormattedMessage id="wineTastingList.text.emptyCaseDescr" />
          </Paragraph>
        </Box>
      </Hideable>
    </>
  );
}
