/* eslint-disable indent */
import { ApiCallStatus, ServerValidationError } from 'napa-react-core';
import { API_STATE_UPDATE_CALL_STATUS, ApiStateUpdateCallStatusAction } from './types';


export const apiStateUpdateCallStatus = <T>(
  id: string,
  type: ApiCallStatus,
  errorMessage?: string,
  validationErrors?: Array<ServerValidationError>,
  resultObject?: T,
): ApiStateUpdateCallStatusAction => ({
  type: API_STATE_UPDATE_CALL_STATUS,
  payload: {
    id: id,
    status: type,
    errorMessage: errorMessage,
    validationErrors: validationErrors,
    resultObject: resultObject,
  },
});
