import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgOysterIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 66 66"
      width={16} height={18} fill="none" {...props}
    >
      <path
        d="M59.4 15.4c-1.1 0-2 .4-2.8.8-.7-10-10.8-14.1-16-6.3C39 6.1 36.2 3.8 33 3.8c-3.2 0-6 2.4-7.6 6.1-2.3-3.5-5.4-4.8-8.8-3.7-5.7 1.7-6.9 7-7.1 10-.9-.6-2.1-1.1-3.4-1-2.8.1-6.1 3.1-4.8 8.8.2.4.2 8.6 21.7 28.8-.6 2.6-.3 4.7 1 6.3 1.6 2.1 4.7 3.2 9.1 3.2 4.4 0 7.4-1 9-3.1 1.2-1.6 1.6-3.7 1-6.3C64 33.1 64.5 24.4 64.7 24.2c1.3-5.9-1.7-8.9-5.3-8.8zm3.4 8.4c-.2.2-.4 8.3-21.4 28-.3.2-.4.6-.3 1 .6 2.2.4 4-.5 5.2-1.2 1.6-3.7 2.4-7.5 2.4-3.7 0-6.3-.8-7.5-2.4-.9-1.2-1.1-2.9-.5-5.1.1-.4 0-.7-.3-1C3.4 31.9 3.4 23.8 3.2 23.5c-1.3-5.3 2.7-8.7 6.5-4.7.7 2.2 4.6 13.5 11.9 24.2.3.4.9.6 1.4.2.4-.3.5-.9.2-1.3-5.1-7.3-9.9-17.6-11.9-24-.1-1.1-.3-8 5.8-9.9 3.1-.9 5.6.6 7.5 4.5.1 2 .7 18.4 4.5 30.1.2.5.7.8 1.2.6.5-.2.8-.7.6-1.2-3.6-11.4-4.3-27.8-4.4-29.6 1.1-4 3.6-6.7 6.4-6.7 2.8 0 5.3 2.7 6.4 6.7 0 1.3-.5 18.1-4.2 29.7-.2.5.1 1 .6 1.2.5.2 1.1-.1 1.2-.6 3.7-11.6 4.2-27.8 4.3-30.1 4.5-9.3 14.1-3.7 13.3 5.4-.3 1-4.1 12.8-11.8 23.9-.3.4-.2 1 .2 1.3.4.3 1.1.2 1.4-.2 7.6-10.9 11.4-22.5 12-24.3.4-.3 1.5-1.3 3-1.4 2.5 0 4.5 1.8 3.5 6.5z"
        fill="var(--ion-color-dark)"
      />
    </svg>
  );
}

export default SvgOysterIcon;
