import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgChalkIcon(props) {
  return (
    <svg width={10} height={18} fill="none" {...props}>
      <path
        d="M1.327 17.664c-.531-.324-.967-.88-1.301-1.656a.32.32 0 01-.005-.241L5.445 1.745a.33.33 0 01.052-.089C6.215.787 6.984.265 7.918.011a.318.318 0 01.342.12c.555.766 1.057 1.794 1.492 3.059a.32.32 0 01-.004.219L4.324 17.43a.319.319 0 01-.166.175c-.939.427-1.769.508-2.467.238a2.3 2.3 0 01-.364-.18zm-.663-1.79c.33.716.753 1.18 1.257 1.374.505.194 1.128.138 1.853-.171L9.11 3.283C8.74 2.232 8.327 1.361 7.872.69c-.694.233-1.288.66-1.85 1.332L.663 15.873z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M5.29 3.515a3.743 3.743 0 01-.218-.145.32.32 0 01.372-.518c.49.356 1.084.54 1.69.523a.32.32 0 01.31.161c.078.139.231.303.322.318a.319.319 0 11-.1.63c-.31-.051-.54-.293-.672-.469a3.434 3.434 0 01-1.703-.5z"
        fill="var(--ion-color-dark)"
      />
    </svg>
  );
}

export default SvgChalkIcon;
