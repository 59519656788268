import { IonRange as IonRangeBase } from '@ionic/react';
import styled from 'styled-components';
import { detect } from 'detect-browser';
import { bgKnob, cssIonRange } from './styles';

const browser = detect();

const showKnobStyles = () => {
  return !(browser?.os?.includes('Android') && parseInt(browser?.version || '0') < 70);
};

const IonRange = styled(IonRangeBase)`
  ${showKnobStyles() ? cssIonRange : ''}
`;

IonRange.defaultProps = {
  $backgroundColor: 'transparent',
  $backgroundImage: bgKnob(),
  $backgroundPosition: 'center',
  $backgroundRepeat: 'no-repeat',
  barBackground: 'rgba(var(--ion-color-medium-rgb), 0.5)',
  barBackgroundActive: 'transparent',
  $borderRadius: 0,
  $boxShadow: 'none',
  knobBackground: 'transparent',
  knobSize: '40px',
  $mt: '6px',
};

export { IonRange };
