/* eslint-disable indent */
import { GuiStateUpdateModalAction, GUI_STATE_UPDATE_MODAL } from './types';
import { ModalProperties } from '../types';


export const guiStateUpdateModal = <T>(
  modalProps: ModalProperties<T>,
): GuiStateUpdateModalAction => ({
    type: GUI_STATE_UPDATE_MODAL,
  payload: {
    modalProps: modalProps
  },
});
