import { faUserPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RefresherEventDetail } from '@ionic/core';
import { IonContent, IonRefresher, IonRefresherContent } from '@ionic/react';
import AttendeeList from 'components/attendee/AttendeeList';
import { Box } from 'components/baseElements/grid';
import { BackHeader } from 'components/baseElements/header';
import { chevronDownCircleOutline } from 'ionicons/icons';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { TastingWithAttendee, toMyTastingVm } from 'store/tastings';
import { clearApiCallStatus, GetLoadingStatus, makeApiCall } from 'utils/api';
import AttendeeListFilter from '../../../components/attendee/AttendeeListFilter';
import { HeaderSubmitButton } from '../../../components/baseElements/header';
import { InviteAttendeesModalId } from '../../../modals/InviteAttendeesModal';
import {
  ETastingAttendeeFilter,
  getTastingAttendeesWhoAreGoing,
  getTastingAttendeesWhoAreInvited,
  getTastingAttendeesWhoCantGo,
  TastingAttendee,
} from '../../../store/tastingAttendees';
import { showModal } from '../../../utils/modals';

interface ViewAttendeeListContainerProps {
  tastingId?: string;
}

const ViewAttendeeListContainer: React.FC<ViewAttendeeListContainerProps> = (props: ViewAttendeeListContainerProps) => {
  const { tastingId } = props;
  const VIEW_TASTING_KEY = 'viewTasting';

  // LOCAL (CONTAINER) STATE SETUP
  const [tasting, setTasting] = useState(undefined as TastingWithAttendee | undefined);
  const [attendeeFilter, setAttendeeFilter] = useState<ETastingAttendeeFilter>(ETastingAttendeeFilter.Going);
  const [filteredTastingAttendees, setFilteredTastingAttendees] = useState<TastingAttendee[]>([]);
  // GLOBAL (REDUX) STATE SETUP
  const dispatch = useDispatch();
  const auth = useSelector((state: RootState) => state.authorization);
  const apiBaseUrl = useSelector((state: RootState): string => state.appSettings.apiBaseUrl);
  const numberOfHoursUntilEndOfTasting = useSelector((state: RootState): number => state.appSettings.numberOfHoursUntilEndOfTasting);
  const viewTastingApiCall = useSelector((state: RootState) =>
    state.api.callStatuses.find(e => e.id === VIEW_TASTING_KEY),
  );
  const intl = useIntl();

  // LOCAL FUNCTIONS
  const fetchTasting = useCallback(async (tastingId?: string): Promise<void> => {
    try {
      if (!tastingId) {
        return;
      }
      const result = await makeApiCall<TastingWithAttendee>({
        authToken: auth.token,
        dispatch: dispatch,
        callId: VIEW_TASTING_KEY,
        request: {
          url: `${apiBaseUrl}/tastings/${tastingId}`,
          httpMethod: 'GET',
        },
      });
      setTasting(toMyTastingVm(result, numberOfHoursUntilEndOfTasting));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [apiBaseUrl, auth.token, dispatch, numberOfHoursUntilEndOfTasting]);
  const doRefresh = (event: CustomEvent<RefresherEventDetail>): void => {
    clearApiCallStatus(dispatch, VIEW_TASTING_KEY);
    fetchTasting(tastingId)
      // eslint-disable-next-line no-console
      .catch(console.error);
    event.detail.complete();
  };
  const handleChangeAttendeeFilter = (filter: ETastingAttendeeFilter): void => setAttendeeFilter(filter);
  const handleInviteAttendeesModalClose = async (): Promise<void> => {
    await fetchTasting(tastingId);
  };

  // EFFECTS SETUP
  useEffect(() => {
    switch (attendeeFilter) {
      case ETastingAttendeeFilter.CantGo:
        setFilteredTastingAttendees(getTastingAttendeesWhoCantGo(tasting?.attendeeList));
        break;
      case ETastingAttendeeFilter.Invited:
        setFilteredTastingAttendees(getTastingAttendeesWhoAreInvited(tasting?.attendeeList));
        break;
      default:
        setFilteredTastingAttendees(getTastingAttendeesWhoAreGoing(tasting?.attendeeList));
        break;
    }
  }, [attendeeFilter, tasting]);
  useEffect(() => {
    clearApiCallStatus(dispatch, VIEW_TASTING_KEY);
    fetchTasting(tastingId)
      // eslint-disable-next-line no-console
      .catch(console.error);
  }, [dispatch, fetchTasting, tastingId]);

  const EditButton = (
    <HeaderSubmitButton
      color="primary"
      data-cy="inviteAttendeesButton"
      slot="icon-only"
      onClick={(): void => {
        return showModal(dispatch, InviteAttendeesModalId, { params: { tastingId, handleInviteAttendeesModalClose } });
      }}
    >
      <FontAwesomeIcon icon={faUserPlus} />
    </HeaderSubmitButton>
  );
  const isHost = !!tasting?.attendee?.isHost;
  const showEditButton = auth.tokenData && auth.tokenData['userData/userId'] === tasting?.tasting?.ownerId && !tasting?.tasting?.hasEnded;
  return (
    <>
      <BackHeader
        backHref={`/tastings/details/${tastingId}`}
        ionButtonsEnd={showEditButton ? EditButton : null}
      >
        <FormattedMessage id="attendeeList.header.title"/>
      </BackHeader>
      <IonContent>
        <Box backgroundColor="white" position="sticky" top="0" zIndex="1000">
          <AttendeeListFilter
            changeFilter={handleChangeAttendeeFilter}
            tastingAttendees={tasting?.attendeeList}
            showViewAsHost={isHost}
          />
        </Box>
        <IonRefresher
          onIonRefresh={doRefresh}
          slot="fixed"
        >
          <IonRefresherContent
            pullingIcon={chevronDownCircleOutline}
            pullingText={intl.formatMessage({ id: 'general.refresher.pullingText' })}
            refreshingSpinner="circles"
            refreshingText={intl.formatMessage({ id: 'general.refresher.refreshingText' })}
          />
        </IonRefresher>
        <AttendeeList
          loadingStatus={GetLoadingStatus(viewTastingApiCall?.status)}
          tastingAttendees={filteredTastingAttendees}
        />
      </IonContent>
    </>
  );
}

export default ViewAttendeeListContainer;
