import { CallbackID, Geolocation, Position } from '@capacitor/geolocation';
import { useEffect, useState } from 'react';
import { UseGeolocationHook } from '../../store/geoLocation';
import usePostMyGeoCoordinate from './use-post-my-geo-coordinate';


export function useGeoLocation(): UseGeolocationHook {
  const [error, setError] = useState<Error | undefined>();
  const [latitude, setLatitude] = useState<number>(0);
  const [longitude, setLongitude] = useState<number>(0);
  const { data: geoLocation,  mutate: mutateMyGeoLocation } = usePostMyGeoCoordinate();
  useEffect(() => {
    if (latitude && longitude) {
      mutateMyGeoLocation({ latitude, longitude });
    }
  }, [latitude, longitude, mutateMyGeoLocation]);
  useEffect(() => {
    let cancel = false;

    function onGetCurrentPositionFailure(err: any): void {
      if (cancel) return;
      // eslint-disable-next-line no-console
      console.error(err);
      setError(err);
    }

    function onGetCurrentPositionSuccess(currentPosition: Position): void {
      if (cancel) return;
      setLatitude(currentPosition.coords.latitude);
      setLongitude(currentPosition.coords.longitude);
    }

    const currentWatchIdPromise = Geolocation.watchPosition({}, (currentPosition: Position | null, err: any) => {
      if (err) {
        onGetCurrentPositionFailure(err);
      } else {
        if (currentPosition) {
          onGetCurrentPositionSuccess(currentPosition);
        } else {
          // TODO: Decide what to do when position is null
        }
      }
    });

    return (): void => {
      cancel = true;
      currentWatchIdPromise.then((id: CallbackID) => {
        Geolocation.clearWatch({ id })
          // eslint-disable-next-line no-console
          .catch(console.error);
      })
        // eslint-disable-next-line no-console
        .catch(console.error);
    };
  }, []);

  return {
    error,
    geoLocation: geoLocation,
    getPosition: () => {
      Geolocation.getCurrentPosition({}, ).then((currentPosition: Position): void => {
        setLatitude(currentPosition.coords.latitude);
        setLongitude(currentPosition.coords.longitude);
      }).catch((err: any) => {
        setError(err);
      });
    },
    position: { latitude, longitude },
  };
}
