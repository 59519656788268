import { ApiCall } from 'napa-react-core';
import { AuthorizationState } from 'store/authorization/types';

export interface BrowseTastingsViewSchema {
  getStartedNoSession: {
    isVisible: boolean;
  };
  getStarted: {
    isVisible: boolean;
  };
  myTastings: {
    isVisible: boolean;
  };
}
export const getViewSchema = (auth: AuthorizationState, browseTastingApiCall: ApiCall<any> | undefined): BrowseTastingsViewSchema => {
  const getStartedNoSession = {
    isVisible: !auth.token,
  };
  const getStarted = {
    isVisible: !!(auth.token &&
      browseTastingApiCall?.resultObject?.items?.length < 1),
  };
  const myTastings = {
    isVisible: !!(auth.token &&
      browseTastingApiCall?.resultObject?.items?.length > 0),
  };
  return {
    getStartedNoSession,
    getStarted,
    myTastings,
  }
}
