import { IonPage } from '@ionic/react';
import { BackHeader } from 'components/baseElements/header';
import { Hideable } from 'components/baseElements/hideable';
import EditWineListForTastingContainer from 'containers/wine/EditWineListForTastingContainer';
import * as React from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const EditWineListForTastingPage = (props: any): any => {
  const { tastingId } = props.match.params;
  const [mode, setMode] = useState('edit');
  return (
    <IonPage>
      <Hideable hide={mode!=='edit'}>
        <BackHeader backHref="/tastings">
          <FormattedMessage id="editWineListTasting.header.title" />
        </BackHeader>
      </Hideable>
      <EditWineListForTastingContainer tastingId={tastingId} mode={mode} setMode={setMode} />
    </IonPage>
  );
};

export default EditWineListForTastingPage;
