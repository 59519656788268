import { IonLabel, IonText } from 'components/ionicComponents';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { SummaryValue } from '../ScoreAWineSteps/SummaryValue';

interface SliderStepLabelProps {
  field: string;
  stepName: string;
  value: any;
}

const SliderStepLabel: FunctionComponent<SliderStepLabelProps> = (
  props: SliderStepLabelProps,
) => {
  const {
    field,
    stepName,
    value,
  } = props;
  
  return (
    <IonLabel>
      <FormattedMessage id={`scoreWine.${stepName}.labels.${field}`}/>
        :&nbsp;
        <IonText fontWeight="bold">
        {SummaryValue({
          field,
          value: value,
        })}
        </IonText>
    </IonLabel>
  );
}

export default SliderStepLabel;