import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
import { TastingAttendee } from 'store/tastingAttendees';
import { Tasting, TastingStatus } from '..';
import { BrowseTastingResponseData, TastingWithAttendee } from '../types';

dayjs.extend(utc);
dayjs.extend(isBetween);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const toRequestInsertTastingDto = (item: any, auth: any): Tasting => {
  const { dateTime, time } = item;
  const dateTimeObj = new Date(dateTime);
  const timeObj = new Date(time); // note; returns in UTC time
  // Ensure we convert to plaintext in locale date & time;
  const dateStr = dateTimeObj.toLocaleDateString();
  const timeStr = timeObj.toLocaleTimeString();

  // Convert to plaintext in UTC time
  const parsedDt = new Date(`${dateStr} ${timeStr}`).toISOString()
  const requestDto = {
    ...item,
    dateTime: parsedDt,
    ownerId: auth.tokenData?.['userData/userId'],
    isTastingPublic: item.privacy === 'true',
  } as Tasting;
  delete requestDto.time;
  return requestDto;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const toRequestInsertTastingAttendeeDto = (item: TastingAttendee, auth: any, isHost: boolean): TastingAttendee => {
  return {
    ...item,
    email: auth.tokenData?.['userData/email'],
    isHost: isHost,
    attendeeId: auth.tokenData?.['userData/userId'] || null
  } as TastingAttendee;
}

export const toEditTastingVm = (item: Tasting): Tasting => {
  const { dateTime } = item;

  // get tasting event datetime flagged to use UTC time
  // note, when viewing from console, utcDt will show in local time zone but underlying data is still UTC.
  const utcDt = dayjs.utc(dateTime);
  // get start and end datetime
  const startDt = dayjs(utcDt).local(); // flag as local datetime

  return {
    ...item,
    dateTime: startDt.toString(),
    time: startDt.toString(),
    privacy: item.isTastingPublic ? 'true' : 'false',
  } as Tasting
}

export const getTastingStatus = (item?: TastingWithAttendee, numberOfHoursUntilEndOfTasting = 0): TastingStatus => {
  if (!item) {
    return {} as TastingStatus;
  }

  const { dateTime } = item.tasting || {};

  // get current local time
  const currentDt = dayjs().local();

  // get tasting event datetime flagged to use UTC time
  // note, when viewing from console, utcDt will show in local time zone but underlying data is still UTC.
  const utcDt = dayjs.utc(dateTime);

  // get start and end datetime
  const tastingDt = dayjs(utcDt).local(); // flag as local datetime
  const startDt = dayjs(tastingDt).subtract(1, 'hour'); // flag as start datetime - 1 hour
  const endDt = tastingDt.add(numberOfHoursUntilEndOfTasting, 'hour'); // currently we set end datetime to be x hours ahead of start datetime of the tasting event

  return {
    dateTime: tastingDt.format('ddd, MMM D, h:mm a'),
    hasEnded: dayjs(currentDt).isAfter(endDt),
    isHappeningNow: dayjs(currentDt).isBetween(startDt, endDt, null, '[)'),
  };
};


export const toMyTastingVm = (item?: TastingWithAttendee, numberOfHoursUntilEndOfTasting = 0): TastingWithAttendee => {
  if (!item) {
    return {} as TastingWithAttendee;
  }
  const { dateTime, hasEnded, isHappeningNow } = getTastingStatus(item, numberOfHoursUntilEndOfTasting);

  const myTastingVm = {
    ...item.tasting,
    dateTime,
    hasEnded,
    isHappeningNow,
    privacy: item?.tasting?.isTastingPublic ? 'true' : 'false',
  } as Tasting;

  const attendeeListNoHost = item.attendeeList ? item?.attendeeList.filter((att: TastingAttendee) => !att.isHost) : [];
  return {
    attendee: item?.attendee,
    attendeeList: attendeeListNoHost,
    tasting: myTastingVm,
  };
}

export const toMyTastingListVm = (item: BrowseTastingResponseData): Array<TastingWithAttendee> => {
  const items = [...item.items];
  return items.map(toMyTastingVm);
}

export const defaultBrowseTastingResponseData = {
  items: [],
  totalItems: 0,
} as BrowseTastingResponseData;
