import ScoreAWineAromaStep from 'components/wineScores/ScoreAWineSteps/ScoreAWineAromaStep';
import ScoreAWineFlavorsStep from 'components/wineScores/ScoreAWineSteps/ScoreAWineFlavorsStep';
import ScoreAWinePalateStep from 'components/wineScores/ScoreAWineSteps/ScoreAWinePalateStep';
import ScoreAWineRatingStep from 'components/wineScores/ScoreAWineSteps/ScoreAWineRatingStep';
import ScoreAWineTasteStep from 'components/wineScores/ScoreAWineSteps/ScoreAWineTasteStep';
import ScoreAWineVisualStep from 'components/wineScores/ScoreAWineSteps/ScoreAWineVisualStep';
import { StepBaseProperties } from 'components/wineScores/types';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { Wine } from 'store/wines';
import { getEmptyWineScore, toRequestUpdateWineScoreDto, WineColors, WineScore } from 'store/wineScores';
import { clearApiCallStatus, makeApiCall } from 'utils/api';
import { LoadingStatus } from 'utils/formValidation';
import { schema } from './validation';

interface ScoreAWineContainerProps {
  wine: Wine;
  stepHandler?: (wine: Wine) => any;
  completionHandler?: (wine: Wine, isCompleted: boolean) => any;
  scoreStep: number;
  modalId: string;
}
interface SaveScoringDataResult {
  wineScore?: WineScore;
  isSuccess: boolean;
}

export default function ScoreAWineContainer(props: ScoreAWineContainerProps): JSX.Element {
  const { completionHandler, modalId, stepHandler, wine, scoreStep = 1 } = props;
  // CONSTANT DECLARATION
  const INSERT_SCORE_KEY = 'insertWineScore';
  const EDIT_SCORE_KEY = 'editWineScore';
  const intl = useIntl();
  const dispatch = useDispatch();
  const steps: Array<FunctionComponent<StepBaseProperties>> = [ScoreAWineRatingStep, ScoreAWineVisualStep, ScoreAWineAromaStep, ScoreAWineTasteStep, ScoreAWineFlavorsStep, ScoreAWinePalateStep];
  const stepNames: Array<string> = ['scoreWine.ratingStep.title', 'scoreWine.visualStep.title', 'scoreWine.aromaStep.title', 'scoreWine.tasteStep.title', 'scoreWine.flavorsStep.title', 'scoreWine.palateStep.title'];
  // LOCAL (CONTAINER) STATE SETUP
  const [wineScore, setWineScore] = useState(wine?.scoring?.userScore || getEmptyWineScore() as WineScore);
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.None);
  const [currentStep, setCurrentStep] = useState(scoreStep);
  // GLOBAL (REDUX) STATE SETUP
  const auth = useSelector((state: RootState) => state.authorization);
  const apiBaseUrl = useSelector((state: RootState): string => state.appSettings.apiBaseUrl);

  // CALLBACK FUNCTIONS
  const insertScoreData = useCallback(async (data: WineScore): Promise<SaveScoringDataResult> => {
    try {
      if (!wine?.id) {
        return { isSuccess: false };
      }

      const result = await makeApiCall<WineScore>({
        authToken: auth.token,
        callId: INSERT_SCORE_KEY,
        dispatch: dispatch,
        request: {
          url: `${apiBaseUrl}/wines/${wine.id}/scores`,
          httpMethod: 'POST',
          body: data,
        },
        showSuccessMessage: false,
      });
      setWineScore(result);
      return { isSuccess: true, wineScore: result };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return { isSuccess: false };
    }
  }, [apiBaseUrl, auth.token, dispatch, wine]);

  const editScoreData = useCallback(async (data: WineScore): Promise<SaveScoringDataResult> => {
    try {
      if (!wine?.id) {
        return { isSuccess: false };
      }

      const result = await makeApiCall<WineScore>({
        authToken: auth.token,
        callId: EDIT_SCORE_KEY,
        dispatch: dispatch,
        request: {
          url: `${apiBaseUrl}/wines/${wine.id}/scores/${data.id}`,
          httpMethod: 'PUT',
          body: data,
        },
        showSuccessMessage: false,
      });
      setWineScore(result);
      return { isSuccess: true, wineScore: result };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return { isSuccess: false };
    }
  }, [apiBaseUrl, auth.token, dispatch, wine]);

  const saveScoringData = async (data: any): Promise<SaveScoringDataResult> => {
    const score = toRequestUpdateWineScoreDto(wineScore, data);
    score.userId = auth.tokenData && auth.tokenData['userData/userId'];
    if (setLoadingStatus) {
      setLoadingStatus(LoadingStatus.Loading);
    }
    let result;
    if (score.id) {
      result = await editScoreData(score);
    } else {
      result = await insertScoreData(score);
    }

    if (setLoadingStatus) {
      setLoadingStatus(LoadingStatus.Complete);
    }
    return result;
  }

  const stepNextAction = async (data: any): Promise<void> => {
    const result = await saveScoringData(data);
    if (!result.isSuccess || !result.wineScore) {
      return;
    }

    if (currentStep === steps.length) {
      if (completionHandler) {
        const responseData = { ...wine };
        responseData.scoring = { ...wine.scoring, userScore: result.wineScore }
        completionHandler(responseData, true);
      }
      return;
    }
    if (stepHandler) {
      const responseData = { ...wine };
      responseData.scoring = { ...wine.scoring, userScore: result.wineScore }
      stepHandler(responseData);
    }
    setCurrentStep(currentStep + 1);
  };

  const stepPreviousAction = async (data: any): Promise<void> => {
    const result = await saveScoringData(data);
    if (!result.isSuccess || !result.wineScore) {
      return;
    }
    if (stepHandler) {
      const responseData = { ...wine };
      responseData.scoring = { ...wine.scoring, userScore: result.wineScore }
      stepHandler(responseData);
    }
    setCurrentStep(currentStep - 1);
  };

  const stepSaveCloseAction = async (data: any, isScoringCompleted?: boolean): Promise<void> => {
    const result = await saveScoringData(data);
    if (!result.isSuccess || !result.wineScore) {
      return;
    }

    if (completionHandler) {
      const responseData = { ...wine };
      responseData.scoring = { ...wine.scoring, userScore: result.wineScore }
      completionHandler(responseData, !!isScoringCompleted);
    }
    return;
  };

  const cleanScore = (scoreToClean: WineScore): WineScore => {
    const newScore = { ...scoreToClean };
    if (newScore.acidity === null)
      newScore.acidity = 50;
    if (newScore.alcohol === null)
      newScore.alcohol = 50;
    if (newScore.color === null)
      newScore.color = WineColors[Math.floor(WineColors.length / 2)];
    if (newScore.brightness === null)
      newScore.brightness = 50;
    if (newScore.clarity === null)
      newScore.clarity = 50;
    if (newScore.sweetness === null)
      newScore.sweetness = 50;
    if (newScore.tannins === null)
      newScore.tannins = 60;
    if (newScore.body === null)
      newScore.body = 50;
    if (newScore.complexity === null)
      newScore.complexity = 50;
    if (newScore.finish === null)
      newScore.finish = 50;
    if (newScore.rating === null)
      newScore.rating = 75;
    return newScore;
  }

  // EFFECTS SETUP
  useEffect(() => {
    clearApiCallStatus(dispatch, INSERT_SCORE_KEY);
    clearApiCallStatus(dispatch, EDIT_SCORE_KEY);
  }, [dispatch]);

  return (
    <>
      {React.createElement(
        steps[currentStep - 1],
        {
          loadingStatus,
          modalId,
          onSaveAndClose: stepSaveCloseAction,
          nextAction: stepNextAction,
          previousAction: stepPreviousAction,
          score: cleanScore(wineScore),
          validationSchema: schema,
          stepNumber: currentStep,
          totalSteps: steps.length,
          stepName: intl.formatMessage({ id: stepNames[currentStep - 1] }),
          nextStepName: currentStep < steps.length ? `Step ${currentStep + 1}: ${intl.formatMessage({ id: stepNames[currentStep] })} ` : `${intl.formatMessage({ id: 'scoreWine.header.submitCaption' })} `,
          showNextStepButton: currentStep <= steps.length,
          previousStepName: currentStep > 1 ? ` Step ${currentStep - 1}: ${intl.formatMessage({ id: stepNames[currentStep - 2] })}` : '',
          showPreviousStepButton: currentStep > 1,
        })}
    </>
  );
}
