import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgSoilIcon(props) {
  return (
    <svg width={18} height={15} fill="none" {...props}>
      <path
        d="M17.87 11.646C14.555 6.134 10.18-.363 8.115.016 5.955.414 1.788 8.18.102 11.495a.817.817 0 00-.057.663.805.805 0 00.436.492c2.065 1.023 4.755 1.364 7.407 1.364 3.959 0 7.842-.758 9.433-1.117a.883.883 0 00.606-.493c.114-.227.095-.511-.057-.758zM3.758 11.42a.44.44 0 01-.436-.436c0-.246.19-.435.436-.435a.44.44 0 01.436.435c0 .247-.19.436-.436.436zm1.194-3.012a.32.32 0 01-.323-.322c0-.17.152-.322.323-.322.17 0 .322.152.322.322s-.152.322-.322.322zm.795-3.106a.44.44 0 01.436-.436c.246 0 .435.19.435.436a.44.44 0 01-.435.435.429.429 0 01-.436-.435zm1.023 7.103a.32.32 0 01-.322-.322.32.32 0 01.322-.322.32.32 0 01.322.322c.019.19-.133.322-.322.322zm.682-2.841a.44.44 0 01-.436-.436c0-.246.19-.436.436-.436a.44.44 0 01.436.436.44.44 0 01-.436.436zm.587-7.35a.44.44 0 01.436-.436c.246 0 .435.19.435.436s-.189.436-.435.436a.44.44 0 01-.436-.436zm.625 2.728a.32.32 0 01-.322-.322c0-.17.152-.322.322-.322a.32.32 0 01.322.322.32.32 0 01-.322.322zm.303 1.818a.44.44 0 01.436-.435c.246 0 .436.189.436.435a.44.44 0 01-.436.436.44.44 0 01-.436-.436zm1.042 5.19a.44.44 0 01-.436-.435c0-.246.19-.436.436-.436s.436.19.436.436-.19.435-.436.435zm.91-2.595a.32.32 0 01-.323-.322c0-.17.152-.322.322-.322a.32.32 0 01.322.322c0 .17-.151.322-.322.322zm.435-3.845a.32.32 0 01-.322-.322.32.32 0 01.322-.322.32.32 0 01.322.322c0 .17-.152.322-.322.322zm1.705 3.088a.44.44 0 01-.436-.436.44.44 0 01.436-.436c.246 0 .436.19.436.436s-.19.436-.436.436zm1.023 2.67a.44.44 0 01-.436-.435c0-.246.19-.436.436-.436s.435.19.435.436-.19.435-.435.435z"
        fill="var(--ion-color-dark)"
      />
    </svg>
  );
}

export default SvgSoilIcon;
