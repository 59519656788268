import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgStrawberryIcon(props) {
  return (
    <svg width={14} height={18} fill="none" {...props}>
      <path
        d="M12.223 5.045l.089-.028a.304.304 0 00.198-.183c.018-.046.021-.094.018-.143a.289.289 0 00-.04-.15c-.567-.936-1.564-1.558-2.97-1.854.173-.238.301-.495.384-.77a.277.277 0 00.009-.121.303.303 0 00-.195-.278c-.452-.167-.87-.253-1.245-.253-.42 0-.726.107-.93.226a.87.87 0 00-.418-.25L7.04.28A.306.306 0 006.653.01L5.128.457a.319.319 0 00-.186.152.294.294 0 00-.019.238l.21.632c-.203-.116-.496-.214-.902-.214-.372 0-.79.086-1.245.253a.303.303 0 00-.195.278.277.277 0 00.01.122c.085.287.219.555.402.8-1.391.295-2.324.899-2.837 1.836a.304.304 0 00-.033.146.327.327 0 00.018.143c.033.086.107.15.195.177.11.034.217.061.323.089C.287 5.92 0 7.058 0 8.559 0 12.726 4.072 18 6.57 18c2.5 0 6.571-5.274 6.571-9.441 0-1.54-.302-2.697-.918-3.514zM1.135 4.52c.534-.671 1.43-1.092 2.727-1.275a.302.302 0 00.262-.314.302.302 0 00-.1-.241 2.15 2.15 0 01-.522-.674c.265-.077.51-.116.726-.116.583 0 .784.274.79.277.07.11.204.162.333.131l.051-.018.037.107.357 1.058a.303.303 0 00.119.156l.003.003.018.006a.364.364 0 00.08.034l.036.006c.012 0 .025.006.037.006.021 0 .042-.003.064-.006l.897-.186a.303.303 0 00.24-.324l-.079-.92a.326.326 0 00.138.078.313.313 0 00.332-.119c.01-.012.214-.283.793-.283.217 0 .46.04.726.116a2.202 2.202 0 01-.497.646.303.303 0 00-.098.241.307.307 0 00.262.314c1.328.187 2.261.617 2.847 1.31a5.46 5.46 0 01-1.27.176c-1.201 0-1.384-.622-1.39-.644a.307.307 0 00-.317-.238.307.307 0 00-.284.278C8.3 5.761 7.147 6.381 6.735 6.552c-.414-.171-1.564-.79-1.717-2.447a.305.305 0 00-.561-.134c-.019.03-.497.738-1.937.738a6.142 6.142 0 01-1.385-.189zM6.46.704l.07.814.122 1.41-.366.076-.448-1.34L5.6.958l.86-.253zm.11 16.686c-2.16 0-5.96-5.107-5.96-8.831 0-1.532.313-2.62.948-3.328.332.055.646.082.945.082.98 0 1.611-.29 1.99-.564a3.387 3.387 0 002.129 2.41.293.293 0 00.2 0A3.397 3.397 0 008.92 4.87c.295.235.765.439 1.516.439.332 0 .701-.046 1.1-.128.666.708.992 1.812.992 3.374.003 3.727-3.8 8.834-5.957 8.834z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M2.91 6.613s-.463 1.613-.463 1.769a.58.58 0 00.582.582.58.58 0 00.583-.582c0-.156-.464-1.77-.464-1.77-.033-.118-.201-.118-.238 0zM6.519 7.833s-.464 1.613-.464 1.769a.58.58 0 00.171.412.579.579 0 00.824 0c.11-.11.17-.256.17-.412 0-.156-.463-1.77-.463-1.77-.034-.118-.202-.118-.238 0zM10.167 6.61c-.036-.12-.204-.12-.244 0 0 0-.464 1.613-.464 1.769a.58.58 0 00.583.582.58.58 0 00.583-.582c.006-.156-.458-1.77-.458-1.77zM4.384 10.581s-.464 1.614-.464 1.77a.58.58 0 00.583.582.58.58 0 00.582-.582c0-.156-.463-1.77-.463-1.77-.034-.119-.202-.119-.238 0zM6.519 13.32s-.464 1.614-.464 1.77a.58.58 0 00.583.582.58.58 0 00.583-.583c0-.155-.464-1.769-.464-1.769-.034-.118-.202-.118-.238 0zM8.654 10.578s-.464 1.614-.464 1.77a.58.58 0 00.583.582.58.58 0 00.583-.582c0-.156-.464-1.77-.464-1.77-.034-.119-.201-.119-.238 0z"
        fill="var(--ion-color-dark)"
      />
    </svg>
  );
}

export default SvgStrawberryIcon;
