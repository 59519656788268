import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import {
  IonContent,
  IonIcon,
  IonModal,
} from '@ionic/react';
import { close } from 'ionicons/icons';
import { HeaderSubmitButton } from 'components/baseElements/header';
import { IonButton } from 'components/ionicComponents';
import { PrivatePropertyContainer } from 'containers/authorization/PrivatePropertyContainer';
import { RootState } from 'store';
import { hideModal } from 'utils/modals';
import { HeaderWithButtons } from 'components/baseElements/header';
import AddWineForScoringContainer from 'containers/wine/AddWineForScoringContainer';
import { LoadingStatus } from 'utils/formValidation';
import { Wine } from 'store/wines';

export const AddWineForScoringModalId = 'CreateWineForScoring';

export default function AddWineForScoringModal(): JSX.Element {
  const dispatch = useDispatch();
  const modalProps = useSelector((state: RootState) =>
    state.gui.modals.find(e => e.id === AddWineForScoringModalId),
  );

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.None);
  return (
    <IonModal
      isOpen={modalProps?.show || false}
      onDidDismiss={(): void => hideModal(dispatch, AddWineForScoringModalId, {})}
    >
      <HeaderWithButtons
        ionButtonsEnd={
          <HeaderSubmitButton
            isLoading={loadingStatus === LoadingStatus.Loading}
            form="wineForm"
          />
        }
        ionButtonsStart={(
          <IonButton onClick={(): void => hideModal(dispatch, AddWineForScoringModalId, {})}>
            <IonIcon icon={close} slot="icon-only" />
          </IonButton>
        )}
      >
        <FormattedMessage id="createWineForScoringModal.header.title" />
      </HeaderWithButtons>
      <IonContent>
        <PrivatePropertyContainer modalCustomMessageComponent={(<div>This is a custom message in the register popup for the add wine to tasting page</div>)}>
          <PrivatePropertyContainer.Unauthorized>
            This is what the add wine for scoring modal looks like when you are not logged in.
          </PrivatePropertyContainer.Unauthorized>
          <PrivatePropertyContainer.Authorized>
            <AddWineForScoringContainer
              loadingStatus={loadingStatus}
              setLoadingStatus={setLoadingStatus}
              successHandler={(wine: Wine): void => hideModal(dispatch, AddWineForScoringModalId, modalProps?.params, modalProps?.callback, { created: true, wine })}
            />
          </PrivatePropertyContainer.Authorized>
        </PrivatePropertyContainer>
      </IonContent>
    </IonModal>
  );
}
