import { ImageOptions, CameraSource } from '@capacitor/camera';
import { faCamera } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SearchbarChangeEventDetail } from '@ionic/core/dist/types/components/searchbar/searchbar-interface';
import { IonButtons, IonContent, IonHeader, IonIcon, IonModal, IonToolbar } from '@ionic/react';
import { Box, Flex } from 'components/baseElements/grid';
import { SearchBar } from 'components/baseElements/SearchBar';
import { IonButton, IonLabel, IonText, IonTitle } from 'components/ionicComponents';
import { PrivatePropertyContainer } from 'containers/authorization/PrivatePropertyContainer';
import SearchMasterWinesContainer from 'containers/masterWines/SearchMasterWinesContainer';
import { close } from 'ionicons/icons';
import { AddWineForScoringModalId } from 'modals/AddWineForScoringModal';
import { AddWineToTastingModalId } from 'modals/AddWineToTastingModal';
import { GrapeUploadImageButton, Hideable } from 'napa-react-core';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { guiStateUpdateToast } from 'store/gui/guiStateUpdateToast';
import { Wine } from 'store/wines';
import { hideModal, showModal } from 'utils/modals';

export const masterWinesSearchModalId = 'SearchMasterWines';

export default function MasterWinesSearchModal(): JSX.Element {
  const dispatch = useDispatch();
  const modalProps = useSelector((state: RootState) =>
    state.gui.modals.find(e => e.id === masterWinesSearchModalId),
  );
  const intl = useIntl();
  const [searchText, setSearchText] = useState('');
  const [hasUserSearched, setHasUserSearched] = useState(false);
  useEffect((): void => {
    searchText && setHasUserSearched(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);
  const { apiBaseUrl, cameraApiSettings } = useSelector((state: RootState): any => state.appSettings);
  const onUploadError = (): void => {
    dispatch(
      guiStateUpdateToast({
        message: 'api.defaults.failureMessage',
      }),
    );
  };
  const onUploadSuccess = (ids: Array<string>): void => {
    if (ids.length) {
      setSearchText(ids[0]);
    }
  };
  const findWineButton = (
    <IonButton
      onClick={(): void => {
        setSearchText('');
        hideModal(
          dispatch,
          masterWinesSearchModalId,
          modalProps?.params,
          modalProps?.callback,
          { created: true }
        );
        modalProps?.params?.isSearchForMyWines ?
          showModal(
            dispatch,
            AddWineForScoringModalId,
            {
              params: {
                tastingId: modalProps?.params.tastingId,
                flights: modalProps?.params.flights,
                wines: modalProps?.params.wines,
              },
              callback: modalProps?.callback,
            }
          ) :
          showModal(
            dispatch,
            AddWineToTastingModalId,
            {
              params: {
                tastingId: modalProps?.params.tastingId,
                flights: modalProps?.params.flights,
                wines: modalProps?.params.wines,
              },
              callback: modalProps?.callback,
            }
          );
      }}
      size="small"
    >
      <FormattedMessage id="searchMasterWinesModal.buttons.addWine" />
    </IonButton>
  );
  const headerTitle = modalProps?.params?.isSearchForMyWines ? 'searchMasterWinesModal.header.myRatingsTitle' : 'searchMasterWinesModal.header.title';
  return (
    <IonModal
      isOpen={modalProps?.show || false}
      onDidDismiss={(): void => {
        setHasUserSearched(false);
        setSearchText('');
        hideModal(dispatch, masterWinesSearchModalId, {});
      }}
    >
      <IonHeader>
        <IonToolbar color="light">
          <IonButtons slot="start">
            <IonButton onClick={(): void => hideModal(dispatch, masterWinesSearchModalId, {})}>
              <IonIcon icon={close} slot="icon-only"/>
            </IonButton>
          </IonButtons>
          <IonTitle>
            <FormattedMessage id={headerTitle}/>
          </IonTitle>
        </IonToolbar>
        <IonToolbar color="light">
          <Flex
            $alignItems="center"
            flexDirection="row"
            width={1}
          >
            <SearchBar
              enableAutoFocus
              onIonCancel={(): void => hideModal(dispatch, masterWinesSearchModalId, {})}
              onIonChange={(e: CustomEvent<SearchbarChangeEventDetail>): void => setSearchText(e.detail.value || '')}
              onIonClear={(): void => setSearchText('')}
              placeHolder={intl.formatMessage({ id: 'searchMasterWinesModal.searchBar.placeholderText' })}
              searchText={searchText}
            />
            {/* Hiding the photo upload button for now */}
            <Hideable hide={true}>
              <GrapeUploadImageButton
                color="secondary"
                imageName="labelPhoto"
                onUploadError={onUploadError}
                onUploadSuccess={onUploadSuccess}
                options={{
                  quality: cameraApiSettings?.imageUploadQuality,
                  source: CameraSource.Prompt,
                  width: cameraApiSettings?.uploadImageTargetWidth,
                } as ImageOptions}
                renderChildrenOnly
                shape="round"
                uploadUrl={`${apiBaseUrl}/files/ocr`}
              >
                <Box
                  pb={3}
                  pl={1}
                  pr={3}
                >
                  <Flex
                    border={1}
                    borderColor="white"
                    $borderRadius={12}
                    fill="outline"
                    flexDirection="row"
                  >
                    <FontAwesomeIcon
                      icon={faCamera}
                    />
                    <IonLabel
                      ml={2} mt="-1px"
                    >
                      <FormattedMessage id="searchMasterWinesModal.buttons.takePhoto"/>
                    </IonLabel>
                  </Flex>
                </Box>
              </GrapeUploadImageButton>
            </Hideable>
          </Flex>
        </IonToolbar>
        <Hideable hide={!hasUserSearched}>
          <Flex
            alignItems="center"
            backgroundColor="rgba(var(--ion-color-medium-rgb), 0.21)"
            justifyContent="space-around"
            py={2}
          >
            <IonText
              fontWeight="bold"
            >
              <FormattedMessage
                id="searchMasterWinesModal.header.findWineHelpText"
              />
            </IonText>
            {findWineButton}
          </Flex>
        </Hideable>
      </IonHeader>
      <IonContent>
        <PrivatePropertyContainer>
          <PrivatePropertyContainer.Authorized>
            <SearchMasterWinesContainer
              hasUserSearched={hasUserSearched}
              isSearchForMyWines={modalProps?.params?.isSearchForMyWines}
              onWineSelect={(wine?: Wine): void => {
                setSearchText('');
                hideModal(dispatch, masterWinesSearchModalId, modalProps?.params, modalProps?.callback, { created: true, wine });
              }}
              searchText={searchText}
              tastingId={modalProps?.params?.tastingId}
              wines={modalProps?.params?.wines}
            />
          </PrivatePropertyContainer.Authorized>
        </PrivatePropertyContainer>
      </IonContent>
    </IonModal>
  );
}
