import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgYeastIcon(props) {
  return (
    <svg width={17} height={18} fill="none" {...props}>
      <path
        d="M4.316 18c-.95 0-1.691-.288-2.211-.862-.533-.588-1.023-1.723-.336-3.794.577-1.737.419-1.92-.609-3.11-.103-.12-.213-.246-.327-.382C.05 8.926-.192 7.87.155 6.875c.503-1.441 2.07-2.353 3.4-2.618 1.384-.275 1.752-.842 2.262-1.627C6.299 1.886 6.9.96 8.353.395c1.583-.614 3.442-.507 4.852.281 1.125.629 1.864 1.63 2.082 2.82.241 1.316-.042 2.264-.27 3.027-.118.396-.22.738-.22 1.048 0 .361.255.847.524 1.36.354.677.756 1.445.81 2.337.156 2.634-.617 3.783-3.066 4.555-.608.191-1.35.128-2.069.067-.542-.046-1.218-.104-1.5.007-.456.18-.914.471-1.399.78-.732.467-1.488.949-2.393 1.153-.503.113-.966.17-1.388.17zM2.285 8.623c.11.13.216.252.316.368 1.242 1.44 1.85 2.314.974 4.953-.355 1.07-.264 1.692-.06 1.917.241.266.903.308 1.77.113.581-.131 1.168-.505 1.79-.9.539-.345 1.097-.7 1.725-.948.696-.273 1.54-.202 2.357-.131.484.041 1.086.092 1.336.013 1.53-.482 1.849-.761 1.737-2.626-.029-.485-.303-1.011-.595-1.567-.363-.695-.74-1.414-.74-2.242 0-.59.152-1.1.3-1.594.195-.655.38-1.274.22-2.14-.145-.797-.699-1.256-1.138-1.502-.914-.51-2.184-.576-3.235-.168-.88.342-1.21.851-1.63 1.497-.585.902-1.313 2.025-3.485 2.457-.9.18-1.768.785-1.976 1.38-.044.127-.179.514.334 1.12z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M10.582 6.375a.811.811 0 100-1.622.811.811 0 000 1.622zM10.372 11.777a1.02 1.02 0 100-2.041 1.02 1.02 0 000 2.041zM6.816 9.218a1.26 1.26 0 100-2.519 1.26 1.26 0 000 2.519z"
        fill="var(--ion-color-dark)"
      />
    </svg>
  );
}

export default SvgYeastIcon;
