import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgPearIcon(props) {
  return (
    <svg width={12} height={18} fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.687 3.3c-.825.005-1.374.418-1.78 1.34-.202.46-.306.98-.308 1.547-.008 1.774-.109 2.447-1.287 3.625C.78 11.344.897 13.159.899 13.177c0 2.809 1.614 4.221 4.796 4.223 3.187-.002 4.8-1.414 4.8-4.2.003-.042.12-1.856-1.412-3.388-1.178-1.178-1.279-1.85-1.287-3.625a3.866 3.866 0 00-.309-1.548C7.08 3.715 6.53 3.301 5.7 3.3h-.012zM5.7 18C2.165 17.998.3 16.339.3 13.2c-.006-.06-.147-2.077 1.588-3.812C2.888 8.387 2.992 7.888 3 6.185c.003-.65.123-1.252.36-1.788.183-.418.743-1.688 2.325-1.697h.013c1.593.003 2.155 1.279 2.34 1.697.235.536.356 1.137.36 1.787.007 1.704.11 2.203 1.11 3.204 1.736 1.735 1.594 3.751 1.587 3.836C11.095 16.339 9.23 17.998 5.7 18z"
        fill="var(--ion-color-dark)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.9 16.586a.293.293 0 01-.08-.012C1.74 15.988 1.5 14.367 1.5 13.2a.3.3 0 11.6 0c0 1.12.223 2.329 1.882 2.797a.3.3 0 01-.081.589zM5.7 3.3a.3.3 0 01-.3-.3V.3a.3.3 0 11.6 0V3a.3.3 0 01-.3.3z"
        fill="var(--ion-color-dark)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.9 1.5a.3.3 0 01-.167-.55C3.773.924 4.719.3 5.699.3a.3.3 0 110 .6c-.8 0-1.625.544-1.633.55a.301.301 0 01-.166.05z"
        fill="var(--ion-color-dark)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.797 1.5c-.694 0-1.328.367-1.656.956l-.493.888a.354.354 0 00.052.423c.096.104.27.185.472.094l1.097-.49a1.83 1.83 0 001.016-1.149l.213-.722h-.7zm-1.802 3a.996.996 0 01-.738-.328.95.95 0 01-.133-1.12l.492-.887A2.487 2.487 0 012.797.9H3.9a.3.3 0 01.288.385l-.326 1.107c-.2.676-.69 1.232-1.347 1.527l-1.097.49a1.034 1.034 0 01-.422.092z"
        fill="var(--ion-color-dark)"
      />
    </svg>
  );
}

export default SvgPearIcon;
