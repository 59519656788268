import { AppSettingsState } from './types';
import { createSubReducer, Reducer } from 'utils/store';

const initialState = {
  apiBaseUrl: '',
  baseUrl: '',
  cameraApiSettings: {
    imageUploadQuality: 70,
    uploadImageTargetHeight: 400,
    uploadImageTargetWidth: 400,
  },
  pwaModalSettings: {
    pwaModalExpirationInMinutes: 120
  },
  securitySettings: {
    sessionExpirationInMinutes: 60,
    sessionExpirationWarningInMinutes: 55,
    refreshServerTokenThresholdInMinutes: 30,
  },
  userSupportEmail: '',
  numberOfHoursUntilEndOfTasting: 4,
  infiniteScrollLimit: 30
};

const reducers: Array<Reducer<AppSettingsState>> = [];

export const appSettingsReducer = createSubReducer<AppSettingsState>(
  initialState,
  reducers,
);
