import { IonContent } from '@ionic/react';
import { BackHeader } from 'components/baseElements/header';
import ScoreSummary from 'components/wineScores/ScoreSummary';
import { ScoreAWineModalId } from 'modals/ScoreAWineModal';
import { Hideable } from 'napa-react-core';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { Wine, WineStatus } from 'store/wines';
import { clearApiCallStatus, GetLoadingStatus, makeApiCall } from 'utils/api';
import { showModal } from 'utils/modals';
import { EditWineInTastingModalId } from '../../../modals/EditWineInTastingModal';

interface ViewWineScoreSummaryContainerProps {
  editAction?: boolean;
  hideBackHeader?: boolean;
  wineId: string;
}

const ViewWineScoreSummaryContainer: React.FC<ViewWineScoreSummaryContainerProps> = (props: ViewWineScoreSummaryContainerProps) => {
  const { editAction, hideBackHeader, wineId } = props;

  // CONSTANT DECLARATION
  const FETCH_WINE_KEY = 'fetchWine';

  // GLOBAL (REDUX) STATE SETUP
  const dispatch = useDispatch();
  const auth = useSelector((state: RootState) => state.authorization);
  const apiBaseUrl = useSelector((state: RootState): string => state.appSettings.apiBaseUrl);
  const fetchWineApiCall = useSelector((state: RootState) =>
    state.api.callStatuses.find(e => e.id === FETCH_WINE_KEY),
  );

  // LOCAL (CONTAINER) STATE SETUP
  const [wine, setWine] = useState({} as Wine);
  const updateWineScore = (updatedWine: Wine): void => {
    const nextWine = {
      ...wine,
      scoring: {
        userScore: updatedWine?.scoring?.userScore,
      },
    };
    setWine(nextWine);
  };

  // LOCAL FUNCTIONS
  const fetchWine = useCallback(async (): Promise<void> => {
    try {
      const result = await makeApiCall<Wine>({
        authToken: auth.token,
        dispatch: dispatch,
        callId: FETCH_WINE_KEY,
        request: {
          url: `${apiBaseUrl}/wines/${wineId}`,
          httpMethod: 'GET',
        },
      });
      setWine(result);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [apiBaseUrl, auth.token, dispatch, wineId]);

  const scoreClickHandler = (scoreStep: number): void => {
    showModal(dispatch, ScoreAWineModalId, {
      params: { wine: wine, scoreStep: scoreStep },
      callback: (data: Wine) => updateWineScore(data),
    });
  }
  useEffect(() => {
    clearApiCallStatus(dispatch, FETCH_WINE_KEY);
    fetchWine();
  }, [dispatch, fetchWine]);

  useEffect(() => {
    if (editAction === undefined) {
      return;
    }
    scoreClickHandler(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editAction]);
  return (
    <>
      <Hideable hide={!!hideBackHeader}>
        <BackHeader
          backHref={`/tastings/details/room/${wine?.tastingId}`}
        >
          <FormattedMessage id="wineScoreSummary.header.caption" />
        </BackHeader>
      </Hideable>
      <IonContent>
        <ScoreSummary
          loadingStatus={GetLoadingStatus(fetchWineApiCall?.status)}
          wine={wine}
          tasting={wine?.tasting}
          editClickHandler={(wine?: Wine): void => {
            if (wine?.approvalStatus !== WineStatus.Approved) {
              showModal(dispatch, EditWineInTastingModalId, {
                params: { wine },
                callback: () => fetchWine(),
              });
            }
          }}
          scoreClickHandler={scoreClickHandler}
        />
      </IonContent>
    </>
  );
};

export default ViewWineScoreSummaryContainer;
