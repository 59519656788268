import { faAward } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Flex } from 'components/baseElements/grid';
import * as React from 'react';
import { Hideable } from '../hideable';

interface RankingButtonProps {
  color?: string;
  rank?: number | null;
  size?: number;
}

export default function RankingButton(props: RankingButtonProps): JSX.Element {
  const { color = 'light', rank, size = 40 } = props;
  return (
    <Flex
      $alignItems="center"
      $backgroundColor={'var(--ion-color-' + color + ')'}
      $border={'2px solid var(--ion-color-' + color + '-shade)'}
      $borderRadius={7}
      $color={'var(--ion-color-' + color + ')'}
      $fontSize={size * .55}
      $justifyContent="center"
      $position="relative"
      $maxHeight={size}
      $minHeight={size}
      $maxWidth={size}
      $minWidth={size}
      $size={size}
    >
      <Hideable hide={!rank}>
        <Box
          $color="#333"
          $fontFamily="Arial"
          $fontWeight="bold"
          $fontSize="8px"
          $lineHeight="10px"
          $left="0"
          $position="absolute"
          $margin="0 auto"
          $size={size}
          $textAlign="center"
          $top="12px"
          $width="100%"
          $zIndex={1}>
          {rank}
        </Box>
      </Hideable>
      <FontAwesomeIcon icon={faAward} color={'var(--ion-color-' + color + '-shade)'}/>
    </Flex>
  );
}
