import WineForm from 'components/wine/WineForm';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { AppSettingsState } from 'store/appSettings';
import { guiStateUpdateToast } from 'store/gui/guiStateUpdateToast';
import { Flight, Wine } from 'store/wines';
import { GetSortedFlights } from 'store/wines/utils/helpers';
import { toRequestEditWineTastingDto } from 'store/wines/utils/mappers';
import { clearApiCallStatus, makeApiCall } from 'utils/api';
import { LoadingStatus } from 'utils/formValidation';
import { schema } from './validation';

interface EditWineInTastingContainerProps {
  loadingStatus?: LoadingStatus;
  setLoadingStatus?: (status: LoadingStatus) => void;
  flights?: Array<Flight>;
  successHandler?: (tastingId: string) => any;
  wine?: Wine;
  tastingId: string;
}

function EditWineInTastingContainer(props: EditWineInTastingContainerProps): JSX.Element {
  const {
    flights,
    loadingStatus,
    setLoadingStatus,
    successHandler,
    tastingId,
    wine
  } = props;
  // CONSTANT DECLARATIONs
  const EDIT_WINE_KEY = 'editWine';
  const dispatch = useDispatch();
  // LOCAL (CONTAINER) STATE SETUP
  const [uploadedFileId, setUploadedFileId] = useState<string>(wine?.imageFileId || '');

  // GLOBAL (REDUX) STATE SETUP
  const auth = useSelector((state: RootState) => state.authorization);
  const { apiBaseUrl, cameraApiSettings } = useSelector((state: RootState): AppSettingsState => state.appSettings);
  const editWineApiCall = useSelector((state: RootState) =>
    state.api.callStatuses.find(e => e.id === EDIT_WINE_KEY),
  );

  const saveWine = async (wineToSave: Wine): Promise<void> => {
    if (!wineToSave || loadingStatus === LoadingStatus.Loading) {
      return;
    }
    if (setLoadingStatus) {
      setLoadingStatus(LoadingStatus.Loading);
    }
    try {
      await makeApiCall({
        authToken: auth.token,
        dispatch: dispatch,
        callId: EDIT_WINE_KEY,
        request: {
          url: `${apiBaseUrl}/wines/${wineToSave.id}`,
          httpMethod: 'PUT',
          body: toRequestEditWineTastingDto({ ...wineToSave, imageFileId: uploadedFileId }),
        }
      });
      if (successHandler) {
        successHandler(tastingId);
      }
    }
    catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    if (setLoadingStatus) {
      setLoadingStatus(LoadingStatus.Complete);
    }
  };

  // LOCAL FUNCTIONS
  const wineSubmitAction = async (data: any): Promise<void> => {
    await saveWine({ ...wine, ...data });
  };

  const onUploadError = (): void => {
    dispatch(
      guiStateUpdateToast({
        message: 'api.defaults.failureMessage',
      })
    );
  };
  const onUploadSuccess = (ids: Array<string>): void => {
    setUploadedFileId(ids[0]);
  }
  // EFFECTS SETUP
  useEffect(() => {
    clearApiCallStatus(dispatch, EDIT_WINE_KEY);
  }, [dispatch]);
  return (
    <>
      <WineForm
        apiBaseUrl={apiBaseUrl}
        cameraApiSettings={cameraApiSettings}
        onUploadError={onUploadError}
        onUploadSuccess={onUploadSuccess}
        submitAction={wineSubmitAction}
        serverValidation={editWineApiCall?.validationErrors}
        uploadUrl={`${apiBaseUrl}/files/upload`}
        uploadedFileId={uploadedFileId}
        validationSchema={schema}
        flights={GetSortedFlights(flights)}
        wine={wine}
      />
    </>
  );
}

export default EditWineInTastingContainer;
