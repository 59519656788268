import { IonSegment, IonSegmentButton } from '@ionic/react';
import { Box } from 'components/baseElements/grid';
import { Hideable } from 'components/baseElements/hideable';
import { IonLabel } from 'components/ionicComponents';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ETastingAttendeeFilter,
  getTastingAttendeesWhoAreGoing,
  getTastingAttendeesWhoAreInvited,
  getTastingAttendeesWhoCantGo,
  TastingAttendee,
} from '../../../store/tastingAttendees';

interface AttendeeListFilterProps {
  changeFilter?: (filter: ETastingAttendeeFilter) => void;
  tastingAttendees?: TastingAttendee[];
  showViewAsHost?: boolean;
}

export default function AttendeeListFilter(props: AttendeeListFilterProps): JSX.Element {
  const { changeFilter, showViewAsHost, tastingAttendees } = props;
  const [filter, setFilter] = useState<ETastingAttendeeFilter>(ETastingAttendeeFilter.Going);
  const handleChangeFilter = (filter: ETastingAttendeeFilter): void => {
    setFilter(filter);
    if (changeFilter) {
      changeFilter(filter);
    }
  };
  return (
    <Box $borderBottom="1px solid var(--ion-color-dark)">
      <IonSegment
        mode="md"
        onIonChange={(e): void => handleChangeFilter(e.detail.value as ETastingAttendeeFilter)}
        value={filter}
      >
        <IonSegmentButton value={ETastingAttendeeFilter.Going}>
          <IonLabel>
            <FormattedMessage id="viewAttendeeListFilter.segment.going" />&nbsp;
            ({getTastingAttendeesWhoAreGoing(tastingAttendees).length})
          </IonLabel>
        </IonSegmentButton>
        <Hideable hide={!showViewAsHost}>
          <IonSegmentButton value={ETastingAttendeeFilter.Invited}>
            <IonLabel>
              <FormattedMessage id="viewAttendeeListFilter.segment.invited" />&nbsp;
              ({getTastingAttendeesWhoAreInvited(tastingAttendees).length})
            </IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value={ETastingAttendeeFilter.CantGo}>
            <IonLabel>
              <FormattedMessage id="viewAttendeeListFilter.segment.cantGo" />&nbsp;
              ({getTastingAttendeesWhoCantGo(tastingAttendees).length})
            </IonLabel>
          </IonSegmentButton>
        </Hideable>
      </IonSegment>
    </Box>
  );
}
