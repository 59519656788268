import { ApiCall, CustomHookProps, useBasicMutation } from 'napa-react-core';
import { UseMutationResult } from 'react-query/types/react/types';
import { TastingAttendee } from '../../store/tastingAttendees';

export default function usePostTastingAttendee(
  tastingId: string | undefined,
  props?: CustomHookProps<TastingAttendee>,
): UseMutationResult<TastingAttendee, ApiCall<TastingAttendee>> {
  return useBasicMutation<TastingAttendee>({
    apiRoute: `tastings/${tastingId}/tastingAttendee`,
    httpMethod: 'POST',
    isFormData: false,
    params: props,
  });
}
