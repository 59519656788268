import { yupResolver } from '@hookform/resolvers/yup';
import { IonContent, IonList } from '@ionic/react';
import CheckboxList, {
  CheckboxGroup,
  getSelectedValuesAsPipedList,
} from 'components/baseElements/formControls/CheckboxList';
import HookInput from 'components/baseElements/formControls/HookInput';
import Form from 'components/baseElements/formControls/StyledForm';
import { StepBaseProperties } from 'components/wineScores/types';
import { ScoreAWineHeader } from 'containers/wineScores/ScoreAWineContainer/ScoreAWineHeader';
import React, { FunctionComponent, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { getWineProfilesAsCheckboxGroups } from 'store/wineScores';
import { handleFormDataReset } from 'utils/formValidation';
import StepBottomButtons from '../StepBottomButtons';
import StepHeader from '../StepHeader';
import StepMidHeaderButtons from '../StepMidHeaderButtons';

const ScoreAWineFlavorsStep: FunctionComponent<StepBaseProperties> = (
  props: StepBaseProperties,
) => {
  const {
    loadingStatus,
    modalId,
    onSaveAndClose,
    stepName,
    nextAction,
    previousAction,
    validationSchema,
    score,
    nextStepName,
    showNextStepButton,
    previousStepName,
    showPreviousStepButton,
    stepNumber,
    totalSteps,
  } = props;

  const form = useForm({
    defaultValues: {
      flavors: '',
    },
    resolver: yupResolver(validationSchema),
  });

  const checkboxChangedAction = (data: Array<CheckboxGroup>): void => {
    form.setValue('flavors', getSelectedValuesAsPipedList(data));
  };

  useEffect(() => {
    handleFormDataReset(score, form.reset);
  }, [score, form.reset]);

  return (
    <>
      <ScoreAWineHeader
        modalId={modalId}
        onSaveAndClose={(): any => onSaveAndClose(form.getValues())}
        stepName={stepName}
      />
      <IonContent>
        <StepMidHeaderButtons
          loadingStatus={loadingStatus}
          nextClickedAction={(): any => nextAction(form.getValues())}
          previousClickedAction={(): any => previousAction(form.getValues())}
          nextStepName={nextStepName}
          showNextStepButton={showNextStepButton}
          previousStepName={previousStepName}
          showPreviousStepButton={showPreviousStepButton}
        />
        <StepHeader
          modalId={modalId}
          stepName="scoreWine.flavorsStep.title"
          stepNumber={stepNumber}
          totalSteps={totalSteps}
        />
        <Form>
          <HookInput
            form={form}
            hidden
            name="flavors"
          />
          <IonList>
            <CheckboxList
              checkboxGroups={getWineProfilesAsCheckboxGroups(score.flavors)}
              checkboxChangedAction={checkboxChangedAction}
              localizationPrefix="scoring.profiles"
            />
          </IonList>
        </Form>
      </IonContent>
      <StepBottomButtons
        loadingStatus={loadingStatus}
        nextClickedAction={(): any => nextAction(form.getValues())}
        previousClickedAction={(): any => previousAction(form.getValues())}
        nextStepName={nextStepName}
        onSaveAndClose={(): any => onSaveAndClose(form.getValues())}
        showNextStepButton={false}
        previousStepName={previousStepName}
        showPreviousStepButton={false}
      />
    </>
  );
};

export default ScoreAWineFlavorsStep;
