import { WineListItemType } from '../WineListItem';

export const showAvgVsYouTitles = (type: WineListItemType): boolean => {
  switch (type) {
    case WineListItemType.TastingResultsWineScoreAsAttendee:
    case WineListItemType.TastingResultsWineScoreAsHost: {
      return true;
    }
    default:
      return false;
  }
};


export const showTotalVsYouTitles = (type: WineListItemType): boolean => {
  switch (type) {
    case WineListItemType.TastingResultsWineRankAsAttendee:
    case WineListItemType.TastingResultsWineRankAsHost: {
      return true;
    }
    default:
      return false;
  }
};
