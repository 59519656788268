import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';

dayjs.extend(isoWeek);

export const toNextHour =(date: Date): dayjs.Dayjs => {
  let value = dayjs(date);
  value = value.add(1, 'h');
  value = value.minute(0);
  value = value.millisecond(0);
  return value;
}
export const daysUntilNextDayOfTheWeek = (current: number, desired = 1): number => {
  const daysUntilNextDayOfWeek = (desired - current + 7) % 7;
  return daysUntilNextDayOfWeek === 0 ? 7 :daysUntilNextDayOfWeek ;
};

export const getThisWeekend = (current: dayjs.Dayjs): { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs } => {
  const startOfDay = current.startOf('d');
  const offset = daysUntilNextDayOfTheWeek(startOfDay.isoWeekday(), 6);
  const startDate = startOfDay.add(offset, 'd');
  const endDate = dayjs(startDate).add(2, 'd').subtract(1, 's');
  return { startDate, endDate }
};

export const getNextWeek = (current: dayjs.Dayjs): { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs } => {
  const startOfDay = current.startOf('d');
  const offset = daysUntilNextDayOfTheWeek(startOfDay.isoWeekday(), 1);
  const startDate = startOfDay.add(offset, 'd');
  const endDate = dayjs(startDate).add(7, 'd').subtract(1, 's');
  return { startDate, endDate }
};

export const getNextWeekend = (current: dayjs.Dayjs): { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs } => {
  const startOfDay = current.startOf('d');
  const offset = daysUntilNextDayOfTheWeek(startOfDay.isoWeekday(), 6);
  const startDate = startOfDay.add(offset + 7, 'd');
  const endDate = dayjs(startDate).add(2, 'd').subtract(1, 's');
  return { startDate, endDate }
};
