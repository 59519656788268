
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { makeApiCall, clearApiCallStatus, GetLoadingStatus } from 'utils/api';
import { Flight } from 'store/wines';
import DeleteFlight from 'components/flights/DeleteFlight';

interface DeleteFlightInTastingContainerProps {
  successHandler?: (tastingId: string) => any;
  flight?: Flight;
  tastingId: string;
}

function DeleteFlightInTastingContainer(props: DeleteFlightInTastingContainerProps): JSX.Element {
  const { successHandler, tastingId } = props;

  // CONSTANT DECLARATIONs
  const DELETE_FLIGHT_KEY = 'deleteFlight';
  const dispatch = useDispatch();

  // LOCAL (CONTAINTER) STATE SETUP
  const [flight, setFlight] = useState(props.flight as Flight | undefined);

  // GLOBAL (REDUX) STATE SETUP
  const auth = useSelector((state: RootState) => state.authorization);
  const apiBaseUrl = useSelector((state: RootState): string => state.appSettings.apiBaseUrl);
  const deleteFlightApiCall = useSelector((state: RootState) =>
    state.api.callStatuses.find(e => e.id === DELETE_FLIGHT_KEY),
  );

  const deleteFlight = async (flightToDelete: Flight): Promise<void> => {
    if (!flightToDelete) {
      return;
    }
    try {
      await makeApiCall({
        authToken: auth.token,
        dispatch: dispatch,
        callId: DELETE_FLIGHT_KEY,
        request: {
          url: `${apiBaseUrl}/flights/${flightToDelete.id}`,
          httpMethod: 'DELETE',
          body: flightToDelete
        }
      });
      if (successHandler) {
        successHandler(tastingId);
      }
    }
    catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  // LOCAL FUNCTIONS
  const flightDeleteAction = async (data: any): Promise<void> => {
    await deleteFlight(data);
    setFlight(data);
  };

  // EFFECTS SETUP
  useEffect(() => {
    clearApiCallStatus(dispatch, DELETE_FLIGHT_KEY);
  }, [dispatch]);
  
  return (
    <DeleteFlight
      loadingStatus={GetLoadingStatus(deleteFlightApiCall?.status)}
      deleteAction={flightDeleteAction}
      flight={flight}
    />    
  );
}

export default DeleteFlightInTastingContainer;