import React, { useState } from 'react';
import {
  IonItem,
  IonList,
  IonReorderGroup,
  IonReorder,
  IonContent,
} from '@ionic/react';
import { Flight } from 'store/wines';
import { ItemReorderEventDetail } from '@ionic/core';
import arrayMove from 'array-move';
import { IonListHeader, IonText } from 'components/ionicComponents';


interface ReorderFlightsProperties {
  data: Array<Flight>;
  moveHandler: (data: Array<Flight>) => any;
}

export default function ReorderFlights(props: ReorderFlightsProperties): JSX.Element {
  const {
    data,
    moveHandler
  } = props;

  const [orderedData, setOrderedData] = useState(data);

  const doReorder = (event: CustomEvent<ItemReorderEventDetail>): void => {
    const arr = [...orderedData];
    const orderedArr = arrayMove(arr, event.detail.from, event.detail.to)
    setOrderedData(orderedArr);
    moveHandler(orderedArr);
    // Finish the reorder and position the item in the DOM based on
    // where the gesture ended. This method can also be called directly
    // by the reorder group
    event.detail.complete();
  }

  return (
    <IonContent>
      <IonList>
        <IonReorderGroup id="reorder" disabled={false} onIonItemReorder={doReorder}>
          {data && data.map((item: Flight, key: any) => (
            <IonItem key={key}>
              <IonListHeader>
                <IonText ellipsis={2}>
                  {item.name}
                </IonText>
              </IonListHeader>
              <IonReorder slot="end" />
            </IonItem>
          ))}
        </IonReorderGroup>
      </IonList>
    </IonContent>
  )
}