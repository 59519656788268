import { faEdit } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonItem } from '@ionic/react';
import { Flex } from 'components/baseElements/grid';
import { Heading } from 'components/baseElements/typography';
import { IonButton } from 'components/ionicComponents';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

interface ScoreSummaryStepProps {
  children?: any;
  label?: string;
  lines?: 'full' | 'inset' | 'none' | undefined;
  scoreClickHandler: (scoreStep: number) => any;
  scoreStep: number;
}

const ScoreSummaryStep: FunctionComponent<ScoreSummaryStepProps> = (
  props: ScoreSummaryStepProps,
) => {
  const {
    children,
    label,
    lines,
    scoreClickHandler,
    scoreStep
  } = props;

  return (
    <IonItem lines={lines}>
      <Flex flexDirection="column" pb={3} width={1}>
        <Flex alignItems="center" mt={1}>
          <Heading headingSize="xs" m={0}>
            <FormattedMessage id={label} />
          </Heading>
          <IonButton
            fill="clear"
            onClick={(): void => scoreClickHandler(scoreStep)}
            paddingStart="4px"
          >
            <FontAwesomeIcon icon={faEdit} />
          </IonButton>
        </Flex>
        {children}
      </Flex>
    </IonItem>
  );
}

export default ScoreSummaryStep;
