import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonModal,
  IonToolbar,
} from '@ionic/react';
import { close } from 'ionicons/icons';
import { HeaderSubmitButton } from 'components/baseElements/header';
import { IonButton, IonTitle } from 'components/ionicComponents';
import { PrivatePropertyContainer } from 'containers/authorization/PrivatePropertyContainer';
import { RootState } from 'store';
import { hideModal } from 'utils/modals';
import EditFlightInTastingContainer from 'containers/flights/EditFlightInTastingContainer';
import { LoadingStatus } from 'utils/formValidation';

export const EditFlightInTastingModalId = 'EditFlight';

export default function EditFlightInTastingModal(): JSX.Element {
  const dispatch = useDispatch();
  const modalProps = useSelector((state: RootState) =>
    state.gui.modals.find(e => e.id === EditFlightInTastingModalId),
  );

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.None);
  return (
    <IonModal
      isOpen={modalProps?.show || false}
      onDidDismiss={(): void => hideModal(dispatch, EditFlightInTastingModalId, {})}
    >
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={(): void => hideModal(dispatch, EditFlightInTastingModalId, {})}>
              <IonIcon icon={close} slot="icon-only" />
            </IonButton>
          </IonButtons>
          <IonTitle>
            <FormattedMessage id="editFlightModal.header.title" />
          </IonTitle>
          <IonButtons slot="end">
            <HeaderSubmitButton
              form="flightForm"
              isLoading={loadingStatus === LoadingStatus.Loading}
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <PrivatePropertyContainer modalCustomMessageComponent={(<div>This is a custom message in the register popup for the edit flight in tasting page</div>)}>
          <PrivatePropertyContainer.Unauthorized>
            This is what the add flight to tasting modal looks like when you are not logged in.
          </PrivatePropertyContainer.Unauthorized>
          <PrivatePropertyContainer.Authorized>
            <EditFlightInTastingContainer
              flight={modalProps?.params?.flight}
              loadingStatus={loadingStatus}
              setLoadingStatus={setLoadingStatus}
              successHandler={(): void => hideModal(dispatch, EditFlightInTastingModalId, modalProps?.params, modalProps?.callback, { modified: true })}
              tastingId={modalProps?.params?.tastingId}
            />
          </PrivatePropertyContainer.Authorized>
        </PrivatePropertyContainer>
      </IonContent>
    </IonModal>
  );
}
