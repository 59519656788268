import { IonCheckbox, IonItem, IonList } from '@ionic/react';
import { Loadable } from 'components/baseElements/loadable';
import { Heading } from 'components/baseElements/typography';
import { IonLabel } from 'components/ionicComponents';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { LoadingStatus } from 'utils/formValidation';
import { Box } from '../../baseElements/grid';
import { Hideable } from '../../baseElements/hideable';
import { Paragraph } from '../../baseElements/typography';
import { schema } from './validation';

interface AttendeeSearchProps {
  searchText?: string;
  loadingStatus?: LoadingStatus;
  updateInvite: (invited: boolean) => void;
}

export default function AttendeeSearch(props: AttendeeSearchProps): JSX.Element {
  const {
    loadingStatus,
    searchText,
    updateInvite,
  } = props;
  const [checked, setChecked] = useState(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  const handleInvite = (nextChecked: boolean): void => {
    setChecked(nextChecked);
    updateInvite(nextChecked);
  };
  useEffect(() => {
    // Create an scoped async function in the hook
    async function autoValidateSchema(): Promise<void> {
      const contactEmailAddressIsValid = await schema.isValid({ searchText });
      setIsValid(contactEmailAddressIsValid);
    }

    // Execute the created function directly
    autoValidateSchema().catch(() => undefined);
  }, [searchText]);
  return (
    <>
      <Loadable isLoading={loadingStatus === LoadingStatus.Loading}>
        <Loadable.Loaded>
          <IonList>
            <Hideable hide={!searchText || isValid}>
              <IonItem class="ion-text-center" lines="none">
                <FormattedMessage id="inviteAttendeesModal.header.emptyState"/>
              </IonItem>
            </Hideable>
            <Hideable hide={!isValid}>
              <IonItem onClick={(): void => handleInvite(!checked)}>
                <Box slot="start">
                  <IonCheckbox checked={checked}/>
                </Box>
                <IonLabel>
                  <Paragraph>Invite via email</Paragraph>
                  <Heading fontFamily="var(--ion-font-family)" headingSize="lg">{searchText}</Heading>
                </IonLabel>
              </IonItem>
            </Hideable>
          </IonList>
        </Loadable.Loaded>
      </Loadable>
    </>
  );
}
