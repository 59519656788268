import ForgotPasswordForm from 'components/users/ForgotPasswordForm';
import { useToast } from 'napa-react-core';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { GetLoadingStatus } from 'utils/api';
import usePostForgotPassword from '../../../hooks/users/usePostForgotPassword';
import { schema } from './validation';

interface ForgotPasswordContainerProps {
  successHandler?: () => any;
}

const ForgotPasswordContainer: React.FC<ForgotPasswordContainerProps> = (props: ForgotPasswordContainerProps) => {
  // CONSTANT DECLARATION
  const { successHandler } = props;
  const FORGOT_PASSWORD_KEY = 'forgotPassword';
  const intl = useIntl();
  const { showToast } = useToast();

  // LOCAL (CONTAINER) STATE SETUP
  const { mutate: mutatePostForgotPassword } = usePostForgotPassword({
    errorHandler: (): void => {
      showToast({ message: intl.formatMessage({ id: 'api.defaults.failureMessage' }) });
    },
    successHandler: (): void => {
      if (successHandler) {
        successHandler();
      }
      showToast({
        duration: 3000,
        message: intl.formatMessage({ id: 'forgotPasswordContainer.toasts.success' }),
        show: true,
      });
    },
  });

  // GLOBAL (REDUX) STATE SETUP
  const forgotPasswordApiCall = useSelector((state: RootState) =>
    state.api.callStatuses.find(e => e.id === FORGOT_PASSWORD_KEY),
  );

  return (
    <ForgotPasswordForm
      loadingStatus={GetLoadingStatus(forgotPasswordApiCall?.status)}
      submitAction={mutatePostForgotPassword}
      serverValidation={forgotPasswordApiCall?.validationErrors}
      validationSchema={schema}
    />
  );
};

export default ForgotPasswordContainer;
