import {
  API_STATE_UPDATE_CALL_STATUS,
  apiStateUpdateCallStatusReducer,
} from './actions/apiStateUpdateCallStatus';
import { ApiState } from './types';
import { Reducer, createSubReducer } from 'utils/store';

const initialState = {
  callStatuses: [],
};

const reducers: Array<Reducer<ApiState>> = [
  {
    type: API_STATE_UPDATE_CALL_STATUS,
    reducer: apiStateUpdateCallStatusReducer,
  },
];

export const apiStateReducer = createSubReducer<ApiState>(
  initialState,
  reducers,
);
