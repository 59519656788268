import { faEdit } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Hideable } from 'components/baseElements/hideable';
import React from 'react';
import { Highlight } from 'react-highlighter-ts';
import { Wine } from 'store/wines';
import { getFullWineName } from 'store/wines/utils';
import { MasterWine } from '../../../store/masterWines';

interface FormattedWineProps {
  canEdit?: boolean;
  highlight?: string;
  onEditWineClick?: (wine: Wine | MasterWine) => void;
  wine: Wine | MasterWine | undefined;
}

export function FormattedWine(props: FormattedWineProps): JSX.Element {
  const {
    canEdit,
    highlight,
    onEditWineClick,
    wine,
  } = props;
  if (!wine) {
    return <></>;
  }

  return <>
    <Highlight
      ignoreDiacritics
      search={highlight}
    >
      {getFullWineName(wine)}
    </Highlight>
    <Hideable hide={!canEdit}>
      <FontAwesomeIcon
        color="var(--ion-color-primary)"
        icon={faEdit}
        size="sm" style={{ marginLeft: '6px' }}
        onClick={(): void => onEditWineClick && onEditWineClick(wine)}
      />
    </Hideable>
  </>;
}
