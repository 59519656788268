import * as React from 'react';
import { IonIcon, IonItem } from '@ionic/react';
import { IonLabel, IonListHeader } from 'components/ionicComponents';
import { IonCheckbox } from 'components/ionicComponents/IonCheckbox';
import { FormattedMessage } from 'react-intl';
import { FunctionComponent } from 'react';
import { Flex, Box } from 'components/baseElements/grid';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState
} from 'react-accessible-accordion';
import { chevronDownOutline, chevronUpOutline } from 'ionicons/icons';

export interface CheckboxItem {
  label: string;
  value: string;
  icon?: FunctionComponent;
  checked: boolean;
}

export interface CheckboxGroup {
  name: string;
  checkboxes?: Array<CheckboxItem>;
  groups?: Array<CheckboxGroup>;
}

interface CheckboxListProps {
  checkboxGroups: Array<CheckboxGroup>;
  checkboxChangedAction?: (data: Array<CheckboxGroup>) => any;
  localizationPrefix: string;
}

export function getSelectedValuesAsPipedList(data: Array<CheckboxGroup>): string {
  let result = '|';
  data.forEach((group: CheckboxGroup) => {
    if (group.checkboxes) {
      group.checkboxes.forEach((cb: CheckboxItem) => {
        if (cb.checked) {
          result += cb.value + '|';
          return;
        }
      })
    }
    if (group.groups) {

      group.groups.forEach((subGroup: CheckboxGroup) => {
        if (subGroup.checkboxes) {
          subGroup.checkboxes.forEach((cb: CheckboxItem) => {
            if (cb.checked) {
              result += cb.value + '|';
              return;
            }
          })
        }
      })
    }
  });

  return result;
}

export default function CheckboxList(props: CheckboxListProps): JSX.Element {
  const {
    checkboxGroups,
    localizationPrefix,
    checkboxChangedAction
  } = props;
  const [checkboxData, setCheckboxData] = React.useState(checkboxGroups);

  const findCheckbox = (data: Array<CheckboxGroup>, checkbox: CheckboxItem): CheckboxItem | null => {
    let item: CheckboxItem | null = null;
    data.forEach((group: CheckboxGroup) => {
      if (group.checkboxes) {
        group.checkboxes.forEach((cb: CheckboxItem) => {
          if (checkbox.value === cb.value) {
            item = cb;
            return;
          }
        })
      }
      if (group.groups) {

        group.groups.forEach((subGroup: CheckboxGroup) => {
          if (subGroup.checkboxes) {
            subGroup.checkboxes.forEach((cb: CheckboxItem) => {
              if (checkbox.value === cb.value) {
                item = cb;
                return;
              }
            })
          }
        })
      }
    });

    return item;
  }

  const handleCheckAction = (e: any, checkbox: CheckboxItem): void => {
    const data = [...checkboxData];
    const cb = findCheckbox(data, checkbox);
    if (!cb) {
      return;
    }
    cb.checked = e.detail.checked;
    setCheckboxData(data);
    if (checkboxChangedAction) {
      checkboxChangedAction(data);
    }
  }

  const checkboxMapper: any = (item: CheckboxItem, key: any) => (
    <IonItem key={key}>
      <IonLabel>
        <Flex alignItems="center">
          <Flex
            alignItems="center"
            justifyContent="center"
            size={18}
          >
            {item.icon ? React.createElement(item.icon, {}) : undefined}
          </Flex>
          <Box ml={3}>
            <FormattedMessage id={`${localizationPrefix}.${item.label}`} />
          </Box>
        </Flex>
      </IonLabel>
      <IonCheckbox
        checked={item.checked}
        onIonChange={(e: any): void => handleCheckAction(e, item)}
        slot="start"
        value={item.value}
      />
    </IonItem>
  )

  let subGroupData = {};
  checkboxData.forEach((group: CheckboxGroup) => {
    subGroupData = !!group.groups ? group.groups.reduce((accum, subGrp) => {
      return {
        ...accum,
        [`${subGrp.name}`]: subGrp.checkboxes?.filter(cb => cb.checked).length,
      };
    }, subGroupData) : {};
  });
  return (
    <Accordion
      allowMultipleExpanded
      allowZeroExpanded
      preExpanded={Object.keys(subGroupData).filter(subName => subGroupData[subName] > 0)}
    >
      {checkboxData.map((group: CheckboxGroup, key: any) => (
        <React.Fragment key={key}>
          <IonListHeader>
            <FormattedMessage id={`${localizationPrefix}.${group.name}`} />
          </IonListHeader>
          {group.checkboxes && group.checkboxes.map(checkboxMapper)}
          {group.groups && group.groups.map((subGroup: CheckboxGroup, key: any) => (
            <AccordionItem key={key} uuid={subGroup.name}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <IonItem>
                  <AccordionItemState>
                  {({ expanded }): JSX.Element => (
                    <Flex width={1} justifyContent="space-between">
                      <IonLabel color={expanded ? 'primary' : ''}>
                        <FormattedMessage id={`${localizationPrefix}.${subGroup.name}`} />
                        {` (${subGroupData[subGroup.name]} selected)`}
                      </IonLabel>
                      <IonIcon
                        color={expanded ? 'primary' : undefined}
                        icon={expanded ? chevronDownOutline : chevronUpOutline}
                        slot="start"
                      />
                    </Flex>
                  )}
                  </AccordionItemState>
                  </IonItem>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                {subGroup.checkboxes && subGroup.checkboxes.map(checkboxMapper)}
              </AccordionItemPanel>
            </AccordionItem>
          ))}
        </React.Fragment>
      ))}
    </Accordion>
  );
}
