import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgMeatIcon(props) {
  return (
    <svg width={18} height={14} fill="none" {...props}>
      <path
        d="M1.622 8.14c.017.055.228.651.26.345.054-.492-.11-1.015-.138-1.506a3.949 3.949 0 01.334-1.56c.635-1.45 2.454-1.99 3.782-2.57.085-.038.629-.238.58-.381-.049-.15-.74.077-.838.104-.745.204-1.498.423-2.172.809-1.523.87-2.46 2.394-1.972 4.183.05.194.103.387.164.577zM12.964 8.868c-1.25.755-2.59 1.386-4.007 1.757a11.138 11.138 0 01-4.122.162c-.641-.094-1.276-.352-1.844-.655a3.109 3.109 0 01-.805-.582c-.066-.069-.682-.815-.794-.725-.265.213 1.153 1.46 1.32 1.584.612.379 1.318.709 2.03.836 1.587.284 3.192.278 4.74-.21 1.655-.523 3.168-1.337 4.584-2.332.56-.393 1.145-.79 1.605-1.3.247-.274.466-.582.63-.911.046-.092.389-1.049.239-1.111-.076-.032-.235.463-.246.494-.397 1.426-2.168 2.292-3.33 2.993z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M17.912 4.435c.035-1.382-.305-2.688-1.438-3.57-1.317-1.027-2.766-1.057-4.287-.52-1.551.547-2.877 1.106-4.561.917-1.886-.211-3.542.076-5.193 1.051C1.042 3.135.159 4.275.034 5.917c-.101 1.337.018 2.9.403 4.185 1.06 3.53 5.816 3.78 8.78 2.961 2.363-.652 4.657-1.935 6.57-3.458.771-.613 1.378-1.39 1.716-2.32.166-.453.27-.926.342-1.403.05-.34.278-1.152.067-1.447zM16.85 6.033c-.155 1.345-.897 2.448-1.978 3.254.305-.227.541-.403 0 .001l-.486.362.484-.36c-1.692 1.26-3.588 2.373-5.613 3.002a9.591 9.591 0 01-3.214.422c-.374-.014-.219-.19-.32-.488-.156-.464-.445.225-.438.432-.693-.085-.27-.265-.55-.723-.306-.5-.463.247-.44.54-.297-.072-.289-.101-.367-.369-.055-.187-.087-.388-.12-.58-.034-.202-.228-.35-.333-.087-.112.245-.116.502-.074.763-.457-.187-.46-.599-.514-1.067-.012-.096-.112-.445-.262-.253-.152.193-.184.532-.166.765-1.11-.913-1.174-2.2-1.381-3.486-.199-1.232-.322-2.575.314-3.708.66-1.18 1.981-1.968 3.263-2.294 1.419-.36 2.8-.12 4.233-.148 1.426-.027 2.671-.756 4-1.177 1.397-.443 2.724-.08 3.597 1.143.89 1.247.529 2.652.365 4.055zm-2.468 3.62c-.02.014-.017.013 0 0z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M4.269 6.276c-.024.614.873.488 1.255.449.953-.098 2.244-.507 3.176-.199.813.27 1.107 1.232 1.933 1.456.429.117.786-.152.981-.52.288-.54-.155-.8-.461-1.184-.259-.325-.454-.708-.486-1.128-.039-.514.33-.958.273-1.451-.13-1.132-1.456.072-1.805.31-.837.572-1.838.706-2.81.887-.752.14-2.018.39-2.056 1.38zm5.75-2.012c.272.131-.037 1.07-.014 1.31.048.503.516.978.874 1.293.393.344-.383.257-.547.237-.334-.387-.681-.804-1.144-1.04-.513-.263-1.084-.225-1.639-.16-.819.095-1.634.224-2.458.27.062-.273.018-.359.3-.47.215-.084.438-.147.665-.186.43-.074.862-.136 1.292-.208l-.062.01.064-.01c.402-.068.146-.025 0 0 .61-.103 1.216-.262 1.746-.591.194-.12.67-.577.923-.455zM13.475 4.629c.264-.148.583-.35.746-.62.094-.154.3-.881-.118-.553-.182.14-.341.285-.5.452-.095.1-.667 1.023-.128.72zM14.725 4.396c-.017.008-.031.014 0 0 .046-.02.021-.01 0 0 .226-.1.476-.241.594-.47.071-.137.183-.592-.15-.48-.178.061-.328.244-.452.376-.092.098-.45.774.008.574z"
        fill="var(--ion-color-dark)"
      />
    </svg>
  );
}

export default SvgMeatIcon;
