import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgGingerIcon(props) {
  return (
    <svg width={13} height={18} fill="none" {...props}>
      <path
        d="M2.7 8.1a.3.3 0 000-.6c-.827 0-1.5.673-1.5 1.5s.673 1.5 1.5 1.5a.3.3 0 000-.6.901.901 0 010-1.8zM7.5 1.2c-1.82 0-3.3 1.48-3.3 3.3 0 .78.282 1.54.795 2.14a.3.3 0 10.456-.39A2.697 2.697 0 014.8 4.5c0-1.489 1.211-2.7 2.7-2.7a.3.3 0 000-.6zM9.087 10.287A.316.316 0 009 10.5c0 .078.033.156.087.213a.315.315 0 00.213.087.315.315 0 00.213-.087.315.315 0 00.087-.213.316.316 0 00-.087-.213.314.314 0 00-.426 0zM4.887 9.987a.31.31 0 00-.087.213c0 .078.033.156.087.213a.315.315 0 00.213.087.315.315 0 00.213-.087.315.315 0 00.087-.213.316.316 0 00-.087-.213.314.314 0 00-.426 0zM3.387 8.187A.31.31 0 003.3 8.4a.31.31 0 00.087.213.315.315 0 00.213.087.315.315 0 00.213-.087A.315.315 0 003.9 8.4a.315.315 0 00-.087-.213.314.314 0 00-.426 0zM7.887 12.087a.316.316 0 00-.087.213c0 .078.033.156.087.213a.315.315 0 00.213.087.315.315 0 00.213-.087.316.316 0 00.087-.213.316.316 0 00-.087-.213.314.314 0 00-.426 0zM9.3 14.4a.315.315 0 00.213-.087.315.315 0 00.087-.213.315.315 0 00-.087-.213.314.314 0 00-.426 0A.315.315 0 009 14.1c0 .078.033.156.087.213a.315.315 0 00.213.087zM7.887 15.687a.315.315 0 00-.087.213c0 .078.033.156.087.213a.315.315 0 00.213.087.315.315 0 00.213-.087.315.315 0 00.087-.213.315.315 0 00-.087-.213.314.314 0 00-.426 0zM7.887 4.887A.315.315 0 007.8 5.1c0 .078.033.156.087.213A.315.315 0 008.1 5.4a.31.31 0 00.213-.087A.315.315 0 008.4 5.1a.315.315 0 00-.087-.213.314.314 0 00-.426 0zM8.913 2.013A.316.316 0 009 1.8a.316.316 0 00-.087-.213.314.314 0 00-.426 0A.316.316 0 008.4 1.8c0 .078.033.156.087.213A.31.31 0 008.7 2.1a.315.315 0 00.213-.087zM9.6 6.6a.315.315 0 00.213-.087A.316.316 0 009.9 6.3a.316.316 0 00-.087-.213.314.314 0 00-.426 0A.316.316 0 009.3 6.3c0 .078.033.156.087.213A.315.315 0 009.6 6.6z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M12 7.965V6.302 6.3 5.103 5.1v-.921C11.832 1.836 9.856 0 7.5 0A4.505 4.505 0 003 4.5c0 .66.147 1.308.425 1.9A2.703 2.703 0 000 9c0 1.489 1.211 2.7 2.7 2.7.294 0 1.017.84 1.34 3.3H3.9c-.827 0-1.5.673-1.5 1.5S3.073 18 3.9 18h6.6a.3.3 0 00.3-.3c0-1.72.347-2.866.714-4.079.29-.956.588-1.943.718-3.24A1.78 1.78 0 0012 7.965zm-.287 2.104a.297.297 0 00-.07.166c-.06.655-.168 1.231-.297 1.765H9a.3.3 0 000 .6h2.188c-.08.29-.165.57-.248.848-.037.118-.072.238-.108.358-.011 0-.02-.006-.032-.006h-.6a.3.3 0 000 .6h.462c-.104.379-.198.775-.275 1.2H9a.3.3 0 000 .6h1.294c-.048.373-.08.77-.09 1.2H3.9a.901.901 0 010-1.8h.478a.3.3 0 00.298-.333C4.398 12.736 3.622 11.1 2.7 11.1 1.542 11.1.6 10.158.6 9c0-1.158.942-2.1 2.1-2.1.505 0 .99.184 1.373.517.462.4.727.971.727 1.583a.3.3 0 00.6 0c0-.754-.313-1.459-.86-1.97A3.895 3.895 0 013.6 4.5C3.6 2.35 5.35.6 7.5.6c2.043 0 3.757 1.59 3.9 3.6v.6H9a.3.3 0 000 .6h2.4V6h-.9a.3.3 0 000 .6h.9v1.008a1.757 1.757 0 00-.6-.108c-.993 0-1.8.807-1.8 1.8a.3.3 0 00.6 0c0-.662.538-1.2 1.2-1.2.257 0 .506.085.719.246.306.231.481.579.481.954 0 .28-.1.546-.287.769z"
        fill="var(--ion-color-dark)"
      />
    </svg>
  );
}

export default SvgGingerIcon;
