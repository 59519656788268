import { IonSpinner } from '@ionic/react';
import { Box, Flex } from 'components/baseElements/grid';
import { Loadable } from 'components/baseElements/loadable';
import { OuterSubmitButton } from 'components/baseElements/OuterSubmitButton';
import { IonButton } from 'components/ionicComponents';
import React from 'react';
import { FormattedMessage } from 'react-intl';

interface HeaderSubmitButtonProps {
  boxProps?: any;
  children?: React.ReactNode;
  color?: string | undefined;
  disabled?: boolean;
  fill?: 'clear' | 'outline' | 'solid';
  flexProps?: any;
  form?: string;
  isDisabledOnLoad?: boolean;
  isLoading?: boolean;
  loadingElement?: any;
  onClick?: any;
  slot?: string;
  type?: 'button' | 'submit' | 'reset';
}

export function HeaderSubmitButton(props: HeaderSubmitButtonProps): JSX.Element {
  const {
    boxProps = { px: 2 },
    children = <FormattedMessage id="general.buttons.headerSubmit"/>,
    color = 'secondary',
    disabled,
    fill = 'solid',
    flexProps = { alignItems: 'center' },
    form,
    isDisabledOnLoad = true,
    onClick,
    slot,
    type = 'submit',
    isLoading,
    loadingElement = (
      <Box mr={-2}>
        <IonSpinner color="light" style={{ transform: 'scale(0.5)' }}/>
      </Box>
    ),
  } = props
  return (
    <OuterSubmitButton
      form={form}
      type={type}
    >
      <IonButton
        color={color}
        disabled={isDisabledOnLoad ? isLoading : disabled}
        fill={fill}
        onClick={onClick}
        slot={slot}
        {...props}
      >
        <Flex {...flexProps}>
          <Loadable
            isLoading={isLoading}
            loadingElement={loadingElement}
          />
          <Box {...boxProps}>
            {children}
          </Box>
        </Flex>
      </IonButton>
    </OuterSubmitButton>
  );
}
