import jwtDecode from 'jwt-decode';

export const getUserAuthToken = (): string => {
  try {
    const currentToken = JSON.parse(localStorage.getItem('authToken') || '{}');
    return currentToken.token;
  } catch (ignore) {
  }
  return '';
};

export const getUserIdFromToken = (): string => {
  try {
    const currentToken = JSON.parse(localStorage.getItem('authToken') || '{}');
    const tokenData: any = currentToken.token ? jwtDecode(currentToken.token) : {};
    return tokenData['userData/userId'];
  } catch (ignore) {
  }
  return '';
};
