import { yupResolver } from '@hookform/resolvers/yup';
import { IonInput, IonList, IonText } from '@ionic/react';
import { SubmitButton } from 'components/baseElements/formControls';
import HookInput from 'components/baseElements/formControls/HookInput';
import Form from 'components/baseElements/formControls/StyledForm';
import { Logo } from 'components/baseElements/Logo';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { handleServerHookForm } from 'utils/formValidation';

interface LoginFormProperties {
  isLoading: boolean;
  setModeToForgotPassword: any;
  submitAction: (data: any) => any;
  serverValidation?: any;
  validationSchema?: any;
}

export default function LoginForm(props: LoginFormProperties): JSX.Element {
  const {
    isLoading = false,
    serverValidation,
    setModeToForgotPassword,
    submitAction,
    validationSchema,
  } = props;
  const form = useForm({
    defaultValues: {
      username: '',
      password: '',
    },
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    handleServerHookForm(serverValidation, form.setError);
  }, [serverValidation, form.setError]);

  return (
    <Form onSubmit={form.handleSubmit(submitAction)}>
      <Logo
        $maxWidth={210}
        $mx="auto"
        $py={5}
        $width={2 / 5}
      />
      <IonList lines="full">
        <HookInput
          form={form}
          isRequired
          labelText="userRegistrationForm.labels.email"
          name="username"
        >
          <IonInput data-cy="loginEmail"/>
        </HookInput>
        <HookInput
          assistiveTextChildren={(
            <IonText
              color="primary"
              onClick={setModeToForgotPassword}
            >
              <FormattedMessage id="general.nav.forgotPassword"/>
            </IonText>
          )}
          form={form}
          isRequired
          labelText="userRegistrationForm.labels.password"
          name="password"
        >
          <IonInput
            clearOnEdit={false}
            data-cy="loginPassword"
            type="password"
          />
        </HookInput>
      </IonList>
      <SubmitButton
        isLoading={isLoading}
      >
        <FormattedMessage id="login.button.submit"/>
      </SubmitButton>
    </Form>
  );
}
