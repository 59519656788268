import { ImageOptions, CameraSource } from '@capacitor/camera';
import { faCamera } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import { IonInput, IonItem, IonList, IonRadio, IonRadioGroup, isPlatform } from '@ionic/react';
import HookInput from 'components/baseElements/formControls/HookInput';
import Form from 'components/baseElements/formControls/StyledForm';
import { Box, Flex } from 'components/baseElements/grid';
import { Hideable } from 'components/baseElements/hideable';
import Image from 'components/baseElements/Image';
import { IonLabel, IonListHeader } from 'components/ionicComponents';
import { GrapeUploadImageButton } from 'napa-react-core/dist/lib/components/ionicComponents/GrapeUploadImageButton';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { CameraApiSettings } from 'store/appSettings';
import { Flight, Wine } from 'store/wines';
import { getImageUrl } from 'utils/dataFormat/strings';
import { handleFormDataReset, handleServerHookForm } from 'utils/formValidation';
import WineRatingForm from '../WineRatingForm';

interface WineFormProperties {
  apiBaseUrl?: string;
  cameraApiSettings?: CameraApiSettings;
  onUploadError?: () => void;
  onUploadSuccess?: (ids: Array<string>) => void;
  flights?: Array<Flight>;
  imageUploadQuality?: number;
  serverValidation?: any;
  showRating?: boolean | undefined;
  submitAction: (data: Wine) => any;
  uploadUrl?: string;
  uploadedFileId?: string;
  validationSchema: any;
  wine?: Wine;
}

const ImageLabel = (): JSX.Element => (
  <IonLabel color="medium" position="stacked" style={{ whiteSpace: 'normal' }}>
    <FormattedMessage id="wineTastingForm.labels.imageHelperText"/>
  </IonLabel>
);

const WineForm: FunctionComponent<WineFormProperties> = (
  props: WineFormProperties,
) => {
  const {
    apiBaseUrl,
    cameraApiSettings,
    onUploadError,
    onUploadSuccess,
    flights,
    serverValidation,
    showRating = false,
    submitAction,
    uploadUrl = '',
    uploadedFileId = '',
    validationSchema,
    wine,
  } = props;

  const form = useForm({
    defaultValues: {
      name: '',
      producer: '',
      vintage: '',
      flightId: null,
      rating: 75,
    },
    resolver: yupResolver(validationSchema),
  });

  const [rating, setRating] = useState(75);
  useEffect(() => {
    handleServerHookForm(serverValidation, form.setError);
  }, [serverValidation, form.setError]);

  useEffect(() => {
    handleFormDataReset(wine, form.reset);
  }, [wine, form.reset]);
  return (
    <Form id="wineForm" onSubmit={form.handleSubmit(submitAction)}>
      <IonList lines="full">
        <HookInput
          form={form}
          labelText="wineTastingForm.labels.vintage"
          name="vintage"
        >
          <IonInput
            data-cy="wineVintageYear"
            type="number"
          />
        </HookInput>
        <HookInput
          form={form}
          labelText="wineTastingForm.labels.producer"
          name="producer"
        >
          <IonInput data-cy="wineProducerName"/>
        </HookInput>
        <HookInput
          form={form}
          isRequired
          labelText="wineTastingForm.labels.name"
          name="name"
        >
          <IonInput data-cy="wineName"/>
        </HookInput>
        <HookInput
          form={form}
          labelText="wineTastingForm.labels.designation"
          name="designation"
        >
          <IonInput/>
        </HookInput>
        <HookInput
          form={form}
          labelText="wineTastingForm.labels.bottleSize"
          name="bottleSize"
        >
          <IonInput/>
        </HookInput>
        <IonItem className={isPlatform('ios') ? 'ion-item-no-min-height' : ''} lines="none">
          <IonLabel>
            <FormattedMessage id="wineTastingForm.labels.imageHeader"/>
            <Box mb={isPlatform('android') && 2} mt={isPlatform('android') && -2}>
              <ImageLabel/>
            </Box>
          </IonLabel>
        </IonItem>
        <IonItem lines="full">
          <Flex
            alignItems="center"
            justifyContent="center"
            width={1}
          >
            <Hideable hide={!uploadedFileId}>
              <Image
                alt={`${wine?.vintage} ${wine?.producer} ${wine?.name}`}
                borderRadius={4}
                m={1}
                objectFit="contain"
                src={getImageUrl(apiBaseUrl || '', uploadedFileId)}
                size="42vw"
              />
            </Hideable>
            <GrapeUploadImageButton
              imageName="wineImage"
              onUploadError={onUploadError}
              onUploadSuccess={onUploadSuccess}
              options={{
                quality: cameraApiSettings?.imageUploadQuality,
                source: CameraSource.Prompt,
                width: cameraApiSettings?.uploadImageTargetWidth,
              } as ImageOptions}
              uploadUrl={uploadUrl}

            >
              <>
                <Box mr={1}>
                  <FontAwesomeIcon icon={faCamera}/>
                </Box>
                <FormattedMessage id={`general.buttons.${uploadedFileId ? 'changePhoto' : 'uploadPhoto'}`}/>
              </>
            </GrapeUploadImageButton>
          </Flex>
        </IonItem>
        <Hideable hide={!showRating}>
          <WineRatingForm form={form} rating={rating} setRating={setRating}/>
        </Hideable>
        <Hideable hide={!flights || !flights.length}>
          <IonListHeader>
            <IonLabel>
              <FormattedMessage id="wineTastingForm.labels.flight"/>
            </IonLabel>
          </IonListHeader>
          <HookInput
            form={form}
            name="flightId"
          >
            <IonRadioGroup style={{ width: '100%' }}>
              <IonItem lines="none">
                <IonLabel>
                  <FormattedMessage id="wineTastingForm.labels.noFlight"/>
                </IonLabel>
                <IonRadio
                  slot="start"
                  value={null}
                />
              </IonItem>
              {flights && flights.map((flight: Flight, key: any): JSX.Element => (
                <IonItem lines="none" key={key}>
                  <IonLabel>
                    {flight.name}
                  </IonLabel>
                  <IonRadio
                    slot="start"
                    value={flight.id}
                  />
                </IonItem>
              ))}
            </IonRadioGroup>
          </HookInput>
        </Hideable>
      </IonList>
    </Form>
  );
};

export default WineForm;
