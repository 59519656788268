import { faArrowLeft, faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonToolbar } from '@ionic/react';
import { SubmitButton } from 'components/baseElements/formControls';
import { Flex } from 'components/baseElements/grid';
import React, { FunctionComponent } from 'react';
import { LoadingStatus } from 'utils/formValidation';
import { Box } from '../../../baseElements/grid';

interface StepMidHeaderButtonsProps {
  loadingStatus: LoadingStatus;
  nextClickedAction: () => any;
  previousClickedAction: () => any;
  nextStepName: string;
  showNextStepButton: boolean;
  previousStepName: string;
  showPreviousStepButton: boolean;
  style?: any;
}

const StepMidHeaderButtons: FunctionComponent<StepMidHeaderButtonsProps> = (
  props: StepMidHeaderButtonsProps,
) => {
  const {
    loadingStatus,
    nextClickedAction,
    previousClickedAction,
    nextStepName,
    showNextStepButton,
    previousStepName,
    showPreviousStepButton,
  } = props;

  return (
    <Box backgroundColor="white" position="sticky" top="0" zIndex="1000">
      <IonToolbar>
        <Flex width={1}>
          {showPreviousStepButton && (
            <SubmitButton
              expand={null}
              fill="outline"
              isLoading={loadingStatus === LoadingStatus.Loading}
              loadingElement={null}
              maxWidth="calc(50% - 4px)"
              onClick={(): any => previousClickedAction()}
            >
              <FontAwesomeIcon icon={faArrowLeft}/>
              &nbsp;
              {previousStepName}
            </SubmitButton>)}
          <Flex flex={1}/>
          {showNextStepButton && (
            <SubmitButton
              color="primary"
              expand={null}
              isLoading={loadingStatus === LoadingStatus.Loading}
              loadingElement={null}
              maxWidth="calc(50% - 4px)"
              onClick={(): any => nextClickedAction()}
            >
              {nextStepName}
              &nbsp;
              <FontAwesomeIcon icon={faArrowRight}/>
            </SubmitButton>
          )}
        </Flex>
      </IonToolbar>
    </Box>
  );
};

export default StepMidHeaderButtons;
