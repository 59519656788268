import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgCinnamonIcon(props) {
  return (
    <svg width={18} height={17} fill="none" {...props}>
      <path
        d="M16.843 2.721c.149.14.236.335.296.526.079.249.14.487.46.363.884-.344.126-1.77-.545-1.958-.727-.204-1.515.38-2.044.802-.772.616-1.447 1.351-2.089 2.098-1.84 2.144-3.508 4.436-5.38 6.551a37.595 37.595 0 01-1.934 2.06c-.532.516-1.075 1.031-.998 1.83.142 1.467 1.643 2.335 3 1.836 1.163-.428 1.982-1.52 2.698-2.477.968-1.291 1.961-2.563 2.944-3.843 1.059-1.381 2.108-2.77 3.111-4.194a70.4 70.4 0 00.743-1.073c.23-.34.506-.682.691-1.05.248-.49-.592-.452-.783-.107-.627 1.128-3.8 5.325-5.802 7.911a1.167 1.167 0 00-.174-.314c-.14-.18-.31-.328-.54-.373-.126-.024-.32-.058-.366.107-.061.197.166.187.166.304.189.147.357.325.555.46a.76.76 0 00.145.092l-.405.525a1.541 1.541 0 00-.248-.392c-.174-.204-.374-.373-.634-.45-.124-.037-.407-.129-.466.055-.056.166.153.263.25.35.248.21.485.444.74.642a1.2 1.2 0 00.121.103l-.366.476-.144.19-.006-.027c-.024-.26-.292-.518-.531-.583-.108-.03-.299-.073-.375.045-.078.121.137.374.15.357.168.186.307.358.416.59a.28.28 0 00.022.036c-.132.168-.269.335-.411.5a1.185 1.185 0 00-.024-.192c-.071-.312-.24-.598-.536-.733-.11-.05-.31-.121-.37.04-.058.141.048.228.12.333.166.205.284.45.426.671.03.047.077.114.133.156-.432.453-.917.843-1.476 1.045-.506.183-1.011.082-1.392-.304-.364-.37-.911-1.359.149-1.408.284.02.56.147.72.384.012-.008.197.356.184.42-.035.172-.225.199-.372.18-.15-.019-.304-.135-.454-.113-.22.033.264.409.31.431.383.185.856.075.997-.359.204-.783-.657-1.45-1.363-1.408a2.652 2.652 0 00-.357.063c.486-.756 1.273-1.378 1.903-1.966 1.215-1.136 2.147-2.58 3.193-3.866.944-1.162 1.946-2.275 2.905-3.425.59-.71 1.254-1.54 2.103-1.951.3-.145.668-.216.934.034z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M14.862 5.223c-.512.516-.93 1.13-1.427 1.66-.513.549-.987 1.097-1.395 1.728-.222.345-.597.848-.63 1.272-.022.298.397-.175.441-.23.08-.099.154-.203.23-.307.044-.061.264-.278.264-.352l.336-.429c.712-.906 1.534-1.703 2.106-2.715a4.54 4.54 0 00.243-.48c.096-.228.055-.37-.168-.147zM7.713 9.736c.07-.066.396-.374.5-.467.56-.506 1.072-1.071 1.572-1.634.166-.187.373-.398.447-.642.086-.282-.245-.157-.372-.088-.437.238-.814.597-1.199.91-.353.293-1.172.912-1.229.962-1.31 1.153-2.548 2.431-4.084 3.293-.617.345-1.323.619-1.98.183a1.066 1.066 0 01-.5-.87c-.025-.213.056-.36.136-.422.223-.175.753-.276.88-.272.203.006.276.03.426.154.25.207.132.45.008.695-.08.088-.195.13-.285.07-.124-.083-.268-.22-.42-.242-.206-.03.198.471.222.497.485.486 1.056-.181 1.129-.659.071-.468-.324-.91-.766-1.023a1.053 1.053 0 00-.718.099c-.043.023-.156.08-.267.147.027-.032.053-.063.082-.093.305-.319.68-.572 1.027-.843l1.244-.972c.794-.62 1.587-1.24 2.39-1.848 1.6-1.21 6.352-4.971 7.243-5.453.306-.166.757-.383 1.101-.201.48.253.176.878.492 1.197.138.14.383.166.51-.003.178-.237.061-.698-.009-.957a1.702 1.702 0 00-1.317-1.23c-.647-.128-1.286.27-1.78.642-.52.393-.967.868-1.467 1.286C9.224 3.211 7.64 4.371 6.057 5.53 4.62 6.582 3.221 7.696 1.756 8.71c-.56.387-1.154.808-1.514 1.4-.366.602-.276 1.23-.001 1.851.253.572.629 1.108 1.225 1.35.488.197 1.025.17 1.523.023 1.326-.39 2.439-1.415 3.421-2.343.438-.414.861-.844 1.303-1.255z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M6.971 7.403c-.656.47-1.493 1.353-2.318 1.942-.333.239-.764.5-1.018.832-.222.29.866-.15 1.36-.503C5.797 9.1 6.519 8.4 7.249 7.777c.05-.043.455-.432.52-.493.075-.072.407-.347.313-.474-.1-.133-1.018.526-1.11.593zM10.725 4.774c-.21.318.238.594.453.266.202-.305-.26-.56-.453-.266zM9.099 5.358c-.21.318.238.594.453.267.203-.306-.26-.561-.453-.267zM11.737 3.154c-.21.318.238.593.454.266.202-.305-.26-.56-.454-.266zM15.576 3.87c-.21.318.238.593.453.267.203-.306-.26-.56-.453-.267z"
        fill="var(--ion-color-dark)"
      />
    </svg>
  );
}

export default SvgCinnamonIcon;
