import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgBakingsodaIcon(props) {
  return (
    <svg width={18} height={18} fill="none" {...props}>
      <path
        d="M15.041.002C9.816.962 6.335 2.917 5.695 4.92c0 0-.054.13-.198.327-.395-.04-.817-.109-.912-.156a.808.808 0 01-.11-.11c-.224-.251-.701-.77-1.6-.347-.634.286-1.002.824-1.274 1.213-.102.15-.239.347-.3.381-.456.13-.436.743-.395 1.077-.082.027-.17.054-.266.081-.109.034-.184.137-.122.232.408.66 1.764.872 3.222.47l.054-.014c1.554-.456 2.099-1.185 2.392-2.261.081-1.642 3.467-3.951 8.89-5.64.115-.035.074-.192-.035-.171zM1.465 6.739c.224-.068.388-.3.585-.586.246-.36.552-.804 1.056-1.042.545-.252.756-.02.974.225a.847.847 0 00.266.231c.136.069.423.116.695.157-.368.32-.92.66-1.723.886l-.293.054c-.811.225-1.159.334-1.595.47-.013-.177 0-.34.035-.395zM17.569 12.019a.283.283 0 00-.02-.11c.006-.033.013-.06.013-.095.02-.674-.265-.892-.497-1.062a1.226 1.226 0 01-.198-.17c-.15-.178-.565-.334-1.614-.702-.716-.252-1.908-.668-2.078-.88-.286-.374-.913-.449-1.574-.524-.422-.047-.858-.102-1.15-.231-.335-.143-.553-.348-.743-.525-.239-.218-.484-.443-.832-.368-.19.041-.408.157-.667.293-.368.191-.818.43-1.226.464-.695.06-1.193.701-1.37.98C2.521 9.472.204 11.427.11 11.516l-.089.075-.006.115-.007.103c0 .034.007.068.013.109a.273.273 0 00-.02.102c0 1.86 1.669 3.515 4.408 4.4a.531.531 0 00-.184.396c0 .408.47.701 1.471.912.831.178 1.928.273 3.093.273s2.262-.095 3.093-.273c1.001-.21 1.471-.504 1.471-.912a.528.528 0 00-.184-.389c2.725-.885 4.401-2.54 4.401-4.407zM5.797 9.614l.137-.014.068-.122c.006-.007.442-.77 1.022-.824.51-.048 1.015-.314 1.423-.525.218-.109.416-.218.538-.245.069-.014.178.081.348.238.204.184.484.443.9.62.367.157.844.211 1.3.266.498.061 1.063.122 1.213.32.245.313 1.076.627 2.323 1.063.531.184 1.26.443 1.39.545.095.109.19.177.279.245.19.143.293.225.286.6-.048.054-.402.38-2.493.66-1.567.211-3.604.327-5.75.327-2.139 0-4.176-.116-5.743-.327-1.812-.238-2.323-.518-2.459-.62.525-.415 2.616-1.928 5.218-2.207z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M11.036 11.14a.307.307 0 100-.613.307.307 0 000 .613zM12.528 11.957a.307.307 0 100-.613.307.307 0 000 .614zM11.963 10.445a.306.306 0 100-.613.306.306 0 000 .613z"
        fill="var(--ion-color-dark)"
      />
    </svg>
  );
}

export default SvgBakingsodaIcon;
