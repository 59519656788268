import { faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons';
import { faBicycle, faCar, faPlane, faWalking } from '@fortawesome/pro-solid-svg-icons';

export interface GeoLocationFilterState {
  distanceFromZipCode?: number;
  zipCode?: string;
}

export interface GeoLocation {
  city: string;
  daylightSavings: boolean;
  latitude: number;
  longitude: number;
  state: string;
  timezone: number;
  zipCode: string;
}

export interface GeoLocationCoordinates {
  latitude: number;
  longitude: number;
}

export interface UseGeolocationHook {
  error?: any;
  geoLocation?: GeoLocation;
  getPosition: () => void;
  position?: GeoLocationCoordinates;
}

export interface GeoLocationDistanceOptions {
  icon: any;
  label: string;
  value?: number;
}

export const geoLocationDistanceOptions: GeoLocationDistanceOptions[] = [{
  icon: faWalking,
  label: 'geoLocationFilterModal.labels.withinOneMile',
  value: 1,
}, {
  icon: faBicycle,
  label: 'geoLocationFilterModal.labels.withinFiveMiles',
  value: 5,
}, {
  icon: faCar,
  label: 'geoLocationFilterModal.labels.withinTwentyFiveMiles',
  value: 25,
}, {
  icon: faPlane,
  label: 'geoLocationFilterModal.labels.anyDistance',
  value: undefined,
}];

export const getDistanceFromZipCodeIcon = (distanceFromZipCode?: number): any =>
  geoLocationDistanceOptions.find(g => g.value === distanceFromZipCode)?.icon || faMapMarkerAlt;
