import TastingActionBar from 'components/tastings/TastingActionBar';
import { AuthorizationModalId } from 'modals/AuthorizationModal';
import { useToast } from 'napa-react-core';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { TastingAttendee } from 'store/tastingAttendees';
import { TastingWithAttendee, toMyTastingVm, toRequestInsertTastingAttendeeDto } from 'store/tastings';
import { GetLoadingStatus, makeApiCall } from 'utils/api';
import { showModal } from 'utils/modals';
import usePostTastingAttendeeStatus from '../../../hooks/tastings/usePostTastingAttendeeStatus';

interface TastingActionBarContainerProps {
  reFetchTastingAction?: any;
  tasting?: TastingWithAttendee;
}

const TastingActionBarContainer: React.FC<TastingActionBarContainerProps> = (props: TastingActionBarContainerProps) => {
  const {
    reFetchTastingAction,
    tasting,
  } = props;

  // CONSTANT DECLARATION
  const numberOfHoursUntilEndOfTasting = useSelector((state: RootState): number => state.appSettings.numberOfHoursUntilEndOfTasting);
  const vm = tasting ? toMyTastingVm(tasting, numberOfHoursUntilEndOfTasting) : tasting;
  const REGISTER_TASTING_KEY = 'registerTasting';
  const dispatch = useDispatch();

  // GLOBAL (REDUX) STATE SETUP
  const intl = useIntl();
  const { showToast } = useToast();
  const auth = useSelector((state: RootState) => state.authorization);
  const userEmail = auth.tokenData && auth.tokenData['userData/email'];
  const userId = auth.tokenData && auth.tokenData['userData/userId'];
  const apiBaseUrl = useSelector((state: RootState): string => state.appSettings.apiBaseUrl);
  const registerTastingApiCall = useSelector((state: RootState) =>
    state.api.callStatuses.find(e => e.id === REGISTER_TASTING_KEY),
  );

  const postTastingAttendeeStatus = usePostTastingAttendeeStatus(vm?.tasting?.id, {
    errorHandler: (): void => {
      showToast({ message: intl.formatMessage({ id: 'tastingActionBar.toasts.attendeeStatusSaveFailure' }) });
    },
    successHandler: () => {
      reFetchTastingAction();
    },
  });
  // LOCAL FUNCTIONS
  const registerForTastingSubmitAction = useCallback(async (tasting): Promise<void> => {
    const tastingId = tasting?.tasting?.id;
    const tastingAttendee = { isGoing: true } as TastingAttendee;
    try {
      await makeApiCall({
        authToken: auth.token,
        dispatch: dispatch,
        callId: REGISTER_TASTING_KEY,
        request: {
          url: `${apiBaseUrl}/tastings/${tastingId}/tastingAttendee`,
          httpMethod: 'POST',
          body: toRequestInsertTastingAttendeeDto(tastingAttendee, auth, false),
        },
        successMessage: 'tastingActionBar.toasts.youAreGoingToThisTasting',
      });
      reFetchTastingAction();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [apiBaseUrl, auth, dispatch, reFetchTastingAction]);

  const RegistrationSubmitAction = (): void => {
    registerForTastingSubmitAction(tasting)
      // eslint-disable-next-line no-console
      .catch(console.error);
  };
  const onUnauthorizedModalClose = (): void => {
    reFetchTastingAction();
  };

  // EFFECTS SETUP
  return (
    <TastingActionBar
      attendeeGoingAction={(isGoing: boolean): void => postTastingAttendeeStatus.mutate({
        attendeeId: userId,
        email: userEmail,
        isGoing,
      })}
      registerForTastingButtonAction={RegistrationSubmitAction}
      registerLoadingStatus={GetLoadingStatus(registerTastingApiCall?.status)}
      tasting={tasting}
      tastingRoomUrl={`/tastings/details/room/${vm?.tasting.id}`}
      unauthorizedButtonAction={(): void => showModal(dispatch, AuthorizationModalId, { callback: onUnauthorizedModalClose })}
    />
  );
};

export default TastingActionBarContainer;
