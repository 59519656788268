import React from 'react';
import { Flex } from 'components/baseElements/grid';
import { IonButton } from 'components/ionicComponents';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

export default function ViewMoreTastingsButton(): JSX.Element {
  const history = useHistory();
  return (
    <Flex
      justifyContent="center"
      width="100%"
    >
      <IonButton
        fill="clear"
        onClick={(): void => history.push('/tastings/mytastings')}
        size="small"
      >
        <FormattedMessage id="myTastings.text.viewMore" />
      </IonButton>

    </Flex>
  );
}
