import React, { createRef } from 'react';
import { SearchbarChangeEventDetail } from '@ionic/core/dist/types/components/searchbar/searchbar-interface';
import { IonSearchbar } from '@ionic/react';
import { useAutoInputFocus } from '../../../hooks';
import { useIntl } from 'react-intl';

interface SearchBarProperties {
  cancelButtonText?: string;
  enableAutoFocus?: boolean;
  debounce?: number;
  searchText?: string;
  showCancelButton?: 'never' | 'focus' | 'always';
  onIonCancel?: (event: CustomEvent<void>) => void;
  onIonChange?: (event: CustomEvent<SearchbarChangeEventDetail>) => void;
  onIonClear?: (event: CustomEvent<void>) => void;
  placeHolder?: string;
}

export function SearchBar(props: SearchBarProperties): JSX.Element {
  const {
    cancelButtonText,
    debounce,
    enableAutoFocus = false,
    onIonCancel,
    onIonChange,
    onIonClear,
    searchText = '',
    showCancelButton = 'never',
    placeHolder,
  } = props;
  // Focus on the input element when rendered the first time
  const searchBarRef = createRef<HTMLIonSearchbarElement>();
  const intl = useIntl();

  useAutoInputFocus(searchBarRef, enableAutoFocus);
  return (
    <IonSearchbar
      cancelButtonText={cancelButtonText || intl.formatMessage({ id: 'general.search.cancelText' })}
      debounce={debounce ?? 1000}
      mode="ios"
      value={searchText}
      onIonCancel={onIonCancel}
      onIonChange={onIonChange}
      onIonClear={onIonClear}
      ref={searchBarRef}
      showCancelButton={showCancelButton}
      placeholder={placeHolder || intl.formatMessage({ id: 'general.search.placeholderText' })}
    />
  );
}
