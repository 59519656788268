import { TastingResultViewState } from 'containers/tastings/ViewTastingResultsContainer';
import { PaginatedData } from 'napa-react-core';
import { TastingResultInfo, TastingWithAttendee } from 'store/tastings';
import { Flight, Wine } from 'store/wines';

export const viewTastingResultInfoCaptions = (
  tastingResultViewState?: TastingResultViewState,
  wineData?: PaginatedData<Wine>,
  flightData?: PaginatedData<Flight>,
  tasting?: TastingWithAttendee): TastingResultInfo => {
  const anyWineScoresPublished = !!(wineData && wineData?.items.filter(w => w.areScoresPublished).length > 0);
  const anyFlightRankingsPublished = !!(flightData && flightData?.items.filter(f => f.areRanksPublished).length > 0);
  const anyFlights = !!(wineData && wineData?.items.filter(w => w.flightId).length > 0);
  const anyWines = !!(wineData && wineData?.items.length > 0);
  const anyRankingsPublished = !!tasting?.tasting?.areRanksPublished;
  let messageId = '';

  if (tastingResultViewState === TastingResultViewState.Scoring && !anyWineScoresPublished) {
    messageId = 'tastingResults.header.scoresNotPublished';
  }
  if (tastingResultViewState === TastingResultViewState.FlightRanking && !anyFlights) {
    messageId = 'tastingResults.header.noFlightsInEvent';
  }
  if (tastingResultViewState === TastingResultViewState.FlightRanking && !anyFlightRankingsPublished && anyFlights) {
    messageId = 'tastingResults.header.noFlightRanksPublished';
  }
  // Commented code may be used later if feature changes.
  // if (tastingResultViewState === TastingResultViewState.OverallRanking && !anyWines) {
  //   messageId = 'tastingResults.header.noWinesInEvent';
  // }
  if (tastingResultViewState === TastingResultViewState.OverallRanking && !anyRankingsPublished) {
    messageId = 'tastingResults.header.noOverallRanksPublished';
  }
  const isHost = tasting?.attendee?.isHost;
  const showWineList = !!(isHost || (!isHost && !messageId));
  return {
    anyFlights,
    anyWines,
    messageId,
    showWineList,
  };
}
