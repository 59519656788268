import { IonContent, IonIcon, IonModal } from '@ionic/react';
import { HeaderSubmitButton, HeaderWithButtons } from 'components/baseElements/header';
import { IonButton } from 'components/ionicComponents';
import { PrivatePropertyContainer } from 'containers/authorization/PrivatePropertyContainer';
import AddWineToTastingContainer from 'containers/wine/AddWineToTastingContainer';
import { close } from 'ionicons/icons';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { LoadingStatus } from 'utils/formValidation';
import { hideModal } from 'utils/modals';

export const AddWineToTastingModalId = 'CreateWineTasting';

export default function AddWineToTastingModal(): JSX.Element {
  const dispatch = useDispatch();
  const modalProps = useSelector((state: RootState) =>
    state.gui.modals.find(e => e.id === AddWineToTastingModalId),
  );

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.None);
  return (
    <IonModal
      isOpen={modalProps?.show || false}
      onDidDismiss={(): void => hideModal(dispatch, AddWineToTastingModalId, {})}
    >
      <HeaderWithButtons
        ionButtonsEnd={
          <HeaderSubmitButton
            isLoading={loadingStatus === LoadingStatus.Loading}
            form="wineForm"
          />
        }
        ionButtonsStart={(
          <IonButton onClick={(): void => hideModal(dispatch, AddWineToTastingModalId, {})}>
            <IonIcon icon={close} slot="icon-only" />
          </IonButton>
        )}
      >
        <FormattedMessage id="createWineTastingModal.header.title" />
      </HeaderWithButtons>
      <IonContent>
        <PrivatePropertyContainer modalCustomMessageComponent={(<div>This is a custom message in the register popup for the add wine to tasting page</div>)}>
          <PrivatePropertyContainer.Unauthorized>
            This is what the add wine to tasting modal looks like when you are not logged in.
          </PrivatePropertyContainer.Unauthorized>
          <PrivatePropertyContainer.Authorized>
            <AddWineToTastingContainer
              loadingStatus={loadingStatus}
              setLoadingStatus={setLoadingStatus}
              tastingId={modalProps?.params?.tastingId}
              successHandler={(): void => hideModal(dispatch, AddWineToTastingModalId, modalProps?.params, modalProps?.callback, {
                created: true,
              })}
              flights={modalProps?.params?.flights}
              wines={modalProps?.params?.wines}
            />
          </PrivatePropertyContainer.Authorized>
        </PrivatePropertyContainer>
      </IonContent>
    </IonModal>
  );
}
