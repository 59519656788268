import styled from 'styled-components';
import { space } from 'styled-system';
import { transient } from 'utils/styledHelpers';

const OuterSubmitButton = styled('button')`
  ${transient(space)}
  visibility: hidden;
  > * {
    visibility: visible;
  }
`;

OuterSubmitButton.defaultProps = {
  $m: 0,
  $p: 0,
}

export { OuterSubmitButton };
