import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SearchbarChangeEventDetail } from '@ionic/core/dist/types/components/searchbar/searchbar-interface';
import {
  IonActionSheet,
  IonChip,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonList,
  IonText,
  IonThumbnail,
} from '@ionic/react';
import TastingBg from 'assets/images/tastingbg-01.jpg';
import { EmptyStateImage } from 'components/baseElements/EmptyStateImage';
import { Box, Flex } from 'components/baseElements/grid';
import Image from 'components/baseElements/Image';
import { Loadable } from 'components/baseElements/loadable';
import { SearchBar } from 'components/baseElements/SearchBar';
import { Paragraph } from 'components/baseElements/typography';
import { IonLabel } from 'components/ionicComponents';
import dayjs from 'dayjs';
import { DateFilterModalId } from 'modals/DateFilterModal';
import { AuthorizationStore, showModal } from 'napa-react-core';
import React, { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import {
  BrowseTastingResponseData,
  DateFilterState,
  DiscoveryFilterState,
  LocationTypeFilterState,
  Tasting,
  toMyTastingVm,
} from 'store/tastings';
import { getImageUrl } from 'utils/dataFormat/strings';
import { GeoLocationFilterModalId } from '../../../modals/GeoLocationFilterModal';
import { GeoLocationFilterState } from '../../../store/geoLocation';

interface DiscoverTastingsProperties {
  data: BrowseTastingResponseData;
  disableInfiniteScroll?: boolean;
  filterState: DiscoveryFilterState;
  isLoading?: boolean;
  onScrollNext: (e: CustomEvent<void>) => void;
  onSearchNext: (searchText: string) => void;
  onFilterChange: (filterValue: DiscoveryFilterState) => void;
}

export default function SearchMyTastings(props: DiscoverTastingsProperties): JSX.Element {
  const {
    disableInfiniteScroll,
    filterState,
    data,
    isLoading,
    onScrollNext,
    onSearchNext,
    onFilterChange,
  } = props;

  const dispatch = useDispatch();
  const apiBaseUrl = useSelector((state: RootState): string => state.appSettings.apiBaseUrl);
  const authData = useContext<any>(AuthorizationStore);
  const numberOfHoursUntilEndOfTasting = useSelector((state: RootState): number => state.appSettings.numberOfHoursUntilEndOfTasting);
  const [showDiscoveryFilterActionSheet, setShowDiscoveryFilterActionSheet] = useState(false);
  const intl = useIntl();
  const filterCaptionLookup = {
    [LocationTypeFilterState.InPerson]: intl.formatMessage({ id: 'discoverTastings.filter.InPerson' }),
    [LocationTypeFilterState.Virtual]: intl.formatMessage({ id: 'discoverTastings.filter.Virtual' }),
    [LocationTypeFilterState.VirtualAndInPerson]: intl.formatMessage({ id: 'discoverTastings.filter.VirtualAndInPerson' }),
  };
  const dateFilterStateValues = Object.values(DateFilterState);
  const dateFilterLengthHalf = dateFilterStateValues.length / 2;
  const dateFilterKeys = dateFilterStateValues.splice(0, dateFilterLengthHalf);

  const dateFilterCaptionLookup = dateFilterKeys.reduce((prevValue: any, curr: string | DateFilterState): any => {
    const showDateRange = DateFilterState[curr] === DateFilterState.ChooseDate && !!(filterState.startDate || filterState.endDate);
    let dateRangeCaption = intl.formatMessage({ id: `discoverTastings.dateFilter.${DateFilterState[DateFilterState.ChooseDate]}` });
    if (showDateRange) {
      const startDate = dayjs(filterState.startDate);
      const endDate = dayjs(filterState.endDate);
      const startIsValid = (startDate.isValid() && !!filterState.startDate);
      const endIsValid = (endDate.isValid() && !!filterState.endDate);
      if (startIsValid && endIsValid) {
        dateRangeCaption = startDate.isSame(endDate, 'd') ?
          `${startDate.format('MMM D, YYYY')}` : `${startDate.format('MMM D')} - ${endDate.format('MMM D, YYYY')}`;
      } else if (!endIsValid) {
        dateRangeCaption = `${startDate.format('MMM D, YYYY')}`;
      }
    }
    return {
      ...prevValue,
      [DateFilterState[curr]]: showDateRange ? dateRangeCaption : intl.formatMessage({ id: `discoverTastings.dateFilter.${curr}` }),
    };
  }, {});
  const filterCaption = filterCaptionLookup[filterState.filter];
  const dateFilterCaption = dateFilterCaptionLookup[filterState.dateFilter];
  const locationTypeFilterActionSheetButtons = [{
    text: filterCaptionLookup[LocationTypeFilterState.InPerson],
    handler: (): void => {
      onFilterChange({
        ...filterState,
        filter: LocationTypeFilterState.InPerson,
      });
    },
  }, {
    text: filterCaptionLookup[LocationTypeFilterState.Virtual],
    handler: (): void => {
      onFilterChange({
        ...filterState,
        filter: LocationTypeFilterState.Virtual,
      });
    },
  }, {
    text: filterCaptionLookup[LocationTypeFilterState.VirtualAndInPerson],
    handler: (): void => {
      onFilterChange({
        ...filterState,
        filter: LocationTypeFilterState.VirtualAndInPerson,
      });
    },
  }];
  const getInPersonTastingLocation = (tasting: Tasting): string => {
    if (!tasting?.city && !tasting.stateProvince) {
      return intl.formatMessage({ id: 'tastingForm.labels.inPersonTasting' });
    }
    if (tasting.city) {
      return `${tasting?.city || ''}${tasting?.stateProvince ? ', ' + tasting.stateProvince : ''}`;
    }
    return tasting.stateProvince;
  };

  const onDateFilterChange = (dateFilter: DateFilterState, startDate?: string, endDate?: string): void => {
    let nextStartDate = '';
    let nextEndDate = '';
    if (dateFilter === DateFilterState.ChooseDate) {
      nextStartDate = dayjs(startDate).isValid() && !!startDate ? dayjs(startDate).toISOString() : '';
      nextEndDate = dayjs(endDate).isValid() && !!endDate ? dayjs(endDate).toISOString() : '';
    }
    onFilterChange({
      ...filterState,
      dateFilter,
      endDate: nextEndDate,
      startDate: nextStartDate,
    });
  };
  const onGeoLocationFilterChange = (geoLocationFilterState?: GeoLocationFilterState): void => {
    onFilterChange({
      ...filterState,
      ...geoLocationFilterState,
    });
  };
  return (
    <>
      <SearchBar
        debounce={1000}
        onIonChange={(e: CustomEvent<SearchbarChangeEventDetail>): void => {
          if (onSearchNext) {
            onSearchNext(e.detail.value || '');
          }
        }}
        onIonClear={(): void => onSearchNext('')}
        searchText={filterState.searchText}
        showCancelButton="never"
      />
      <Box
        pl={2}
      >
        <Flex>
          <IonChip
            color={filterState.filter === LocationTypeFilterState.VirtualAndInPerson ? undefined : 'primary'}
            onClick={(): void => setShowDiscoveryFilterActionSheet(true)}
            style={{ minWidth: 'min-content' }}
          >
            <IonLabel pr={2} whiteSpace="nowrap">
              {filterCaption}
            </IonLabel>
            <FontAwesomeIcon icon={faCaretDown} />
          </IonChip>
          <IonChip
            color={!!filterState?.zipCode ? 'primary' : undefined}
            onClick={(): void => showModal(dispatch, GeoLocationFilterModalId, {
              params: {
                distanceFromZipCode: filterState?.distanceFromZipCode,
                userId: authData?.state?.tokenData && authData?.state?.tokenData['userData/userId'],
                zipCode: filterState?.zipCode,
              },
              callback: onGeoLocationFilterChange,
            })}
            style={{ minWidth: 'min-content' }}
          >
            <IonLabel pl={2} pr={2} whiteSpace="nowrap">
              {filterState?.zipCode || intl.formatMessage({ id: 'discoverTastings.filter.AllLocations' })}
            </IonLabel>
            <FontAwesomeIcon icon={faCaretDown} />
          </IonChip>
          <IonChip
            color={filterState.dateFilter === DateFilterState.AnyTime ? undefined : 'primary'}
            onClick={(): void => showModal(dispatch, DateFilterModalId, { params: {}, callback: onDateFilterChange })}
            style={{ minWidth: 'min-content' }}
          >
            <IonLabel pl={2} pr={2} whiteSpace="nowrap">
              {dateFilterCaption}
            </IonLabel>
            <FontAwesomeIcon icon={faCaretDown} />
          </IonChip>
        </Flex>
      </Box>
      <Box
        pl={2}
      >
      </Box>
      <Loadable
        isLoading={isLoading}
      >
        <Loadable.Loaded>
          {!data &&
            <Box py={4}>
              <EmptyStateImage />
              <Paragraph
                maxWidth={210}
                mx="auto"
                textAlign={'center'}
                width={2 / 3}
              >
                <FormattedMessage id="discoverTastings.text.emptyCaseDescr" />
              </Paragraph>
            </Box>
          }
          <IonList>
            {data.items.map(item => {
              const { tasting: tastingVm } = toMyTastingVm(item, numberOfHoursUntilEndOfTasting);
              const viewTastingDetailUrl = `/tastings/details/${tastingVm.id}`;
              return (
                <IonItem
                  data-cy={`discoverTastingItem${tastingVm.name.replace(/\s+/g, '')}`}
                  key={tastingVm.id}
                  routerLink={viewTastingDetailUrl}
                >
                  <IonThumbnail slot="start">
                    <Image
                      alt={tastingVm.name}
                      src={getImageUrl(apiBaseUrl, tastingVm?.tastingImageFileId, TastingBg)}
                    />
                  </IonThumbnail>
                  <IonLabel>
                    <Paragraph
                      $fontWeight={tastingVm.isHappeningNow && '600'}
                      whiteSpace="nowrap"
                    >
                      {tastingVm.isHappeningNow ? (
                        <IonText color="primary">
                          <FormattedMessage id="myTastings.text.happeningNow" />
                        </IonText>
                      ) : tastingVm.dateTime}
                    </Paragraph>
                    <Paragraph $fontWeight="600">
                      <IonText
                        color="dark"
                        data-cy={`tastingCardLabel${tastingVm.name.replace(/\s+/g, '')}`}
                      >
                        {tastingVm.name}
                      </IonText>
                    </Paragraph>
                    <Paragraph>
                      {tastingVm.locationType === 'Virtual' ? <FormattedMessage
                        id="tastingForm.labels.virtualTasting" /> : getInPersonTastingLocation(tastingVm)}
                    </Paragraph>
                  </IonLabel>
                </IonItem>
              );
            })}
            {!data?.totalItems && (
              <Box py={4}>
                <EmptyStateImage />
                <Paragraph
                  maxWidth={210}
                  mx="auto"
                  textAlign={'center'}
                  width={2 / 3}
                >
                  <FormattedMessage id="discoverTastings.filter.noResults" />
                </Paragraph>
              </Box>
            )}
          </IonList>
          <IonInfiniteScroll threshold="100px"
            disabled={disableInfiniteScroll}
            onIonInfinite={onScrollNext}>
            <IonInfiniteScrollContent
              loadingText="Loading more tastings...">
            </IonInfiniteScrollContent>
          </IonInfiniteScroll>
          <IonActionSheet
            header={intl.formatMessage({ id: 'discoverTastings.filterActionSheet.header' })}
            buttons={locationTypeFilterActionSheetButtons}
            isOpen={showDiscoveryFilterActionSheet}
            onDidDismiss={(): void => setShowDiscoveryFilterActionSheet(false)}
          />
        </Loadable.Loaded>
      </Loadable>
    </>
  );
}
