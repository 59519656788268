import * as React from 'react';
import { IonSpinner } from '@ionic/react';

interface LoadableProps {
  children?: React.ReactNode;
  isLoading?: boolean;
  loadingElement?: any;
}

interface LoadableChildProps {
  children?: any;
}

export function Unloaded(props: LoadableChildProps): JSX.Element {
  const {
    children,
  } = props;

  return (children);
}



export function Loaded(props: LoadableChildProps): JSX.Element {
  const {
    children,
  } = props;

  return (children);
}

export function Loadable(props: LoadableProps): JSX.Element {
  const {
    children = (<></>),
    isLoading,
    loadingElement
  } = props;
  if (loadingElement) {
    return isLoading ? loadingElement : children;
  }
  else {
    const childArray = React.Children.toArray(children);
    let unloadedElement = childArray.find((x: any) => x.type.name === Unloaded.name);
    let loadedElement = childArray.find((x: any) => x.type.name === Loaded.name);
    if (!unloadedElement) {
      unloadedElement = (<IonSpinner />);
    }
    if (!loadedElement) {
      loadedElement = (<></>);
    }
    return (isLoading ?
      React.cloneElement(unloadedElement as any)
      : React.cloneElement(loadedElement as any));
  }
}

Loadable.Unloaded = Unloaded;
Loadable.Loaded = Loaded;
