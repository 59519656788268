import { Box, Flex } from 'components/baseElements/grid';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

interface ScoreSummaryItemProps {
  alignItems?: string;
  children?: any;
  label?: string;
}

const ScoreSummaryItem: FunctionComponent<ScoreSummaryItemProps> = (
  props: ScoreSummaryItemProps,
) => {
  const {
    alignItems = 'flex-start',
    children,
    label,
  } = props;

  return (
    <Flex
      alignItems={alignItems}
      pb={1}
      width={1}
    >
      <Box px={1} textAlign="right" width="calc(33% + 14px)">
        <FormattedMessage id={label} />
      </Box>
      <Box fontWeight="bold" px={1} width="calc(66% - 14px)">
        {children === null ? '-' : children}
      </Box>
    </Flex>
  );
}

export default ScoreSummaryItem;