import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgGrassIcon(props) {
  return (
    <svg width={16} height={18} fill="none" {...props}>
      <path
        d="M7.195 18h.675c.03-1.639.27-3.267.718-4.844C7.931 9.342 6.21 4.018 1.784 0c0 0 4.99 6.782 4.937 15.78.21.72.368 1.453.477 2.195L7.195 18z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M3.622 13.765c1.013.842 1.769 2.21 2.237 4.066h.974c-.972-6.057-4.414-7.9-6.09-8.444 1.304 1.035 2.238 2.763 2.88 4.378zM8.212 17.831h.621c.167-.969.58-1.879 1.202-2.641a13.726 13.726 0 014.117-8.706C8.602 8.9 8.235 16.601 8.212 17.831z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M9.173 17.831h2.061c.329-2.524 2.7-4.513 4.017-5.429-5.153 1.433-5.956 4.644-6.078 5.43zM3.139 17.831h2.374c-.56-2.166-1.51-3.6-2.826-4.275A4.138 4.138 0 000 13.206c2.565.949 3.06 3.905 3.139 4.625z"
        fill="var(--ion-color-dark)"
      />
    </svg>
  );
}

export default SvgGrassIcon;
