import { TokenData } from 'store/authorization/types';

export const AUTHORIZATION_SET_TOKEN = 'AUTHORIZATION_SET_TOKEN';

export interface AuthorizationSetTokenActionPayload {
  token?: string;
  tokenData?: TokenData;
  tokenServerExpiration?: Date;
  tokenServerExpirationRefreshThreshold?: Date;
  tokenClientExpiration?: Date;
  tokenClientExpirationWarningThreshold?: Date;
}

export interface AuthorizationSetTokenAction {
  type: typeof AUTHORIZATION_SET_TOKEN;
  payload: AuthorizationSetTokenActionPayload;
}
