import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgGrapefruitIcon(props) {
  return (
    <svg width={18} height={17} fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.6 7.2c-3.59 0-6 1.241-6 2.4 0 1.159 2.41 2.4 6 2.4s6-1.241 6-2.4c0-1.159-2.41-2.4-6-2.4zm0 5.4c-3.701 0-6.6-1.318-6.6-3s2.899-3 6.6-3c3.701 0 6.6 1.318 6.6 3s-2.899 3-6.6 3z"
        fill="var(--ion-color-dark)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.848 9.599C2.186 9.946 3.731 10.8 6.6 10.8c2.864 0 4.412-.852 4.752-1.199C11.014 9.253 9.469 8.4 6.6 8.4c-2.863 0-4.412.852-4.752 1.199zM6.6 11.4c-3.371 0-5.305-1.145-5.397-1.758l-.006-.044.006-.045C1.295 8.941 3.23 7.8 6.6 7.8c3.371 0 5.305 1.144 5.397 1.758l.006.044-.006.045c-.092.611-2.028 1.753-5.397 1.753z"
        fill="var(--ion-color-dark)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.73 16.502c-.081 0-.13-.002-.144-.002-.085.003-2.755.11-4.687-1.733C.639 13.566 0 11.827 0 9.6a.3.3 0 11.6 0c0 2.054.575 3.645 1.709 4.729 1.742 1.665 4.25 1.572 4.277 1.571.053.002 2.563.094 4.305-1.571 1.134-1.084 1.709-2.675 1.709-4.73a.3.3 0 11.6 0c0 2.228-.64 3.967-1.9 5.168-1.701 1.623-3.975 1.735-4.57 1.735z"
        fill="var(--ion-color-dark)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.3 11.1h.6v-3h-.6v3z"
        fill="var(--ion-color-dark)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 9.9h10.2v-.6H1.5v.6z"
        fill="var(--ion-color-dark)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 11.1a.3.3 0 01-.149-.56l4.2-2.4a.3.3 0 11.298.52l-4.2 2.4a.296.296 0 01-.149.04z"
        fill="var(--ion-color-dark)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.7 11.1a.296.296 0 01-.149-.04l-4.2-2.4a.3.3 0 11.298-.52l4.2 2.4a.3.3 0 01-.15.56zM11.4 3.3c-.662 0-1.2-.538-1.2-1.2a.3.3 0 11.6 0 .6.6 0 001.2 0 .3.3 0 11.6 0c0 .662-.538 1.2-1.2 1.2zM14.1 5.1a.3.3 0 01-.212-.512l.3-.3a.3.3 0 11.424.424l-.3.3a.3.3 0 01-.212.088zM7.8 4.2a.3.3 0 01-.212-.512l.3-.3a.3.3 0 11.424.424l-.3.3A.3.3 0 017.8 4.2zM10.5 6a.3.3 0 01-.212-.512l.3-.3a.3.3 0 11.424.424l-.3.3A.3.3 0 0110.5 6zM3.6 13.8a.3.3 0 01-.212-.512l.3-.3a.3.3 0 11.424.424l-.3.3a.3.3 0 01-.212.088zM9.9 13.8a.3.3 0 01-.212-.088l-.3-.3a.3.3 0 11.424-.424l.3.3a.3.3 0 01-.212.512zM6.6 15a.3.3 0 01-.212-.512l.3-.3a.3.3 0 11.424.424l-.3.3A.3.3 0 016.6 15zM15.6 8.1a.3.3 0 01-.212-.512l.3-.3a.3.3 0 11.424.424l-.3.3a.3.3 0 01-.212.088z"
        fill="var(--ion-color-dark)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.6 6.6c3.701 0 6.6 1.318 6.6 3 0 1.05-.145 2.001-.433 2.835C15.442 11.81 17.4 9.388 17.4 6.6c0-3.308-2.692-6-6-6s-6 2.692-6 6v.05c.407-.033.809-.05 1.2-.05zm5.197 6.603l.22-.495c.387-.873.583-1.92.583-3.108 0-1.159-2.41-2.4-6-2.4-.47 0-.958.026-1.45.077l-.312.032-.025-.413A4.2 4.2 0 014.8 6.6C4.8 2.96 7.76 0 11.4 0 15.04 0 18 2.96 18 6.6c0 3.262-2.436 6.068-5.667 6.527l-.536.076z"
        fill="var(--ion-color-dark)"
      />
    </svg>
  );
}

export default SvgGrapefruitIcon;
