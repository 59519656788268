import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgCloveIcon(props) {
  return (
    <svg width={16} height={18} fill="none" {...props}>
      <path
        d="M3.192 13.278c.193-.425.384-.883.568-1.326.605-1.457 1.292-3.109 2.1-3.532.317-.166.656-.312.984-.453 1.028-.445 2.092-.904 2.54-2.005a.517.517 0 00-.047-.484.547.547 0 00-.441-.23c-.11 0-.216.028-.427.085l-.114.031a3.772 3.772 0 00.212-3.163A3.41 3.41 0 006.664.248 3.355 3.355 0 005.395 0c-1.451 0-2.81.943-3.38 2.347a3.818 3.818 0 00-.122.35l-.036-.054c-.227-.339-.351-.526-.65-.526-.206 0-.4.131-.477.322-.449 1.103-.008 2.174.419 3.21.136.33.276.672.387 1.012.283.868-.377 2.53-.96 3.997-.18.453-.366.92-.526 1.364-.25.692.483 1.479 1.12 1.739.231.093.517.145.803.145.613 0 1.057-.23 1.219-.628zM2.955 2.729c.417-1.025 1.398-1.713 2.44-1.713.306 0 .604.058.887.173.614.25 1.09.74 1.338 1.38a2.76 2.76 0 01-.03 2.045c-.143.351-.352.66-.607.918a.7.7 0 01-.133-.036c-.506-.206-.707-.705-.883-1.145-.03-.074-.058-.144-.085-.207-.048-.112-.23-.488-.55-.618-.26-.108-.665-.018-.85.069-.075.036-.156.08-.243.125-.256.138-.547.294-.85.294a.956.956 0 01-.366-.075 1.038 1.038 0 01-.275-.182c.003-.34.069-.69.207-1.028zM1.02 12.327c.15-.418.328-.863.499-1.293.678-1.707 1.38-3.471.983-4.688-.123-.377-.271-.736-.414-1.084-.171-.415-.331-.804-.43-1.172.295.349.621.643.982.79.244.1.496.15.748.15.56 0 1.005-.24 1.331-.414.07-.038.136-.073.196-.102a.112.112 0 01.016-.006 6.32 6.32 0 01.093.22c.201.502.538 1.34 1.443 1.709.27.11.617.149.983.125-.301.166-.645.315-1.009.472-.164.071-.332.144-.5.22l-.292-.506a.152.152 0 10-.264.152l.281.486c-.092.045-.185.086-.276.134-.14.073-.273.164-.4.27l-.34-.642a.153.153 0 00-.27.142l.378.715c-.186.19-.361.413-.527.66l-.25-.491a.152.152 0 10-.272.137l.332.655c-.452.756-.84 1.684-1.219 2.596-.179.43-.364.876-.548 1.282a.801.801 0 01-.3.046 1.21 1.21 0 01-.422-.07c-.252-.103-.492-.374-.532-.493z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M5.62 2.332c.124.025.247.066.362.124a.305.305 0 00.273-.545 2.014 2.014 0 00-.52-.177.305.305 0 00-.116.598zM3.669 3.383a.305.305 0 00.392-.177c.13-.34.393-.622.723-.773a.304.304 0 10-.254-.554 2.013 2.013 0 00-1.038 1.112c-.06.157.02.333.177.392zM10.508 10.306a.305.305 0 00.279.543c.673-.345 1.48-.116 1.801.51a.304.304 0 10.542-.278c-.475-.925-1.65-1.273-2.622-.775z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M14.658 10.298c-.592-1.153-1.797-1.87-3.145-1.87a3.854 3.854 0 00-2.07.613l.008-.063c.049-.4.071-.582-.099-.774a.525.525 0 00-.523-.155l-.03.009a.515.515 0 00-.07.03l-.009.004c-1.05.545-1.414 1.64-1.766 2.7-.112.34-.229.69-.366 1.02-.352.843-1.938 1.672-3.337 2.403-.431.226-.877.459-1.288.69-.64.362-.598 1.437-.285 2.049.259.503.888 1.046 1.453 1.046a.835.835 0 00.377-.084l.012-.006c.425-.197.87-.42 1.3-.637 1.308-.658 2.662-1.34 3.585-1.34.114 0 .219.011.311.033.349.08.7.191 1.042.298.661.207 1.346.42 2.015.42a2.61 2.61 0 001.219-.282.524.524 0 00.283-.466c-.004-.338-.253-.457-.567-.607l-.106-.052a3.77 3.77 0 002.223-2.26 3.408 3.408 0 00-.167-2.72zm-4.597 4.997c-.12-.038-.244-.075-.367-.112l-.012-.64c-.002-.085-.078-.159-.155-.15a.152.152 0 00-.15.155l.01.546a9.56 9.56 0 00-.44-.117 2.317 2.317 0 00-.351-.048l.014-.75a.152.152 0 00-.15-.155c-.078-.007-.153.066-.155.15l-.014.749c-.243.01-.499.048-.764.111l.031-.656a.152.152 0 00-.144-.16c-.08-.008-.156.061-.16.145l-.036.754c-.9.267-1.89.763-2.855 1.25-.417.209-.847.426-1.252.613-.123-.035-.408-.253-.53-.49-.123-.24-.129-.603-.082-.72.387-.218.811-.44 1.221-.654 1.628-.851 3.311-1.731 3.804-2.913.153-.365.275-.734.394-1.091.141-.426.274-.825.44-1.169-.005.457.05.893.227 1.24.445.866 1.26 1.047 1.748 1.156.079.017.151.033.214.05a.31.31 0 01.017.006 7.643 7.643 0 01-.073.23c-.175.51-.466 1.365-.02 2.234.146.286.39.53.663.736-.34-.071-.7-.184-1.073-.3zm3.806-2.615a2.758 2.758 0 01-1.357 1.53c-.332.17-.694.27-1.059.3a.736.736 0 01-.077-.114c-.249-.486-.075-.994.078-1.443.026-.075.05-.147.07-.211.037-.116.145-.52-.012-.828-.181-.357-.668-.496-.69-.503a5.704 5.704 0 00-.266-.064c-.491-.108-.855-.217-1.065-.627a1.034 1.034 0 01-.09-.318 2.835 2.835 0 012.113-.958c.966 0 1.825.505 2.242 1.318.303.59.343 1.27.113 1.918z"
        fill="var(--ion-color-dark)"
      />
    </svg>
  );
}

export default SvgCloveIcon;
