import React, { useEffect } from 'react';
import {
  IonList, IonInput,
} from '@ionic/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { handleServerHookForm, LoadingStatus } from 'utils/formValidation';
import { Heading } from 'components/baseElements/typography';
import { FormattedMessage } from 'react-intl';
import HookInput from 'components/baseElements/formControls/HookInput';
import { SubmitButton } from 'components/baseElements/formControls';
import Form from 'components/baseElements/formControls/StyledForm';

interface ForgotPasswordFormProperties {
  loadingStatus: LoadingStatus;
  submitAction: (data: any) => any;
  serverValidation?: any;
  validationSchema: any;
}

export default function ForgotRegistrationForm(
  props: ForgotPasswordFormProperties,
): JSX.Element {
  const {
    loadingStatus,
    serverValidation,
    submitAction,
    validationSchema,
  } = props;

  const form = useForm({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(validationSchema)
  });

  useEffect(() => {
    handleServerHookForm(serverValidation, form.setError);
  }, [serverValidation, form.setError])

  return (
    <Form onSubmit={form.handleSubmit(submitAction)}>
      <Heading headingSize="sm" textAlign="center">
        <FormattedMessage id="forgotPasswordForm.header.caption"/>
      </Heading>
      <IonList lines="full">
        <HookInput
          form={form}
          isRequired
          labelText="userRegistrationForm.labels.email"
          name="email"
        >
          <IonInput />
        </HookInput>
      </IonList>
      <SubmitButton
        isLoading={loadingStatus === LoadingStatus.Loading}
      >
        <FormattedMessage id="forgotPasswordForm.buttons.submit" />
      </SubmitButton>
    </Form>
  );
}
