import { HeaderSubmitButton, HeaderWithButtons } from 'components/baseElements/header';
import React, { useCallback, useState } from 'react';
import ReorderWines from 'components/wine/ReorderWines';
import { Flight, Wine } from 'store/wines';
import { getDataForWineReorder, WineAndFlightOrder } from 'store/wines/utils';
import { IonButton } from 'components/ionicComponents';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { GetLoadingStatus, makeApiCall } from 'utils/api';
import { LoadingStatus } from 'utils/formValidation';

interface ReorderWinesContainerProps {
  wines?: Array<Wine>;
  flights?: Array<Flight>;
  tastingId?: string;
  handleCancelAction: () => any;
  handleSaveAction: (data: Array<Wine>) => any;
}

const ReorderWinesContainer: React.FC<ReorderWinesContainerProps> = (props: ReorderWinesContainerProps) => {
  const { wines, flights, handleCancelAction, handleSaveAction, tastingId } = props;
  const [wineReorderData, setWineReorderData] = useState(getDataForWineReorder(wines, flights));
  const dispatch = useDispatch();
  const REORDER_WINES_KEY = 'reorderWines';
  const auth = useSelector((state: RootState) => state.authorization);
  const apiBaseUrl = useSelector((state: RootState): string => state.appSettings.apiBaseUrl);
  const reorderWinesApiCall = useSelector((state: RootState) =>
    state.api.callStatuses.find(e => e.id === REORDER_WINES_KEY),
  );

  const applyWineReorder = useCallback((winesArray?: Array<Wine>, reorderData?: Array<WineAndFlightOrder>): Array<Wine> => {
    if (!winesArray || !reorderData) {
      return [];
    }
    const winesArr = [...winesArray]
    let currentFlightId: string | undefined | null = null;
    let currentSequenceNumber = 0;
    reorderData.forEach((item: WineAndFlightOrder): void => {
      if (item.flight) {
        currentFlightId = item.flight.id;
        currentSequenceNumber = 0;
        return;
      }
      const wine = winesArr.find((w: Wine) => w.id === item.wine?.id);
      if (!wine) {
        return;
      }

      wine.flightId = currentFlightId;
      wine.flight = null;
      wine.sequence = currentSequenceNumber;
      currentSequenceNumber++;
    })
    return winesArr;
  }, []);

  const handleWineReorderSave = useCallback(async (): Promise<void> => {
    if (!wines) {
      return;
    }
    const orderedWines = applyWineReorder([...wines], wineReorderData);
    try {
      await makeApiCall({
        authToken: auth.token,
        dispatch: dispatch,
        callId: REORDER_WINES_KEY,
        request: {
          url: `${apiBaseUrl}/tastings/${tastingId}/wines/reorder`,
          httpMethod: 'PUT',
          body: orderedWines
        }
      });
      handleSaveAction(orderedWines);
    }
    catch (error) {
      return;
    }
  }, [applyWineReorder, handleSaveAction, wineReorderData, wines, apiBaseUrl, auth.token, dispatch, tastingId]);

  return (
    <>
      <HeaderWithButtons
        ionButtonsStart={(
          <IonButton onClick={(): void => { handleCancelAction() }}>
            <FormattedMessage id="general.buttons.cancel" />
          </IonButton>
        )}
        ionButtonsEnd={(
          <HeaderSubmitButton
            isLoading={GetLoadingStatus(reorderWinesApiCall?.status) === LoadingStatus.Loading}
            onClick={(): void => { handleWineReorderSave() }}
          />
        )}
      >
        <FormattedMessage id="reorderWines.header.caption" />
      </HeaderWithButtons>
      <ReorderWines
        data={getDataForWineReorder(wines, flights)}
        moveHandler={(data: Array<WineAndFlightOrder>): void => {
          setWineReorderData(data);
        }}
      />
    </>
  )
}

export default ReorderWinesContainer;