import {
  faCalendar as faCalendarRegular,
  faCalendarStar as faCalendarStarRegular,
} from '@fortawesome/pro-regular-svg-icons';
import { faCalendarStar, faCalendarWeek, faChevronRight, faFluxCapacitor } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import { IonDatetime, IonItem, IonList } from '@ionic/react';
import { SubmitButton } from 'components/baseElements/formControls';
import HookInput from 'components/baseElements/formControls/HookInput';
import Form from 'components/baseElements/formControls/StyledForm';
import { Flex } from 'components/baseElements/grid';
import { IonLabel } from 'components/ionicComponents';
import dayjs from 'dayjs';
import { Hideable, Paragraph } from 'napa-react-core';
import React, { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { DateFilterState } from 'store/tastings';
import { getNextWeek, getNextWeekend, getThisWeekend } from 'utils/dataFormat/date';


interface DateFilterFormProperties {
  onClickFilterSelection: (filter: DateFilterState) => any;
  onChooseDate: () => void;
  showDateRange: boolean;
  submitAction: (data: any) => any;
  validationSchema: any;
}

const DateFilterForm: FunctionComponent<DateFilterFormProperties> = (
  props: DateFilterFormProperties,
) => {
  const {
    onChooseDate,
    onClickFilterSelection,
    showDateRange,
    submitAction,
    validationSchema,
  } = props;

  const form = useForm({
    defaultValues: {
      startDate: (new Date()).toDateString(),
      endDate: null,
    },
    resolver: yupResolver(validationSchema),
  });

  const dateFilterStateValues = Object.values(DateFilterState);
  const dateFilterLengthHalf = dateFilterStateValues.length / 2;
  const dateFilterKeys = dateFilterStateValues.splice(0, dateFilterLengthHalf);

  const thisWeekEndDates = getThisWeekend(dayjs());
  const nextWeekDates = getNextWeek(dayjs());
  const nextWeekendDates = getNextWeekend(dayjs());
  const dateFilterCaptions = {
    [DateFilterState.AnyTime]: {
      icon: faCalendarRegular,
      text: '',
    },
    [DateFilterState.ThisWeekend]: {
      icon: faCalendarStarRegular,
      text: `${thisWeekEndDates.startDate.format('MMM D')} - ${thisWeekEndDates.endDate.format('MMM D YYYY')}`,
    },
    [DateFilterState.NextWeek]: {
      icon: faCalendarWeek,
      text: `${nextWeekDates.startDate.format('MMM D')} - ${nextWeekDates.endDate.format('MMM D YYYY')}`,
    },
    [DateFilterState.NextWeekend]: {
      icon: faCalendarStar,
      text: `${nextWeekendDates.startDate.format('MMM D')} - ${nextWeekendDates.endDate.format('MMM D YYYY')}`,
    },
    [DateFilterState.ChooseDate]: {
      icon: faFluxCapacitor,
      text: '',
      iconRight: faChevronRight,
    },
  };
  const dateFilterSelections = dateFilterKeys.map(curr => {
    return (
      <IonItem key={`date-filter-selection-${curr}`} onClick={(): void => {
        if (DateFilterState[curr] === DateFilterState.ChooseDate) {
          onChooseDate();
        } else {
          onClickFilterSelection(DateFilterState[curr]);
        }
      }}>
        <Flex
          alignItems="center"
          backgroundColor="var(--ion-color-medium-tint)"
          borderRadius="50%"
          justifyContent="center"
          mr={3}
          size={40}
        >
          <FontAwesomeIcon
            icon={dateFilterCaptions[DateFilterState[curr]].icon}
            size="lg"
          />
        </Flex>
        <IonLabel>
          <FormattedMessage id={`discoverTastings.dateFilter.${curr}`}/>
          <Paragraph>
            {dateFilterCaptions[DateFilterState[curr]].text}
          </Paragraph>
        </IonLabel>
        <Hideable hide={!dateFilterCaptions[DateFilterState[curr]].iconRight}>
          <FontAwesomeIcon icon={faChevronRight}/>
        </Hideable>
      </IonItem>
    );
  });
  return (
    <>
      <Hideable hide={showDateRange}>
        <IonList lines="full">
          {dateFilterSelections}
        </IonList>
      </Hideable>
      <Hideable hide={!showDateRange}>

        <Form style={{ height: '100%' }} id="dateFilterForm" onSubmit={form.handleSubmit(submitAction)}>
          <Flex flexDirection="column" height="100%">
            <IonList lines="full">
              <HookInput
                form={form}
                labelPosition="fixed"
                labelText="dateFilterModal.labels.startDate"
                name="startDate"
              >
                <IonDatetime
                  displayFormat="MMM D, YYYY"
                  max="2050"
                  min="2000"
                />
              </HookInput>
              <HookInput
                form={form}
                labelPosition="fixed"
                labelText="dateFilterModal.labels.endDate"
                name="endDate"
              >
                <IonDatetime
                  displayFormat="MMM D, YYYY"
                  max="2050"
                  min="2000"
                />
              </HookInput>
            </IonList>
            <Flex flex={1}/>
            <SubmitButton
            >
              <FormattedMessage id="dateFilterModal.button.submit"/>
            </SubmitButton>
          </Flex>
        </Form>
      </Hideable>
    </>
  );
};

export default DateFilterForm;
