import TastingForm from 'components/tastings/TastingForm';
import jwtDecode from 'jwt-decode';
import { ApiCall } from 'napa-react-core';
import queryStringHelper from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { AppSettingsState } from 'store/appSettings';
import { BrowseFilesResponseData } from 'store/files';
import { guiStateUpdateToast } from 'store/gui/guiStateUpdateToast';
import { Tasting, TastingWithAttendee, toEditTastingVm, toRequestInsertTastingDto } from 'store/tastings';
import { clearApiCallStatus, GetLoadingStatus, makeApiCall } from 'utils/api';
import { LoadingStatus } from 'utils/formValidation';
import { schema } from './validation';

interface EditTastingContainerProps {
  loadingStatus?: LoadingStatus;
  setLoadingStatus?: (status: LoadingStatus) => void;
  successHandler?: () => any;
  tastingId: string;
}

const EditTastingContainer: React.FC<EditTastingContainerProps> = (props: EditTastingContainerProps) => {
  const {
    loadingStatus,
    setLoadingStatus,
    successHandler,
    tastingId
  } = props;
  // CONSTANT DECLARATION
  const EDIT_TASTING_KEY = 'editTasting';
  const FETCH_TASTING_KEY= 'fetchTasting';
  const BROWSE_PUBLIC_IMAGE_FILES_KEY = 'browsePublicImageFiles';
  const dispatch = useDispatch();
  // LOCAL (CONTAINER) STATE SETUP
  const [tasting, setTasting] = useState(undefined as Tasting | undefined);
  const [tastingResponse, setTastingResponse] = useState(undefined as Tasting | undefined);

  const [fileId, setFileId] = useState<string>('');
  const [uploadedFileId, setUploadedFileId] = useState<string>(tasting?.tastingImageFileId || '');
  const [publicImageFileIds, setPublicImageFileIds] = useState<Array<string>>([]);

  // GLOBAL (REDUX) STATE SETUP
  const auth = useSelector((state: RootState) => state.authorization);
  const tokenData = jwtDecode(auth.token) as any;
  const allowPublicEventCreation = tokenData['claim/AllowPublicEventCreation'] === 'true';
  const { apiBaseUrl, cameraApiSettings } = useSelector((state: RootState): AppSettingsState => state.appSettings);
  const editTastingApiCall = useSelector((state: RootState) =>
    state.api.callStatuses.find(e => e.id === EDIT_TASTING_KEY),
  );
  const fetchTastingApiCall = useSelector(
    (state: RootState) =>
      state.api.callStatuses.find(e => e.id === FETCH_TASTING_KEY) as ApiCall<Tasting>,
  );

  // CALLBACK FUNCTIONS
  const onUploadError = (): void => {
    dispatch(
      guiStateUpdateToast({
        message: 'api.defaults.failureMessage',
      })
    );
  };

  const onUploadSuccess = (ids: Array<string>): void => {
    setFileId(ids[0]);
    setUploadedFileId(ids[0]);
  }

  const editTastingSubmitAction = (data: any): void => {
    setTasting(data);
  };

  const handleImageSelect = (id: string): void => {
    setFileId(id);
  }

  const fetchPublicImageFiles = useCallback(async (): Promise<Array<string>> => {
    try {
      const queryParams = queryStringHelper.stringify({
        isFilePublic: true,
      })
      const result = await makeApiCall({
        authToken: auth.token,
        dispatch: dispatch,
        callId: BROWSE_PUBLIC_IMAGE_FILES_KEY,
        request: {
          url: `${apiBaseUrl}/files/?${queryParams}`,
          httpMethod: 'GET',
        },
      }) as BrowseFilesResponseData;
      return result?.items || [];
    }
    catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    return [];
  }, [apiBaseUrl, auth.token, dispatch]);

  const fetchTastingData = useCallback(async (): Promise<void> => {

    try {
      const publicImageIds = await fetchPublicImageFiles();
      const tastingResponse = await makeApiCall<TastingWithAttendee>({
        authToken: auth.token,
        callId: FETCH_TASTING_KEY,
        dispatch: dispatch,
        request: {
          url: `${apiBaseUrl}/tastings/${tastingId}`,
          httpMethod: 'GET',
        },
      });
      setTastingResponse(toEditTastingVm(tastingResponse.tasting as Tasting));
      const tastingImage = tastingResponse?.tasting?.tastingImageFileId || '';
      setFileId(tastingImage);
      setUploadedFileId(tastingImage);
      setPublicImageFileIds([...publicImageIds])
    }

    catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [apiBaseUrl, auth.token, dispatch, fetchPublicImageFiles, tastingId])

  const handleEditTastingSubmit = useCallback(async (): Promise<void> => {
    try {
      if (!tasting || loadingStatus === LoadingStatus.Loading) {
        return;
      }
      if (setLoadingStatus) {
        setLoadingStatus(LoadingStatus.Loading);
      }
      await makeApiCall({
        authToken: auth.token,
        dispatch: dispatch,
        callId: EDIT_TASTING_KEY,
        showSuccessMessage: false,
        request: {
          url: `${apiBaseUrl}/tastings/${tastingId}`,
          httpMethod: 'PUT',
          body: toRequestInsertTastingDto({
            ...tasting,
            tastingImageFileId: fileId,
          },
          auth),
        },
      });
      if (successHandler) {
        successHandler();
      }
    }
    catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    if (setLoadingStatus) {
      setLoadingStatus(LoadingStatus.Complete);
    }
  }, [setLoadingStatus, tasting, loadingStatus, auth, dispatch, apiBaseUrl, tastingId, fileId, successHandler]);

  // EFFECTS SETUP
  useEffect(() => {
    clearApiCallStatus(dispatch, EDIT_TASTING_KEY);
    clearApiCallStatus(dispatch, BROWSE_PUBLIC_IMAGE_FILES_KEY);
    fetchTastingData();
  }, [dispatch, fetchPublicImageFiles, fetchTastingData]);

  useEffect(() => {
    handleEditTastingSubmit();
  }, [handleEditTastingSubmit]);

  return (
    <TastingForm
      allowPublicEventCreation={allowPublicEventCreation}
      apiBaseUrl={apiBaseUrl}
      cameraApiSettings={cameraApiSettings}
      currentSelectedFileId={fileId}
      editTastingLoadingStatus={GetLoadingStatus(editTastingApiCall?.status)}
      fetchTastingLoadingStatus={GetLoadingStatus(
        fetchTastingApiCall?.status,
      )}
      onImageSelect={handleImageSelect}
      onUploadError={onUploadError}
      onUploadSuccess={onUploadSuccess}
      publicImageFileIds={publicImageFileIds}
      tasting={tastingResponse}
      submitAction={editTastingSubmitAction}
      serverValidation={editTastingApiCall?.validationErrors}
      uploadedFileId={uploadedFileId}
      uploadUrl={`${apiBaseUrl}/files/upload`}
      validationSchema={schema}
    />
  );
}

export default EditTastingContainer;
