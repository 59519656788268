import {
  AUTHORIZATION_SET_TOKEN,
  AuthorizationSetTokenAction,
  AuthorizationSetTokenActionPayload,
} from './types';

export const authorizationSetToken = (
  payload: AuthorizationSetTokenActionPayload,
): AuthorizationSetTokenAction => ({
  type: AUTHORIZATION_SET_TOKEN,
  payload: payload,
});
