import { Device, DeviceInfo } from '@capacitor/device';
import { yupResolver } from '@hookform/resolvers/yup';
import { IonDatetime, IonInput, IonList } from '@ionic/react';
import { Divider } from 'components/baseElements/Divider';
import { SubmitButton } from 'components/baseElements/formControls';
import HookInput from 'components/baseElements/formControls/HookInput';
import Form from 'components/baseElements/formControls/StyledForm';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { AppSettingsState, CameraApiSettings } from 'store/appSettings';
import { User } from 'store/users';
import { handleFormDataReset, handleServerHookForm, LoadingStatus } from 'utils/formValidation';
import { Box } from '../../baseElements/grid';

interface UserProfileFormProperties {
  appSettings?: AppSettingsState;
  cameraApiSettings?: CameraApiSettings;
  createUserProfileLoadingStatus?: LoadingStatus;
  fetchUserProfileLoadingStatus?: LoadingStatus;
  onUploadError?: () => void;
  serverValidation?: any;
  submitAction: (data: any) => any;
  uploadUrl: string;
  user?: User;
  validationSchema?: any;
}

export default function UserProfileForm(props: UserProfileFormProperties): JSX.Element {
  const {
    createUserProfileLoadingStatus,
    fetchUserProfileLoadingStatus,
    validationSchema,
    user,
    serverValidation,
    submitAction,
  } = props;

  const form = useForm({
    defaultValues: {
      birthday: '',
      email: '',
      fullName: '',
      password: '',
      passwordConfirm: '',
      photoId: null,
      zipCode: '',
    },
    resolver: yupResolver(validationSchema),
  });
  const [deviceInfo, setDeviceInfo] = useState<DeviceInfo>();
  const getDeviceInfo = async (): Promise<void> => {
    setDeviceInfo(await Device.getInfo());
  };
  const currentYear = (new Date()).getFullYear();
  const oldestYear = currentYear - 120;
  // Callbacks
  // Effects
  useEffect(() => {
    if (!deviceInfo) {
      getDeviceInfo();
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    handleServerHookForm(serverValidation, form.setError);
  }, [serverValidation, form.setError]);

  useEffect(() => {
    handleFormDataReset(user, form.reset);
  }, [user, form.reset]);

  return (
    <Box flex={1}>
      <Form onSubmit={form.handleSubmit(submitAction)}>
        <IonList lines="full">
          <HookInput
            data-cy="fullNameMyProfile"
            form={form}
            isRequired
            labelPosition="stacked"
            labelText="userRegistrationForm.labels.fullName"
            name="fullName"
          >
            <IonInput data-cy="fullNameMyProfile"/>
          </HookInput>
          <HookInput
            hidden
            form={form}
            name="email"
          />
          <HookInput
            hidden
            form={form}
            name="photoId"
          />
          <HookInput
            form={form}
            labelPosition="stacked"
            labelText="userRegistrationForm.labels.zipCode"
            name="zipCode"
          >
            <IonInput data-cy="zipCodeMyProfile" type="number"/>
          </HookInput>
          <HookInput
            form={form}
            labelPosition="stacked"
            labelText="userRegistrationForm.labels.dateOfBirth"
            name="birthday"
          >
            <IonDatetime
              displayFormat="MMMM YYYY"
              data-cy="birthdayMyProfile"
              max={currentYear.toString()}
              min={oldestYear.toString()}
            />
          </HookInput>
        </IonList>
        <Box mx={3}>
          <SubmitButton
            isLoading={(createUserProfileLoadingStatus || fetchUserProfileLoadingStatus) === LoadingStatus.Loading}
          >
            <FormattedMessage id="general.buttons.save"/>
          </SubmitButton>
        </Box>
      </Form>
      <Divider mt={0} $width={1}/>
    </Box>
  );
}
