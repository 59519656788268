import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import { IonInput, IonItem, IonList } from '@ionic/react';
import HookInput from 'components/baseElements/formControls/HookInput';
import Form from 'components/baseElements/formControls/StyledForm';
import { Box } from 'components/baseElements/grid';
import { Hideable } from 'napa-react-core';
import React, { FunctionComponent, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { GeoLocation, geoLocationDistanceOptions, GeoLocationFilterState } from 'store/geoLocation';
import { Paragraph } from '../../baseElements/typography';
import { IonLabel, IonText } from '../../ionicComponents';

interface GeoLocationFilterFormProperties {
  distanceFromZipCode?: number;
  geoLocation?: GeoLocation;
  geoLocationError?: any;
  getPosition: () => void;
  validationSchema: any;
  zipCode?: string;
  onCurrentLocationSelection: (geoLocationFilterState?: GeoLocationFilterState) => any;
  submitAction: (geoLocationFilterState?: GeoLocationFilterState) => void;
}

const GeoLocationFilterForm: FunctionComponent<GeoLocationFilterFormProperties> = (
  props: GeoLocationFilterFormProperties,
) => {
  const {
    distanceFromZipCode,
    onCurrentLocationSelection,
    geoLocation,
    submitAction,
    zipCode,
    validationSchema,
  } = props;
  const form = useForm({
    defaultValues: {
      zipCode: zipCode || '',
    },
    resolver: yupResolver(validationSchema),
  });
  const onCurrentLocationClick = (): void => {
    onCurrentLocationSelection({
      distanceFromZipCode: geoLocation?.zipCode ? (distanceFromZipCode || 25) : undefined,
      zipCode: geoLocation?.zipCode,
    });
  };
  const onDistanceClick = (value?: number): void => {
    const { zipCode } = form.getValues();
    if (!value || zipCode?.length === 5) {
      submitAction({
        distanceFromZipCode: (zipCode && value) ? value : undefined,
        zipCode: (zipCode && value) ? zipCode : undefined,
      });
    } else {
      form.setError('zipCode', { message: 'geoLocationFilterModal.validation.zipCode', type: 'error' });
    }
  };
  // This effect is used to ensure pre-population of the user zip code from their profile or a zip code if it is not
  // available on the first render cycle.
  useEffect(() => {
    const { zipCode: formZipCode } = form.getValues();
    if (!formZipCode) {
      form.setValue('zipCode', zipCode || geoLocation?.zipCode || '');
    }
  }, [geoLocation, form, zipCode]);
  const geoLocationFilterSelections = geoLocationDistanceOptions.map(option => {
    return (
      <IonItem key={`geo-location-selection-${option.value}`} onClick={(): void => onDistanceClick(option.value)}>
        <Box mr={3} width={16}>
          <FontAwesomeIcon icon={option.icon}/>
        </Box>
        <IonLabel>
          <Paragraph>
            <FormattedMessage id={option.label}/>
          </Paragraph>
        </IonLabel>
        <Hideable hide={distanceFromZipCode !== option.value}>
          <FontAwesomeIcon icon={faCheck}/>
        </Hideable>
      </IonItem>
    );
  });
  return (
    <Form id="geoLocationFilterForm" onSubmit={form.handleSubmit(submitAction)}>
      <IonList lines="full">
        <HookInput
          form={form}
          isRequired
          labelPosition="fixed"
          labelText="geoLocationFilterModal.labels.zipCode"
          name="zipCode"
        >
          <IonInput/>
        </HookInput>
        <Hideable hide={!!geoLocation?.zipCode}>
          <Box p={3}>
            &nbsp;
            <IonText color="primary">
              <strong>&nbsp;</strong>
            </IonText>
          </Box>
        </Hideable>
        <Hideable hide={!geoLocation?.zipCode}>
          <Box p={3} onClick={(): void => onCurrentLocationClick()}>
            <FormattedMessage id="geoLocationFilterModal.labels.or"/>&nbsp;
            <IonText color="primary">
              <strong><FormattedMessage
                id="geoLocationFilterModal.labels.currentLocation"/>&nbsp;({geoLocation?.zipCode})</strong>
            </IonText>
          </Box>
        </Hideable>
        {geoLocationFilterSelections}
      </IonList>
    </Form>
  );
};

export default GeoLocationFilterForm;
