import { ApiCall, ApiCallStatus, ServerValidationError } from 'napa-react-core';
import { ApiState } from '../../types';
import { ApiStateUpdateCallStatusAction } from './types';

const upsertCallStatus = (
  existingCallStatuses: Array<ApiCall<any>>,
  id: string,
  status: ApiCallStatus,
  errorMessage?: string,
  validationErrors?: Array<ServerValidationError>,
  resultObject?: any,
): Array<ApiCall<any>> => {
  const index = existingCallStatuses.findIndex(element => element.id === id);
  if (index === -1) {
    return [
      ...existingCallStatuses,
      {
        id: id,
        status: status,
        errorMessage: errorMessage,
        validationErrors: validationErrors,
        resultObject: resultObject,
      },
    ];
  }

  return existingCallStatuses.map((callStatus, i) => {
    if (i === index) {
      return {
        id: callStatus.id,
        errorMessage: errorMessage,
        status: status,
        validationErrors: validationErrors,
        resultObject: resultObject,
      };
    }

    return callStatus;
  });
};

export const apiStateUpdateCallStatusReducer = (
  apiState: ApiState,
  action: ApiStateUpdateCallStatusAction,
): ApiState => ({
  ...apiState,
  callStatuses: upsertCallStatus(
    apiState.callStatuses,
    action.payload.id,
    action.payload.status,
    action.payload.errorMessage,
    action.payload.validationErrors,
    action.payload.resultObject,
  ),
});
