import { useIonViewWillEnter } from '@ionic/react';
import { Box } from 'components/baseElements/grid';
import DiscoverTastings from 'components/tastings/DiscoverTastings';
import GetStarted from 'components/tastings/GetStarted';
import GetStartedNoSession from 'components/tastings/GetStartedNoSession';
import MyTastings from 'components/tastings/MyTastings';
import QuickRateContainer from 'containers/wine/QuickRateContainer';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { AuthorizationStore, Hideable, useAuthorization, useToast } from 'napa-react-core';
import queryStringHelper from 'query-string';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { RootState } from 'store';
import {
  BrowseTastingResponseData,
  DateFilterState,
  defaultBrowseTastingResponseData,
  DiscoveryFilterState,
  LocationTypeFilterState,
  TastingWithAttendee,
} from 'store/tastings';
import { clearApiCallStatus, GetLoadingStatus, makeApiCall } from 'utils/api';
import { LoadingStatus } from 'utils/formValidation';
import { useLocalStorage } from '../../../hooks/filters';
import { getUserAuthToken, getUserId } from '../../../store/authorization';
import { authorizationSetToken } from '../../../store/authorization/actions/authorizationSetToken';
import { getViewSchema } from './viewSchema';

dayjs.extend(utc);
dayjs.extend(timezone);

const BrowseTastingsContainer: React.FC = () => {
  // CONSTANT DECLARATION
  const BROWSE_TASTING_KEY = 'browseTasting';
  const DISCO_TASTING_KEY = 'discoverTasting';
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const { showToast } = useToast();

  // LOCAL (CONTAINER) STATE SETUP
  const infiniteScrollLimit = useSelector((state: RootState): number => state.appSettings.infiniteScrollLimit);
  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(false);
  const [discoverTastings, setDiscoverTastings] = useState<BrowseTastingResponseData>({ items: [], totalItems: 0 });
  const [disableLoading, setDisableLoading] = useState<boolean>(false);
  const [filterParams, setFilterParams, getFilterParams] = useLocalStorage<DiscoveryFilterState>('BROWSE_TASTING_FILTER', {
    filter: LocationTypeFilterState.VirtualAndInPerson,
    dateFilter: DateFilterState.AnyTime,
    limit: infiniteScrollLimit,
    offset: 0,
    searchText: '',
    startDate: undefined,
    endDate: undefined,
  });
  const [refreshData, setRefreshData] = useState(false);
  // GLOBAL (REDUX) STATE SETUP
  const authData = useContext(AuthorizationStore);
  const { logoff } = useAuthorization();
  const auth = authData.state;
  const apiBaseUrl = useSelector((state: RootState): string => state.appSettings.apiBaseUrl);
  const browseTastingApiCall = useSelector((state: RootState) =>
    state.api.callStatuses.find(e => e.id === BROWSE_TASTING_KEY),
  );
  const discoverTastingApiCall = useSelector((state: RootState) =>
    state.api.callStatuses.find(e => e.id === DISCO_TASTING_KEY),
  );

  // LOCAL FUNCTIONS
  const handleDiscoverTastingsResponse = useCallback((prevPublicTastings: BrowseTastingResponseData, response: BrowseTastingResponseData, offset: number): void => {
    if (response?.items) {
      if (offset) {
        // When logging in or out, because the auth state changes, another fetch is made to the API and duplicates
        // will go in the list if not filtered out.
        setDiscoverTastings({
          items: Object.values([...prevPublicTastings.items, ...response.items].reduce((result: any, item: TastingWithAttendee) => {
            if (item.tasting.id) {
              result[item.tasting.id] = item;
            }
            return result;
          }, {})),
          totalItems: response.totalItems,
        });
      } else {
        setDiscoverTastings({
          items: response.items,
          totalItems: response.totalItems,
        });
      }
      setDisableLoading(true);
      setDisableInfiniteScroll(response.items.length < infiniteScrollLimit);
    }
  }, [infiniteScrollLimit]);
  const fetchDiscoverTastings = async (prevPublicTastings: BrowseTastingResponseData, currentFilterState: DiscoveryFilterState): Promise<void> => {
    try {
      const queryParams = queryStringHelper.stringify({
        ...currentFilterState,
        startDate: currentFilterState.startDate ? dayjs(currentFilterState.startDate).startOf('d').toString() : null,
        endDate: currentFilterState.endDate ? dayjs(currentFilterState.endDate).startOf('d').toString() : null,
        tz: dayjs.tz.guess(),
        sortByAndDirection: 'DateTime Asc',
      });
      const browseResponse: BrowseTastingResponseData = await makeApiCall({
        authToken: auth?.token,
        dispatch: dispatch,
        callId: DISCO_TASTING_KEY,
        request: {
          url: `${apiBaseUrl}/tastings/public/?${queryParams}`,
          httpMethod: 'GET',
        },
      });
      handleDiscoverTastingsResponse(prevPublicTastings, browseResponse, currentFilterState.offset);
    } catch (error) {
      if (error.message === 'Validation errors') {
        showToast({
          duration: 3000,
          message: intl.formatMessage({ id: 'discoverTastings.filter.failure' }),
          show: true,
        });
      }
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };
  const handleFilterChange = async (changedFilterParams: DiscoveryFilterState): Promise<void> => {
    const nextFilterParams = {
      ...filterParams,
      ...changedFilterParams,
      offset: 0,
    };
    setFilterParams(nextFilterParams);
    await fetchDiscoverTastings(defaultBrowseTastingResponseData, nextFilterParams);
  };
  const onSearchNext = async (searchText: string): Promise<void> => {
    const nextFilterParams = {
      ...filterParams,
      offset: 0,
      searchText,
    };
    setFilterParams({ ...nextFilterParams });
    setDisableLoading(true);
    await fetchDiscoverTastings(discoverTastings, nextFilterParams);
  };
  const onScrollNext = async (event: CustomEvent<void>): Promise<void> => {
    const nextFilterParams = {
      ...filterParams,
      offset: filterParams.offset + infiniteScrollLimit,
    };
    setFilterParams({ ...nextFilterParams });
    setDisableLoading(true);
    await fetchDiscoverTastings(discoverTastings, nextFilterParams);
    await (event.target as HTMLIonInfiniteScrollElement).complete();
  };
  const fetchMyTastings = useCallback(async (): Promise<void> => {
    try {
      const authToken = getUserAuthToken();
      if (!authToken) {
        return;
      }
      const queryParams = queryStringHelper.stringify({
        hostId: getUserId(),
        sortByAndDirection: 'DateTime Asc',
      });
      await makeApiCall({
        authToken: getUserAuthToken(),
        dispatch: dispatch,
        callId: BROWSE_TASTING_KEY,
        request: {
          url: `${apiBaseUrl}/tastings/?${queryParams}`,
          httpMethod: 'GET',
        },
      });
    } catch (error) {
      if (error.message === 'A forbidden error has occurred') {
        dispatch(authorizationSetToken({}));
        logoff();
      }
      // eslint-disable-next-line no-console
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiBaseUrl, auth.token, auth.tokenData, dispatch]);
  const handleCreateTastingModalCallback = (result: any): any => {
    if (result && result.createdTasting) {
      setRefreshData(true);
      history.push(`/tastings/details/${result.tastingId}`);
    }
  };

  // EFFECTS SETUP
  useIonViewWillEnter((): void => {
    // We use filter data from localStorage; useIonViewWillEnter seems to capture component state from the time of mount
    const filterLocalStorage = getFilterParams();
    clearApiCallStatus(dispatch, BROWSE_TASTING_KEY);
    clearApiCallStatus(dispatch, DISCO_TASTING_KEY);
    fetchMyTastings()
      // eslint-disable-next-line no-console
      .catch(console.error);
    fetchDiscoverTastings(discoverTastings, filterLocalStorage)
      // eslint-disable-next-line no-console
      .catch(console.error);
  });
  useEffect(() => {
    if (!refreshData) {
      return;
    }
    clearApiCallStatus(dispatch, BROWSE_TASTING_KEY);
    clearApiCallStatus(dispatch, DISCO_TASTING_KEY);
    fetchMyTastings()
      // eslint-disable-next-line no-console
      .catch(console.error);
    setRefreshData(false);
  }, [dispatch, fetchMyTastings, refreshData, setRefreshData]);
  useEffect(() => {
    if (!auth.token) {
      return;
    }
    setRefreshData(true);
  }, [auth.token, setRefreshData]);
  const viewSchema = getViewSchema(auth, browseTastingApiCall);
  return (
    <>
      <Box
        background={!viewSchema.myTastings.isVisible && 'rgba(var(--ion-color-secondary-rgb), 0.21)'}
        minHeight={200}
      >
        <Hideable hide={!viewSchema.myTastings.isVisible}>
          <MyTastings
            browseTastingLoadingStatus={GetLoadingStatus(browseTastingApiCall?.status)}
            createTastingModalCallback={handleCreateTastingModalCallback}
            browseTastings={browseTastingApiCall?.resultObject}
          />
        </Hideable>
        <Hideable hide={!viewSchema.getStartedNoSession.isVisible}>
          <GetStartedNoSession />
        </Hideable>
        <Hideable hide={!viewSchema.getStarted.isVisible}>
          <GetStarted
            createTastingModalCallback={handleCreateTastingModalCallback}
          />
        </Hideable>
      </Box>
      <DiscoverTastings
        disableInfiniteScroll={disableInfiniteScroll}
        filterState={filterParams}
        data={discoverTastings}
        isLoading={GetLoadingStatus(discoverTastingApiCall?.status) === LoadingStatus.Loading && !disableLoading}
        onFilterChange={handleFilterChange}
        onSearchNext={onSearchNext}
        onScrollNext={onScrollNext}
      />
      <Hideable hide={!auth.token}>
        <QuickRateContainer />
      </Hideable>
    </>
  );
};

export default BrowseTastingsContainer;
