import { IonFab, IonFabButton } from '@ionic/react';
import { Box } from 'components/baseElements/grid';
import { ScoreAWineModalId } from 'modals/ScoreAWineModal';
import { masterWinesSearchModalId } from 'modals/SearchMasterWinesModal';
import { WineScoreSummaryModalId } from 'modals/WineScoreSummaryModal';
import React, { } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Wine } from 'store/wines';
import { showModal } from 'utils/modals';

interface QuickRateContainerProps {
  addWineCallback?: (result: any) => void;
  onAddWineClick?: () => void;
}
const QuickRateContainer: React.FC<QuickRateContainerProps> = (props: QuickRateContainerProps) => {

  const { addWineCallback, onAddWineClick } = props;
  // GLOBAL (REDUX) STATE SETUP
  const dispatch = useDispatch();
  const scoreAWineCallback = (wine: Wine, isSaveAndClose: any): void => {
    if (isSaveAndClose) {
      showModal(dispatch, WineScoreSummaryModalId, {
        params: { wine, hideBackHeader: true },
      });
    }
  }
  const addWineModalCallback = addWineCallback ? addWineCallback : (result: any): void => {
    if (result?.wine) {
      showModal(dispatch, ScoreAWineModalId, {
        params: { wine: result.wine },
        callback: (data: Wine, isSaveAndClose): void => scoreAWineCallback(result.wine, isSaveAndClose),
      });
    }
  }

  const onAddWineClickHandler = onAddWineClick ? onAddWineClick : (): void => {
    showModal(dispatch, masterWinesSearchModalId, {
      params: {
        wines: [],
        isSearchForMyWines: true,
      },
      callback: addWineModalCallback
    });
  };

  return (
    <IonFab vertical="bottom" horizontal="end" slot="fixed">
      <IonFabButton color="secondary" onClick={onAddWineClickHandler} >
        <Box p={1}>
          <FormattedMessage id="myRatings.buttons.quickRate" />
        </Box>
      </IonFabButton>
    </IonFab>
  );
}

export default QuickRateContainer;
