import { IonContent, IonPage } from '@ionic/react';
import ResetPasswordContainer from 'containers/users/ResetPasswordContainer';
import * as React from 'react';
import { useLocation } from 'react-router-dom';

export default function ResetPasswordPage(): JSX.Element {
  function useQuery(): URLSearchParams {
    return new URLSearchParams(useLocation().search);
  }

  const token = useQuery().get('token') || '';
  const email = useQuery().get('email') || '';

  // eslint-disable-next-line no-console
  console.log(`[ResetPasswordPage] Token: ${token}`);

  return (
    <IonPage>
      <IonContent>
        <ResetPasswordContainer
          email={email}
          resetToken={token}
        />
      </IonContent>
    </IonPage>
  );
}
