/* eslint react/prop-types: 0 */
import { ErrorMessage } from '@hookform/error-message';
import { IonItem, IonLabel, isPlatform } from '@ionic/react';
import { Box, Flex } from 'components/baseElements/grid';
import * as React from 'react';
import { FunctionComponent } from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import AssistiveText from '../AssistiveText';
import { TextareaStyles } from '../TextareaStyles';

const scrollToFormControl = (e: { srcElement: { parentElement: any } }): void => {
  const parent = e.srcElement?.parentElement;
  if (isPlatform('mobileweb') || isPlatform('pwa')) {
    return;
  }
  if (parent) {
    parent.scrollIntoView(true);
  }
};

type HookInputProps = {
  additionalChangeHandler?: (e: any) => void;
  assistiveText?: string;
  assistiveTextChildren?: React.ReactNode;
  autoGrow?: boolean;
  defaultValue?: any;
  enableScroll?: boolean;
  form: UseFormMethods<any>;
  isRequired?: boolean;
  isTextarea?: boolean;
  labelPosition?: 'default' | 'floating' | 'fixed' | 'stacked' | undefined;
  labelStyle?: any;
  labelText?: string;
  lines?: 'full' | 'inset' | 'none' | undefined;
  name?: any;
  hidden?: boolean;
  placeholder?: string;
}

const HookInput: FunctionComponent<HookInputProps> = (props) => {
  const {
    assistiveText,
    assistiveTextChildren,
    autoGrow,
    defaultValue,
    enableScroll,
    form,
    hidden = false,
    isRequired,
    isTextarea = false,
    labelPosition = 'floating',
    labelStyle,
    labelText,
    lines = 'full',
    name,
    placeholder,
  } = props;

  const children: any = React.Children.toArray(props.children);
  if (hidden) {
    return (
      <Controller
        control={form.control}
        name={name}
        defaultValue={defaultValue}
        render={(): any => <></>}
      />
    );
  }

  return (
    <div>
      <IonItem lines={lines}>
        <TextareaStyles isTextarea={isTextarea}>
          {labelText ? (
            <IonLabel
              position={isTextarea ? 'stacked' : labelPosition === 'default' ? undefined : labelPosition}
              style={labelStyle}
            >
              <FormattedMessage id={labelText} />
              {isRequired ? '*' : undefined}
            </IonLabel>
          ) : ''}
          <Controller
            control={form.control}
            name={name}
            defaultValue={defaultValue}
            render={({ onChange, value }): any => {
              return React.cloneElement(props.children as any, {
                onIonChange: (e: any): void => {
                  if (children[0].props.onIonChange) {
                    children[0].props.onIonChange(e);
                  }
                  if (e.detail.checked !== undefined) {
                    onChange(e.detail.checked);
                  } else {
                    onChange(e.detail.value);
                  }
                },
                onIonFocus: (e: any): void => {
                  if (children[0].props.onIonFocus) {
                    children[0].props.onIonFocus(e);
                  } else if (enableScroll) {
                    scrollToFormControl(e);
                  }
                },
                checked: children[0].props.checked !== undefined ? value : undefined,
                placeholder: placeholder,
                value: value,
                autoGrow: autoGrow,
              });
            }}
          />
        </TextareaStyles>
      </IonItem>
      <ErrorMessage
        errors={form.errors}
        name={name}
        render={({ message }): React.ReactNode => (
          <AssistiveText
            color="danger"
            data-cy={`${name}ValidationMessage`}
          >
            <Flex justifyContent="space-between">
              <Box>
                <FormattedMessage id={message}/>
              </Box>
              <Box>
                {assistiveTextChildren}
              </Box>
            </Flex>
          </AssistiveText>
        )}
      />
      {!assistiveText && isRequired ? (
        <AssistiveText data-cy={`${name}AssistiveTextRequired`}>
          <Flex justifyContent="space-between">
            <Box>
              <FormattedMessage id="general.assistiveText.required"/>
            </Box>
            <Box>
              {assistiveTextChildren}
            </Box>
          </Flex>
        </AssistiveText>
      ) : undefined}
      {assistiveText ? (
        <AssistiveText data-cy={`${name}AssistiveText`}>
          <Flex justifyContent="space-between">
            <Box>
              <FormattedMessage id={assistiveText}/>
            </Box>
            <Box>
              {assistiveTextChildren}
            </Box>
          </Flex>
        </AssistiveText>
      ) : undefined}
    </div>
  );
};

export default HookInput;
