import * as React from 'react';
import {
  IonCol,
  IonGrid,
  IonRow,
} from '@ionic/react';
import { IonText } from 'components/ionicComponents';

interface AssistiveTextProps {
  children?: React.ReactNode;
  color?: string;
  ionColProps?: any;
  ionGridProps?: any;
  ionRowProps?: any;
}

export default function AssistiveText(props: AssistiveTextProps): JSX.Element {
  const {
    children,
    color = 'medium',
    ionColProps = {
      className: 'ion-no-padding',
    },
    ionGridProps = {
      className: 'assistive-text ion-padding-bottom ion-padding-horizontal',
      style: {
        backgroundColor: 'var(--background)',
      },
    },
    ionRowProps = {
      className: 'ion-no-padding',
    },
  } = props;

  return (
    <IonGrid {...ionGridProps} {...props}>
      <IonRow {...ionRowProps}>
        <IonCol {...ionColProps}>
          <IonText
            color={color}
            $display="block"
            $fontWeight={400}
          >
            {children}
          </IonText>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
}
