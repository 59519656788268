import { IonActionSheet, IonContent, IonLoading } from '@ionic/react';
import { Loadable, Loaded, Unloaded } from 'components/baseElements/loadable';
import ChangeFlightAlert from 'components/tastings/ChangeFlightAlert';
import EditWineForTastingActionSheet from 'components/tastings/EditWineForTastingActionSheet';
import { ConfirmAlert } from 'components/wine/ConfirmAlert';
import { EditWineListForTastingActionBar } from 'components/wine/EditWineListForTastingActionBar';
import WineListForTasting from 'components/wine/WineListForTasting';
import { WineListItemType } from 'components/wine/WineListItem';
import ReorderFlightsContainer from 'containers/flights/ReorderFlightsContainer';
import useDeleteTastingWines from 'hooks/wines/useDeleteTastingWines';
import { EditFlightInTastingModalId } from 'modals/EditFlightInTastingModal';
import { EditWineInTastingModalId } from 'modals/EditWineInTastingModal';
import { ApiCall } from 'napa-react-core';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { PaginatedData } from 'store/api';
import { Flight, Wine, WineStatus } from 'store/wines';
import { toRequestEditWineTastingDto } from 'store/wines/utils';
import { clearApiCallStatus, GetLoadingStatus, makeApiCall } from 'utils/api';
import { LoadingStatus } from 'utils/formValidation';
import { showModal } from 'utils/modals';
import ReorderWinesContainer from '../ReorderWinesContainer';

interface EditWineListForTastingContainerProps {
  tastingId: string;
  mode: string;
  setMode: (data: any) => any;
}

const EditWineListForTastingContainer: React.FC<EditWineListForTastingContainerProps> = (props: EditWineListForTastingContainerProps) => {
  const { tastingId, mode, setMode } = props;
  const FETCH_WINES_KEY = 'EDIT_WINE_TASTING_CONTAINER_FETCH_WINE';
  const FETCH_FLIGHTS_KEY = 'EDIT_WINE_TASTING_CONTAINER_FETCH_FLIGHTS';
  const dispatch = useDispatch();
  const intl = useIntl();
  const deleteWineHeader = intl.formatMessage({
    id: 'deleteWine.alert.header'
  });
  const deleteWineMessage = intl.formatMessage({
    id: 'deleteWine.alert.message'
  });
  const deleteWineConfirm = intl.formatMessage({
    id: 'deleteWine.alert.confirm'
  });
  const deleteWineCancel = intl.formatMessage({
    id: 'deleteWine.alert.cancel'
  });
  const [wines, setWines] = useState([] as Array<Wine>);
  const [flights, setFlights] = useState([] as Array<Flight>);
  const [changeFlightAlert, setChangeFlightAlert] = useState<{ flightId?: string | null; wineId?: string }>();
  const [showDeleteWineAlert, setShowDeleteWineAlert] = useState(false);
  const [showReorderActionSheet, setShowReorderActionSheet] = useState(false);
  const [showEditWineActionSheet, setShowEditWineActionSheet] = useState(false);
  const [wineForEdit, setWineForEdit] = useState({} as Wine);
  const [wineForDelete, setWineForDelete] = useState('');

  const deleteTastingWine = useDeleteTastingWines(wineForDelete);

  const apiBaseUrl = useSelector((state: RootState): string => state.appSettings.apiBaseUrl);
  const fetchWinesApiCall = useSelector(
    (state: RootState) =>
      state.api.callStatuses.find(e => e.id === FETCH_WINES_KEY) as ApiCall<PaginatedData<Wine>>,
  );
  const fetchFlightsApiCall = useSelector(
    (state: RootState) =>
      state.api.callStatuses.find(e => e.id === FETCH_FLIGHTS_KEY) as ApiCall<PaginatedData<Flight>>,
  );

  const handleFlightReorderSave = useCallback((data: Array<Flight>): void => {
    setFlights(data);
    setMode('edit');
  }, [setMode]);

  const handleWineReorderSave = useCallback((data: Array<Wine>): void => {
    setWines(data);
    setMode('edit');
  }, [setMode]);

  const fetchWines = useCallback(async (tastingId): Promise<void> => {
    try {
      if (!tastingId) {
        return;
      }
      const result = await makeApiCall<PaginatedData<Wine>>({
        dispatch: dispatch,
        callId: FETCH_WINES_KEY,
        request: {
          url: `${apiBaseUrl}/tastings/${tastingId}/wines`,
          httpMethod: 'GET',
        },
      });
      setWines(result.items);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [apiBaseUrl, dispatch]);

  const fetchFlights = useCallback(async (tastingId): Promise<void> => {
    try {
      if (!tastingId) {
        return;
      }
      const result = await makeApiCall<PaginatedData<Flight>>({
        dispatch: dispatch,
        callId: FETCH_FLIGHTS_KEY,
        request: {
          url: `${apiBaseUrl}/tastings/${tastingId}/flights`,
          httpMethod: 'GET',
        },
      });
      setFlights(result.items);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [apiBaseUrl, dispatch]);

  const handleModalCallback = useCallback((result: any): any => {
    setShowEditWineActionSheet(false);
    if (result) {
      if (result.created || result.modified) {
        clearApiCallStatus(dispatch, FETCH_WINES_KEY);
        clearApiCallStatus(dispatch, FETCH_FLIGHTS_KEY);
        fetchWines(tastingId)
          // eslint-disable-next-line no-console
          .catch(console.error);
        fetchFlights(tastingId)
          // eslint-disable-next-line no-console
          .catch(console.error);
      }
      // Show edit flights if there are some
      if (flights?.length && result.created && result.wine) {
        setWineForEdit(toRequestEditWineTastingDto({ ...result.wine }));
        setChangeFlightAlert({ wineId: result.wine.id, flightId: null });
      }
    }
  }, [dispatch, fetchFlights, fetchWines, flights, tastingId]);
  const handleWineRemoval = async () => {
    setShowEditWineActionSheet(false);
    await deleteTastingWine.mutateAsync({})
      // eslint-disable-next-line no-console
      .catch(console.error);
    handleModalCallback({ modified: true });
  }

  useEffect(() => {
    clearApiCallStatus(dispatch, FETCH_WINES_KEY);
    clearApiCallStatus(dispatch, FETCH_FLIGHTS_KEY);
    fetchWines(tastingId)
      // eslint-disable-next-line no-console
      .catch(console.error);
    fetchFlights(tastingId)
      // eslint-disable-next-line no-console
      .catch(console.error);
  }, [dispatch, fetchFlights, fetchWines, tastingId]);
  if (mode === 'edit') {
    return (
      <>
        <ChangeFlightAlert
          flights={flights}
          isOpen={!!changeFlightAlert}
          wine={wineForEdit}
          onClose={(): void => {
            setChangeFlightAlert(undefined);
          }}
          onRefreshData={(): void => {
            handleModalCallback({ modified: true });
          }}
        />
        <ConfirmAlert
          cancel={deleteWineCancel}
          confirmCaption={deleteWineConfirm}
          header={deleteWineHeader}
          isOpen={showDeleteWineAlert}
          onDidDismiss={(): void => setShowDeleteWineAlert(false)}
          onConfirm={(): void => {
            handleWineRemoval();
          }}
          message={deleteWineMessage}
        />
        <Loadable
          isLoading={
            GetLoadingStatus(fetchWinesApiCall?.status) === LoadingStatus.Loading
            || GetLoadingStatus(fetchFlightsApiCall?.status) === LoadingStatus.Loading
          }
        >
          <Loaded>
            <EditWineListForTastingActionBar
              addWineToTastingModalCallback={handleModalCallback}
              addFlightToTastingModalCallback={handleModalCallback}
              flights={flights}
              sortMenuOnClick={(): void => setShowReorderActionSheet(!showReorderActionSheet)}
              tastingId={tastingId}
              wines={wines}
            />
            <IonContent>
              <WineListForTasting
                flights={flights}
                flightClickHandler={(flight: Flight): void =>
                  showModal(dispatch,
                    EditFlightInTastingModalId,
                    {
                      params: { tastingId: tastingId, flight: flight },
                      callback: handleModalCallback,
                    },
                  )}
                tastingId={tastingId}
                wineListItemType={WineListItemType.ActionMenu}
                wines={wines}
                wineClickHandler={(wine: Wine): void => {
                  // Only non approved wines can be edited
                  if (wine.approvalStatus !== WineStatus.Approved) {
                    showModal(dispatch,
                      EditWineInTastingModalId,
                      {
                        params: { tastingId: tastingId, wine: wine, flights: flights },
                        callback: handleModalCallback,
                      },
                    );
                  }
                }}
                wineEllipsisClickHandler={(wine: Wine): void => {
                  setWineForEdit(toRequestEditWineTastingDto({ ...wine }));
                  setShowEditWineActionSheet(true);
                }}
                wineRemoveClickHandler={(wine: Wine): void => {
                  setWineForDelete(wine?.id || '');
                  setShowDeleteWineAlert(true);
                }}
              />
            </IonContent>
            <IonActionSheet
              buttons={[{
                text: intl.formatMessage({
                  id: 'reorderFlights.header.caption',
                }),
                handler: (): void => {
                  setMode('reorderFlights');
                },
              }, {
                text: intl.formatMessage({
                  id: 'reorderWines.header.caption',
                }),
                handler: (): void => {
                  setMode('reorderWines');
                },
              }]}
              isOpen={showReorderActionSheet}
              onDidDismiss={(): void => setShowReorderActionSheet(false)}
            />
            <EditWineForTastingActionSheet
              flightCount={flights?.length}
              isOpen={showEditWineActionSheet}
              wine={wineForEdit}
              onClose={(): void => setShowEditWineActionSheet(false)}
              onChangeFlight={(wineId?: string, flightId?: string | null): void => {
                setShowEditWineActionSheet(false);
                setChangeFlightAlert({ wineId, flightId });
              }}
              onEdit={(): void => {
                if (wineForEdit?.approvalStatus !== WineStatus.Approved) {
                  showModal(dispatch, EditWineInTastingModalId, {
                    params: {
                      tastingId: tastingId,
                      wine: wineForEdit,
                      flights: flights,
                    }, callback: handleModalCallback,
                  });
                }
              }}
              onRemove={(): void => {
                setWineForDelete(wineForEdit?.id || '');
                setShowDeleteWineAlert(true);
              }}
            />
          </Loaded>
          <Unloaded>
            <IonLoading isOpen />
          </Unloaded>
        </Loadable>
      </>
    );
  } else if (mode === 'reorderWines') {
    return (
      <ReorderWinesContainer
        flights={flights}
        handleCancelAction={(): void => setMode('edit')}
        handleSaveAction={handleWineReorderSave}
        tastingId={tastingId}
        wines={wines}
      />
    );
  }

  return (
    <ReorderFlightsContainer
      flights={flights}
      handleCancelAction={(): void => setMode('edit')}
      handleSaveAction={handleFlightReorderSave}
      tastingId={tastingId}
    />
  );
};

export default EditWineListForTastingContainer;
