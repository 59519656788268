import { ApiCall, CustomHookProps, useBasicMutation } from 'napa-react-core';
import { UseMutationResult } from 'react-query/types/react/types';
import { WineRank } from '../../store/wineRanks';

export default function usePostTastingWineRankings(
  tastingId: string | undefined,
  userId: string,
  props?: CustomHookProps<WineRank[]>,
): UseMutationResult<WineRank[], ApiCall<WineRank[]>> {
  return useBasicMutation<WineRank[]>({
    apiRoute: `tastings/${tastingId}/rankings/${userId}`,
    httpMethod: 'POST',
    isFormData: false,
    params: props
  });
}
