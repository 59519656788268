import { WineScore } from '..';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const toRequestInsertWineScoreDto = (item: WineScore, auth: any, wineId: string, rating?: number): WineScore => {
  return {
    ...item,
    wineId: wineId,
    rating: rating,
    userId: auth.tokenData?.['userData/userId'] || null,
  } as WineScore;
};

export const toRequestUpdateWineScoreDto = (wineScore: WineScore, updatedWineScore: Partial<WineScore>): WineScore => {
  // Trims out user and wine object from request data
  return { ...wineScore, ...updatedWineScore, wine: undefined, user: undefined } as WineScore;
};
