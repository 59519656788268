import React from 'react';
import { AlertOptions } from '@ionic/core';
import { IonAlert } from '@ionic/react';
import { ReactControllerProps } from '@ionic/react/dist/types/components/createControllerComponent';

interface ConfirmAlertProps extends AlertOptions, ReactControllerProps {
  cancel?: string;
  confirmCaption?: string;
  header?: string;
  message?: string;
  onConfirm: () => void;
}

export function ConfirmAlert(props: ConfirmAlertProps): JSX.Element {
  const {
    cancel = 'Cancel',
    confirmCaption = 'OK',
    header,
    onConfirm,
    message,
    ...alertProps
  } = props;
  return (
    <IonAlert
      {...alertProps}
      header={header}
      message={message}
      buttons={[
        {
          text: cancel,
          role: 'cancel',
          cssClass: 'secondary',
        },
        {
          text: confirmCaption,
          handler: (): void => {
            onConfirm();
          }
        }
      ]}
    />
  );
}
