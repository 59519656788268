import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgAlmondIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 128 128"
      width={16} height={18} fill="none" {...props}
    >
      <path
        d="M86.016.166l-.068-.003c-.029 0-.058-.004-.087-.004a.459.459 0 00-.037.002l-.064.003c-9.525.093-20.662 13.27-29.078 30.949-17.522-14.82-37.78-24.08-47.166-18.904l-.1.05c-.022.013-.047.022-.069.035C-4.98 20.566-2.36 68.983 13.942 97.222c8.579 14.859 19.627 22.438 32.163 22.438 3.262 0 6.628-.533 10.075-1.57 6.961 6.466 16.597 9.749 28.738 9.749h.931l.012.001.009-.001h.946c14.203 0 24.99-4.477 32.06-13.306 6.053-7.559 9.123-17.99 9.123-31.003C128 47.79 104.122.435 86.016.166zM72.612 105.888C63.488 82.981 74.522 44.212 82.407 22v96.625c-4.349-2.959-7.576-7.164-9.795-12.737zm-26.866-5.846C29.747 85.934 21.283 53.594 17.363 33.083l26.5 45.899a85.23 85.23 0 00-.127 4.547c0 6.081.679 11.59 2.01 16.513zm-.589-32.633L16.35 17.512c8.051.045 23.539 8.176 37.393 20.225-3.869 9.38-6.914 19.634-8.586 29.672zM19.925 93.768C7.255 71.821 4.504 38.848 8.853 24.657c2.999 19.449 12.141 64.716 34.068 82.029a33.978 33.978 0 007.614 4.559c.21.336.429.664.649.99-12.306 2.379-22.786-3.792-31.259-18.467zm38.326 16.447c-5.047-6.303-7.607-15.281-7.607-26.685 0-28.834 16.555-63.029 28.777-73.355-2.78 7.088-6.856 18.292-10.361 31.149-7.98 29.274-8.945 51.855-2.869 67.116 1.705 4.281 3.971 7.961 6.769 11.038-6.175-1.666-11.102-4.759-14.709-9.263zm55.234 0c-3.61 4.509-8.543 7.603-14.726 9.267 2.851-3.134 5.15-6.896 6.866-11.281a3.452 3.452 0 00-1.958-4.475 3.45 3.45 0 00-4.475 1.958c-2.221 5.674-5.474 9.947-9.877 12.942V21.943c.331.931.666 1.879 1.006 2.865a3.454 3.454 0 106.53-2.252 320.525 320.525 0 00-4.568-12.414c12.218 10.285 28.809 44.524 28.809 73.389-.001 11.402-2.56 20.38-7.607 26.684z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M96.826 33.538a3.455 3.455 0 00-2.337 4.29c6.193 21.01 8.893 39.419 7.806 53.236a3.455 3.455 0 006.887.543c1.168-14.859-1.545-33.609-8.066-55.732a3.456 3.456 0 00-4.29-2.337z"
        fill="var(--ion-color-dark)"
      />
    </svg>
  );
}

export default SvgAlmondIcon;
