import { IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonToolbar } from '@ionic/react';
import { EmptyStateImage } from 'components/baseElements/EmptyStateImage';
import { Flex } from 'components/baseElements/grid';
import { Heading, Paragraph } from 'components/baseElements/typography';
import { IonButton, IonTitle } from 'components/ionicComponents';
import VinviteVersion from 'components/version';
import { Authorized, PrivatePropertyContainer, Unauthorized } from 'containers/authorization/PrivatePropertyContainer';
import UserProfileContainer from 'containers/users/UserProfileContainer';
import { ellipsisVertical } from 'ionicons/icons';
import { AuthorizationModalId } from 'modals/AuthorizationModal';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { showModal } from 'utils/modals';

export default function UserProfilePage(): JSX.Element {
  const dispatch = useDispatch();
  const [showActionSheet, setShowActionSheet] = useState(false);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>
             <FormattedMessage id="userProfile.header.caption" />
          </IonTitle>
          <PrivatePropertyContainer>
            <Authorized>
              <IonButtons slot="end">
                <IonButton onClick={() => setShowActionSheet(true)}>
                  <IonIcon slot="icon-only" icon={ellipsisVertical} />
                </IonButton>
              </IonButtons>
            </Authorized>
          </PrivatePropertyContainer>
         </IonToolbar>
      </IonHeader>
      <IonContent>
        <PrivatePropertyContainer>
          <Authorized>
            <UserProfileContainer
              setShowActionSheet={setShowActionSheet}
              showActionSheet={showActionSheet}
            />
          </Authorized>
          <Unauthorized>
            <Flex flexDirection="column" height="100%">
              <Flex alignItems="center" flex={1} flexDirection="column" pt={5}>
                <Heading headingSize="sm" textAlign="center">
                  <FormattedMessage id="userProfile.header.emptyState" />
                </Heading>
                <EmptyStateImage />
                <Paragraph textAlign="center" width={2 / 3}>
                  <FormattedMessage id="userProfile.text.emptyState" />
                </Paragraph>
                <IonButton
                  data-cy="signUpBtnMyProfile"
                  onClick={(): void =>
                    showModal(dispatch, AuthorizationModalId, { params: { mode: 'registration' } })
                  }
                >
                  <FormattedMessage id="general.nav.registration"/>
                </IonButton>
              </Flex>
              <VinviteVersion/>
            </Flex>
          </Unauthorized>
        </PrivatePropertyContainer>
      </IonContent>
    </IonPage>
  );
}
