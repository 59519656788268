import { IonActionSheet } from '@ionic/react';
import React from 'react';
import { useIntl } from 'react-intl';
import { Wine, WineStatus } from '../../../store/wines';

interface EditWineForTastingActionSheetProps {
  flightCount?: number;
  isOpen?: boolean;
  wine: Wine;
  onChangeFlight: (wineId?: string, flightId?: string | null) => void;
  onClose: () => void;
  onEdit: () => void;
  onRemove: () => void;
}

export default function EditWineForTastingActionSheet(props: EditWineForTastingActionSheetProps): JSX.Element {
  const { flightCount, isOpen, onClose, onChangeFlight, onEdit, onRemove, wine } = props;
  const intl = useIntl();
  const editWineActionSheetButtons = [{
    text: intl.formatMessage({
      id: 'editWineListTasting.editWineActionSheet.removeWine',
    }),
    handler: onRemove,
  }, {
    text: intl.formatMessage({
      id: 'editWineListTasting.editWineActionSheet.cancel',
    }),
    handler: onClose,
  }];
  if (wine?.approvalStatus === WineStatus.Proposed || wine?.approvalStatus === WineStatus.Rejected) {
    editWineActionSheetButtons.unshift({
      text: intl.formatMessage({
        id: 'editWineListTasting.editWineActionSheet.editWine',
      }),
      handler: onEdit,
    });
  }
  if (flightCount && flightCount > 1) {
    editWineActionSheetButtons.unshift({
      text: intl.formatMessage({
        id: 'editWineListTasting.editWineActionSheet.changeFlight',
      }),
      handler: () => onChangeFlight(wine.id, wine.flightId),
    });
  }
  return (
    <IonActionSheet
      header={[wine?.vintage, wine.producer, wine?.name].filter(e => !!e).join(' ')}
      buttons={editWineActionSheetButtons}
      isOpen={!!isOpen}
      onDidDismiss={(): void => onClose()}
    />
  );
}
