import { AlertInput, IonAlert } from '@ionic/react';
import { useToast } from 'napa-react-core';
import React from 'react';
import { useIntl } from 'react-intl';
import usePutTastingWine from '../../../hooks/wines/usePutTastingWine';
import { Flight, Wine } from '../../../store/wines';
import { GetSortedFlights } from '../../../store/wines/utils';

interface ChangeFlightAlertProps {
  flights?: Flight[];
  isOpen?: boolean;
  wine?: Wine;
  onClose: (modified?: boolean) => void;
  onRefreshData: () => void;
}

export default function ChangeFlightAlert(props: ChangeFlightAlertProps): JSX.Element {
  const {
    flights = [],
    isOpen,
    onClose,
    onRefreshData,
    wine,
    ...alertProps
  } = props;
  const intl = useIntl();
  const { showToast } = useToast();
  const cancelCaption = intl.formatMessage({
    id: 'editWineListTasting.changeFlightAlert.cancel',
  });
  const headerCaption = intl.formatMessage({
    id: 'editWineListTasting.changeFlightAlert.header',
  });
  const noFlightCaption = intl.formatMessage({
    id: 'editWineListTasting.changeFlightAlert.noFlight',
  });
  const saveCaption = intl.formatMessage({
    id: 'editWineListTasting.changeFlightAlert.save',
  });
  const { mutate: mutateTastingWine } = usePutTastingWine(
    wine?.id,
    {
      errorHandler: () => {
        showToast({ message: intl.formatMessage({ id: 'api.defaults.failureMessage' }) });
      },
      successHandler: () => {
        onRefreshData();
      },
    },
  );
  const inputs = GetSortedFlights(flights).map((flight: Flight): AlertInput => ({
    checked: wine?.flightId === flight.id,
    label: flight.name,
    name: 'flight',
    type: 'radio',
    value: flight.id,
  }));
  inputs.unshift({
    checked: !wine?.flightId,
    label: noFlightCaption,
    name: 'flight',
    type: 'radio',
    value: null,
  });
  return (
    <IonAlert
      {...alertProps}
      isOpen={!!isOpen}
      header={headerCaption}
      inputs={inputs}
      buttons={[
        {
          text: cancelCaption,
          role: 'cancel',
          cssClass: 'secondary',
          handler: onClose,
        },
        {
          text: saveCaption,
          handler: (flightId: string): void => {
            onClose(true);
            mutateTastingWine({ ...wine, flightId });
          },
        },
      ]}
    />
  );
}
