import { faWineBottle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from 'components/baseElements/grid';
import { IonText } from 'components/ionicComponents';
import * as React from 'react';
import { LayoutProps } from 'styled-system';

type EmptyStateWineImageProps = LayoutProps

export default function EmptyStateWineImage(props: EmptyStateWineImageProps): JSX.Element {
  const { size = 40 } = props;

  function getFontSize(size) {
    if (isFinite(size) ) {
      return size * .55;
    }
    return 'small'
  }

  return (
    <Flex
      $alignItems="center"
      $backgroundColor="var(--ion-color-medium-tint)"
      $borderRadius={7}
      $color="var(--ion-color-medium)"
      $fontSize={getFontSize(size)}
      $justifyContent="center"
      $maxHeight={size}
      $minHeight={size}
      $maxWidth={size}
      $minWidth={size}
      $size={size}
    >
      <IonText color="medium">
        <FontAwesomeIcon icon={faWineBottle} />
      </IonText>
    </Flex>
  );
}
