import { ApiCallStatus, ServerValidationError } from 'napa-react-core';

export const API_STATE_UPDATE_CALL_STATUS = 'API_STATE_UPDATE_CALL_STATUS';

interface Payload {
  id: string;
  errorMessage?: string;
  resultObject?: any;
  status: ApiCallStatus;
  validationErrors?: Array<ServerValidationError>;
}

export interface ApiStateUpdateCallStatusAction {
  type: typeof API_STATE_UPDATE_CALL_STATUS;
  payload: Payload;
}
