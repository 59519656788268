import { IonItem, IonList } from '@ionic/react';
import { Loadable } from 'components/baseElements/loadable';
import { Heading } from 'components/baseElements/typography';
import { IonLabel } from 'components/ionicComponents';
import React from 'react';
import { sortTastingAttendees, TastingAttendee } from 'store/tastingAttendees';
import { LoadingStatus } from 'utils/formValidation';
import UserAvatar from '../../baseElements/User/UserAvatar';

interface AttendeeListProperties {
  loadingStatus?: LoadingStatus;
  tastingAttendees: TastingAttendee[];
}

export default function AttendeeList(props: AttendeeListProperties): JSX.Element {
  const { loadingStatus, tastingAttendees } = props;
  return (
    <>
      <Loadable isLoading={loadingStatus === LoadingStatus.Loading}>
        <Loadable.Loaded>
          <IonList>
            {tastingAttendees.sort(sortTastingAttendees).map(att => (
              <IonItem key={att.id}>
                <UserAvatar avatarId={att.photoId} fullName={att.fullName || att.email} size={40} slot="start"/>
                <IonLabel>
                  <Heading
                    fontFamily="var(--ion-font-family)"
                    headingSize="lg"
                  >
                    {att.fullName || att.email}
                  </Heading>
                </IonLabel>
              </IonItem>
            ))}
          </IonList>
        </Loadable.Loaded>
      </Loadable>
    </>
  )
}
