import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgWhiteFlowerIcon(props) {
  return (
    <svg width={18} height={18} fill="none" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M10.323 2.496C10.323.723 9.897 0 9 0S7.676.723 7.676 2.496c0 1.382.243 3.407.562 4.52a.265.265 0 10.51-.146c-.305-1.062-.542-3.039-.542-4.374C8.206.976 8.47.529 9 .529s.794.448.794 1.967c0 1.335-.237 3.312-.541 4.374a.265.265 0 10.509.146c.32-1.113.561-3.138.561-4.52zm4.972 4.398c1.535-.886 1.948-1.617 1.5-2.394-.45-.777-1.289-.785-2.824.102-1.197.69-2.83 1.913-3.634 2.747a.265.265 0 00.38.368c.768-.795 2.362-1.989 3.519-2.657 1.315-.76 1.835-.754 2.1-.295.265.459.01.911-1.306 1.67-1.157.668-2.987 1.452-4.06 1.72a.265.265 0 00.129.513c1.124-.28 2.999-1.083 4.196-1.774zm-1.324 6.504c1.535.887 2.375.879 2.823.102.449-.777.036-1.508-1.5-2.394-1.196-.691-3.071-1.494-4.195-1.774a.265.265 0 00-.128.514c1.072.267 2.902 1.05 4.059 1.719 1.315.759 1.57 1.211 1.306 1.67-.265.46-.785.464-2.1-.295-1.157-.668-2.751-1.862-3.518-2.657a.265.265 0 00-.381.368c.804.834 2.437 2.056 3.634 2.747zm-6.295 2.106C7.676 17.277 8.103 18 9 18s1.323-.723 1.323-2.496c0-1.382-.242-3.407-.561-4.52a.265.265 0 00-.51.146c.305 1.062.542 3.039.542 4.374 0 1.52-.264 1.967-.794 1.967s-.794-.448-.794-1.967c0-1.335.237-3.312.541-4.374a.265.265 0 10-.509-.147c-.32 1.114-.562 3.14-.562 4.521zm-4.97-4.398c-1.536.886-1.949 1.617-1.5 2.394.448.777 1.288.785 2.823-.102 1.197-.69 2.83-1.913 3.634-2.747a.265.265 0 10-.38-.368c-.768.795-2.362 1.989-3.519 2.657-1.315.76-1.835.754-2.1.295-.265-.459-.01-.911 1.306-1.67 1.157-.668 2.987-1.452 4.06-1.72a.265.265 0 00-.129-.513c-1.124.28-2.999 1.083-4.196 1.774zm1.323-6.504c-1.535-.887-2.375-.879-2.823-.102-.449.777-.036 1.508 1.5 2.394 1.196.691 3.071 1.494 4.195 1.774a.265.265 0 00.128-.514c-1.072-.267-2.902-1.05-4.059-1.718-1.315-.76-1.57-1.212-1.306-1.671.265-.46.785-.464 2.1.295 1.157.668 2.751 1.862 3.518 2.657a.265.265 0 00.381-.368C6.86 6.515 5.226 5.293 4.03 4.602z" fill="var(--ion-color-dark)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M13.398 4.029c.887-1.535.879-2.375.102-2.823-.777-.449-1.508-.036-2.394 1.5-.691 1.196-1.494 3.071-1.774 4.195a.265.265 0 00.514.128c.267-1.072 1.05-2.902 1.719-4.06.759-1.314 1.211-1.57 1.67-1.305.46.265.464.785-.295 2.1-.668 1.157-1.862 2.751-2.657 3.518a.265.265 0 10.368.381c.834-.804 2.056-2.437 2.747-3.634zm2.106 6.294C17.277 10.323 18 9.897 18 9s-.723-1.324-2.496-1.324c-1.382 0-3.407.243-4.52.562a.265.265 0 00.146.51c1.062-.306 3.039-.542 4.374-.542 1.52 0 1.967.264 1.967.794s-.448.794-1.967.794c-1.335 0-3.312-.237-4.374-.541a.265.265 0 10-.147.509c1.114.32 3.14.561 4.521.561zm-4.398 4.972c.886 1.535 1.617 1.948 2.394 1.5.777-.45.785-1.289-.102-2.824-.69-1.197-1.913-2.83-2.747-3.634a.265.265 0 10-.368.38c.795.768 1.989 2.362 2.657 3.519.76 1.315.754 1.835.295 2.1-.459.265-.911.01-1.67-1.306-.668-1.157-1.452-2.987-1.72-4.06a.265.265 0 10-.513.129c.28 1.124 1.083 2.999 1.774 4.196zM4.602 13.97c-.887 1.535-.879 2.375-.102 2.823.777.449 1.508.036 2.394-1.5.691-1.196 1.494-3.071 1.774-4.195a.265.265 0 00-.514-.128c-.267 1.072-1.05 2.902-1.718 4.059-.76 1.315-1.212 1.57-1.671 1.306-.46-.265-.464-.785.295-2.1.668-1.157 1.862-2.751 2.657-3.519a.265.265 0 10-.368-.38c-.834.804-2.056 2.437-2.747 3.634zM2.496 7.676C.723 7.676 0 8.103 0 9s.723 1.323 2.496 1.323c1.382 0 3.407-.242 4.52-.561a.265.265 0 10-.146-.51c-1.062.305-3.039.542-4.374.542C.976 9.794.529 9.53.529 9s.448-.794 1.967-.794c1.335 0 3.312.236 4.374.541a.265.265 0 10.146-.509c-1.113-.32-3.138-.562-4.52-.562zm4.398-4.97C6.008 1.17 5.277.756 4.5 1.205c-.777.448-.785 1.288.102 2.823.69 1.197 1.913 2.83 2.747 3.634a.265.265 0 10.368-.38C6.922 6.514 5.728 4.92 5.06 3.763c-.76-1.315-.754-1.835-.295-2.1.459-.265.911-.01 1.67 1.306.668 1.157 1.452 2.987 1.72 4.06a.265.265 0 00.513-.129c-.28-1.124-1.083-2.999-1.774-4.196z" fill="var(--ion-color-dark)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M11.382 9a2.382 2.382 0 10-4.764 0 2.382 2.382 0 004.764 0zM7.147 9a1.853 1.853 0 113.706 0 1.853 1.853 0 01-3.706 0z" fill="var(--ion-color-dark)" />
    </svg>
  );
}

export default SvgWhiteFlowerIcon;
