import { useIntl } from 'react-intl';

interface SummaryValueProps {
  field: string;
  value: any;
}
function SummaryValue(props: SummaryValueProps): string {
  const { field, value } = props;
  // for handling delimited values, translations...
  const intl = useIntl();
  const getValueForRange = (field: string, value: string): string => {
    return intl.formatMessage({ id: `scoring.${field}.${value}` });
  }
  if (value === null || value === undefined || value === '|') {
    return '-'
  }
  if (field === 'aromas' || field === 'flavors') {
    const result = value.substring(1, value.length - 1).split('|');
    result.forEach((s: string, index: number): void => {
      result[index] = intl.formatMessage({ id: `scoring.profiles.${s}` });
    })
    return result.join(', ');
  }
  if (field === 'color' || field === 'notes') {
    return value;
  }
  return getValueForRange(field, value);
}

export { SummaryValue };
