import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgHoneyIcon(props) {
  return (
    <svg width={18} height={18} fill="none" {...props}>
      <path
        d="M4.155 7.61a1.69 1.69 0 001.026 2.456 1.69 1.69 0 001.569 2.31h4.5a1.69 1.69 0 001.569-2.31 1.69 1.69 0 001.026-2.457 1.687 1.687 0 000-2.843A1.69 1.69 0 0012.82 2.31 1.69 1.69 0 0011.25 0h-4.5a1.69 1.69 0 00-1.569 2.31 1.69 1.69 0 00-1.026 2.456 1.687 1.687 0 000 2.843zm.907-1.985h4.5a.563.563 0 000-1.125H5.626a.563.563 0 010-1.125h2.813a.563.563 0 000-1.125H6.75a.563.563 0 010-1.125h4.5a.563.563 0 010 1.125.563.563 0 000 1.125h1.125a.563.563 0 010 1.125.563.563 0 000 1.125h.563a.563.563 0 010 1.125h-.563a.563.563 0 000 1.125.563.563 0 010 1.125H11.25a.563.563 0 000 1.125.563.563 0 010 1.125h-4.5a.563.563 0 010-1.125h1.688a.563.563 0 000-1.125H5.624a.563.563 0 010-1.125h3.938a.563.563 0 000-1.125h-4.5a.563.563 0 010-1.125zM16.313 11.813h-.563a.563.563 0 00-1.125 0h-.563a1.69 1.69 0 00-1.687 1.687 1.69 1.69 0 002.25 1.59v.098a.563.563 0 001.125 0v-.099A1.69 1.69 0 0018 13.5a1.69 1.69 0 00-1.688-1.686zm-2.25 2.25a.563.563 0 010-1.126h.562v.563c0 .31-.252.563-.563.563zm2.25 0a.563.563 0 01-.563-.563v-.563h.563a.563.563 0 010 1.126zM3.938 11.813h-.563a.563.563 0 00-1.125 0h-.563A1.69 1.69 0 000 13.5a1.69 1.69 0 002.25 1.59v.098a.563.563 0 001.125 0v-.099a1.69 1.69 0 002.25-1.59 1.69 1.69 0 00-1.688-1.686zm-2.25 2.25a.563.563 0 010-1.126h.562v.563c0 .31-.252.563-.563.563zm2.25 0a.563.563 0 01-.563-.563v-.563h.563a.563.563 0 010 1.126zM10.125 14.063h-.563a.563.563 0 00-1.124 0h-.563a1.69 1.69 0 00-1.688 1.687 1.69 1.69 0 002.25 1.59v.098a.563.563 0 001.126 0v-.099c.176.063.365.099.562.099a1.69 1.69 0 001.688-1.688 1.69 1.69 0 00-1.688-1.688zm-2.25 2.25a.563.563 0 010-1.125h.563v.562c0 .31-.253.563-.563.563zm2.25 0a.563.563 0 01-.563-.563v-.563h.563a.563.563 0 010 1.126z"
        fill="var(--ion-color-dark)"
      />
    </svg>
  );
}

export default SvgHoneyIcon;
