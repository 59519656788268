import { yupResolver } from '@hookform/resolvers/yup';
import { IonContent, IonList } from '@ionic/react';
import HookInput from 'components/baseElements/formControls/HookInput';
import Form from 'components/baseElements/formControls/StyledForm';
import { IonTextarea } from 'components/ionicComponents/IonTextarea';
import WineRatingForm from 'components/wine/WineRatingForm';
import { StepBaseProperties } from 'components/wineScores/types';
import { ScoreAWineHeader } from 'containers/wineScores/ScoreAWineContainer/ScoreAWineHeader';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { handleFormDataReset } from 'utils/formValidation';
import StepBottomButtons from '../StepBottomButtons';
import StepHeader from '../StepHeader';
import StepMidHeaderButtons from '../StepMidHeaderButtons';

const ScoreAWineRatingStep: FunctionComponent<StepBaseProperties> = (
  props: StepBaseProperties,
) => {
  const {
    loadingStatus,
    modalId,
    onSaveAndClose,
    stepName,
    nextAction,
    previousAction,
    validationSchema,
    score,
    nextStepName,
    showNextStepButton,
    previousStepName,
    showPreviousStepButton,
    stepNumber,
    totalSteps,
  } = props;

  const [rating, setRating] = useState(score.rating || 75);
  const form = useForm({
    defaultValues: {
      notes: '',
      rating: 75,
    },
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    handleFormDataReset(score, form.reset);
  }, [score, form.reset]);

  // Ensure the form populates with the initial value. For some reason, the form does not use the initial values
  useEffect(() => {
    form.setValue('rating', score.rating || 75);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ScoreAWineHeader
        modalId={modalId}
        onSaveAndClose={(): any => onSaveAndClose(form.getValues())}
        stepName={stepName}
      />
      <IonContent>
        <StepMidHeaderButtons
          loadingStatus={loadingStatus}
          nextClickedAction={(): any => nextAction(form.getValues())}
          previousClickedAction={(): any => previousAction(form.getValues())}
          nextStepName={nextStepName}
          showNextStepButton={showNextStepButton}
          previousStepName={previousStepName}
          showPreviousStepButton={showPreviousStepButton}
        />
        <StepHeader
          modalId={modalId}
          stepName="scoreWine.ratingStep.title"
          stepNumber={stepNumber}
          totalSteps={totalSteps}
        />
        <Form>
          <IonList lines="none">
            <WineRatingForm form={form} rating={rating} setRating={setRating} />
            <HookInput
              autoGrow
              form={form}
              isTextarea
              labelText="scoreWine.ratingStep.labels.notes"
              name="notes"
              placeholder="Type notes here..."
            >
              <IonTextarea />
            </HookInput>
          </IonList>
        </Form>
      </IonContent>
      <StepBottomButtons
        loadingStatus={loadingStatus}
        nextClickedAction={(): any => nextAction(form.getValues())}
        previousClickedAction={(): any => previousAction(form.getValues())}
        nextStepName={nextStepName}
        onSaveAndClose={(): any => onSaveAndClose(form.getValues())}
        showNextStepButton={false}
        previousStepName={previousStepName}
        showPreviousStepButton={false}
      />
    </>
  );
};

export default ScoreAWineRatingStep;
