import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgApricotIcon(props) {
  return (
    <svg width={15} height={18} fill="none" {...props}>
      <path
        d="M13.827 5.112c-.754-1.145-2.025-1.75-3.677-1.75-.787 0-1.849.17-2.533.328.1-2.406.754-3.158.77-3.176a.272.272 0 00-.39-.38c-.018.018-.226.239-.441.799C7.264.622 6.496 0 5.005 0 3.962 0 2.758.308 1.427.915a.274.274 0 00-.082.44c.062.062 1.53 1.523 3.403 1.523h.001c.926 0 1.78-.359 2.548-1.062a10.68 10.68 0 00-.226 1.88c-.683-.16-1.76-.334-2.556-.334-1.652 0-2.924.605-3.678 1.75-.98 1.487-1.11 3.977-.333 6.344.79 2.408 2.357 4.2 4.295 4.915.705.26 1.581.933 2.096 1.448a.62.62 0 00.874 0c.516-.516 1.391-1.189 2.096-1.448 1.939-.716 3.504-2.507 4.295-4.915.778-2.367.647-4.858-.333-6.344zM4.75 2.332c-1.18 0-2.214-.695-2.707-1.092C3.146.78 4.14.545 5.005.545c1.207 0 1.842.455 2.1.703-.711.72-1.502 1.084-2.355 1.084zm8.893 8.954c-.739 2.249-2.185 3.916-3.967 4.574-.782.288-1.736 1.018-2.293 1.574a.075.075 0 01-.05.02.075.075 0 01-.052-.02c-.556-.556-1.511-1.286-2.293-1.574-1.782-.658-3.228-2.325-3.966-4.574-.727-2.215-.62-4.52.272-5.874.648-.984 1.762-1.505 3.222-1.505.6 0 1.392.11 2.016.231-.196.148-.4.35-.574.634-.559.918-.576 2.26-.052 3.988a.273.273 0 00.522-.158c-.468-1.543-.472-2.765-.01-3.534.377-.632.938-.752.965-.758.005 0 .008-.003.013-.005h.012c.562-.163 1.862-.399 2.742-.399 1.46 0 2.574.52 3.222 1.504.892 1.355.998 3.66.27 5.876z"
        fill="var(--ion-color-dark)"
      />
    </svg>
  );
}

export default SvgApricotIcon;
