import { TastingResultViewState } from 'containers/tastings/ViewTastingResultsContainer';
import dayjs from 'dayjs';
import { stringIncludes } from 'napa-base/dist';
import { removeDiacritics } from 'utils/dataFormat/strings';
import { Flight, Wine } from '..';
import { MasterWine } from '../../masterWines';
import { TastingAttendee } from '../../tastingAttendees';
import { tastingAttendeeIsGoing } from '../../tastingAttendees/utils';
import { TastingWithAttendee } from '../../tastings';

export const isTastingAttendee = (tasting: TastingWithAttendee, userId?: string): boolean => {
  if (!tasting || !userId) {
    return false;
  }
  let attendeeMatch = false;
  tasting.attendeeList?.forEach((attendee: TastingAttendee) => {
    if (attendee.attendeeId === userId) {
      attendeeMatch = true;
    }
  });
  return attendeeMatch;
};

export const canManageTastingWines = (tasting?: TastingWithAttendee, userId?: string): boolean => {
  if (!tasting || !userId) {
    return false;
  }
  // Host
  if (userId === tasting.tasting?.ownerId) {
    return true;
  }
  // Attendee is going and Permission AttendeesManageWine is true
  const tastingAttendee = tasting.attendeeList?.find((attendee: TastingAttendee) => attendee.attendeeId === userId);
  return (tastingAttendeeIsGoing(tastingAttendee) && tasting.tasting.canAttendeesManageWine);
};

export const GetDistinctFlightsInOrder = (wines: Array<Wine>): Array<Flight> => {
  if (!wines || !wines.length) {
    return [];
  }
  const distinctFlights: Array<Flight> = [{ id: undefined, name: '', sequence: 0, areRanksPublished: false }];

  wines.forEach((wine: Wine) => {
    if (!wine.flightId || distinctFlights.findIndex((flight: Flight) => flight.id === wine.flightId) > -1) {
      return;
    }
    distinctFlights.push(wine.flight);
  });

  return distinctFlights.sort((a: Flight, b: Flight) => a.sequence > b.sequence ? 1 : -1);
};

const defaultSortForWinesInFlight = (a: Wine, b: Wine): number => {
  if (a.sequence === b.sequence) {
    if (dayjs(a.createdAt).isAfter(dayjs(b.createdAt))) {
      return 1;
    }
    return -1;
  }
  if (a.sequence > b.sequence)
    return 1;
  return -1;
};
export const GetSortedWinesInFlight = (wines?: Array<Wine>, flightId?: string | null, resultViewState?: TastingResultViewState): Array<Wine> => {
  if (!wines?.length) {
    return [];
  }
  const filteredWines = wines.filter((wine: Wine) => wine.flightId === flightId);
  if (!filteredWines || !filteredWines.length) {
    return [];
  }
  // Start of custom sort here
  if (resultViewState === TastingResultViewState.Scoring) {
    return filteredWines.sort((a: Wine, b: Wine) => {
      const aTotal = a?.scoring?.averageRating || 0;
      const bTotal = b?.scoring?.averageRating || 0;
      if (aTotal === bTotal) {
        return 0;
      }
      if (aTotal < bTotal) {
        return 1;
      }
      return -1;
    });
  }
  if (resultViewState === TastingResultViewState.FlightRanking) {
    if (!filteredWines[0].flight.areRanksPublished) {
      return filteredWines.sort(defaultSortForWinesInFlight);
    }
    return filteredWines.sort((a: Wine, b: Wine) => {
      const aTotal = a?.wineFlightRanking?.totalPoints || 0;
      const bTotal = b?.wineFlightRanking?.totalPoints || 0;
      if (aTotal === bTotal) {
        return 0;
      }
      if (aTotal < bTotal) {
        return 1;
      }
      return -1;
    });
  }
  if (resultViewState === TastingResultViewState.OverallRanking) {
    if (!filteredWines[0]?.tasting?.areRanksPublished) {
      return filteredWines.sort(defaultSortForWinesInFlight);
    }
    return filteredWines.sort((a: Wine, b: Wine) => {
      const aTotal = a?.ranking?.totalPoints || 0;
      const bTotal = b?.ranking?.totalPoints || 0;
      if (aTotal === bTotal) {
        return 0;
      }
      if (aTotal < bTotal) {
        return 1;
      }
      return -1;
    });
  }
  // End of custom sort
  return filteredWines.sort(defaultSortForWinesInFlight);
};

export const GetSortedFlights = (flights?: Array<Flight>): Array<Flight> => {
  if (!flights || !flights.length) {
    return [];
  }

  return flights.sort((a: Flight, b: Flight): number => {
    if (dayjs(a?.createdAt) > dayjs(b?.createdAt)) {
      return 1;
    }
    return -1;
  });
};

export const GetFlightsInOrder = (flights: Array<Flight>): Array<Flight> => {
  if (!flights) {
    return [];
  }
  const distinctFlights: Array<Flight> = [{ id: null, name: '', sequence: -1, areRanksPublished: false }, ...flights];

  return distinctFlights.sort((a: Flight, b: Flight) => a.sequence > b.sequence ? 1 : -1);
};

export interface WineAndFlightOrder {
  wine?: Wine;
  flight?: Flight;
}

export const getDataForWineReorder = (wineArray?: Array<Wine>, flightArray?: Array<Flight>): Array<WineAndFlightOrder> => {
  if (!wineArray || !flightArray) {
    return [];
  }
  const wineReorderDataArr: Array<WineAndFlightOrder> = [];
  const orderedFlights = GetFlightsInOrder(flightArray);

  orderedFlights.forEach((flight: Flight) => {
    if (flight.name) {
      wineReorderDataArr.push({ flight: flight });
    }
    const winesInFlight = GetSortedWinesInFlight(wineArray, flight.id);
    if (winesInFlight) {
      winesInFlight.forEach((wine: Wine) => {
        wineReorderDataArr.push({ wine: wine });
      });
    }
  });
  return wineReorderDataArr;
};

export const getDataForFlightReorder = (flightArray?: Array<Flight>): Array<Flight> => {
  if (!flightArray) {
    return [];
  }
  const flightsInOrder = GetFlightsInOrder(flightArray);
  return flightsInOrder.filter((f: Flight) => f.id);
};

export enum SortDir {
  asc = 1,
  desc = -1
}

export enum WineSortBy {
  ratedDate,
  rating,
  wineName,
}

export const getFullWineName = (wine: Wine | MasterWine | undefined): string => {
  if (!wine) {
    return '';
  }
  return ((wine.vintage ? `${wine.vintage} ` : '') +
    (wine.producer ? `${wine.producer} ` : '') +
    (wine.name ? `${wine.name} ` : '') +
    // (wine.designation ? `${wine.designation} ` : '') +
    (wine.bottleSize ? `${wine.bottleSize}` : '')).trim();
};

export const getWinePoints = (wine: Wine, flightId?: string): number => {
  if (!wine?.id) {
    return 0;
  }
  return flightId
    ? (wine.wineFlightRanking?.userRank?.points || 0)
    : (wine.ranking?.userRank?.points || 0);
};

export const sortWines = (wines?: Array<Wine>, sortBy = WineSortBy.ratedDate, sortDir = SortDir.desc): Array<Wine> => {
  if (!wines) {
    return [];
  }
  if (sortBy === WineSortBy.ratedDate) {
    return wines.sort((a: Wine, b: Wine) => {
      const valA = a?.scoring?.userScore?.updatedAt;
      const valB = b?.scoring?.userScore?.updatedAt;
      if (dayjs(valA).isSame(dayjs(valB)) && (valA !== null || true)) {
        return 0;
      }
      if (valA === null || valA === undefined) {
        return 1;
      }
      if (valB === null || valB === undefined) {
        return -1;
      }
      return (dayjs(valA).isAfter(dayjs(valB)) ? 1 : -1) * sortDir;
    });
  }
  if (sortBy === WineSortBy.rating) {
    return wines.sort((a: Wine, b: Wine) => {
      const valA = a?.scoring?.userScore?.rating;
      const valB = b?.scoring?.userScore?.rating;
      if (valA === valB) {
        return 0;
      }
      if (valA === null || valA === undefined) {
        return -1 * sortDir;
      }
      if (valB === null || valB === undefined) {
        return 1 * sortDir;
      }
      return (valA > valB ? 1 : -1) * sortDir;
    });
  }
  // Sort by wine name
  return wines.sort((a: Wine, b: Wine) => {
    const valA = a?.name;
    const valB = b?.name;
    if (valA === valB) {
      return 0;
    }
    if (valA === null || valA === undefined) {
      return -1 * sortDir;
    }
    if (valB === null || valB === undefined) {
      return 1 * sortDir;
    }
    return (valA > valB ? 1 : -1) * sortDir;
  });
};

export const getFilteredWines = (wines?: Array<Wine>, viewState?: TastingResultViewState, searchText?: string): Array<Wine> => {
  if (!wines) {
    return [];
  }
  if (viewState === TastingResultViewState.OverallRanking) {
    return wines.map(w => ({ ...w, flightId: null }))
  }
  if (viewState === TastingResultViewState.FlightRanking) {
    return wines.filter(w => !!w.flightId);
  }
  return wines.filter((wine: Wine) => {
    if (!searchText) {
      return true;
    }
    return searchText.split(' ').reduce((result: boolean, subSearchText) => {
      if (!subSearchText) {
        return result;
      }
      const match = stringIncludes(removeDiacritics(wine?.name), subSearchText) ||
        stringIncludes(removeDiacritics(wine?.producer), subSearchText) ||
        stringIncludes(removeDiacritics(wine?.vintage), subSearchText);
      return !match ? false : result;
    }, true);
  });
};
