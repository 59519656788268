import { ServerValidationError } from 'napa-react-core';
import * as yup from 'yup';

export interface FormValidationError {
  field: string;
  entity?: any;
  validationErrors: Array<string>;
}

export enum LoadingStatus {
  None,
  Loading,
  Complete,
  Error,
}

interface FormValidator {
  fieldName: string;
  validators: Array<(objectToValidate: any) => string>;
}

interface FormValidationConstructorParams {
  clientValidators?: Array<FormValidator>;
  apiValidation?: Array<ServerValidationError>;
  objectToValidate?: any;
  requiredProps?: Array<RequiredProp | string>;
  yupValidator?: yup.ObjectSchema<any>;
}

interface RequiredProp {
  propName: string;
  fieldName: string;
  displayName: string;
}

interface DirtyField {
  field: string;
  entity?: any;
  isDirty: boolean;
}

interface ComposeValidationParams {
  validation: FormValidation;
  apiValidation?: Array<ServerValidationError>;
  objectToValidate?: any;
}

export function handleServerHookForm(
  serverValidation: ServerValidationError[],
  setError: (field: any, data: any) => void,
): void {
  if (serverValidation) {
    serverValidation.forEach((item: any) => {
      setError(item.field, {
        type: 'manual',
        message: `api.validation.${item.errors[0]}`,
      });
    });
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function handleFormDataReset(data: any, reset: (data: any) => void): void {
  reset(data);
}

export function composeValidation(params: ComposeValidationParams): FormValidation {
  const newValidation = params.validation;
  newValidation.apiValidation = params.apiValidation;
  newValidation.objectToValidate = params.objectToValidate;
  return newValidation;
}

interface FormValidationErrorsConstructorParams {
  clientValidationErrors: Array<FormValidationError>;
  apiValidationErrors: Array<FormValidationError>;
}

export class FormValidationErrors {
  clientValidationErrors: Array<FormValidationError>;
  apiValidationErrors: Array<FormValidationError>;

  constructor(params?: FormValidationErrorsConstructorParams) {
    this.clientValidationErrors = params?.clientValidationErrors || [];
    this.apiValidationErrors = params?.apiValidationErrors || [];
  }

  getAllValidationErrors = (fieldName: string): string => {
    let errorString = '';
    errorString += this.getClientValidationErrors(fieldName);
    const apiErrors = this.getApiValidationErrors(fieldName);
    if (apiErrors) {
      errorString += ` ${apiErrors}`;
    }
    return errorString;
  };

  getApiValidationErrors = (fieldName: string): string => {
    const existingApiError = this.apiValidationErrors.find(
      e => e.field === fieldName,
    );
    if (existingApiError) {
      return existingApiError.validationErrors.join(' ');
    }
    return '';
  };

  getClientValidationErrors = (fieldName: string): string => {
    let errorString = '';
    const existingClientError = this.clientValidationErrors.find(
      e => e.field === fieldName,
    );
    if (existingClientError) {
      errorString += existingClientError.validationErrors.join(' ');
    }
    if (errorString.length > 0) {
      return errorString;
    }
    return '';
  };
}

export class FormValidation {
  clientValidationErrors: Array<FormValidationError>;

  apiValidationErrors: Array<FormValidationError>;

  apiValidation?: Array<ServerValidationError>;

  requiredProps?: Array<RequiredProp | string>;

  clientValidators?: Array<FormValidator>;

  objectToValidate?: any;

  yupValidator?: yup.ObjectSchema<any>;

  dirtyFields: Array<DirtyField>;

  allAreDirty: boolean;

  constructor(params?: FormValidationConstructorParams) {
    this.clientValidationErrors = [];
    this.apiValidationErrors = [];
    this.apiValidation = params?.apiValidation;
    this.clientValidators = params?.clientValidators;
    this.objectToValidate = params?.objectToValidate;
    this.requiredProps = params?.requiredProps;
    this.yupValidator = params?.yupValidator;
    this.dirtyFields = [];
    this.allAreDirty = false;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  validate = (entity: any): FormValidationErrors => {
    this.validateClient(entity);
    this.setAllApiValidationErrors(this.apiValidation);
    return new FormValidationErrors({
      clientValidationErrors: this.clientValidationErrors,
      apiValidationErrors: this.apiValidationErrors,
    });
  };

  setFieldAsDirty = (fieldName: string): void => {

    const existingDirty = this.dirtyFields.find(
      e => e.field === fieldName,
    );

    if (existingDirty) {
      existingDirty.isDirty = true;
    } else {
      this.dirtyFields.push({
        field: fieldName,
        isDirty: true,
      });
    }

    this.dirtyFields = [...this.dirtyFields];
  };

  setAllAsDirty = (): void => {
    this.allAreDirty = true;
  };

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  validateClient = (entity: any): boolean => {
    this.clientValidationErrors = [];
    if (!this.yupValidator) {
      return true;
    }
    try {
      this.yupValidator.validateSync(entity, { abortEarly: false });
    } catch (errors) {
      errors.inner.forEach((err: any) => {
        const existingDirty = this.dirtyFields.find(
          e => e.field === err.path,
        );
        if (this.allAreDirty || (existingDirty && existingDirty.isDirty)) {
          const existingError = this.clientValidationErrors.find(
            e => e.field === err.path,
          );
          if (existingError) {
            existingError.validationErrors.push(err.message);
          } else {
            this.clientValidationErrors.push({
              field: err.path,
              entity: errors.value,
              validationErrors: [err.message],
            });
          }
        }
      });

      return false;
    }

    return true;
  };

  setApiValidationFieldErrors = (fieldName: string, errors: Array<string>): void => {
    const existingErrorIndex = this.apiValidationErrors.findIndex(
      e => e.field === fieldName,
    );
    if (existingErrorIndex > -1) {
      this.apiValidationErrors = this.apiValidationErrors.splice(
        existingErrorIndex,
        1,
      );
    }

    if (errors && errors.length) {
      const newError = {
        field: fieldName,
        validationErrors: new Array<string>(),
      };
      errors.forEach(error => {
        newError.validationErrors.push(error);
      });
      this.apiValidationErrors.push(newError);
    }
  };

  setAllApiValidationErrors = (
    apiValidationErrors?: Array<ServerValidationError>,
  ): void => {
    this.apiValidationErrors = [];
    if (apiValidationErrors) {
      apiValidationErrors.forEach(error => {
        this.setApiValidationFieldErrors(error.field, error.errors || []);
      });
    }
  };
}
