import { faCalendarTimes, faEnvelope, faWineBottle, faWineGlassAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonText } from '@ionic/react';
import { Paragraph } from 'components/baseElements/typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { TastingAttendee, tastingAttendeeIsGoing, tastingAttendeeIsInvited } from '../../../store/tastingAttendees';
import { Hideable } from '../../baseElements/hideable';

interface AttendeeStatusProps {
  attendee?: TastingAttendee;
}

export default function AttendeeStatus(props: AttendeeStatusProps): JSX.Element {
  const { attendee } = props;
  if (!attendee) {
    return (<></>);
  }
  let icon;
  let status;
  if (attendee.isHost) {
    icon = faWineBottle;
    status = 'Hosting';
  } else if (tastingAttendeeIsInvited(attendee)) {
    icon = faEnvelope;
    status = 'Invited';
  } else if (tastingAttendeeIsGoing(attendee)) {
    icon = faWineGlassAlt;
    status = 'Going';
  } else { // Not going
    icon = faCalendarTimes;
    status = 'NotGoing';
  }
  return (
    <Hideable hide={!icon}>
      <Paragraph mx={2} mt={0} textAlign="center" whiteSpace="nowrap">
        <IonText color="dark">
          <FontAwesomeIcon icon={icon}/>
          &nbsp;&nbsp;
          <FormattedMessage
            id={`myTastings.text.youre${status}`}
          />
        </IonText>
      </Paragraph>
    </Hideable>
  );
}
