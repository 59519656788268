import { ModalProperties } from 'store/gui';
import { Dispatch } from 'react';
import { guiStateUpdateModal } from 'store/gui/guiStateUpdateModal';

export function showModal(dispatch: Dispatch<any>, id: string, modalProps: ModalProperties<any>): void {
  dispatch(guiStateUpdateModal({ id: id, show: true, ...modalProps}));
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function hideModal(dispatch: Dispatch<any>, id: string, modalProps: ModalProperties<any>, callback?: (result: any) => any, result?: any): void {
  dispatch(guiStateUpdateModal({ id: id, show: false, ...modalProps }));
  if (callback) {
    callback(result);
  }
}
