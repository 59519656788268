import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgVinegarIcon(props) {
  return (
    <svg width={12} height={18} fill="none" {...props}>
      <path
        d="M6.141 4.75h-1.12a.878.878 0 01-.876-.878V.877c0-.484.393-.877.877-.877h1.12c.483 0 .876.393.876.877v2.995a.878.878 0 01-.877.878zM5.052.575a.271.271 0 00-.272.272v2.995c0 .151.12.272.272.272h1.12a.271.271 0 00.272-.272V.847A.271.271 0 006.17.575H5.052zM9.741 11.556c-.181 0-.302-.12-.302-.302v-6.05c0-1.574-1.513-1.574-1.573-1.574-.182 0-.303-.12-.303-.302s.121-.303.303-.303c.363 0 .756.091 1.089.273.514.242 1.119.786 1.119 1.936v6.05c0 .151-.151.272-.333.272z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M5.597 18c-1.452 0-2.844 0-3.842-.393C.575 17.153 0 16.185 0 14.672c0-2.057 1.24-3.963 3.176-4.961V3.388L2.3 1.936a.326.326 0 01.09-.423c.061-.03.092-.03.152-.03H7.08a.91.91 0 01.908.907v7.29c1.936 1 3.176 2.935 3.176 4.962 0 1.513-.575 2.48-1.755 2.934C8.44 18 7.048 18 5.597 18zM3.116 2.087l.635 1.06c.03.06.03.09.03.15v6.565c0 .121-.06.212-.181.272-1.845.878-3.025 2.633-3.025 4.508 0 1.27.423 1.997 1.361 2.36.908.363 2.239.363 3.63.363 1.392 0 2.723 0 3.63-.363.938-.363 1.362-1.09 1.362-2.36 0-1.875-1.18-3.63-3.025-4.508-.091-.06-.182-.15-.182-.272V2.39a.303.303 0 00-.302-.303H3.116z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M8.985 13.069H2.178c-.181 0-.302-.121-.302-.303 0-.181.12-.302.302-.302h6.807c.181 0 .303.12.303.302s-.122.303-.303.303zM7.866 16.85c-.182 0-.303-.12-.303-.302 0-.181.121-.302.303-.302 1.573 0 1.573-1.15 1.573-1.21 0-.182.12-.303.302-.303s.303.12.303.302c.03.03 0 1.816-2.178 1.816zM6.716 16.85a.275.275 0 01-.212-.09s-.03-.03-.03-.06c0-.031-.03-.031-.03-.061s0-.03-.03-.06v-.061c0-.091.03-.152.09-.212 0 0 .03-.03.06-.03s.03-.03.061-.03c.06-.03.151-.03.242 0 .03 0 .03.03.06.03s.03.03.061.03c.06.06.09.12.09.212v.06c0 .03 0 .03-.03.06 0 .031-.03.031-.03.061s-.03.03-.03.06c0 0-.03.031-.06.031s-.03.03-.06.03c-.031 0-.031 0-.061.03h-.091z"
        fill="var(--ion-color-dark)"
      />
    </svg>
  );
}

export default SvgVinegarIcon;
