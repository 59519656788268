import { IonContent, IonSegment, IonSegmentButton, useIonViewWillEnter } from '@ionic/react';
import { Box } from 'components/baseElements/grid';
import { BackHeader } from 'components/baseElements/header';
import { Heading } from 'components/baseElements/typography';
import { IonLabel } from 'components/ionicComponents';
import { WineListItemType } from 'components/wine/WineListItem';
import WineListForTastingContainer from 'containers/wine/WineListForTastingContainer';
import { Hideable } from 'napa-react-core';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { TastingResultInfo, TastingWithAttendee } from 'store/tastings';
import { Wine } from 'store/wines';
import { clearApiCallStatus, makeApiCall } from 'utils/api';

export enum TastingResultViewState {
  Scoring = 0,
  FlightRanking = 1,
  OverallRanking = 2,
}

interface ViewTastingResultsContainerProps {
  successHandler?: () => any;
  tastingId?: string;
}

const ViewTastingResultsContainer: React.FC<ViewTastingResultsContainerProps> = (props: ViewTastingResultsContainerProps) => {
  const { successHandler, tastingId } = props;
  // CONSTANT DECLARATION
  const VIEW_TASTING_KEY = 'viewTastingResults';
  const PUBLISH_WINE_KEY = 'PUBLISH_WINE_KEY';
  const UNPUBLISH_WINE_KEY = 'UNPUBLISH_WINE_KEY';
  // LOCAL (CONTAINER) STATE SETUP
  const [refreshData, setRefreshData] = useState<boolean>(false);
  const [winesAndFlightsRefresh, triggerWinesAndFlightsRefresh] = useState<string>('');
  const [tasting, setTasting] = useState(undefined as TastingWithAttendee | undefined);
  const [viewState, setViewState] = useState(TastingResultViewState.Scoring);
  const [resultInfo, setResultInfo] = useState<TastingResultInfo | undefined>({
    messageId: '',
    anyWines: false,
    anyFlights: false,
    showWineList: false,
  });
  // GLOBAL (REDUX) STATE SETUP
  const dispatch = useDispatch();
  const auth = useSelector((state: RootState) => state.authorization);
  const apiBaseUrl = useSelector((state: RootState): string => state.appSettings.apiBaseUrl);
  // LOCAL FUNCTIONS
  const fetchTasting = useCallback(async (tastingId): Promise<void> => {
    try {
      if (!tastingId) {
        return;
      }
      const result = await makeApiCall<TastingWithAttendee>({
        authToken: auth.token,
        dispatch: dispatch,
        callId: VIEW_TASTING_KEY,
        request: {
          url: `${apiBaseUrl}/tastings/${tastingId}`,
          httpMethod: 'GET',
        },
      });
      setTasting(result);
      if (successHandler) {
        successHandler();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [apiBaseUrl, auth.token, dispatch, successHandler]);
  const publishClickHandler = async (wine: Wine, publish: boolean): Promise<void> => {
    wine.areScoresPublished = publish;
    try {
      await makeApiCall({
        authToken: auth.token,
        dispatch: dispatch,
        callId: publish ? PUBLISH_WINE_KEY : UNPUBLISH_WINE_KEY,
        request: {
          url: `${apiBaseUrl}/wines/${wine.id}/${publish ? 'publishScores' : 'unPublishScores'}`,
          httpMethod: 'PUT',
        },
        showSuccessMessage: false,
      });
      // Need to refresh to tasting results caption for wine scores.
      setRefreshData(true);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const updateResultInfo = (nextResultInfo: TastingResultInfo): void => {
    if (resultInfo?.anyWines !== nextResultInfo.anyWines || resultInfo?.anyFlights !== nextResultInfo.anyFlights) {
      setResultInfo(nextResultInfo);
    }
  }
  // EFFECTS SETUP
  useIonViewWillEnter((): void => {
    setRefreshData(true);
  });
  useEffect(() => {
    if (!refreshData) {
      return;
    }
    clearApiCallStatus(dispatch, VIEW_TASTING_KEY);
    fetchTasting(tastingId)
      // eslint-disable-next-line no-console
      .catch(console.error);
    setRefreshData(false);
  }, [dispatch, fetchTasting, refreshData, tastingId]);
  const isHost = !!tasting?.attendee?.isHost;
  let wineListItemType = WineListItemType.TastingResultsWineScoreAsAttendee;
  if (viewState === TastingResultViewState.Scoring) {
    wineListItemType = isHost ? WineListItemType.TastingResultsWineScoreAsHost : WineListItemType.TastingResultsWineScoreAsAttendee;
  }
  if (viewState !== TastingResultViewState.Scoring) {
    wineListItemType = isHost ? WineListItemType.TastingResultsWineRankAsHost : WineListItemType.TastingResultsWineRankAsAttendee;
  }

  function getHeadingSize(): string {
    if (tasting && tasting.tasting.name.length > 80) {
      return 'xs';
    }
    else if (tasting && tasting.tasting.name.length > 60) {
      return 'sm';
    }
    else if (tasting && tasting.tasting.name.length > 40) {
      return 'md';
    }
    else if (tasting && tasting.tasting.name.length > 20) {
      return 'lg';
    }
    return 'xl';
  }

  return (
    <>
      <BackHeader backHref={`/tastings/details/room/${tastingId}`}>
        <FormattedMessage id="tastingResults.header.caption" />
      </BackHeader>
      <IonContent>
        <Heading
          data-cy="tastingDetailName"
          headingSize={getHeadingSize()}
          px={2}
          textAlign="center"
        >
          {tasting?.tasting?.name}
        </Heading>
        <Box pb={3}>
          <IonSegment
            onIonChange={e => setViewState(e.detail.value ? TastingResultViewState[e.detail.value] : TastingResultViewState.Scoring)}
            value={TastingResultViewState[viewState]}>
            <IonSegmentButton value={TastingResultViewState[TastingResultViewState.Scoring]}>
              <IonLabel>
                <FormattedMessage id="tastingResults.ionSegment.scoring" />
              </IonLabel>
            </IonSegmentButton>
            <Hideable hide={!resultInfo?.anyFlights}>
              <IonSegmentButton value={TastingResultViewState[TastingResultViewState.FlightRanking]}>
                <IonLabel>
                  <FormattedMessage id="tastingResults.ionSegment.flightRanking" />
                </IonLabel>
              </IonSegmentButton>
            </Hideable>
            <IonSegmentButton value={TastingResultViewState[TastingResultViewState.OverallRanking]}>
              <IonLabel>
                <FormattedMessage id="tastingResults.ionSegment.overallRanking" />
              </IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </Box>
        <WineListForTastingContainer
          allowScoring
          publishClickHandler={publishClickHandler}
          tastingId={tastingId}
          tasting={tasting}
          setRefreshData={setRefreshData}
          triggerWinesAndFlightsRefresh={triggerWinesAndFlightsRefresh}
          wineListItemType={wineListItemType}
          winesAndFlightsRefresh={winesAndFlightsRefresh}
          tastingResultViewState={viewState}
          updateTastingResultInfo={updateResultInfo}
        />
      </IonContent>
    </>
  );
};

export default ViewTastingResultsContainer;
