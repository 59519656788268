import { ApiCall, CustomHookProps, useBasicMutation } from 'napa-react-core';
import { UseMutationResult } from 'react-query/types/react/types';
import { TokenResponse } from '../../store/authorization';

export default function usePostUserToken(
  props?: CustomHookProps<TokenResponse>,
): UseMutationResult<TokenResponse, ApiCall<TokenResponse>> {
  return useBasicMutation<TokenResponse>({
    apiRoute: 'tokens',
    httpMethod: 'POST',
    isFormData: false,
    params: props,
  });
}
