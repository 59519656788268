import React from 'react';
import { IonPage } from '@ionic/react';
import ViewTastingResultsContainer from 'containers/tastings/ViewTastingResultsContainer';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ViewTastingResultsPage = (props: any): any => {
  const { tastingId } = props.match.params;

  return (
    <IonPage>
      <ViewTastingResultsContainer tastingId={tastingId} />
    </IonPage>
  );
}

export default ViewTastingResultsPage
