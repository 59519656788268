import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonInfiniteScroll, IonInfiniteScrollContent, IonList } from '@ionic/react';
import { Flex } from 'components/baseElements/grid';
import { Hideable } from 'components/baseElements/hideable';
import { Loadable } from 'components/baseElements/loadable';
import { IonText } from 'components/ionicComponents';
import { WineListItem, WineListItemType } from 'components/wine/WineListItem';
import usePostWine from 'hooks/tastings/usePostWine';
import usePostWineScore from 'hooks/tastings/usePostWineScore';
import { useToast } from 'napa-react-core';
import queryStringHelper from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { PaginatedData } from 'store/api';
import { MasterWine } from 'store/masterWines';
import { Wine } from 'store/wines';
import { toRequestInsertWineTastingDto } from 'store/wines/utils';
import { WineScore } from 'store/wineScores';
import { toRequestInsertWineScoreDto } from 'store/wineScores/utils';
import { GetLoadingStatus, makeApiCall } from 'utils/api';
import { LoadingStatus } from 'utils/formValidation';

interface SearchMasterWinesContainerProps {
  hasUserSearched: boolean;
  isSearchForMyWines?: boolean;
  isSearchForQuickRate?: boolean;
  onWineSelect?: (masterWine?: Wine) => void;
  searchText: string;
  tastingId?: string;
  wines?: Array<Wine>;
}

const SearchMasterWinesContainer: React.FC<SearchMasterWinesContainerProps> = (props: SearchMasterWinesContainerProps) => {
  const {
    hasUserSearched,
    isSearchForMyWines,
    onWineSelect,
    searchText,
    tastingId,
    wines,
  } = props;
  const FETCH_MASTER_WINES_KEY = 'FETCH_MASTER_WINES_CONTAINER';
  const CREATE_WINE_TASTING_KEY = 'CREATE_WINE_TASTING_FROM_SEARCH';
  // GLOBAL (REDUX) STATE SETUP
  const dispatch = useDispatch();
  const auth = useSelector((state: RootState) => state.authorization);
  const viewSearchMasterWinesApiCall = useSelector((state: RootState) =>
    state.api.callStatuses.find(e => e.id === FETCH_MASTER_WINES_KEY),
  );
  const intl = useIntl();
  const { showToast } = useToast();

  // LOCAL (CONTAINER) STATE SETUPs
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.None);
  const isLoading = LoadingStatus.Loading === GetLoadingStatus(viewSearchMasterWinesApiCall?.status);
  const [masterWines, setMasterWines] = useState(undefined as Array<MasterWine> | undefined);
  const infiniteScrollLimit = useSelector((state: RootState): number => state.appSettings.infiniteScrollLimit);
  //THIS WILL BE MOVED TO APP SETTINGS WHEN THE NEED ARISES
  const [offSet, setOffSet] = useState(0);
  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(false);
  const [disableLoading, setDisableLoading] = useState<boolean>(false);
  const apiBaseUrl = useSelector((state: RootState): string => state.appSettings.apiBaseUrl);
  const fetchMasterWines = async (localOffSet, localSearchText): Promise<PaginatedData<MasterWine>> => {
    try {
      const queryParams = queryStringHelper.stringify({
        searchText: localSearchText,
        offSet: localOffSet,
        limit: infiniteScrollLimit,
      });
      return await makeApiCall<PaginatedData<MasterWine>>({
        authToken: auth.token,
        dispatch: dispatch,
        callId: FETCH_MASTER_WINES_KEY,
        request: {
          url: `${apiBaseUrl}/masterwines?${queryParams}`,
          httpMethod: 'GET',
        },
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return { items: [], totalItems: 0 };
    }
  };
  //WE NEED TO SET LOCAL STATE OUTSIDE OF THE MASTERWINE PROMISE TO ELIMINATE RACE CONDITIONS
  const fetchWines = async (offSet, searchText, append): Promise<void> => {
    const result = await fetchMasterWines(offSet, searchText);
    setMasterWines(append ? [...masterWines || [], ...result.items] : result.items);
    setDisableInfiniteScroll(result.items.length < infiniteScrollLimit);
  };

  //THIS FUNCTION IS CALLED WHEN TRIGGERING IONIC'S INFINITE CONTROL
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async function searchNext($event: CustomEvent<void>) {
    setOffSet(offSet + infiniteScrollLimit);
    setDisableLoading(true);
    await fetchWines(offSet + infiniteScrollLimit, searchText, true);
    await ($event.target as HTMLIonInfiniteScrollElement).complete();
  }

  //THIS IS CALLED WHENEVER A SEARCHTEXT IS TRIGGERED..AGAIN WE ARE SETTING THE LOCAL STATE OUTSIDE OF
  //THE PROMISE OF fetchMasterWine
  useEffect((): void => {
    if (searchText.length === 0) {
      setMasterWines([]);
      return;
    }
    setDisableLoading(false);
    setOffSet(0);
    fetchWines(0, searchText, false)
      // eslint-disable-next-line no-console
      .catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const { data: wineData, isSuccess: isPostWineSuccess, mutate: mutatePostWine } = usePostWine({
    errorHandler: (): void => {
      showToast({ message: intl.formatMessage({ id: 'searchMasterWinesModal.toast.wineSaveFailure'}) });
    },
  });

  const { data: wineScoreData, isSuccess: isPostWineScoreSuccess, mutate: mutatePostWineScore } = usePostWineScore(
    wineData?.id,
    {
      errorHandler: (): void => {
        showToast({ message: intl.formatMessage({ id: 'searchMasterWinesModal.toast.wineSaveFailure'}) });
      },
    },
  );

  useEffect(() => {
    if (isPostWineSuccess) {
      const wineScore = {} as WineScore;
      mutatePostWineScore(
        toRequestInsertWineScoreDto(wineScore, auth, wineData?.id || '', undefined),
      );
    }
  }, [auth, wineData, isPostWineSuccess, mutatePostWineScore]);

  useEffect(() => {
    if (isPostWineScoreSuccess) {
      if (onWineSelect) {
        const wineScore = {
          userScore: {
            ...wineScoreData, wine: undefined,
          },
        } as WineScore;
        onWineSelect({
          ...wineData,
          scoring: wineScore,
        } as Wine);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPostWineScoreSuccess]);

  const handleMyWineSubmit = useCallback(async (selectedMasterWine): Promise<void> => {
    const flightlessWines = wines?.length ? wines.filter((wine: Wine): boolean => !wine.flightId) : [];
    const wineForInsert = {
      imageFileId: selectedMasterWine.imageFileId,
      name: selectedMasterWine.name,
      bottleSize: selectedMasterWine.bottleSize,
      designation: selectedMasterWine.designation,
      producer: selectedMasterWine.producer,
      sequence: flightlessWines.length || 0,
      vintage: selectedMasterWine.vintage,
    } as Wine;
    if (selectedMasterWine.id) {
      wineForInsert.masterWineId = selectedMasterWine.id;
    }
    mutatePostWine(toRequestInsertWineTastingDto(wineForInsert));
  }, [mutatePostWine, wines]);

  const handleWineTastingSubmit = useCallback(async (selectedMasterWine): Promise<void> => {
    // Get the sequence based on flightless wines count
    const flightlessWines = wines?.length ? wines.filter((wine: Wine): boolean => !wine.flightId) : [];
    const wineForInsert = {
      imageFileId: selectedMasterWine.imageFileId,
      name: selectedMasterWine.name,
      bottleSize: selectedMasterWine.bottleSize,
      designation: selectedMasterWine.designation,
      producer: selectedMasterWine.producer,
      sequence: flightlessWines.length || 0,
      tastingId,
      vintage: selectedMasterWine.vintage,
    } as Wine;
    if (selectedMasterWine.id) {
      wineForInsert.masterWineId = selectedMasterWine.id;
    }
    if (loadingStatus === LoadingStatus.Loading) {
      return;
    }
    if (setLoadingStatus) {
      setLoadingStatus(LoadingStatus.Loading);
    }
    try {
      const wine = await makeApiCall<Wine>({
        authToken: auth.token,
        dispatch: dispatch,
        callId: CREATE_WINE_TASTING_KEY,
        request: {
          url: `${apiBaseUrl}/wines`,
          httpMethod: 'POST',
          body: toRequestInsertWineTastingDto({ ...wineForInsert }),
        },
        showSuccessMessage: false,
      });
      if (onWineSelect) {
        onWineSelect(wine);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    if (setLoadingStatus) {
      setLoadingStatus(LoadingStatus.Complete);
    }
  }, [apiBaseUrl, auth.token, dispatch, loadingStatus, onWineSelect, tastingId, wines]);
  return (
    <Loadable
      isLoading={isLoading && !disableLoading}
    >
      <Loadable.Loaded>
        <Hideable hide={hasUserSearched}>
          <Flex
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
            mt={4}
            ml={4}
            mr={4}
          >
            <FontAwesomeIcon size="3x" icon={faSearch} />
            <IonText
              alignItems="center"
              justifyContent="center"
              mt={4}
              textAlign="center"
            >
              <FormattedMessage id={isSearchForMyWines ? 'searchMasterWinesModal.header.myRatingsNewState' : 'searchMasterWinesModal.header.newState'} />
            </IonText>
          </Flex>
        </Hideable>
        <Hideable hide={!hasUserSearched || !masterWines || masterWines?.length > 0}>
          <Flex
            justifyContent="center"
            mt={4}
          >
            <IonText>
              <FormattedMessage id="searchMasterWinesModal.header.emptyState" />
            </IonText>
          </Flex>
        </Hideable>
        <Hideable hide={!masterWines?.length}>
          <IonList>
            {masterWines?.map((item: MasterWine) => (
              <WineListItem
                key={item.id}
                type={WineListItemType.Default}
                wine={item}
                onWineClick={async (): Promise<void> => isSearchForMyWines ? handleMyWineSubmit(item) : handleWineTastingSubmit(item)}
              />
            ))}
          </IonList>
          <IonInfiniteScroll
            threshold="100px"
            disabled={disableInfiniteScroll}
            onIonInfinite={(e: CustomEvent<void>): Promise<void> => searchNext(e)}
          >
            <IonInfiniteScrollContent
              loadingText="Loading more wines...">
            </IonInfiniteScrollContent>
          </IonInfiniteScroll>
        </Hideable>
      </Loadable.Loaded>
    </Loadable>
  );
};

export default SearchMasterWinesContainer;
