import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgCherryIcon(props) {
  return (
    <svg width={17} height={18} fill="none" {...props}>
      <path
        d="M3.678 14.014c-.855-.19-1.573-.836-1.836-1.646a.302.302 0 00-.574.188 3.099 3.099 0 002.28 2.047.306.306 0 00.362-.23.307.307 0 00-.232-.359zM11.487 16.23c-.855-.19-1.574-.836-1.836-1.645a.302.302 0 00-.574.187 3.099 3.099 0 002.28 2.047c.02.006.045.006.066.006a.306.306 0 00.296-.235.308.308 0 00-.232-.36zM1.431 11.23c.021.003.04.006.06.006.143 0 .266-.1.297-.242.07-.338.193-.658.368-.948a.3.3 0 00-.1-.413.3.3 0 00-.413.1c-.215.35-.363.733-.447 1.144a.3.3 0 00.235.353z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M14.174 9.747c.661-2.207-.063-4.218-1.576-6.622.208.049.423.079.646.079 1.063 0 2.11-.529 2.733-1.377l.178-.245-.245-.178a2.752 2.752 0 00-1.64-.516c-.875 0-1.736.362-2.36.963C11.42.773 10.217 0 8.895 0c-.163 0-.326.012-.483.036l-.3.046.046.298c.217 1.414 1.63 2.522 3.216 2.522.07 0 .139-.003.208-.01-.139 2.655-2.126 5.025-4.106 5.783-.72-.948-1.773-1.549-2.948-1.549h-.603C1.76 7.126 0 9.16 0 11.656a4.232 4.232 0 004.227 4.227c1.54 0 2.887-.83 3.627-2.065A4.234 4.234 0 0012.082 18a4.232 4.232 0 004.227-4.227c0-1.752-.87-3.274-2.135-4.026zm.097-8.255c.365 0 .706.078 1.005.232a2.889 2.889 0 01-2.035.879c-.365 0-.707-.079-1.008-.233a2.889 2.889 0 012.038-.878zM8.829.604c1.057-.027 2.262.604 2.612 1.694h-.066c-1.036 0-2.201-.625-2.546-1.694zm3.304 2.92c1.546 2.485 1.999 4.212 1.498 5.96a3.457 3.457 0 00-1.248-.238h-.603c-1.408 0-2.64.86-3.334 2.15a4.995 4.995 0 00-.628-2.201c2.01-.828 3.937-3.05 4.315-5.671zM4.23 15.282A3.626 3.626 0 01.607 11.66c0-2.165 1.489-3.926 3.322-3.926h.603c.906 0 1.728.432 2.329 1.13a3.18 3.18 0 01-.647.07.303.303 0 00-.302.301c0 .166.136.302.302.302.336 0 .68-.054 1.024-.148.387.64.616 1.422.616 2.268a3.627 3.627 0 01-3.623 3.626zm7.85 2.114a3.626 3.626 0 01-3.623-3.623c0-2.166 1.489-3.926 3.322-3.926h.604c.368 0 .721.075 1.053.205-.114.281-.25.565-.41.855a.303.303 0 00.528.296 7.91 7.91 0 00.417-.879c1.03.667 1.733 1.963 1.733 3.449a3.626 3.626 0 01-3.623 3.623z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M6.646 9.847c-.667 0-1.208-.54-1.208-1.208a.303.303 0 00-.302-.302.303.303 0 00-.302.302c0 1 .813 1.812 1.812 1.812a.303.303 0 00.302-.302.303.303 0 00-.302-.302zM13.893 11.96c-.667 0-1.208-.54-1.208-1.207a.303.303 0 00-.302-.302.303.303 0 00-.301.302c0 1 .812 1.812 1.811 1.812a.303.303 0 00.302-.302.303.303 0 00-.302-.302z"
        fill="var(--ion-color-dark)"
      />
    </svg>
  );
}

export default SvgCherryIcon;
