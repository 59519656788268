import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonModal,
  IonToolbar,
} from '@ionic/react';
import { close } from 'ionicons/icons';
import { IonButton, IonTitle } from 'components/ionicComponents';
import { RootState } from 'store';
import { hideModal } from 'utils/modals';

export const PrivacyModalId = 'Privacy';

export default function PrivacyModal(): JSX.Element {
  const dispatch = useDispatch();
  const modalProps = useSelector((state: RootState) =>
    state.gui.modals.find(e => e.id === PrivacyModalId),
  );

  return (
    <IonModal
      isOpen={modalProps?.show || false}
      onDidDismiss={(): void => hideModal(dispatch, PrivacyModalId, {})}
    >
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={(): void => hideModal(dispatch, PrivacyModalId, {})}>
              <IonIcon icon={close} slot="icon-only" />
            </IonButton>
          </IonButtons>
          <IonTitle>
            <FormattedMessage id="privacy.header.caption" />
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <iframe
          src="https://storage.googleapis.com/napaqa-cdn/vinvite/privacy.html"
          style={{ border: '0', maxHeight: 'calc(100% - 4px)' }}
          width="100%"
          height="100%"
        />
      </IonContent>
    </IonModal>
  );
}
