import { Contact, Contacts, EmailAddress, PermissionStatus } from '@capacitor-community/contacts';
import { SearchbarChangeEventDetail } from '@ionic/core/dist/types/components/searchbar/searchbar-interface';
import {
  IonButtons,
  IonContent,
  IonFab,
  IonHeader,
  IonIcon,
  IonList,
  IonModal,
  IonSpinner,
  IonToolbar,
  isPlatform,
} from '@ionic/react';
import AttendeeSearch from 'components/attendee/AttendeeSearch';
import { Box } from 'components/baseElements/grid';
import { SearchBar } from 'components/baseElements/SearchBar';
import { IonButton, IonTitle } from 'components/ionicComponents';
import { PrivatePropertyContainer } from 'containers/authorization/PrivatePropertyContainer';
import usePostTastingAttendee from 'hooks/attendees/usePostTastingAttendee';
import { close } from 'ionicons/icons';
import { stringIncludes } from 'napa-base/dist';
import { Hideable, useToast } from 'napa-react-core';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { LoadingStatus } from 'utils/formValidation';
import { hideModal } from 'utils/modals';
import PhoneContact from '../../components/attendee/PhoneContact';

interface ContactMap {
  [emailAddress: string]: Contact;
}

export const InviteAttendeesModalId = 'InviteAttendees';

export default function InviteAttendeesModal(): JSX.Element {
  const dispatch = useDispatch();
  const modalProps = useSelector((state: RootState) =>
    state.gui.modals.find(e => e.id === InviteAttendeesModalId),
  );
  const intl = useIntl();
  const { showToast } = useToast();
  const [contactPermissionGranted, setContactPermissionGranted] = useState<boolean>(false);
  const [contacts, setContacts] = useState<Array<Contact>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [newTastingAttendees, setNewTastingAttendees] = useState<{ [email: string]: boolean }>({});
  const [searching, setSearching] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<Array<Contact>>([]);
  const [searchText, setSearchText] = useState<string>('');
  const closeModal = (): void => {
    resetSearchState();
    setContacts([]);
    setTimeout(() => {
      hideModal(dispatch, InviteAttendeesModalId, {});
      if (modalProps?.params?.handleInviteAttendeesModalClose) {
        modalProps.params.handleInviteAttendeesModalClose();
      }
    }, 50);
  };
  const postTastingAttendees = usePostTastingAttendee(modalProps?.params?.tastingId, {
    errorHandler: () => {
      setLoading(false);
      showToast({ message: intl.formatMessage({ id: 'inviteAttendeesModal.toast.saveFailure' }) });
    },
    successHandler: (): void => {
      resetSearchState();
      showToast({
        show: false,
        message: intl.formatMessage({ id: 'inviteAttendeesModal.toast.saveSuccess' }),
      });
    },
  });
  const askForContactPermissions = async (): Promise<boolean> => {
    try {
      // Ask permissions on Android explicitly if not approved.
      if (isPlatform('android')) {
        // Attempt to update permission
        if (!contactPermissionGranted) {
          const permissionStatus: PermissionStatus = await Contacts.getPermissions();
          setContactPermissionGranted(!!permissionStatus?.granted);
          return !!permissionStatus?.granted;
        }
      }

      // iOS does not need to explicitly ask for permissions
      if (isPlatform('ios')) {
        if (!contactPermissionGranted) {
          // iOS does not need to explicitly ask for permissions
          setContactPermissionGranted(true);
        }
        // iOS is always true
        return true;
      }

      // keep existing permission
      return contactPermissionGranted;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
    setContactPermissionGranted(false);
    return false;
  };
  const getCachedContacts = async (): Promise<Array<Contact>> => {
    if (contacts?.length) {
      return [...contacts];
    }
    try {
      setSearching(true);
      const results = await Contacts.getContacts();
      setContacts(results.contacts);
      setSearching(false);
      return results.contacts || [];
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      setSearching(false);
      return [];
    }
  };
  const handleClickToInvite = (nextSearchText: string): void => {
    Object.keys(newTastingAttendees).forEach(async (email: string): Promise<void> => {
      if (newTastingAttendees[email]) {
        setLoading(true);
        await postTastingAttendees.mutateAsync({
          email: email || nextSearchText,
          invited: true,
          isHost: false,
          tastingId: modalProps?.params?.tastingId,
        });
      }
    });
  };
  const handleUpdateInvitees = (email?: string, invited?: boolean) => {
    if (email) {
      setNewTastingAttendees({
        ...newTastingAttendees,
        [email]: !!invited,
      });
    }
  };
  const hasInvitees = (): boolean => !!Object
    .keys(newTastingAttendees)
    .filter((key: string): boolean => newTastingAttendees[key])
    .length;
  const isContactMatch = (
    nextSearchText: string,
    displayName?: string,
    organizationName?: string,
    emailAddress?: string,
  ): boolean => {
    // Match contact on displayName and organizationName (company)
    const contactMatch = stringIncludes(displayName || '', nextSearchText) ||
      stringIncludes(organizationName || '', nextSearchText);
    return !!emailAddress && (contactMatch || stringIncludes(emailAddress || '', nextSearchText));
  };
  const searchLocalContacts = async (nextSearchText: string): Promise<void> => {
    try {
      const permissionStatusGranted = await askForContactPermissions();
      if (permissionStatusGranted) {
        if (!nextSearchText) {
          setSearchResults([]);
        } else {
          // Will use contacts in memory if already retrieved
          const cachedContacts = await getCachedContacts();
          // Get a unique result set map based on email address
          const searchResultsMap = cachedContacts
            .reduce((contactMap: ContactMap, contact: Contact): ContactMap => {
              // Match also on any email address
              contact.emails.forEach((email: EmailAddress): void => {
                const emailAddress = email.address || '';
                if (isContactMatch(nextSearchText, contact.displayName, contact.organizationName, emailAddress)) {
                  // Set in a map by email to filter out duplicates
                  contactMap = {
                    ...contactMap,
                    [emailAddress]: {
                      contactId: contact.contactId,
                      displayName: contact.displayName,
                      emails: [email],
                      phoneNumbers: [],
                      organizationName: contact.organizationName,
                      organizationRole: contact.organizationRole,
                    },
                  };
                }
              });
              return contactMap;
            }, {});
          // Sort by display name ascending the top 100
          const nextSearchResults = Object.keys(searchResultsMap).map((email: string): Contact => searchResultsMap[email])
            .sort((a: Contact, b: Contact) => {
              if (!a.displayName) return 1;
              if (!b.displayName) return 1;
              return a.displayName < b.displayName ? -1 : 1;
            })
            .slice(0, 100);
          setSearchResults(nextSearchResults);
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
  const handleSearchChange = async (nextSearchText: string): Promise<void> => {
    setSearchText(nextSearchText);
    await searchLocalContacts(nextSearchText);
  };
  const resetSearchState = (): void => {
    setSearchText('');
    setLoading(false);
    setSearching(false);
    setSearchResults([]);
  };
  useEffect(() => {
    if (!searchText) {
      setNewTastingAttendees({});
    }
  }, [searchText]);
  // Ask for permissions once when opening this modal
  useEffect(() => {
    askForContactPermissions()
      // eslint-disable-next-line no-console
      .catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <IonModal isOpen={modalProps?.show || false}>
      <IonHeader>
        <IonToolbar color="light">
          <IonButtons slot="start">
            <IonButton onClick={closeModal}>
              <IonIcon icon={close} slot="icon-only"/>
            </IonButton>
          </IonButtons>
          <IonTitle>
            <FormattedMessage id="inviteAttendeesModal.header.title"/>
          </IonTitle>
          <Hideable hide={!loading}>
            <IonButtons slot="end">
              <Box mr={3}>
                <IonSpinner color="light"/>
              </Box>
            </IonButtons>
          </Hideable>
        </IonToolbar>
        <IonToolbar color="light">
          <SearchBar
            debounce={300}
            enableAutoFocus={!searchResults.length}
            onIonChange={(e: CustomEvent<SearchbarChangeEventDetail>): Promise<void> => handleSearchChange(e.detail.value || '')}
            onIonClear={(): Promise<void> => handleSearchChange('')}
            placeHolder={intl.formatMessage({ id: 'inviteAttendeesModal.searchBar.placeholderText' })}
            searchText={searchText}
          />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <PrivatePropertyContainer>
          <PrivatePropertyContainer.Authorized>
            <Hideable hide={!searching}>
              <Box mr={3}>
                <IonSpinner color="light"/>
              </Box>
            </Hideable>
            <Hideable hide={searching}>
              <>
                <Hideable hide={!!searchResults?.length}>
                  <AttendeeSearch
                    loadingStatus={LoadingStatus.Complete}
                    searchText={searchText}
                    updateInvite={(invited?: boolean): void => handleUpdateInvitees(searchText, invited)}
                  />
                </Hideable>
                <Hideable hide={!searchResults?.length}>
                  <IonList>
                    {searchResults.map((searchResult: Contact) => <div key={searchResult.emails[0].address}>
                      <PhoneContact
                        displayName={searchResult.displayName}
                        emailAddress={searchResult.emails[0].address}
                        organizationName={searchResult.organizationName}
                        updateInvite={(invited?: boolean): void => handleUpdateInvitees(searchResult.emails[0].address, invited)}
                      />
                    </div>)}
                    <Box pb={5}>&nbsp;</Box>
                  </IonList>
                </Hideable>
              </>
            </Hideable>
            <div style={{height: '100px', width: '100%'}}>&nbsp;</div>
          </PrivatePropertyContainer.Authorized>
        </PrivatePropertyContainer>
      </IonContent>
      <Hideable hide={!hasInvitees()}>
        <IonFab vertical="bottom" horizontal="start" slot="fixed" style={{ width: 'calc(100% - 20px)' }}>
          <IonButton onClick={(): void => handleClickToInvite(searchText)} expand="block">
            <FormattedMessage id="inviteAttendeesModal.buttons.invite"/>
          </IonButton>
        </IonFab>
      </Hideable>
    </IonModal>
  );
}
