import React, { useState } from 'react';
import { Wine } from 'store/wines';
import RankingButton from '../../baseElements/Ranking/RankingButton';
import {
  getNextWineRankPoints,
  getWineRankColorFromPoints,
  getWineRankFromPoints
} from '../../baseElements/Ranking/utils';

interface WineRankingButtonProperties {
  points?: number;
  pointChange: (rank: number) => void;
  onWineRankAllocation?: (wine: Wine, point: number) => number;
  wine?: Wine;
}

export default function WineRankingButton(props: WineRankingButtonProperties): JSX.Element {
  const {
    onWineRankAllocation,
    points,
    pointChange,
    wine,
  } = props;
  // LOCAL STATE
  const [updatedPoints, setUpdatedPoints] = useState<number | undefined>();
  const displayPoints = (updatedPoints || updatedPoints === 0) ? updatedPoints : (points || 0);
  // LOCAL LOGIC

  // CALLBACK FUNCTIONS
  const handleRankChange = (current: number): void => {
    let next = getNextWineRankPoints(current);
    // Override rank allocation with custom callback
    if (onWineRankAllocation && wine) {
      next = onWineRankAllocation(wine, current);
    }
    pointChange(next);
    setUpdatedPoints(next);
  };
  return (
    <div onClick={(): void => handleRankChange(displayPoints)}>
      <RankingButton
        color={getWineRankColorFromPoints(displayPoints)}
        rank={getWineRankFromPoints(displayPoints)}
        size={44}
      />
    </div>
  );
}
