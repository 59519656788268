export interface WineScore {
  id?: string;
  wineId?: string;
  userId?: string;
  color?: string;
  brightness?: number;
  clarity?: number;
  aromas?: string;
  sweetness?: number;
  acidity?: number;
  alcohol?: number;
  tannins?: number;
  flavors?: string;
  body?: number;
  complexity?: number;
  finish?: number;
  rating?: number;
  updatedAt?: string;
  notes?: string;
}

export function getEmptyWineScore(): WineScore {
  return { };
}

export interface WineProfile {
  name: string;
  primaryGroup?: string;
  secondaryGroup?: string;
  icon?: any;
}
