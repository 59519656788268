import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgCardboardIcon(props) {
  return (
    <svg width={18} height={18} fill="none" {...props}>
      <path
        d="M17.621 5.59L14.931.226A.425.425 0 0014.57 0H3.087a.39.39 0 00-.36.227L.038 5.59C.018 5.646 0 5.703 0 5.76v11.842c0 .227.17.398.398.398H17.26c.227 0 .398-.17.398-.398V5.76c0-.057-.019-.114-.038-.17zM9.36 1.06h4.813l2.103 4.206H9.36V1.061zm-5.874 0H8.3v4.206H1.383l2.103-4.206zM16.598 16.94H1.06V6.329h15.537v10.61z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M6.29 12.392h5.097c.986 0 1.781-.796 1.781-1.781 0-.986-.795-1.781-1.78-1.781H6.29c-.986 0-1.781.795-1.781 1.78 0 .986.795 1.782 1.78 1.782zm0-2.52h5.097a.72.72 0 110 1.44H6.291a.72.72 0 110-1.44z"
        fill="var(--ion-color-dark)"
      />
    </svg>
  );
}

export default SvgCardboardIcon;
