import { RefresherEventDetail } from '@ionic/core';
import { IonActionSheet, IonContent, IonRefresher, IonRefresherContent, useIonViewDidEnter } from '@ionic/react';
import { Box } from 'components/baseElements/grid';
import { HeaderWithButtons } from 'components/baseElements/header';
import { IonButton } from 'components/ionicComponents';
import { ConfirmAlert } from 'components/wine/ConfirmAlert';
import MyWineActionBar from 'components/wine/MyWineActionBar';
import WineListForTasting from 'components/wine/WineListForTasting';
import { WineListItemType } from 'components/wine/WineListItem';
import useDeleteWineScore from 'hooks/wines/useDeleteWineScore';
import { chevronDownCircleOutline } from 'ionicons/icons';
import { ScoreAWineModalId } from 'modals/ScoreAWineModal';
import { masterWinesSearchModalId } from 'modals/SearchMasterWinesModal';
import { WineScoreSummaryModalId } from 'modals/WineScoreSummaryModal';
import { useAuthorization } from 'napa-react-core';
import queryStringHelper from 'query-string';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { RootState } from 'store';
import { PaginatedData } from 'store/api';
import { Wine } from 'store/wines';
import { SortDir, WineSortBy } from 'store/wines/utils';
import { clearApiCallStatus, makeApiCall } from 'utils/api';
import { showModal } from 'utils/modals';
import { authorizationSetToken } from '../../../store/authorization/actions/authorizationSetToken';
import { getUserAuthToken, getUserIdFromToken } from '../../../utils/auth';
import QuickRateContainer from '../QuickRateContainer';

interface WinesSortInfo {
  sortBy: WineSortBy;
  sortDir: SortDir;
}

interface MyWinesContainerProps {
  loginRefresh?: boolean;
}

const MyWinesContainer = (props: MyWinesContainerProps): JSX.Element => {
  const { loginRefresh } = props;
  const FETCH_MY_WINES_KEY = 'FETCH_MY_WINES_CONTAINER';

  // GLOBAL (REDUX) STATE SETUP
  const dispatch = useDispatch();
  const { logoff } = useAuthorization();
  const history = useHistory();
  // LOCAL (CONTAINER) STATE SETUPs
  const queryParams = queryStringHelper.parse(useLocation().search);
  // map string value to enum values
  const myWinesSortInfo = useMemo(() => ({
    sortBy: WineSortBy[WineSortBy[queryParams.sortBy as string]] || WineSortBy.ratedDate,
    sortDir: SortDir[SortDir[queryParams.sortDir as string]] || SortDir.desc,
  } as WinesSortInfo), [queryParams]);

  // LOCAL (CONTAINER) STATE SETUPs
  const [loginRefreshCalled, setLoginRefreshCalled] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [myWines, setMyWines] = useState([] as Array<Wine>);
  const [showActionSheet, setShowActionSheet] = useState(false);
  const [wineScoreForDelete, setWineScoreForDelete] = useState('');
  const [showDeleteRatingAlert, setShowDeleteRatingAlert] = useState(false);

  const deleteTastingWine = useDeleteWineScore(wineScoreForDelete);

  const apiBaseUrl = useSelector((state: RootState): string => state.appSettings.apiBaseUrl);
  const intl = useIntl();
  const deleteRatingHeader = intl.formatMessage({
    id: 'deleteRating.alert.header'
  });
  const deleteRatingMessage = intl.formatMessage({
    id: 'deleteRating.alert.message'
  });
  const deleteRatingConfirm = intl.formatMessage({
    id: 'deleteRating.alert.confirm'
  });
  const deleteRatingCancel = intl.formatMessage({
    id: 'deleteRating.alert.cancel'
  });
  // LOCAL FUNCTIONS
  const fetchWines = useCallback(async (reviewerId: string, sortInfo: WinesSortInfo): Promise<void> => {
    try {
      // Directly get this value from local storage because requests from any ionic page hooks (e.g ionViewDidEnter)
      // will have a snapshot of an old state, potentially a different user token or outdated token.
      const authToken = getUserAuthToken();
      if (!reviewerId || !authToken) {
        return;
      }
      const queryParams = queryStringHelper.stringify({
        reviewerId,
        sortByAndDirection: `${WineSortBy[sortInfo.sortBy]} ${SortDir[sortInfo.sortDir]}`,
      });
      const result = await makeApiCall<PaginatedData<Wine>>({
        authToken: authToken,
        dispatch: dispatch,
        callId: FETCH_MY_WINES_KEY,
        request: {
          url: `${apiBaseUrl}/wines?${queryParams}`,
          httpMethod: 'GET',
        },
      });
      setMyWines(result.items);
    } catch (error) {
      if (error.message === 'A forbidden error has occurred') {
        dispatch(authorizationSetToken({}));
        logoff();
      }
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [apiBaseUrl, dispatch, logoff]);

  const refreshMyWines = useCallback((): void => {
    clearApiCallStatus(dispatch, FETCH_MY_WINES_KEY);
    // Directly get this value from local storage because requests from any ionic page hooks (e.g ionViewDidEnter)
    // will have a snapshot of an old state, potentially a different userId.
    const userId = getUserIdFromToken();
    if (userId) {
      fetchWines(userId, myWinesSortInfo)
        // eslint-disable-next-line no-console
        .catch(console.error);
    }
  }, [dispatch, fetchWines, myWinesSortInfo]);

  const doRefresh = (event: CustomEvent<RefresherEventDetail>): void => {
    refreshMyWines();
    event.detail.complete();
  };
  const scoreAWineCallback = (wine: Wine, isSaveAndClose: any): void => {
    refreshMyWines();
    if (isSaveAndClose) {
      showModal(dispatch, WineScoreSummaryModalId, {
        params: { wine, hideBackHeader: true },
        callback: (): void => {
          refreshMyWines();
        },
      });
    }
  };
  const wineClickHandler = (wine: Wine): void => {
    if (!wine?.scoring?.userScore?.rating) {
      showModal(dispatch, ScoreAWineModalId, {
        params: { wine },
        callback: (): void => refreshMyWines(),
      });
      return;
    }
    scoreAWineCallback(wine, true);
  };

  const handleSortClick = (sortInfo: WinesSortInfo): void => {
    const queryString = queryStringHelper.stringify(sortInfo as any);
    history.replace({
      search: `${queryString}`,
      pathname: '/myWines',
    });
    // Directly get this value from local storage because requests from any ionic page hooks (e.g ionViewDidEnter)
    // will have a snapshot of an old state, potentially a different userId.
    const userId = getUserIdFromToken();
    if (userId) {
      fetchWines(userId, sortInfo)
        // eslint-disable-next-line no-console
        .catch(console.error);
    }
  };
  const addWineModalCallback = (result: any): void => {
    refreshMyWines();
    if (result?.wine) {
      showModal(dispatch, ScoreAWineModalId, {
        params: { wine: result.wine },
        callback: (data: Wine, isSaveAndClose): void => scoreAWineCallback(result.wine, isSaveAndClose),
      });
    }
  };

  const onAddWineClick = (): void => {
    showModal(dispatch, masterWinesSearchModalId, {
      params: { wines: myWines, isSearchForMyWines: true },
      callback: addWineModalCallback,
    });
  };

  const handleWineRemoval = async () => {
    await deleteTastingWine.mutateAsync({})
      // eslint-disable-next-line no-console
      .catch(console.error);
    refreshMyWines();
  };
  const refreshWine = (
    <IonRefresher
      onIonRefresh={doRefresh}
      slot="fixed"
    >
      <IonRefresherContent
        pullingIcon={chevronDownCircleOutline}
        pullingText={intl.formatMessage({ id: 'general.refresher.pullingText' })}
        refreshingSpinner="circles"
        refreshingText={intl.formatMessage({ id: 'general.refresher.refreshingText' })}
      />
    </IonRefresher>
  );

  useIonViewDidEnter((): void => {
    // useIonViewDidEnter seems to capture component state from the time of mount
    refreshMyWines();
  });

  const actionSheetHeader = intl.formatMessage({
    id: 'myRatings.text.sortTitle',
  });

  useEffect(() => {
    if (!loginRefreshCalled && loginRefresh) {
      refreshMyWines();
      setLoginRefreshCalled(true);
    }
  }, [loginRefresh, loginRefreshCalled, refreshMyWines, setLoginRefreshCalled]);
  return (
    <>
      <IonActionSheet
        buttons={[{
          text: intl.formatMessage({
            id: 'myRatings.buttons.sortByRatedDate',
          }),
          handler: (): void => handleSortClick({
            sortBy: WineSortBy.ratedDate,
            sortDir: SortDir.desc,
          }),
        }, {
          text: intl.formatMessage({
            id: 'myRatings.buttons.sortByName',
          }),
          handler: (): void => handleSortClick({
            sortBy: WineSortBy.wineName,
            sortDir: SortDir.asc,
          }),
        }, {
          text: intl.formatMessage({
            id: 'myRatings.buttons.sortByRating',
          }),
          handler: (): void => handleSortClick({
            sortBy: WineSortBy.rating,
            sortDir: SortDir.desc,
          }),
        }, {
          text: intl.formatMessage({
            id: 'tastingActionBar.buttons.cancel',
          }),
          role: 'cancel',
          handler: (): void => setShowActionSheet(false),
        }]}
        header={actionSheetHeader}
        isOpen={showActionSheet}
        onDidDismiss={(): void => setShowActionSheet(false)}
      />
      <HeaderWithButtons
        ionButtonsEnd={
          <IonButton onClick={(): void => setShowActionSheet(true)}>Sort</IonButton>}
      >
        <FormattedMessage id="myRatings.header.caption" />
      </HeaderWithButtons>
      <MyWineActionBar
        addWineModalCallback={onAddWineClick}
        onSearchChange={setSearchText}
      />
      <ConfirmAlert
        cancel={deleteRatingCancel}
        confirmCaption={deleteRatingConfirm}
        header={deleteRatingHeader}
        isOpen={showDeleteRatingAlert}
        onDidDismiss={(): void => setShowDeleteRatingAlert(false)}
        onConfirm={(): void => {
          handleWineRemoval();
          setShowDeleteRatingAlert(false);
        }}
        message={deleteRatingMessage}
      />
      <IonContent>
        <WineListForTasting
          flights={[]}
          manualSort
          searchText={searchText}
          scoreWineClickHandler={wineClickHandler}
          wineClickHandler={wineClickHandler}
          wines={myWines}
          wineListItemType={WineListItemType.UserRating}
          wineRatingProps={{ hideLabel: true }}
          wineRemoveClickHandler={(wine: Wine): void => {
            setWineScoreForDelete(wine?.scoring?.userScore?.id || '');
            setShowDeleteRatingAlert(true);
          }}
          refresher={refreshWine}
        />
        <Box pb={5} />
        <QuickRateContainer
          onAddWineClick={onAddWineClick}
          addWineCallback={addWineModalCallback}
        />
      </IonContent>
    </>
  );
}

export default MyWinesContainer;
