import React, { useEffect } from 'react';
import {
  IonCard,
  IonCardContent,
  IonInput,
  IonList,
  useIonViewWillEnter,
} from '@ionic/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import HookInput from 'components/baseElements/formControls/HookInput';
import { LoadingStatus, handleServerHookForm } from 'utils/formValidation';
import { Flex } from 'components/baseElements/grid';
import resetPasswordImage from './reset-password.jpg';
import { Logo } from 'components/baseElements/Logo';
import { SubmitButton } from 'components/baseElements/formControls';
import Form from 'components/baseElements/formControls/StyledForm';

interface ResetPasswordFormProperties {
  loadingStatus?: LoadingStatus;
  serverValidation?: any;
  submitAction: (data: any) => any;
  validationSchema?: any;
}

export default function ResetPasswordForm(
  props: ResetPasswordFormProperties,
): JSX.Element {
  const {
    loadingStatus,
    submitAction,
    serverValidation,
    validationSchema,
  } = props;

  const form = useForm({
    defaultValues: {
      newPassword: '',
      PasswordConfirm: ''
    },
    resolver: yupResolver(validationSchema)
  })

  useIonViewWillEnter((): void => {
    form.reset();
  });
  useEffect(() => {
    handleServerHookForm(serverValidation, form.setError);
  }, [serverValidation, form.setError])

  return (
    <Flex
      backgroundImage={`url(${resetPasswordImage})`}
      backgroundPosition="center"
      backgroundSize="cover"
      height="100vh"
      justifyContent="center"
      pt={4}
    >
      <Form onSubmit={form.handleSubmit(submitAction)}>
        <IonCard
          style={{
            minHeight: '434px',
            width: 'calc(100vw - 32px)',
          }}>
          <IonCardContent>
            <Logo
              $maxWidth={210}
              $mx="auto"
              $my={3}
              $width={1 / 2}
            />
            <IonList lines="full">
              <HookInput
                assistiveText="userRegistrationForm.assistiveText.password"
                form={form}
                isRequired
                labelText="userRegistrationForm.labels.passwordNew"
                name="newPassword"
              >
                <IonInput
                  clearOnEdit={false}
                  type="password"
                />
              </HookInput>
              <HookInput
                form={form}
                isRequired
                labelText="userRegistrationForm.labels.passwordConfirmNew"
                name="passwordConfirm"
              >
                <IonInput
                  clearOnEdit={false}
                  type="password"
                />
              </HookInput>
            </IonList>
            <SubmitButton
              isLoading={loadingStatus === LoadingStatus.Loading}
            >
              <FormattedMessage id="changePasswordForm.labels.saveButton" />
            </SubmitButton>
          </IonCardContent>
        </IonCard>
      </Form>
    </Flex>
  );
}
