import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgLilacIcon(props) {
  return (
    <svg width={18} height={18} fill="none" {...props}>
      <path
        d="M15.577 0a2.44 2.44 0 00-.348.023 7.416 7.416 0 00-6.23 5.718A7.416 7.416 0 002.772.023 2.44 2.44 0 00.023 2.77a7.416 7.416 0 005.717 6.23 7.416 7.416 0 00-5.717 6.228 2.44 2.44 0 002.748 2.748A7.416 7.416 0 009 12.26a7.417 7.417 0 006.229 5.717 2.44 2.44 0 002.748-2.748A7.416 7.416 0 0012.26 9a7.416 7.416 0 005.718-6.229 2.439 2.439 0 00-2.4-2.77zM3.147.758c.076.004.152.015.227.033a6.773 6.773 0 015 5.09.643.643 0 001.252 0 6.773 6.773 0 015-5.09 1.154 1.154 0 011.428 1.155 1.155 1.155 0 011.155 1.428 6.773 6.773 0 01-5.09 5 .643.643 0 000 1.253 6.773 6.773 0 015.09 5 1.153 1.153 0 01-1.155 1.427 1.154 1.154 0 01-1.428 1.155 6.774 6.774 0 01-5-5.09.643.643 0 00-1.253 0 6.774 6.774 0 01-5 5.09 1.156 1.156 0 01-1.427-1.155 1.154 1.154 0 01-1.155-1.428 6.774 6.774 0 015.09-5 .643.643 0 000-1.252 6.773 6.773 0 01-5.09-5 1.154 1.154 0 011.155-1.428 1.154 1.154 0 011.2-1.188z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M9.482 9.482L9 10.93l-.482-1.447L7.07 9l1.447-.482L9 7.07l.482 1.447L10.928 9l-1.446.482z"
        fill="var(--ion-color-dark)"
      />
    </svg>
  );
}

export default SvgLilacIcon;
