import * as React from 'react';

interface HideableProps {
  children: React.ReactNode;
  hide: boolean;
}

export function Hideable(props: HideableProps): JSX.Element {
  const {
    children = (<></>),
    hide
  } = props;
  if (hide) {
    return (<></>);
  }
  else {
    return <>{children}</>;
  }
}
