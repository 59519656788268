import * as yup from 'yup';

export const schema = yup.object().shape({
  email: yup.string()
    .required('general.assistiveText.required')
    .email('userRegistrationForm.validation.emailInvalid'),
  fullName: yup.string()
    .required('general.assistiveText.required'),
  password: yup.string()
    .required('general.assistiveText.required'),
  passwordConfirm: yup.string()
    .required('general.assistiveText.required')
    .test(
      'passwordsMustMatch',
      'userRegistrationForm.validation.passwordsDoNotMatch',
      function () {
        return this.parent.password === this.parent.passwordConfirm;
      }
    )
});
