import { ApiCall, CustomHookProps, useBasicMutation } from 'napa-react-core';
import { UseMutationResult } from 'react-query/types/react/types';

export default function useUnPublishTastingRanks(
  tastingId: string | undefined,
  props?: CustomHookProps<void>,
): UseMutationResult<void, ApiCall<void>> {
  return useBasicMutation<void>({
    apiRoute: `tastings/${tastingId}/unpublishRanks`,
    httpMethod: 'PUT',
    isFormData: false,
    params: props,
  });
}
