import styled from 'styled-components';
import { color, layout, space, system, typography } from 'styled-system';
import { ellipsis, transient } from 'utils/styledHelpers';

const Paragraph = styled('p')`
  ${transient(color)}
  ${transient(layout)}
  ${transient(space)}
  ${transient(system({ whiteSpace: true }))}
  ${transient(typography)}
  ${ellipsis}
`;

Paragraph.defaultProps = {
  $fontWeight: 300,
  $lineHeight: 1.25,
};

export { Paragraph };
