export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';

export const isValidFileId = (fileId?: string): boolean => !!fileId && fileId !== EMPTY_GUID;

export const getImageUrl = (apiBaseUrl: string, fileId?: string, defaultUrl?: string): string => {
  if (!apiBaseUrl || !isValidFileId(fileId)) {
    return defaultUrl || '';
  }
  return `${apiBaseUrl}/files/${fileId}/contents`;
};

export const removeDiacritics = (str: string): string => {
  if (!str) {
    return '';
  }
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};
