import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgFigIcon(props) {
  return (
    <svg width={16} height={18} fill="none" {...props}>
      <path
        d="M8.688 5.251h-.013a.325.325 0 01-.313-.34c.036-.948.366-1.829.927-2.484.58-.676 1.343-1.034 2.14-1.005.178.007.319.16.312.34a.318.318 0 01-.339.312c-.6-.016-1.17.255-1.618.777-.466.544-.74 1.285-.77 2.084a.33.33 0 01-.326.316z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M8.763 3.643L7.43 3.444a2.49 2.49 0 01-2.11-2.11L5.12 0l1.334.199a2.49 2.49 0 012.11 2.11l.2 1.334zM5.897.776l.068.463c.12.812.747 1.442 1.562 1.563l.463.068-.068-.463A1.844 1.844 0 006.36.845L5.897.776zM12.778 13.35a.323.323 0 01-.316-.404c.261-1.032.264-2.105.013-3.1a.325.325 0 01.63-.16 7.008 7.008 0 01-.013 3.418.323.323 0 01-.314.245zM12.168 8.542a.32.32 0 01-.283-.167 5.668 5.668 0 00-.551-.805.33.33 0 01.045-.46.33.33 0 01.46.046c.228.28.434.58.613.897a.326.326 0 01-.124.443.312.312 0 01-.16.046z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M6.85 18A6.857 6.857 0 010 11.15a6.857 6.857 0 016.85-6.848c1.989 0 8.479.492 8.479 6.849 0 6.07-4.534 6.849-8.48 6.849zm0-13.046a6.202 6.202 0 00-6.198 6.197 6.202 6.202 0 006.197 6.197c3.871 0 7.828-.737 7.828-6.197 0-5.593-5.473-6.197-7.828-6.197z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M5.87 17.022A5.877 5.877 0 010 11.15a5.877 5.877 0 015.87-5.87 5.877 5.877 0 015.871 5.87 5.877 5.877 0 01-5.87 5.87zm0-11.09a5.225 5.225 0 00-5.218 5.219 5.225 5.225 0 005.219 5.218 5.225 5.225 0 005.218-5.218 5.225 5.225 0 00-5.218-5.218z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M5.87 13.395a.327.327 0 01-.326-.326V9.233a.327.327 0 01.652 0v3.836c0 .179-.146.326-.325.326z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M4.514 12.834a.325.325 0 01-.232-.554l2.71-2.71a.325.325 0 11.46.459l-2.71 2.71a.32.32 0 01-.228.095z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M7.788 11.477H3.953a.327.327 0 01-.326-.326c0-.18.146-.326.326-.326h3.835c.18 0 .326.147.326.326 0 .18-.146.326-.326.326z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M7.227 12.834a.334.334 0 01-.231-.095l-2.71-2.71a.325.325 0 11.46-.46l2.71 2.71a.325.325 0 01-.229.555zM7.501 7.89a.326.326 0 100-.653.326.326 0 000 .652zM9.458 9.846a.326.326 0 100-.652.326.326 0 000 .652zM9.458 13.108a.326.326 0 100-.652.326.326 0 000 .652zM7.501 15.065a.326.326 0 100-.653.326.326 0 000 .653zM6.85 13.76a.326.326 0 100-.652.326.326 0 000 .652zM8.154 12.456a.326.326 0 100-.653.326.326 0 000 .652zM8.154 10.499a.326.326 0 100-.653.326.326 0 000 .653zM6.85 9.194a.326.326 0 100-.652.326.326 0 000 .652zM4.892 9.194a.326.326 0 100-.652.326.326 0 000 .652zM3.588 10.499a.326.326 0 100-.653.326.326 0 000 .653zM3.588 12.456a.326.326 0 100-.653.326.326 0 000 .652zM4.892 13.76a.326.326 0 100-.652.326.326 0 000 .652zM4.24 15.065a.326.326 0 100-.653.326.326 0 000 .653zM2.283 13.108a.326.326 0 100-.652.326.326 0 000 .652zM2.283 9.846a.326.326 0 100-.652.326.326 0 000 .652zM4.24 7.89a.326.326 0 100-.653.326.326 0 000 .652zM2.935 8.542a.326.326 0 100-.653.326.326 0 000 .653zM8.806 8.542a.326.326 0 100-.653.326.326 0 000 .653zM2.935 14.412a.326.326 0 100-.652.326.326 0 000 .652zM8.48 14.086a.326.326 0 100-.652.326.326 0 000 .652zM5.87 15.39a.326.326 0 100-.651.326.326 0 000 .652zM5.87 7.563a.326.326 0 100-.652.326.326 0 000 .652zM9.784 11.477a.326.326 0 100-.652.326.326 0 000 .652zM1.957 11.477a.326.326 0 100-.652.326.326 0 000 .652z"
        fill="var(--ion-color-dark)"
      />
    </svg>
  );
}

export default SvgFigIcon;
