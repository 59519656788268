import { ApiCall, CustomHookProps, useBasicMutation } from 'napa-react-core';
import { UseMutationResult } from 'react-query/types/react/types';

export default function useDeleteTastingWines(
  wineId?: string | undefined,
  props?: CustomHookProps<any>
): UseMutationResult<any, ApiCall<any>> {
  return useBasicMutation<any>({
    apiRoute: `wines/${wineId}`,
    httpMethod: 'DELETE',
    isFormData: false,
    body: undefined,
    params: props
  });
}
