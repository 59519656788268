import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgRoseIcon(props) {
  return (
    <svg width={15} height={18} fill="none" {...props}>
      <path
        d="M2.882 1.176A6.614 6.614 0 000 1.97a4.705 4.705 0 012.422 3.137c.261 1.155.581 2.295.96 3.417a3.865 3.865 0 003.755 2.629 2.618 2.618 0 002.508-2.127 6.614 6.614 0 00-6.763-7.849zm.026.643a5.97 5.97 0 016.106 7.085 1.976 1.976 0 01-1.892 1.605A3.223 3.223 0 013.99 8.317c-.37-1.1-.685-2.22-.941-3.353a5.348 5.348 0 00-1.72-2.866 5.97 5.97 0 011.579-.279z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M12.045 4.748c-.463 1.257-.91 2.52-1.343 3.787a3.865 3.865 0 01-1.341 1.847 2.94 2.94 0 00.6-1.296c.041-.215.072-.43.093-.648l.04-.11c.434-1.273.883-2.54 1.348-3.803a8.472 8.472 0 011.575-2.65A8.086 8.086 0 008.915 3.93a6.934 6.934 0 00-.384-.52 8.73 8.73 0 016.27-2.297 7.83 7.83 0 00-2.755 3.635zM4.94 1.085A6.935 6.935 0 003.818.882a7.714 7.714 0 017.78.36 9.057 9.057 0 00-.764.29 7.071 7.071 0 00-5.894-.447z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M7.87 18c.052-1.454.048-2.91-.014-4.363a1.928 1.928 0 011.155-1.848 4.91 4.91 0 001.034-.612 1.574 1.574 0 012.197.272 1.503 1.503 0 00-2.536-.823 4.269 4.269 0 01-5.324 0 1.502 1.502 0 00-2.537.823 1.574 1.574 0 012.198-.272c.454.35.965.617 1.51.791a1.928 1.928 0 011.342 1.758c.059 1.424.062 2.85.01 4.274h.964z"
        fill="var(--ion-color-dark)"
      />
    </svg>
  );
}

export default SvgRoseIcon;
