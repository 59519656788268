import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgMelonIcon(props) {
  return (
    <svg width={18} height={15} fill="none" {...props}>
      <path
        d="M4.537 11.282c-1.806 0-3.28-.698-4.026-1.928C-.901 7.015.718 3.45 4.1 1.399c3.384-2.05 7.306-1.82 8.72.518 1.28 2.12.077 5.312-2.797 7.43a.18.18 0 01-.214-.29c2.725-2.006 3.887-4.996 2.698-6.955-1.311-2.158-5.001-2.338-8.225-.395C1.06 3.649-.493 7.015.818 9.174c.749 1.238 2.353 1.883 4.289 1.725a.18.18 0 11.029.358 7.547 7.547 0 01-.6.025z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M4.485 10.133c-1.355-.02-2.397-.518-2.931-1.402-.534-.883-.491-2.038.12-3.238.612-1.2 1.672-2.284 3.026-3.103C7.52.685 10.698.76 11.785 2.554c.54.885.49 2.038-.12 3.247-.612 1.209-1.677 2.276-3.032 3.094-.171.105-.35.203-.527.297a.18.18 0 11-.166-.32c.18-.088.342-.18.506-.284 1.299-.785 2.326-1.835 2.885-2.958.56-1.122.603-2.124.133-2.898-.975-1.626-3.93-1.653-6.582-.034-1.297.784-2.322 1.835-2.89 2.957-.555 1.087-.602 2.116-.134 2.89.468.773 1.401 1.21 2.637 1.228a.18.18 0 010 .36h-.01z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M5.015 3.667a.462.462 0 00.54.139.46.46 0 00.13-.54c-.07-.117-.28-.12-.465-.009-.185.112-.277.293-.205.41zM8.334 7.64a.464.464 0 00-.54-.14.468.468 0 00-.129.539c.072.117.28.12.464.009.186-.112.276-.292.205-.408zM3.33 7.963c.18-.111.485-.422.415-.54-.07-.116-.485 0-.67.118-.186.117-.28.298-.21.415.07.117.28.12.465.007zM10.018 3.342c-.18.111-.485.423-.413.54.071.116.483 0 .67-.116.188-.115.278-.298.207-.415-.07-.117-.278-.114-.464-.01zM10.405 4.651c-.196.04-.6.242-.631.398-.033.157.32.21.516.18a.54.54 0 00.412-.36c.032-.16-.1-.255-.297-.218zM2.952 6.668c.196-.038.6-.241.631-.398.03-.156-.32-.21-.516-.18a.54.54 0 00-.412.36c-.032.16.1.258.297.218zM6.913 2.784c-.03.158.067.43.263.39.196-.04.415-.372.448-.53.032-.159-.103-.254-.299-.214a.54.54 0 00-.412.354zM8.74 2.658c-.125.157-.242.493-.087.54.154.047.52-.22.646-.376.126-.157.102-.318-.053-.36a.54.54 0 00-.505.196zM9.784 6.218c-.154-.046-.552 0-.678.15-.126.149.07.368.223.413a.54.54 0 00.508-.2c.124-.156.1-.318-.053-.363zM3.565 5.089c.153.045.553 0 .679-.15.125-.15-.07-.369-.225-.414a.529.529 0 00-.506.2c-.126.155-.097.311.052.364zM13.095 8.706a.18.18 0 01-.165-.252 7.137 7.137 0 00-.417-6.353.18.18 0 01.197-.268.18.18 0 01.111.082 7.498 7.498 0 01.439 6.678.18.18 0 01-.165.113zM6.672 12.831A7.225 7.225 0 01.511 9.354a.18.18 0 11.307-.18 6.847 6.847 0 005.937 3.297.18.18 0 010 .36h-.083zM11.948 9.04a.18.18 0 01-.18-.165 2.92 2.92 0 00-.338-1.14.18.18 0 01.308-.187c.22.4.353.842.39 1.297a.181.181 0 01-.096.176.18.18 0 01-.067.02l-.017-.002z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M10.792 14.031A7.033 7.033 0 013.887 8.49a.36.36 0 01.267-.429L5 7.846a.583.583 0 01.653.286c.982 1.787 3.632 2.879 5.908 2.443 2.274-.44 4.317-2.444 4.567-4.47a.585.585 0 01.502-.51l.864-.116a.369.369 0 01.408.299 7.06 7.06 0 01-5.7 8.111c-.465.093-.938.14-1.411.142zM5.143 8.188a.317.317 0 00-.056 0l-.845.214a6.696 6.696 0 007.892 5.127 6.701 6.701 0 005.413-7.7l-.867.118a.221.221 0 00-.193.194c-.266 2.205-2.4 4.305-4.857 4.78-2.457.484-5.222-.668-6.29-2.616a.225.225 0 00-.197-.117z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M7.985 9.257a.14.14 0 01-.04 0 10.52 10.52 0 01-2.698-1.033.18.18 0 11.171-.316c.817.45 1.693.786 2.601.998a.18.18 0 11-.037.36l.003-.01z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M9.77 9.458a8.275 8.275 0 01-1.824-.207.184.184 0 11.077-.36c1.679.39 3.44.2 4.996-.54a.18.18 0 11.153.327 7.973 7.973 0 01-3.402.78z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M13.095 8.706a.18.18 0 01-.076-.343 9.222 9.222 0 003.199-2.5.18.18 0 11.29.213 9.442 9.442 0 01-3.336 2.612.18.18 0 01-.078.018z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M10.808 13.182a6.17 6.17 0 01-6.063-4.857.18.18 0 11.351-.077 5.838 5.838 0 006.878 4.46 5.834 5.834 0 004.715-6.706.182.182 0 11.36-.059 6.195 6.195 0 01-4.998 7.12c-.41.08-.826.119-1.243.119z"
        fill="var(--ion-color-dark)"
      />
    </svg>
  );
}

export default SvgMelonIcon;
