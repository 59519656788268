import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgRaisinIcon(props) {
  return (
    <svg width={18} height={18} fill="none" {...props}>
      <path
        d="M16.204 1.893C13.88-.7 10.107-.345 6.455 1.254c-1.18.516-2.53.45-3.575 1.388C.486 4.789.71 7.389.512 10.03c-.156 2.084-1.172 4.265.185 5.777 2.102 2.344 6.36 2.737 9.46 1.52 1.435-.563 2.868-2.662 4.224-3.879 1.695-1.52 1.022-2.712 1.79-4.516 1.174-2.752 1.892-4.968.033-7.04zM5.412 8.754c-3.3 2.55-.783 7.583-.783 7.583-.478-.31-3.4-5.343.213-8.131 3.672-2.832 5.573-6.83 5.573-6.83S9.285 5.76 5.412 8.754zm7.216.383c-2.957 1.445-5.4 5.098-5.4 5.098s1.479-3.846 4.985-5.618c3.136-1.584 1.48-6.249 1.48-6.249.365.312 2.39 5.082-1.065 6.77z"
        fill="var(--ion-color-dark)"
      />
    </svg>
  );
}

export default SvgRaisinIcon;
