import { ApiCall, CustomHookProps, useBasicMutation } from 'napa-react-core';
import { UseMutationResult } from 'react-query/types/react/types';
import { Wine } from 'store/wines';

export default function usePostWine(
  props?: CustomHookProps<Wine>,
): UseMutationResult<Wine, ApiCall<Wine>> {
  return useBasicMutation<Wine>({
    apiRoute: 'wines',
    httpMethod: 'POST',
    isFormData: false,
    params: props,
  });
}
