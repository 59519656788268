import { IonButtons, IonHeader, IonIcon, IonToolbar, isPlatform } from '@ionic/react';
import { IonButton, IonTitle } from 'components/ionicComponents';
import { ScoreWineStepAlert } from 'components/wineScores/ScoreWineStepAlert';
import { close } from 'ionicons/icons';
import * as React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { hideModal } from 'utils/modals';

interface ScoreAWineHeaderProps {
  modalId: string;
  onSaveAndClose: (data: any) => any;
  stepName: string;
}

function ScoreAWineHeader(props: ScoreAWineHeaderProps): JSX.Element {
  const {
    modalId,
    stepName,
  } = props;
  const dispatch = useDispatch();
  const [showDiscardAlert, setShowDiscardAlert] = useState(false);
  return (
    <IonHeader>
      <ScoreWineStepAlert
        isOpen={showDiscardAlert}
        onDidDismiss={(): void => setShowDiscardAlert(false)}
        onDiscard={(): void => {
          setShowDiscardAlert(false);
          setTimeout(() => {
            hideModal(dispatch, modalId, {});
          }, 300);
        }}
      />
      <IonToolbar color="primary">
        <IonButtons slot="start">
          <IonButton onClick={(): void => setShowDiscardAlert(true)}>
            <IonIcon icon={close} slot="icon-only" />
          </IonButton>
        </IonButtons>
        <IonTitle
          px={isPlatform('ios') && 'max(45px, 15vw)'}
          textAlign={isPlatform('ios') && ['left', 'center']}
        >
          {stepName}
        </IonTitle>
      </IonToolbar>
    </IonHeader>
  );
}

export { ScoreAWineHeader };
