import { IonButtons, IonContent, IonHeader, IonIcon, IonModal, IonToolbar } from '@ionic/react';
import { IonButton, IonTitle } from 'components/ionicComponents';
import GeoLocationFilterContainer from 'containers/filters/GeoLocationFilterContainer';
import { close } from 'ionicons/icons';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { hideModal } from 'utils/modals';
import { Hideable } from '../../components/baseElements/hideable';
import { GeoLocationFilterState } from '../../store/geoLocation';

export const GeoLocationFilterModalId = 'GeoLocationFilter';

export default function GeoLocationFilterModal(): JSX.Element {
  const dispatch = useDispatch();
  const modalProps = useSelector((state: RootState) =>
    state.gui.modals.find(e => e.id === GeoLocationFilterModalId),
  );
  return (
    <IonModal
      isOpen={modalProps?.show || false}
      onDidDismiss={(): void => hideModal(dispatch, GeoLocationFilterModalId, {})}
    >
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={(): void => hideModal(dispatch, GeoLocationFilterModalId, {})}>
              <IonIcon icon={close} slot="icon-only"/>
            </IonButton>
          </IonButtons>
          <IonTitle>
            <FormattedMessage id="geoLocationFilterModal.header.title"/>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <Hideable hide={!modalProps?.show}>
          <GeoLocationFilterContainer
            distanceFromZipCode={modalProps?.params?.distanceFromZipCode}
            userId={modalProps?.params?.userId}
            zipCode={modalProps?.params?.zipCode}
            successHandler={(geoLocationFilterState?: GeoLocationFilterState): void => hideModal(
              dispatch,
              GeoLocationFilterModalId,
              modalProps?.params,
              () => modalProps?.callback && modalProps.callback(geoLocationFilterState),
            )}
          />
        </Hideable>
      </IonContent>
    </IonModal>
  );
}
