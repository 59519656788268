import { ToastProperties } from '../types';

export const GUI_STATE_UPDATE_TOAST = 'GUI_STATE_UPDATE_TOAST';

interface Payload {
  toastProps: ToastProperties;
}

export interface GuiStateUpdateToastAction {
  type: typeof GUI_STATE_UPDATE_TOAST;
  payload: Payload;
}
