import React, { useEffect } from 'react';

export const useAutoInputFocus = <T extends HTMLIonSearchbarElement | HTMLIonInputElement>(
  ref: React.RefObject<T>,
  enabled = true,
  inputFocusTimeout = 500,
): void => {
  useEffect(() => {
    if (enabled && ref?.current?.getInputElement) {
      let attempts = 0;
      let interval: any;
      const intervalTimeout = 50;
      ref.current.getInputElement().then((el: HTMLInputElement) => {
        el.focus();
        // Ionic seems to remove focus within the first few milliseconds, so wait before setting focus. This delay is
        // configurable per usage.
        setTimeout(() => {
          // Set focus 10 times within the next 0.5 seconds
          interval = setInterval(() => {
            if (el) {
              el.focus();
            }
            attempts += 1;
            if (attempts > 10) {
              clearInterval(interval);
              interval = null;
            }
          }, intervalTimeout);
        }, inputFocusTimeout);
      });
    }
  }, [enabled, inputFocusTimeout, ref]);
}
