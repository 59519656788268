import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgLeatherIcon(props) {
  return (
    <svg width={18} height={14} fill="none" {...props}>
      <path
        d="M14.568 11.09v.422s-.01.054-.03.074a.1.1 0 01-.073.03H13.8a.314.314 0 100 .628h.666a.733.733 0 00.732-.732l-.002-.423a.314.314 0 00-.627.002zM15.122 1.884a.262.262 0 00-.262-.262H2.512c-.131 0-.25.011-.364.043a.67.67 0 00-.47.44 1.269 1.269 0 00-.056.407v.418a.262.262 0 00.523 0v-.418c0-.1.01-.174.023-.221a.211.211 0 01.032-.07c.015-.018.027-.03.074-.047a.76.76 0 01.237-.029h12.35a.261.261 0 00.261-.261z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M16.744 4.814V1.256A1.258 1.258 0 0015.488 0H1.256A1.258 1.258 0 000 1.256v11.302a1.258 1.258 0 001.256 1.256h14.232a1.258 1.258 0 001.256-1.256V9A1.258 1.258 0 0018 7.744V6.07a1.258 1.258 0 00-1.256-1.256zm-.837 7.744a.416.416 0 01-.123.296.417.417 0 01-.296.123H1.256a.416.416 0 01-.295-.123.416.416 0 01-.124-.296V1.256c0-.115.046-.218.124-.296a.415.415 0 01.295-.123h14.232c.115 0 .218.046.296.123.077.078.123.18.123.296v3.558h-3.558a2.093 2.093 0 000 4.186h3.558v3.558zm1.256-4.814a.416.416 0 01-.123.296.417.417 0 01-.296.123H12.35c-.348 0-.66-.14-.888-.368a1.25 1.25 0 01-.368-.888c0-.348.14-.66.368-.888a1.25 1.25 0 01.888-.368h4.395c.115 0 .218.046.296.123.076.078.123.18.123.296v1.674z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M12.558 7.535a.628.628 0 100-1.256.628.628 0 000 1.256z"
        fill="var(--ion-color-dark)"
      />
    </svg>
  );
}

export default SvgLeatherIcon;
