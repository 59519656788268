import { ImageOptions, CameraSource } from '@capacitor/camera';
import { Flex } from 'components/baseElements/grid';
import { Heading, Paragraph } from 'components/baseElements/typography';
import { GrapeUploadImageButton } from 'napa-react-core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CameraApiSettings } from 'store/appSettings';
import { User } from 'store/users';
import { toMyUserVm } from 'store/users/utils/mappers';
import { Box } from '../../baseElements/grid';
import UserAvatar from '../../baseElements/User/UserAvatar';
import MyProfileBannerBg from './my-profile-banner-bg.jpg';

interface MyProfileBannerProperties {
  cameraApiSettings?: CameraApiSettings;
  onUploadError?: () => void;
  onUploadSuccess?: (ids: Array<string>) => void;
  uploadUrl: string;
  user?: User;
}

export default function MyProfileBanner(props: MyProfileBannerProperties): JSX.Element {
  const {
    cameraApiSettings,
    onUploadError,
    onUploadSuccess,
    uploadUrl,
    user,
  } = props;

  const handleUploadSuccess = (ids: Array<string>): void => {
    if (onUploadSuccess) {
      onUploadSuccess(ids);
    }
  };
  const vm = user ? toMyUserVm(user as User) : user;
  const textColor = 'var(--ion-color-light)';
  const backgroundColor = 'rgba(var(--ion-color-dark-rgb), 0.84)';
  return (
    <>
      <Flex
        backgroundImage={`url(${MyProfileBannerBg})`}
        backgroundPosition="center"
        backgroundSize="cover"
        flexDirection="row"
        overflowY="visible"
      >
        <Flex
          backgroundColor={backgroundColor}
          color="var(--ion-color-dark-shade)"
          flexDirection="column"
          py={3}
          position="relative"
        >
          <Box position="relative" height="100%" width="112px">
            <Box
              left="8px"
              position="absolute"
              top="-2px"
              width="50px"
              slot="start"
            >
            <GrapeUploadImageButton
              color="secondary"
              data-cy="changeProfilePhoto"
              imageName="profilePhoto"
              onUploadError={onUploadError}
              onUploadSuccess={handleUploadSuccess}
              options={{
                quality: cameraApiSettings?.imageUploadQuality,
                source: CameraSource.Prompt,
                width: cameraApiSettings?.uploadImageTargetWidth,
              } as ImageOptions}
              renderChildrenOnly
              shape="round"
              uploadUrl={uploadUrl}
            >
              <UserAvatar
                avatarId={vm?.photoId}
                border={6}
                fullName={vm?.fullName}
                headingSize="2xl"
                size={96}
              />
            </GrapeUploadImageButton>
            </Box>
          </Box>
        </Flex>
        <Flex
          backgroundColor={backgroundColor}
          flexDirection="column"
          minHeight="93px"
          py={3}
          width={1}
        >
          <Heading
            color={textColor}
            headingSize="xs"
            m={0}
          >
            {vm?.fullName}
          </Heading>
          <Paragraph
            color={textColor}
            $fontWeight={600}
            m={0}
          >
            {vm?.email}
          </Paragraph>
          <Paragraph
            color={textColor}
            $fontWeight={600}
            m={0}
          >
            <FormattedMessage id="userRegistrationForm.labels.userSignDate"/> {vm?.createdAt}
          </Paragraph>
        </Flex>
      </Flex>
      <Box height="30px">&nbsp;</Box>
    </>
  );
}
