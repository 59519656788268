import { IonButton, IonModal } from '@ionic/react';
import jwtDecode from 'jwt-decode';
import { AuthorizationStore } from 'napa-react-core';
import React, { useContext, useEffect } from 'react';
import IdleTimer from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'store';
import { TokenResponse } from 'store/authorization';
import { authorizationSetToken } from 'store/authorization/actions/authorizationSetToken';
import { makeApiCall } from 'utils/api';

export default function SessionManagementContainer(): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const authData = useContext(AuthorizationStore);
  const REFRESH_TOKEN_KEY = 'REFRESH_TOKEN_KEY';
  const auth = useSelector((state: RootState) => state.authorization);
  const appSettings = useSelector((state: RootState) => state.appSettings);
  const apiBaseUrl = useSelector((state: RootState): string => state.appSettings.apiBaseUrl);
  const {
    sessionExpirationInMinutes,
    sessionExpirationWarningInMinutes,
    refreshServerTokenThresholdInMinutes,
  } = appSettings.securitySettings;
  const [
    showSessionExpirationWarning,
    setShowSessionExpirationWarning,
  ] = React.useState(false);
  const [
    secondsBeforeSessionExpiration,
    setSecondsBeforeSessionExpiration,
  ] = React.useState(
    (sessionExpirationInMinutes - sessionExpirationWarningInMinutes) * 60,
  );
  const [idleIntervalId, setIdleIntervalId] = React.useState({} as any);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let idleTimer: IdleTimer | null = null;
  const handleLogout = (): void => {
    setShowSessionExpirationWarning(false);
    localStorage.removeItem('authToken');
    dispatch(authorizationSetToken({}));
    // New boilerplate way to set auth state
    authData.setState({});
    history.push('/tastings');
  };
  useEffect(() => {
    if (!auth || !auth.tokenData) {
      setShowSessionExpirationWarning(false);
    }
  }, [auth]);
  const updateIdleTime = (lastActiveTime: number): void => {
    if (lastActiveTime) {
      const now = new Date().getTime();
      const expirationTime =
        lastActiveTime + sessionExpirationInMinutes * 60 * 1000;
      const expirationInSeconds = expirationTime - now;
      if (expirationInSeconds <= 0) {
        handleLogout();
      } else {
        setSecondsBeforeSessionExpiration(expirationInSeconds / 1000);
      }
    }
  };
  const idleHandler = (): void => {
    if (!auth.token || sessionExpirationInMinutes === 0) {
      return;
    }
    if (sessionExpirationWarningInMinutes === 0) {
      handleLogout();
    }
    const thisIdleTimer = idleTimer;
    setIdleIntervalId(
      setInterval(
        () => updateIdleTime(thisIdleTimer?.getLastActiveTime() || 0),
        1000,
      ),
    );
    setShowSessionExpirationWarning(true);
  };
  const refreshToken = (token?: string): void => {
    if (!localStorage.getItem('authToken')) {
      return;
    }
    const currentToken = token || JSON.parse(localStorage.getItem('authToken') || '{}');
    const now = new Date();
    const expiration = now.setMinutes(
      now.getMinutes() + sessionExpirationInMinutes,
    );
    localStorage.setItem('authToken', JSON.stringify({
      token: currentToken.token || currentToken,
      expires: sessionExpirationInMinutes > 0 ? new Date(expiration) : undefined,
    }));

  };
  const handleSessionExtension = (): void => {
    clearInterval(idleIntervalId);
    setShowSessionExpirationWarning(false);
    refreshToken();
  };
  const refreshServerTokenExpiration = async (): Promise<void> => {
    try {
      const result = await makeApiCall<TokenResponse>({
        authToken: auth.token,
        showSuccessMessage: false,
        dispatch: dispatch,
        callId: REFRESH_TOKEN_KEY,
        request: {
          url: `${apiBaseUrl}/tokens/refresh`,
          httpMethod: 'POST',
          // body: {},
          // TODO: Check that this works!!!
        },
      });
      refreshToken(result.token);
    } catch (e) {
      handleLogout();
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
  const handleUserAction = (e: any): void => {
    const tokenData = localStorage.getItem('authToken') ? jwtDecode(localStorage.getItem('authToken') || '{}') : {};
    if (e.srcElement?.innerText === 'Logout' || !tokenData || !(tokenData as any)?.exp) {
      return;
    }
    const serverExpiration = (tokenData as any)?.exp;
    if (serverExpiration) {
      if (new Date(serverExpiration * 1000) <= new Date()) {
        handleLogout();
        return;
      }
      const refreshCutoff = (serverExpiration * 1000) - (refreshServerTokenThresholdInMinutes * 60 * 1000);
      if (new Date(refreshCutoff) <= new Date()) {
        refreshServerTokenExpiration()
          // eslint-disable-next-line no-console
          .catch(console.error);
        return;
      }
      refreshToken();
    }
  };
  const padLeadingZeros = (num: any, size: number): string => {
    let s = `${num}`;
    while (s.length < size) s = `0${s}`;
    return s;
  };

  return (
    <div>
      <IdleTimer
        ref={(ref: any): void => {
          idleTimer = ref;
        }}
        element={document}
        onIdle={idleHandler}
        onAction={handleUserAction}
        debounce={250}
        timeout={1000 * 60 * (sessionExpirationWarningInMinutes || sessionExpirationInMinutes)}
      />
      <IonModal isOpen={showSessionExpirationWarning} cssClass="my-custom-class">
        <h2>Your Session is About to Expire</h2>
        <p>
          Your session will expire in&nbsp;
          {parseInt((secondsBeforeSessionExpiration / 60).toString())}:
          {padLeadingZeros(
            parseInt((secondsBeforeSessionExpiration % 60).toString()),
            2,
          )}
          .
        </p>
        <IonButton onClick={(): void => handleLogout()}>Logout</IonButton>
        <IonButton onClick={(): void => handleSessionExtension()}>Stay Logged In</IonButton>
      </IonModal>
    </div>
  );
}
