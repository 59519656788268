import { TastingWithAttendee } from '../tastings';
import { ETastingAttendeeButtons, TastingAttendee } from './types';

export const tastingAttendeeCantGo = (t?: TastingAttendee): boolean => {
  // If you are invited (true), you must say you are not going (false)
  // If you are not invited (false || null), you must say you are not are (false)
  return t ? (!t.isHost && t.isGoing === false) : false;
};

export const tastingAttendeeIsGoing = (t?: TastingAttendee): boolean => {
  // If you are invited (true), you must say you are going (true)
  // If you are not invited (false || null), you are going (true || null) as long as you don't say no (false)
  return t ? (!t.isHost && ((t.invited === true && t.isGoing === true) || (!t.invited && t.isGoing !== false))) : false;
};

export const tastingAttendeeIsInvited = (t?: TastingAttendee): boolean => {
  return t ? (!t.isHost && t.isGoing === null && !!t.invited) : false;
};

export const getTastingAttendeesWhoCantGo = (tastingAttendees?: TastingAttendee[]): TastingAttendee[] => {
  return tastingAttendees ? tastingAttendees.filter(tastingAttendeeCantGo) : [];
};

export const getTastingAttendeesWhoAreGoing = (tastingAttendees?: TastingAttendee[]): TastingAttendee[] => {
  return tastingAttendees ? tastingAttendees.filter(tastingAttendeeIsGoing) : [];
};

export const getTastingAttendeesWhoAreGoingWithMax = (tastingAttendees?: TastingAttendee[], maxAttendeesCount: number | null = null): string => {
  return ` (${getTastingAttendeesWhoAreGoing(tastingAttendees)?.length || 0}${maxAttendeesCount ? '/' + maxAttendeesCount : ''})`;
};

export const getTastingAttendeesWhoAreInvited = (tastingAttendees?: TastingAttendee[]): TastingAttendee[] => {
  return tastingAttendees ? tastingAttendees.filter(tastingAttendeeIsInvited) : [];
};

export const sortTastingAttendees = (a: TastingAttendee, b: TastingAttendee): number => {
  if (!a.fullName && !a.email) return 1;
  if (!b.fullName && !b.email) return -1;
  return (a.fullName || a.email?.toUpperCase() || '') < (b.fullName || b.email?.toUpperCase() || '') ? -1 : 1;
};

export const getTastingAttendeeButtons = (vm?: TastingWithAttendee): ETastingAttendeeButtons => {
  // No tasting, then do not show the buttons yet
  if (!vm?.tasting) {
    return ETastingAttendeeButtons.None;
  }
  // Tasting has ended
  if (vm.tasting.hasEnded) {
    return ETastingAttendeeButtons.Ended;
  }
  // The host sees the tasting room or no footer
  if (vm.attendee?.isHost) {
    return !!vm.tasting.isHappeningNow ? ETastingAttendeeButtons.TastingRoom : ETastingAttendeeButtons.None;
  }
  // If the user is going, they can change their status
  if (tastingAttendeeIsGoing(vm.attendee)) {
    return !!vm.tasting.isHappeningNow ? ETastingAttendeeButtons.TastingRoom : ETastingAttendeeButtons.ChangeStatus;
  }
  // If the tasting is full, no one else can enter
  const tastingAttendeesGoing = vm.attendeeList ? vm.attendeeList.filter((tastingAttendee: TastingAttendee): boolean => {
    return tastingAttendeeIsGoing(tastingAttendee);
  }) : [];
  const tastingIsFull = !!vm.tasting.maxAttendeesCount && vm.tasting.maxAttendeesCount <= tastingAttendeesGoing.length;
  if (tastingIsFull) {
    return ETastingAttendeeButtons.Full;
  }
  // Users can click "I'm going" if not invited
  if (!vm.attendee) {
    return ETastingAttendeeButtons.NotInvited;
  }
  // This user needs to indicate whether they are going or not
  if (tastingAttendeeIsInvited(vm.attendee)) {
    return ETastingAttendeeButtons.Invited;
  }
  // If the user can't go, they can change their status
  if (tastingAttendeeCantGo(vm.attendee)) {
    return ETastingAttendeeButtons.ChangeStatus;
  }
  // Users can click "I'm going" if not invited
  return ETastingAttendeeButtons.NotInvited;
};
