import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgBellPepperIcon(props) {
  return (
    <svg width={15} height={18} fill="none" {...props}>
      <path
        d="M11.667 3.735a3.412 3.412 0 00-2.282.429 4.182 4.182 0 00-1.628-.536C7.685 2.548 7.335.98 5.98.078a.47.47 0 10-.52.783c1.005.67 1.29 1.878 1.356 2.767a4.214 4.214 0 00-1.631.536A3.443 3.443 0 00.044 7.67l1.177 7.422a3.444 3.444 0 003.944 2.864c.244-.042.482-.11.71-.203.91.33 1.908.33 2.818 0 .23.094.47.162.714.203a3.444 3.444 0 003.941-2.858l1.177-7.428a3.444 3.444 0 00-2.858-3.935zM2.147 14.95L.97 7.531A2.504 2.504 0 014.342 4.8a4.207 4.207 0 00-1.284 3.027v5.948a4.22 4.22 0 001.566 3.284 2.504 2.504 0 01-2.477-2.107zm1.85-1.177V7.826a3.287 3.287 0 016.574 0v5.948a3.287 3.287 0 11-6.573 0zm9.601-6.242l-1.177 7.416A2.504 2.504 0 019.94 17.06a4.221 4.221 0 001.565-3.284V7.83a4.206 4.206 0 00-1.283-3.027 2.504 2.504 0 013.371 2.733l.006-.003z"
        fill="var(--ion-color-dark)"
      />
    </svg>
  );
}

export default SvgBellPepperIcon;
