/**
 * Create the store with dynamic reducers
 */

import { applyMiddleware, createStore, compose, StoreEnhancer } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { composeWithDevTools } from 'redux-devtools-extension';
import { rootReducer } from 'store';

export function configureStore(initialState: any = {}, history: History): any {
  const middlewares = [routerMiddleware(history)];

  const enhancers = [applyMiddleware(...middlewares)];

  let enhancer: StoreEnhancer<any, any>;
  // Master and production environment will not use dev tools
  if (
    process.env.REACT_APP_ENV !== 'production' &&
    typeof window === 'object'
  ) {
    enhancer = composeWithDevTools(...enhancers);
  } else {
    enhancer = compose(...enhancers);
  }

  return createStore(rootReducer, initialState, enhancer);
}

export function createSubReducer<T>(
  initialState: T,
  reducers: Array<Reducer<T>>
) {
  return function reducer(state = initialState, action: Action): T {
    for (let i = 0; i < reducers.length; i++) {
      if (reducers[i].type === action.type) {
        return reducers[i].reducer(state, action);
      }
    }
    return state;
  };
}

export interface Action {
  type: string;
  payload: any;
}

export interface ReducerFunc<T> {
  (state: T, action: any): T;
}

export interface Reducer<T> {
  type: string;
  reducer: ReducerFunc<T>;
}
