import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { showModal } from 'utils/modals';
import { AuthorizationModalId } from 'modals/AuthorizationModal';
import { IonButton, IonContent } from '@ionic/react';
import { Paragraph } from 'components/baseElements/typography';

interface PrivatePropertyContainerProps {
  children: any;
  showAuthModalOnRender?: boolean;
  unauthorizedComponent?: any;
  modalCustomMessageComponent?: any;
}

interface PrivatePropertyChildProps {
  children?: any;
}

export function Unauthorized(props: PrivatePropertyChildProps): JSX.Element {
  const {
    children,
  } = props;

  return (children);
}

export function Authorized(props: PrivatePropertyChildProps): JSX.Element {
  const {
    children,
  } = props;

  return (children);
}

export function PrivatePropertyContainer(props: PrivatePropertyContainerProps): JSX.Element {
  const {
    children = (<></>),
    showAuthModalOnRender,
    unauthorizedComponent,
    modalCustomMessageComponent
  } = props;
  const dispatch = useDispatch();
  const auth = useSelector((state: RootState) => state.authorization);
  const getUnauthorizedElement = (): any => {
    if (unauthorizedComponent) {
      return unauthorizedComponent;
    }
    else {
      const childArray = React.Children.toArray(children);
      const element = childArray.find((x: any) => x.type.name === Unauthorized.name);
      return element || (
        <IonContent>
          <Paragraph>You are not logged in.</Paragraph>
          <IonButton onClick={(): void => showModal(dispatch, AuthorizationModalId, { params: {mode: 'registration'} })}>Sign Up</IonButton>
        </IonContent>
      );
    }
  };
  const getAuthorizedElement = (): any => {
    const childArray = React.Children.toArray(children);
    const element = childArray.find((x: any) => x.type.name === Authorized.name);
    return element || children;
  };
  useEffect((): void => {
    if (showAuthModalOnRender && (!auth.token || !auth.token.length)) {
      showModal(dispatch, AuthorizationModalId, { params: { modalCustomMessageComponent: modalCustomMessageComponent } });
    }
  });
  return (auth.token && auth.token.length ?
    getAuthorizedElement() :
    getUnauthorizedElement()
  );
}

PrivatePropertyContainer.Unauthorized = Unauthorized;

PrivatePropertyContainer.Authorized = Authorized;
