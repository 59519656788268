import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgRocksIcon(props) {
  return (
    <svg width={16} height={18} fill="none" {...props}>
      <path
        d="M11.582 11.49v.908c0 .133.075.246.189.303l1.154.587c.114.056.227.037.341-.02l.7-.434a.295.295 0 00.152-.246l.056-.493a.33.33 0 00-.094-.283l-.946-.928c-.095-.094-.228-.113-.36-.075l-.965.36c-.133.056-.227.17-.227.32zM15.045 14.215l-.605-.265a.38.38 0 00-.398.057l-.549.473a.352.352 0 00-.094.397l.17.474a.33.33 0 00.265.227l.814.189a.37.37 0 00.246-.019l.246-.114c.132-.075.227-.208.208-.36l-.076-.756a.376.376 0 00-.227-.303zM7.835 6.418l1.703.568a.452.452 0 00.454-.095l.87-.738a.476.476 0 00.171-.378l-.019-.682a.43.43 0 00-.19-.36L9.35 3.656c-.152-.113-.34-.113-.511-.038l-1.25.681a.488.488 0 00-.245.474l.17 1.249c.019.189.151.34.322.397zM10.2 8.121l-.019.53c0 .133.057.265.17.34l.739.53c.075.057.17.076.265.076l.284-.019a.386.386 0 00.34-.283l.227-.776a.394.394 0 00-.094-.379l-.492-.51a.381.381 0 00-.417-.095l-.738.265c-.151 0-.246.151-.265.321zM15.272 8.803l-.492-.511a.381.381 0 00-.416-.095l-.738.265a.387.387 0 00-.265.36l-.02.53c0 .132.058.264.171.34l.738.53c.076.057.17.076.265.076l.284-.02a.386.386 0 00.34-.283l.228-.776c.038-.17 0-.322-.095-.416z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M9.992 12.53a.58.58 0 01-.151-.453l.19-1.93a.643.643 0 00-.19-.512L6.15 6.342a.56.56 0 01-.17-.605l1.04-3.918a.608.608 0 00-.151-.586L5.79.173c-.17-.151-.378-.208-.567-.151L.454 1.138A.592.592 0 000 1.725v15.67A.61.61 0 00.606 18H13.55a.55.55 0 00.416-.908L9.992 12.53z"
        fill="var(--ion-color-dark)"
      />
    </svg>
  );
}

export default SvgRocksIcon;
