import { SearchbarChangeEventDetail } from '@ionic/core/dist/types/components/searchbar/searchbar-interface';
import { IonToolbar } from '@ionic/react';
import { Box, Flex } from 'components/baseElements/grid';
import { IonButton } from 'components/ionicComponents';
import { Hideable } from 'napa-react-core';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { SearchBar } from '../../baseElements/SearchBar';

interface MyWineActionBar {
  addWineModalCallback?: (result: any) => any;
  onSearchChange?: (searchText: string) => void;
  showAddWineButton?: boolean;
}

export default function MyWineActionBar(props: MyWineActionBar): JSX.Element {
  const {
    addWineModalCallback,
    onSearchChange,
    showAddWineButton,
  } = props;
  const intl = useIntl();
  const [searchText, setSearchText] = useState<string>('');

  return (
    <Flex>
      <IonToolbar>
        <Hideable hide={!showAddWineButton}>
          <Flex
            borderBottom="1px solid var(--ion-color-medium-tint)"
            width={1}
            justifyContent="center"
          >
            <Box pt={2} pb={2}>
              <IonButton
                color="secondary"
                onClick={addWineModalCallback}
              >
                <FormattedMessage id="myRatings.buttons.addWine" />
              </IonButton>
            </Box>
          </Flex>
        </Hideable>
        <Box pt={2}>
          <SearchBar
            debounce={300}
            onIonChange={(e: CustomEvent<SearchbarChangeEventDetail>): void => {
              setSearchText(e.detail.value || '');
              if (onSearchChange) {
                onSearchChange(e.detail.value || '');
              }
            }}
            onIonClear={(): void => setSearchText('')}
            searchText={searchText}
            placeHolder={intl.formatMessage({ id: 'myRatings.searchBar.placeholderText' })}
          />
        </Box>
      </IonToolbar>
    </Flex>
  );
}
