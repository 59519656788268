import { ApiCallStatus } from 'napa-react-core';
import { apiStateUpdateCallStatus } from 'store/api/actions/apiStateUpdateCallStatus';
import { guiStateUpdateToast } from 'store/gui/guiStateUpdateToast';
import { LoadingStatus } from 'utils/formValidation';

const parseJSON = (response: any): any =>
  new Promise(resolve => {
    if (response.status === 401 || response.status === 403 || response.status === 404 || response.status === 500) {
      resolve({ httpStatus: response.status });
      return;
    }
    response.json().then((body: any) => {
      resolve({ apiResult: body, httpStatus: response.status });
    });
  });

const handleBegin = (
  dispatch: any,
  id: string,
): void => {
  dispatch(
    apiStateUpdateCallStatus(id, ApiCallStatus.AwaitingCompletion, undefined),
  );
};

const handleFailure = (
  dispatch: any,
  status: ApiCallStatus,
  id: string,
): void => {
  dispatch(apiStateUpdateCallStatus(id, status, 'An error has occurred'));
};

const handleFailureOfStatus = <T>(params: MakeApiCallParams<T>, status: ApiCallStatus): void => {
  handleFailure(
    params.dispatch,
    status,
    params.callId,
  );
  if (status === ApiCallStatus.Failure) {
    params.dispatch(
      guiStateUpdateToast({
        message: params.failureMessage || 'api.defaults.failureMessage',
        show: params.showSuccessMessage
      })
    )
  }
}

const handleSuccess = <T>(
  params: MakeApiCallParams<T>,
  result: any,
): boolean => {
  if (
    result.apiResult.validationErrors &&
    result.apiResult.validationErrors.length
  ) {
   params.dispatch(
      apiStateUpdateCallStatus(
        params.callId,
        ApiCallStatus.ValidationError,
        undefined,
        result.apiResult.validationErrors,
      ),
    );
    return false;
  }
  params.dispatch(
    apiStateUpdateCallStatus(
      params.callId,
      ApiCallStatus.Success,
      undefined,
      undefined,
      result.apiResult.responseData,
    ),
  );
  if (params.showSuccessMessage) {
    params.dispatch(
      guiStateUpdateToast({
        message: params.successMessage || 'api.defaults.successMessage',
        show: params.showSuccessMessage
      })
    );
  }

  return true;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function clearApiCallStatus(dispatch: any, id: string): void {
  dispatch(apiStateUpdateCallStatus(id, ApiCallStatus.Unattempted));
}

export function GetLoadingStatus(apiStatus?: ApiCallStatus): LoadingStatus {
  if (apiStatus === null || apiStatus === ApiCallStatus.Unattempted) {
    return LoadingStatus.None;
  }
  if (apiStatus === ApiCallStatus.AwaitingCompletion) {
    return LoadingStatus.Loading;
  }
  if (apiStatus === ApiCallStatus.Failure) {
    return LoadingStatus.Error;
  }
  return LoadingStatus.Complete;
}

interface MakeApiCallParamsRequest<T> {
  url: string;
  httpMethod: string;
  body?: T;
}

interface MakeApiCallParams<T> {
  authToken?: string;
  callId: string;
  dispatch: any;
  request: MakeApiCallParamsRequest<T>;
  failureMessage?: string;
  showSuccessMessage?: boolean;
  successMessage?: string;
}

export function makeApiCall<T>(params: MakeApiCallParams<T>): Promise<T> {
  if (params.showSuccessMessage === undefined
    && params.request.httpMethod.toLowerCase() !== 'get') {
    params.showSuccessMessage = false;
  }
  handleBegin(
    params.dispatch,
    params.callId,
  );

  return new Promise((resolve, reject) => {
    const request = (params.request.httpMethod.toLowerCase() === 'get')
      ? fetch(params.request.url, {
        headers: new Headers({
          Authorization: params.authToken ? `Bearer ${params.authToken}` : '',
        }),
      })
      : fetch(params.request.url, {
        method: params.request.httpMethod,
        body: JSON.stringify(params.request.body),
        headers: new Headers({
          Authorization: params.authToken ? `Bearer ${params.authToken}` : '',
          'Content-Type': 'application/json',
        }),
      });
    request.then(result => parseJSON(result))
      .then(
        result => {
          switch (result.httpStatus) {
            case 401:
              handleFailureOfStatus(params, ApiCallStatus.AuthorizationError);
              // window.open('/Login', '_self');
              reject(new Error('An authorization error has occurred'));
              return;
            case 403:
              handleFailureOfStatus(params, ApiCallStatus.ForbiddenError);
              // window.open('/404', '_self');
              reject(new Error('A forbidden error has occurred'));
              return;
            case 404:
              handleFailureOfStatus(params, ApiCallStatus.NotFoundError);
              // window.open('/404', '_self');
              reject(new Error('A not found error has occurred'));
              return;
            case 500:
              handleFailureOfStatus(params, ApiCallStatus.Failure);
              reject(new Error('An error has occurred'));
              return;
            default:
              break;
          }
          const isSuccess = handleSuccess(
            params,
            result,
          );
          if (isSuccess) {
            resolve(result.apiResult.responseData);
          } else {
            reject(new Error('Validation errors'));
          }
        },
        () => {
          handleFailureOfStatus(params, ApiCallStatus.Failure);
          reject(new Error('An error has occurred'));
        },
      );
  });
}
