import { TastingAttendee } from 'store/tastingAttendees';

export enum ETastingLocationType {
  InPerson = 'InPerson',
  Virtual = 'Virtual'
}

export interface Tasting {
  areRanksPublished?: boolean;
  address1: string;
  address2: string;
  city: string;
  id?: string;
  hasEnded?: boolean;
  name: string;
  dateTime: string;
  tastingImageFileId?: string;
  time?: string;
  latitude: number;
  locationType: ETastingLocationType;
  location: string;
  longitude: number;
  description: string;
  isHappeningNow?: boolean;
  isTastingPublic?: boolean;
  maxAttendeesCount: number | null;
  privacy?: string;
  roomDescription: string;
  showAttendees: boolean;
  stateProvince: string;
  canAttendeesManageWine: boolean;
  ownerId: string | null;
  zipCode: string;
}

export interface TastingWithAttendee {
  tasting: Tasting;
  attendee?: TastingAttendee;
  attendeeList: Array<TastingAttendee>;
}

export interface TastingStatus {
  dateTime: string;
  hasEnded?: boolean;
  isHappeningNow?: boolean;
}

export interface BrowseTastingQueryParams {
  searchText?: string;
  sortByAndDirection?: string;
}

export interface BrowseTastingResponseData {
  items: Array<TastingWithAttendee>;
  totalItems: number;
}

export type TastingsState = any;

export enum LocationTypeFilterState {
  None = 0,
  Virtual = 1,
  InPerson = 2,
  VirtualAndInPerson = Virtual | InPerson,
}

export enum DateFilterState {
  AnyTime = 0,
  ThisWeekend = 1,
  NextWeek = 2,
  NextWeekend = 3,
  ChooseDate = 4,
}

export interface DiscoveryFilterState {
  filter: LocationTypeFilterState;
  dateFilter: DateFilterState;
  distanceFromZipCode?: number;
  limit: number;
  offset: number;
  searchText: string;
  startDate?: string;
  endDate?: string;
  zipCode?: string;
}

export interface TastingResultInfo {
  messageId?: string;
  anyFlights: boolean;
  anyWines: boolean;
  showWineList: boolean;
}
