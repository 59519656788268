import * as React from 'react';
import * as CSS from 'csstype';
import styled from 'styled-components';
import {
  border,
  BorderProps,
  color,
  layout,
  LayoutProps,
  ResponsiveValue,
  space,
  SpaceProps,
  system,
} from 'styled-system';
import { transient } from 'utils/styledHelpers';

const StyledImage = styled('img')`
  ${transient(border)}
  ${transient(color)}
  ${transient(layout)}
  ${transient(space)}
  ${transient(system({
    objectFit: true,
    objectPosition: true,
  }))}
`;

interface StyledImageProps extends React.HTMLAttributes<HTMLFormElement>, BorderProps, LayoutProps, SpaceProps {
  $bg?: ResponsiveValue<CSS.Property.Background>;
  alt: string;
  $height?: ResponsiveValue<CSS.Property.Height>;
  objectFit?: ResponsiveValue<CSS.Property.ObjectFit>;
  objectPosition?: ResponsiveValue<CSS.Property.ObjectPosition>;
  src: string;
  $width?: ResponsiveValue<CSS.Property.Width>;
}

export default function Image(props: StyledImageProps): JSX.Element {
  const {
    alt,
    src,
    ...otherProps
  } = props;

  return (
    <StyledImage
      alt={alt}
      src={src}
      {...otherProps}
    />
  );
}
