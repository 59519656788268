import { GuiStateUpdateToastAction } from './types';
import { GuiState } from '..';


export const guiStateUpdateToastReducer = (
  guiState: GuiState,
  action: GuiStateUpdateToastAction,
): GuiState => ({
    ...guiState,
  toast: action.payload.toastProps
});
