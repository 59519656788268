import { yupResolver } from '@hookform/resolvers/yup';
import { IonCol, IonGrid, IonInput, IonList, IonRow } from '@ionic/react';
import { SubmitButton } from 'components/baseElements/formControls';
import HookInput from 'components/baseElements/formControls/HookInput';
import Form from 'components/baseElements/formControls/StyledForm';
import { Logo } from 'components/baseElements/Logo';
import { Heading, Paragraph } from 'components/baseElements/typography';
import { PrivacyModalId } from 'modals/PrivacyModal';
import { ServerValidationError, showModal } from 'napa-react-core';
import React, { FunctionComponent, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { User } from 'store/users';
import { handleServerHookForm } from 'utils/formValidation';
import * as yup from 'yup';

interface UserRegistrationFormProperties {
  submitAction: (data: User) => void;
  createUserIsLoading?: boolean;
  serverValidation: Array<ServerValidationError>;
  validationSchema: yup.ObjectSchema<any>;
}

const UserRegistrationForm: FunctionComponent<UserRegistrationFormProperties> = (
  props: UserRegistrationFormProperties,
) => {
  const {
    createUserIsLoading,
    submitAction,
    serverValidation,
    validationSchema,
  } = props;

  const dispatch = useDispatch();

  const form = useForm({
    defaultValues: {
      email: '',
      fullName: '',
      password: '',
      passwordConfirm: '',
    },
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    handleServerHookForm(serverValidation, form.setError);
  }, [serverValidation, form.setError]);

  return (
    <IonGrid>
      <IonRow>
        <IonCol>
          <Logo
            $maxWidth={210}
            $mx="auto"
            $my={3}
            $width={1 / 3}
          />
          <Heading
            headingSize="xs"
            textAlign="center"
          >
            <FormattedMessage id="userRegistrationForm.headings.top"/>
          </Heading>
          <Form onSubmit={form.handleSubmit(submitAction)}>
            <IonList lines="full" style={{ backgroundColor: 'transparent' }}>
              <HookInput
                form={form}
                isRequired
                labelText="userRegistrationForm.labels.fullName"
                name="fullName"
              >
                <IonInput data-cy="fullNameSignUp"/>
              </HookInput>
              <HookInput
                form={form}
                isRequired
                labelText="userRegistrationForm.labels.email"
                name="email"
              >
                <IonInput data-cy="emailAddressSignUp"/>
              </HookInput>
              <HookInput
                assistiveText="userRegistrationForm.assistiveText.password"
                form={form}
                isRequired
                labelText="userRegistrationForm.labels.password"
                name="password"
              >
                <IonInput
                  clearOnEdit={false}
                  data-cy="passwordSignUp"
                  type="password"
                />
              </HookInput>
              <HookInput
                data-cy="loginConfirmPwdField"
                form={form}
                isRequired
                labelText="userRegistrationForm.labels.passwordConfirm"
                name="passwordConfirm"
              >
                <IonInput
                  clearOnEdit={false}
                  data-cy="passwordConfirmSignUp"
                  type="password"
                />
              </HookInput>
            </IonList>
            <Paragraph data-cy="termsAndConditionsSection" px={1}>
              By signing up, you agree to our <a onClick={(): void => showModal(dispatch, PrivacyModalId, { params: {} })}>terms and conditions</a>.
            </Paragraph>
            <SubmitButton
              color="secondary"
              isLoading={createUserIsLoading}
            >
              <FormattedMessage id="userRegistrationForm.buttons.register"/>
            </SubmitButton>
          </Form>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default UserRegistrationForm;
