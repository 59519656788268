import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgRaspberryIcon(props) {
  return (
    <svg width={14} height={20} fill="none" {...props}>
      <path
        clipRule="evenodd"
        d="M7.283 2.9C7.379 1.02 5.353 1 5.353 1s.05 1.995 1.93 1.9z"
        stroke="var(--ion-color-dark)"
        strokeWidth={0.6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.899 4.409c0-1.888 1.527-3.105 3.415-3.105M4.453 7.203A.931.931 0 113.135 8.52M10.06 8.308a.931.931 0 11-1.318-1.317M8.494 10.407a.931.931 0 11-1.852-.195M4.763 11.822a.931.931 0 11-1.317 1.317M10.182 12.59a.931.931 0 11-1.813.427M7.52 15.895a.931.931 0 01-1.757-.62"
        stroke="var(--ion-color-dark)"
        strokeWidth={0.6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M6.899 19c.627 0 1.14-.466 1.224-1.07.228.272.566.45.949.45.686 0 1.242-.557 1.242-1.243 0-.062-.01-.122-.019-.182.188.113.405.182.64.182.685 0 1.241-.556 1.241-1.242 0-.415-.206-.78-.52-1.006.483-.17.83-.626.83-1.167 0-.415-.205-.781-.519-1.006.482-.17.83-.626.83-1.167 0-.54-.348-.996-.83-1.166.314-.226.52-.591.52-1.007 0-.627-.467-1.14-1.07-1.224.272-.228.449-.566.449-.95 0-.685-.556-1.241-1.242-1.241-.11 0-.214.019-.315.045 0-.015.005-.03.005-.045 0-.686-.556-1.242-1.242-1.242-.416 0-.782.206-1.007.52a1.238 1.238 0 00-1.166-.83c-.541 0-.996.347-1.167.83a1.238 1.238 0 00-1.007-.52c-.685 0-1.241.556-1.241 1.242 0 .016.004.03.004.045a1.228 1.228 0 00-.315-.045c-.686 0-1.242.556-1.242 1.242 0 .383.177.72.45.949a1.238 1.238 0 00-1.07 1.224c0 .416.206.781.52 1.007-.483.17-.831.625-.831 1.166 0 .541.348.996.83 1.167-.313.225-.52.59-.52 1.006 0 .541.348.996.83 1.167-.313.225-.52.59-.52 1.007 0 .685.557 1.241 1.243 1.241.234 0 .452-.069.639-.182-.009.06-.018.12-.018.182 0 .686.556 1.242 1.241 1.242.383 0 .721-.177.95-.45.083.605.596 1.071 1.224 1.071z"
        stroke="var(--ion-color-dark)"
        strokeWidth={0.6}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgRaspberryIcon;
