import styled from 'styled-components';
import {
  border,
  color,
  layout,
  space,
  system,
} from 'styled-system'
import { transient } from 'utils/styledHelpers';

const Divider = styled('hr')`
  ${transient(border)}
  ${transient(color)}
  ${transient(layout)}
  ${transient(space)}
  ${transient(system({ opacity: true }))}
`;

Divider.defaultProps = {
  $backgroundColor: 'var(--ion-text-color)',
  $opacity: 0.21,
  $width: 2 / 3,
}

export { Divider };
