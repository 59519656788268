import React from 'react';
import ShowMoreText from 'react-show-more-text';

interface AbridgedTextProps {
  children?: any;
  lines?: number;
  showMoreText?: string;
  showLessText?: string;
  anchorClass?: string;
  expanded?: boolean;
  width?: number;
}

export function AbridgedText(props: AbridgedTextProps): JSX.Element {
  const {
    children = (<></>),
    lines = 3,
    showMoreText = 'Show more...',
    showLessText = 'Show less...',
    anchorClass = '',
    expanded = false,
    width = 0
  } = props;
  return (
    <ShowMoreText
      /* Default options */
      lines={lines}
      more={showMoreText}
      less={showLessText}
      anchorClass={anchorClass}
      expanded={expanded}
      width={width}
    >
      {children}
    </ShowMoreText>
  );
}
