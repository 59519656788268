import jwtDecode from 'jwt-decode';
import { AuthorizationState, TokenData } from './index';

const authTokenName = 'authToken';

export const getUserAuthToken = (): string | undefined => {
  try {
    const tokenData: AuthorizationState = JSON.parse(localStorage.getItem(authTokenName) || '{}');
    if (tokenData) {
      return tokenData.token || undefined;
    }
  } catch (ignore) {
  }
  return undefined;
};

export const getUserId = (): string | null => {
  try {
    const token = localStorage.getItem(authTokenName);
    if (token) {
      const user: TokenData = jwtDecode(token);
      if (user) {
        return user['userData/userId'];
      }
    }
  } catch (ignore) {
  }
  return null;
};
