import { css } from 'styled-components';
import { background, border, color, shadow, space } from 'styled-system';
import { transient } from 'utils/styledHelpers';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const bgKnob = (
  lightColor = '%23fff',
  primaryColor = '%23954a98',
  darkColor = '%23383838'
) => (
  `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 38 72' %3E %3Cpath fill='${lightColor}' d='M32.5 1.5h-28C3.667 5.667 1.8 16 1 24c1.2 8.4 9.167 14.167 13 16v30h8.5V40.5c15-9.5 13-13 13-16.5 0-2.8-2-16.167-3-22.5z'/%3E %3Cpath fill='${primaryColor}' fill-rule='evenodd' d='M28.172 7.345a7.075 7.075 0 00-.157-.563 209.47 209.47 0 00-8.335-.153 455.826 455.826 0 00-11.036.162c-.528 2.593-.857 5.171-1.114 7.443l-.082.723c-.408 3.581-.83 7.283-.352 10.57.431 2.962 2.711 4.834 5.648 6.97.745.542 1.56.976 2.355 1.44 1.384.805 2.85 1.56 4.513 1.188 1.4-.312 2.643-1.106 3.787-1.94 1.174-.854 2.355-1.732 3.422-2.719 1.77-1.636 2.74-3.081 3.053-4.549.393-1.841.258-4.082.15-5.882-.237-3.943-.843-8.094-1.852-12.69z' clip-rule='evenodd'/%3E %3Cpath fill='${darkColor}' fill-rule='evenodd' d='M33.739 27.811c-.42 1.907-1.719 3.787-4.09 5.912-2.16 1.937-4.737 3.571-6.972 4.916-.27.162-.573.332-.895.511-.273.154-.561.315-.858.488 0 0-.184 4.474-.145 4.474V68.73H16V44.084h.012c.037.001.025-4.694-.014-4.717-.61-.349-1.218-.681-1.808-1.002-1.226-.67-2.386-1.302-3.4-2.004-3.935-2.775-6.99-5.207-7.567-9.057-.64-4.271-.075-9.081.471-13.734l.11-.94c.319-2.732 1.44-9.421 1.492-9.672.311-.009 9.989-.21 14.785-.21 4.044 0 7.724.065 11.166.199.09.278.165.529.21.73 1.352 5.973 2.164 11.368 2.482 16.49.145 2.34.325 5.251-.201 7.644zM33.898 0H3.25c-.283.726-.997 3.577-1.186 4.487-.432 2.084-.754 4.191-1.04 6.3-.68 5.002-1.405 10.29-.794 15.336.35 2.892 1.6 5.533 3.464 7.763 1.51 1.805 3.422 3.047 5.303 4.415.99.708 2.072 1.285 3.125 1.891.232.132.463.266.695.4v28.137H8.57v2.787h19.638v-2.787h-4.246v-27.8c.76-.469 1.524-.929 2.275-1.412 2.108-1.355 4.172-2.848 5.958-4.614 1.858-1.837 3.47-4.042 4.212-6.576.617-2.109.663-4.418.614-6.598C36.91 16.814 34.905 4.964 33.898 0z' clip-rule='evenodd'/%3E %3C/svg%3E");`
);

export const cssIonRange = css`
  ${props => (props.knobBackground) && `--knob-background: ${props.knobBackground};`}
  ${props => props.knobSize && `--knob-size: ${props.knobSize};`}
  &::part(knob) {
    ${transient(background)}
    ${transient(border)}
    ${transient(color)}
    ${transient(shadow)}
    ${transient(space)}
  }
  ${props => props.barBackground && `--bar-background: ${props.barBackground};`}
  ${props => props.barBackgroundActive && `--bar-background-active: ${props.barBackgroundActive};`}
  &::part(tick),
  &::part(tick-active) {
    background: var(--ion-color-medium);
    margin-left: -2px;
  }
  > ion-label {
    min-width: 40px;
    &[slot="start"] {
      text-align: right;
    }
  }
  &::shadow .range-slider {
    touch-action: none;
  }
`;
