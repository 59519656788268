import { IonContent, IonPage } from '@ionic/react';
import { EmptyStateImage } from 'components/baseElements/EmptyStateImage';
import { Flex } from 'components/baseElements/grid';
import { Heading, Paragraph } from 'components/baseElements/typography';
import { IonButton } from 'components/ionicComponents';
import { Authorized, PrivatePropertyContainer, Unauthorized } from 'containers/authorization/PrivatePropertyContainer';
import MyWinesContainer from 'containers/wine/MyWinesContainer';
import { AuthorizationModalId } from 'modals/AuthorizationModal';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { showModal } from 'utils/modals';
import VinviteVersion from '../../../components/version';

export default function MyWinesPage(): JSX.Element {
  const dispatch = useDispatch();
  const [loginRefresh, setLoginRefresh] = React.useState<boolean>(false);

  const onLoginSuccess = (): void => {
    setLoginRefresh(true);
    setTimeout(() => {
      setLoginRefresh(false);
    }, 1000);
  };

  return (
    <IonPage>
      <PrivatePropertyContainer>
        <Authorized>
          <MyWinesContainer loginRefresh={loginRefresh}/>
        </Authorized>
        <Unauthorized>
          <IonContent>
            <Flex flexDirection="column" height="100%">
              <Flex alignItems="center" flex={1} flexDirection="column" pt={5}>
                <Heading headingSize="sm" textAlign="center">
                  <FormattedMessage id="myRatings.header.emptyState"/>
                </Heading>
                <EmptyStateImage/>
                <Paragraph textAlign="center" width={2 / 3}>
                  <FormattedMessage id="myRatings.text.emptyState"/>
                </Paragraph>
                <IonButton
                  data-cy="signUpBtnMyProfile"
                  onClick={(): void =>
                    showModal(dispatch, AuthorizationModalId, {
                      params: { mode: 'registration' },
                      callback: onLoginSuccess,
                    })
                  }
                >
                  <FormattedMessage id="general.nav.registration"/>
                </IonButton>
              </Flex>
              <VinviteVersion/>
            </Flex>
          </IonContent>
        </Unauthorized>
      </PrivatePropertyContainer>
    </IonPage>
  );
}
