import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgWheyIcon(props) {
  return (
    <svg width={14} height={18} fill="none" {...props}>
      <path
        d="M10.5 3v-.6h.9a.3.3 0 00.3-.3V.3a.3.3 0 00-.3-.3h-9a.3.3 0 00-.3.3v1.8a.3.3 0 00.3.3h.9V3A3.304 3.304 0 000 6.3v9.6A2.103 2.103 0 002.1 18h9.6a2.102 2.102 0 002.1-2.1V6.3A3.304 3.304 0 0010.5 3zM2.7 1.8V.6h8.4v1.2H2.7zm7.2.6V3h-6v-.6h6zM.6 15.6v-9h10.8v9H.6zm12.6.3a1.502 1.502 0 01-1.5 1.5H2.1a1.502 1.502 0 01-1.47-1.2H11.7a.3.3 0 00.3-.3V6.3a.3.3 0 00-.3-.3H.618A2.703 2.703 0 013.3 3.6h7.2a2.703 2.703 0 012.7 2.7v9.6z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M8.497 10.986a1.472 1.472 0 00-1.155.087 1.574 1.574 0 00-.542.445 1.585 1.585 0 00-1.089-.347 1.43 1.43 0 00-.348.073c.06-.192.122-.384.167-.537a.307.307 0 01.204-.207h.716a1.142 1.142 0 00.95-.51l.35-.525A.302.302 0 007.8 9.3V7.8a.3.3 0 00-.25-.296l-1.8-.3a.303.303 0 00-.29.116l-.87 1.162c-3.12 2.207-2.205 4.376-2.164 4.468.016.037.04.07.07.098 1.574 1.466 2.976 1.865 4.036 1.865a3.685 3.685 0 002.048-.573.3.3 0 00.12-.24v-2.83a.3.3 0 00-.203-.284zm-.397 2.95c-.5.294-2.477 1.168-5.144-1.28-.107-.317-.497-1.95 2.016-3.71a.295.295 0 00.068-.066l.79-1.054 1.37.228v.31l-.115.058-.88.294a.3.3 0 00.19.57l.805-.27v.194l-.3.45a.54.54 0 01-.45.24H5.7a.292.292 0 00-.048.004.905.905 0 00-.697.632 63.26 63.26 0 01-.44 1.37.3.3 0 00.525.274c.18-.222.437-.369.72-.41a1.202 1.202 0 01.894.41.3.3 0 00.472-.072 1.025 1.025 0 01.974-.597v2.426z"
        fill="var(--ion-color-dark)"
      />
    </svg>
  );
}

export default SvgWheyIcon;
