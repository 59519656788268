import React from 'react';
import { faBars, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonToolbar } from '@ionic/react';
import { Flex } from 'components/baseElements/grid';
import { IonButton } from 'components/ionicComponents';
import { AddFlightToTastingModalId } from 'modals/AddFlightToTastingModal';
import { masterWinesSearchModalId } from 'modals/SearchMasterWinesModal';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Flight, Wine } from 'store/wines';
import { showModal } from 'utils/modals';

interface EditWineListForTastingActionBarProps {
  addFlightToTastingModalCallback?: (result: any) => any;
  addWineToTastingModalCallback?: (result: any) => any;
  flights?: Array<Flight>;
  sortMenuOnClick?: () => void;
  tastingId: string;
  wines?: Array<Wine>;
}

export function EditWineListForTastingActionBar(props: EditWineListForTastingActionBarProps): JSX.Element {
  const {
    addFlightToTastingModalCallback,
    addWineToTastingModalCallback,
    flights,
    wines,
    sortMenuOnClick,
    tastingId,
  } = props;

  const dispatch = useDispatch();

  const buttonStyleProps = {
    expand: 'block',
    fill: 'outline',
    maxWidth: '31%'
  }

  return (
    <>
      <Flex borderBottom="1px solid var(--ion-color-medium-tint)">
        <IonToolbar>
          <Flex
            alignItems="center"
            flexWrap="wrap"
            justifyContent="center"
          >
            <IonButton
              data-cy="addNewFlightBtn"
              onClick={(): void => showModal(
                dispatch,
                AddFlightToTastingModalId,
                { params: { tastingId, flights }, callback: addFlightToTastingModalCallback },
              )}
              {...buttonStyleProps}
            >
              <FontAwesomeIcon icon={faPlus} size="sm"/>
              &nbsp;
              <FormattedMessage id="editWineListTasting.buttons.addNewFlight"/>
            </IonButton>
            <IonButton
              onClick={(): void => showModal(
                dispatch,
                masterWinesSearchModalId,
                { params: { tastingId, flights, wines }, callback: addWineToTastingModalCallback },
              )}
              {...buttonStyleProps}
            >
              <FontAwesomeIcon icon={faPlus} size="sm"/>
              &nbsp;
              <FormattedMessage id="editWineListTasting.buttons.addNewWine"/>
            </IonButton>
            <IonButton
              onClick={sortMenuOnClick}
              {...buttonStyleProps}
            >
              <FontAwesomeIcon icon={faBars} size="sm"/>
              &nbsp;
              <FormattedMessage id="editWineListTasting.buttons.sortMenu" />
            </IonButton>
          </Flex>
        </IonToolbar>
      </Flex>
    </>
  );
}

