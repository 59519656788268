import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgAppleIcon(props) {
  return (
    <svg width={16} height={18} fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.799 16.502h.005c.098.002.19.05.246.132.003.003.569.766 2.45.766 1.908 0 2.735-1.341 2.743-1.354C13.267 16.008 15 13.356 15 9.6c0-3.76-1.705-5.1-3.3-5.1-1.8 0-3.2.924-3.688 1.412a.3.3 0 01-.424 0C7.099 5.424 5.7 4.5 3.9 4.5 2.305 4.5.6 5.84.6 9.6c0 3.756 1.732 6.408 1.75 6.434.04.066.856 1.366 2.75 1.366 1.88 0 2.447-.763 2.453-.77a.304.304 0 01.246-.128zM10.5 18c-1.532 0-2.336-.466-2.7-.773-.364.307-1.168.773-2.7.773-2.244 0-3.217-1.579-3.257-1.645C1.775 16.253 0 13.545 0 9.6c0-4.203 2.015-5.7 3.9-5.7 1.781 0 3.22.827 3.9 1.396.68-.569 2.118-1.396 3.9-1.396 1.885 0 3.9 1.497 3.9 5.7 0 3.946-1.775 6.653-1.85 6.766-.033.055-1.006 1.634-3.25 1.634z"
        fill="var(--ion-color-dark)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.1 16.8c-1.529 0-2.225-1.058-2.254-1.104a10.661 10.661 0 01-.724-1.483.3.3 0 11.556-.226c.348.856.67 1.382.674 1.387.02.031.558.826 1.748.826a.3.3 0 110 .6zM7.8 6a.3.3 0 01-.3-.3C7.5 2.576 9.057 1.02 9.988.088a.3.3 0 11.424.424C9.547 1.377 8.1 2.824 8.1 5.7a.3.3 0 01-.3.3z"
        fill="var(--ion-color-dark)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.3.6v.444c0 .748.608 1.356 1.357 1.356H8.1v-.443C8.1 1.209 7.492.6 6.743.6H6.3zM8.4 3h-.743A1.959 1.959 0 015.7 1.044V.3A.3.3 0 016 0h.743C7.823 0 8.7.878 8.7 1.957V2.7a.3.3 0 01-.3.3z"
        fill="var(--ion-color-dark)"
      />
    </svg>
  );
}

export default SvgAppleIcon;
