import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgLicoriceIcon(props) {
  return (
    <svg width={18} height={18} fill="none" {...props}>
      <path
        d="M12.691 6.954a5.743 5.743 0 001.582.236c1.707 0 3.032-.92 3.459-2.401a3.2 3.2 0 00-.431-2.713c-.56-.831-1.49-1.453-2.69-1.798A8.005 8.005 0 0012.495 0C10.402 0 8.974.787 8.58 2.159c-.654 2.276 2.067 4.206 4.111 4.795zM9.625 2.459c.356-1.238 2.126-1.372 2.871-1.372.631 0 1.292.086 1.814.236.951.274 1.673.744 2.088 1.361.37.549.47 1.173.289 1.804-.245.852-.926 1.413-1.864 1.569l.037-.534a.163.163 0 00-.325-.023l-.041.596a4.148 4.148 0 01-.798-.036l.212-.803a.164.164 0 00-.117-.2.164.164 0 00-.2.117l-.217.828a5.185 5.185 0 01-.795-.233l.272-.55a.163.163 0 10-.292-.144l-.283.57c-1.354-.595-3.038-1.84-2.651-3.186z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M11.223 2.61c.52-.292 1.736-.215 2.566.024.801.23 1.394.622 1.587 1.05a.326.326 0 10.594-.268c-.277-.618-.989-1.118-2.001-1.409-.799-.23-2.274-.41-3.065.033a.326.326 0 00.32.57zM16.974 9.117a3.476 3.476 0 00-2.472-1.028c-1.194 0-2.388.563-3.454 1.63-1.273 1.272-3.394 4.734-1.402 6.726.542.541 1.238.816 2.069.816 1.675 0 3.588-1.15 4.657-2.219 1.921-1.92 2.168-4.357.602-5.925zm-1.37 5.156a5.874 5.874 0 01-.322.296l-.481-.498a.163.163 0 00-.235.226l.463.48a7.82 7.82 0 01-.628.443l-.54-.83a.163.163 0 00-.274.178l.536.821a7.342 7.342 0 01-.76.385l-.36-.764a.163.163 0 00-.295.14l.352.747a4.765 4.765 0 01-.779.22l-.111-.456a.163.163 0 00-.317.077l.104.425a2.78 2.78 0 01-.242.01c-.54 0-.965-.162-1.3-.497-1.322-1.322.288-4.076 1.401-5.19.845-.844 1.799-1.31 2.685-1.31.65 0 1.24.246 1.703.71 1.133 1.133.891 2.896-.6 4.388z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M14.334 10.09a2.484 2.484 0 00-.598.171.326.326 0 10.253.602c.153-.065.301-.108.44-.128a.327.327 0 00-.095-.646zM12.586 11.02c-.077.07-.154.143-.231.22-.593.593-1.503 1.825-1.585 2.793a.326.326 0 00.65.055c.063-.746.854-1.845 1.396-2.387.069-.068.138-.133.206-.195a.326.326 0 00-.436-.486zM6.76 6.815a2.465 2.465 0 00-.623-.024.326.326 0 00.051.65c.166-.012.32-.007.458.017a.326.326 0 00.113-.643zM4.808 7.15a5.802 5.802 0 00-.29.137c-.747.376-1.999 1.261-2.38 2.154a.326.326 0 10.6.257c.294-.69 1.39-1.484 2.074-1.828.087-.044.172-.085.257-.121a.326.326 0 10-.26-.598z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M9.616 6.81C9.013 5.55 7.771 4.8 6.293 4.8c-.79 0-1.62.205-2.465.61C2.205 6.19-.944 8.751.274 11.29c.535 1.117 1.676 1.707 3.3 1.707 1.363 0 2.707-.412 3.511-.798 2.449-1.175 3.49-3.391 2.531-5.39zm-3.001 4.409c-.17.08-.374.166-.603.247l-.203-.597a.163.163 0 10-.309.104l.202.595a8.26 8.26 0 01-.74.19l-.139-1.001a.164.164 0 00-.184-.14.164.164 0 00-.14.184l.14 1.017c-.278.046-.566.077-.856.088v-.954a.163.163 0 00-.326 0v.953a4.122 4.122 0 01-.832-.096l.127-.624a.164.164 0 00-.127-.192.165.165 0 00-.193.127l-.122.598c-.497-.177-.853-.474-1.056-.898-.808-1.685 1.625-3.749 3.044-4.43.697-.334 1.368-.503 1.995-.503 1.065 0 1.919.508 2.343 1.393.693 1.443-.12 3.027-2.021 3.939z"
        fill="var(--ion-color-dark)"
      />
    </svg>
  );
}

export default SvgLicoriceIcon;
