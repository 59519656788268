import styled from 'styled-components';
import { color, layout, space, typography, variant } from 'styled-system';
import textVariables from 'theme/textVariables.json';
import { ellipsis } from 'utils/styledHelpers';

const Heading = styled('h2')`
  ${color}
  ${layout}
  ${space}
  ${typography}
  ${ellipsis}
  ${variant({
    prop: 'headingSize',
    variants: {
      '2xl': {
        fontSize: textVariables.heading.fontSize.xxl,
      },
      'xl': {
        fontSize: textVariables.heading.fontSize.xl,
      },
      'lg': {
        fontSize: textVariables.heading.fontSize.lg,
      },
      'md': {
        fontSize: textVariables.heading.fontSize.md,
      },
      'sm': {
        fontSize: textVariables.heading.fontSize.sm,
      },
      'xs': {
        fontSize: textVariables.heading.fontSize.xs,
      },
    }
  })}
`;

Heading.defaultProps = {
  color: 'var(--ion-text-color)',
  fontFamily: 'var(--title-font-family)',
  fontWeight: 'bold',
  headingSize: 'xl',
  lineHeight: 1.2,
};

export { Heading };
