/* eslint-disable @typescript-eslint/no-var-requires */
import { faCalendarAlt, faStar, faUserCircle } from '@fortawesome/pro-light-svg-icons';
import { IonApp, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/typography.css';
import { TabLabel } from 'components/baseElements/TabLabel';
import SessionManagementContainer from 'containers/authorization/SessionManagementContainer';
import flatten from 'flat';
import jwtDecode from 'jwt-decode';
import messagesEn from 'localization/en.json';
import AddFlightToTastingModal from 'modals/AddFlightToTastingModal';
import AddWineForScoringContainer from 'modals/AddWineForScoringModal';
import AddWineToTastingModal from 'modals/AddWineToTastingModal';
import AuthorizationModal from 'modals/AuthorizationModal';
import CreateTastingModal from 'modals/CreateTastingModal';
import DateFilterModal from 'modals/DateFilterModal';
import EditFlightInTastingModal from 'modals/EditFlightInTastingModal';
import EditTastingModal from 'modals/EditTastingModal';
import EditWineInTastingModal from 'modals/EditWineInTastingModal';
import PrivacyModal from 'modals/PrivacyModal';
import ScoreAWineModal from 'modals/ScoreAWineModal';
import SearchMasterWinesModal from 'modals/SearchMasterWinesModal';
import WineScoreSummaryModal from 'modals/WineScoreSummaryModal';
import {
  ActionSheetProvider,
  AppSettingsProvider,
  AuthorizationProvider,
  DrawerProvider,
  ModalProvider,
  ToastProvider,
} from 'napa-react-core';
import ViewAttendeesPage from 'pages/attendees/ViewAttendeesPage';
import NotFoundPage from 'pages/authorization/NotFound';
import PrivacyPage from 'pages/privacy';
import BrowseTastingPage from 'pages/tastings/BrowseTastingPage';
import MyTastingsPage from 'pages/tastings/MyTastingsPage';
import ViewTastingResultsPage from 'pages/tastings/TastingResultsPage';
import ViewTastingRoomPage from 'pages/tastings/TastingRoomPage';
import ViewTastingPage from 'pages/tastings/ViewTastingPage';
import ChangePasswordPage from 'pages/users/ChangePasswordPage';
import ResetPasswordPage from 'pages/users/ResetPasswordPage';
import UserProfilePage from 'pages/users/UserProfilePage';
import EditWineListForTastingPage from 'pages/wine/EditWineListForTastingPage';
import MyWinesPage from 'pages/wine/MyWinesPage';
import WineScoreSummaryPage from 'pages/wineScores/WineScoreSummaryPage';
import React from 'react';

import { FormattedMessage, IntlProvider } from 'react-intl';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { Redirect, Route } from 'react-router';
import history from 'utils/history';
import { loadSmartAppBanner } from './utils/smartAppBanner';
import { configureStore } from 'utils/store';
import AppUrlListener from './AppUrlListener';
import GeoLocationFilterModal from './modals/GeoLocationFilterModal';
import InviteAttendeesModal from './modals/InviteAttendeesModal';

/* Theme variables */
import './theme/variables.css';

const envSettings = (window as any).ENV.ENVIRONMENT;

const { sessionExpirationInMinutes } = envSettings.securitySettings;

const messages: { [key: string]: any } = {
  en: flatten(messagesEn),
};
const language = navigator.language.split(/[-_]/)[0];

const getAuthFromCookie = (): any => {
  const localToken = localStorage.getItem('authToken');
  if (localToken && localToken !== '{}') {
    const currentToken = JSON.parse(localStorage.getItem('authToken') || '{}');
    const now = new Date();
    const expiration = now.setMinutes(
      now.getMinutes() + sessionExpirationInMinutes,
    );
    localStorage.setItem(
      'authToken',
      JSON.stringify({
        token: currentToken.token,
        expires:
          sessionExpirationInMinutes > 0 ? new Date(expiration) : undefined,
      }),
    );
    return {
      token: currentToken.token,
      tokenData: currentToken.token ? jwtDecode(currentToken.token) : null,
    };
  }
  return {};
};

const initialState = {
  authorization: getAuthFromCookie(),
  appSettings: envSettings,
};

const store = configureStore(initialState, history);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const masterWinesSearchModalId = 'SearchMasterWines';

loadSmartAppBanner(envSettings);

const App: React.FC = () => {
  return (
    <IonApp>
      <Provider store={store}>
        <IntlProvider
          locale={language}
          messages={messages[language] || messages['en']}
        >
          <AppSettingsProvider environment={envSettings}>
            <AuthorizationProvider environment={envSettings}>
              {/* <Suspense fallback={<IonLoading isOpen />}> */}
              <QueryClientProvider client={queryClient}>
                <ToastProvider>
                  <ActionSheetProvider>
                    <ModalProvider>
                      <DrawerProvider>
                        <IonReactRouter>
                          <AppUrlListener />
                          <IonTabs>
                            <IonRouterOutlet id="main-content">
                              <Route
                                path="/:tab(tastings)"
                                component={BrowseTastingPage}
                                exact={true}
                              />
                              <Route
                                path="/:tab(tastings)/mytastings"
                                component={MyTastingsPage}
                                exact={true}
                              />
                              <Route
                                path="/:tab(tastings)/details/:tastingId"
                                component={ViewTastingPage}
                                exact={true}
                              />
                              <Route
                                path="/:tab(tastings)/attendees/:tastingId"
                                component={ViewAttendeesPage}
                                exact={true}
                              />
                              <Route
                                path="/:tab(tastings)/details/editWineList/:tastingId"
                                component={EditWineListForTastingPage}
                                exact={true}
                              />
                              <Route
                                path="/:tab(tastings)/details/room/:tastingId"
                                component={ViewTastingRoomPage}
                                exact={true}
                              />
                              <Route
                                path="/:tab(tastings)/details/results/:tastingId"
                                component={ViewTastingResultsPage}
                                exact={true}
                              />
                              <Route
                                path="/:tab(tastings)/details/room/wineScore/:wineId"
                                component={WineScoreSummaryPage}
                                exact={true}
                              />
                              <Route
                                path="/:tab(userProfile)/changePassword"
                                component={ChangePasswordPage}
                                exact={true}
                              />
                              <Route
                                path="/ResetPassword"
                                component={ResetPasswordPage}
                                exact={true}
                              />
                              <Route
                                path="/:tab(userProfile)"
                                component={UserProfilePage}
                                exact={true}
                              />
                              <Route
                                path="/:tab(myWines)"
                                component={MyWinesPage}
                                exact={true}
                              />
                              <Route
                                path="/:tab(myWines)/wineScore/:wineId"
                                component={WineScoreSummaryPage}
                                exact={false}
                              />
                              <Route path="/404" component={NotFoundPage} exact={true} />
                              <Route path="/privacy" component={PrivacyPage} exact />
                              <Route
                                path="/"
                                render={(): any => <Redirect to="/tastings" />}
                                exact={true}
                              />
                              <Redirect to="/404" />
                            </IonRouterOutlet>
                            <IonTabBar slot="bottom">
                              <IonTabButton tab="tastings" href="/tastings">
                                <TabLabel icon={faCalendarAlt}>
                                  <FormattedMessage id="appTabBar.labels.tastings" />
                                </TabLabel>
                              </IonTabButton>
                              <IonTabButton tab="wine" href="/myWines">
                                <TabLabel icon={faStar}>
                                  <FormattedMessage id="appTabBar.labels.myRatings" />
                                </TabLabel>
                              </IonTabButton>
                              <IonTabButton
                                data-cy="myProfileBtn"
                                tab="user"
                                href="/userProfile"
                              >
                                <TabLabel icon={faUserCircle}>
                                  <FormattedMessage id="appTabBar.labels.myProfile" />
                                </TabLabel>
                              </IonTabButton>
                            </IonTabBar>
                          </IonTabs>
                          <SessionManagementContainer />
                        </IonReactRouter>
                        <AddFlightToTastingModal />
                        <AddWineToTastingModal />
                        <GeoLocationFilterModal />
                        <WineScoreSummaryModal />
                        <AddWineForScoringContainer />
                        <SearchMasterWinesModal />
                        <AuthorizationModal />
                        <CreateTastingModal />
                        <EditTastingModal />
                        <EditFlightInTastingModal />
                        <EditWineInTastingModal />
                        <InviteAttendeesModal />
                        <ScoreAWineModal />
                        <DateFilterModal />
                        <PrivacyModal />
                      </DrawerProvider>
                    </ModalProvider>
                  </ActionSheetProvider>
                </ToastProvider>
              </QueryClientProvider>
              {/* </Suspense> */}
            </AuthorizationProvider>
          </AppSettingsProvider>
        </IntlProvider>
      </Provider>
    </IonApp>
  );
};
export default App;
