import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgBlackBerryIcon(props) {
  return (
    <svg width={16} height={18} fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.335 8.893a2.799 2.799 0 01-.052.258l-.013.055a3.078 3.078 0 01-1.37 1.843 3.293 3.293 0 01.603 2.498c-.014.088-.033.17-.05.246-.243.984-.884 1.644-1.646 2.025a3.796 3.796 0 01-1.764.38c-.58 1.85-3.163 2.424-4.483 1.02a2.498 2.498 0 01-.113-.13l-.126.066c-1.254.625-2.843.488-3.826-.558-.786-.836-1.038-2.077-.698-3.164a2.435 2.435 0 01-1.088-.671C-.277 11.71-.23 9.989.821 9c.198-.184.403-.335.617-.447a3.03 3.03 0 01-.414-2.394c.129-.523.4-1.019.825-1.419.912-.857 2.18-1.106 3.311-.55a2.862 2.862 0 015.02-.381c.187-.614.212-1.378-.142-2.345A1.093 1.093 0 0112.092.72c.674 1.846.496 3.2.044 4.232a3.25 3.25 0 011.49.416 3.46 3.46 0 011.32 1.323c.36.636.521 1.4.39 2.202zm-2.85-1.816a.545.545 0 01.137-1.082c.273.036.651.173.958.433.244.206.449.488.542.855a.544.544 0 01-.397.663.545.545 0 01-.663-.397.562.562 0 00-.186-.288.808.808 0 00-.392-.184zm-3.86.798a.542.542 0 01-.471-.609.544.544 0 01.608-.473c.274.035.652.172.959.432.243.206.449.488.542.855a.547.547 0 11-1.063.266.538.538 0 00-.186-.285.808.808 0 00-.39-.186zM4.163 5.752A.546.546 0 114.3 4.67c.271.033.652.172.959.43.243.205.449.49.542.857a.55.55 0 01-.4.663.546.546 0 01-.663-.397.551.551 0 00-.186-.288.8.8 0 00-.389-.183zm3.892-1.498a.547.547 0 11.436-1.003c.205.09.402.222.561.38a.548.548 0 01-.78.767.683.683 0 00-.217-.144zM2.805 9.78a.548.548 0 01.435-1.003c.414.181.745.493.912.915a.546.546 0 01-1.013.406.587.587 0 00-.334-.318zm2.643 3.256a.546.546 0 01.137-1.081c.69.085 1.328.605 1.5 1.287a.549.549 0 01-1.063.266c-.06-.244-.333-.441-.574-.472zm3.45 1.217a.548.548 0 01.436-1.003c.414.181.745.493.915.915a.547.547 0 01-1.016.405.604.604 0 00-.334-.317zm3.162-2.44a.544.544 0 11.436-1c.413.18.744.492.911.914a.546.546 0 01-1.013.405.6.6 0 00-.334-.32z"
        fill="var(--ion-color-dark)"
      />
    </svg>
  );
}

export default SvgBlackBerryIcon;
