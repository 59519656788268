import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgBreadIcon(props) {
  return (
    <svg width={18} height={18} fill="none" {...props}>
      <path
        d="M14.197 17.69H1.966a.358.358 0 01-.358-.357V6.346C.541 5.984 0 5.391 0 4.578 0 2.526 3.55.918 8.08.918c4.532 0 8.083 1.608 8.083 3.66 0 .812-.541 1.406-1.608 1.768v10.987c0 .197-.16.357-.358.357zm-11.873-.715h11.515V6.082c0-.16.107-.3.26-.344 1.209-.342 1.348-.825 1.348-1.16 0-1.421-2.96-2.943-7.366-2.943S.716 3.157.716 4.579c0 .334.14.817 1.348 1.159.154.044.26.185.26.344v10.893z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M14.197 17.69a.358.358 0 01-.16-.678l1.64-.82V5.165c0-.16.106-.3.26-.345 1.208-.342 1.348-.825 1.348-1.16 0-1.421-2.96-2.943-7.366-2.943-2.385 0-4.646.485-6.045 1.3a.358.358 0 01-.36-.62C5.042.51 7.375 0 9.92 0 14.45 0 18 1.607 18 3.66c0 .812-.54 1.405-1.608 1.767v10.987c0 .135-.077.259-.198.32l-1.837.919a.352.352 0 01-.16.037z"
        fill="var(--ion-color-dark)"
      />
    </svg>
  );
}

export default SvgBreadIcon;
