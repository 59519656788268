import { faGlobe, faMapMarkerAlt, faUserFriends } from '@fortawesome/pro-solid-svg-icons';
import { Divider } from 'components/baseElements/Divider';
import { Box, Flex } from 'components/baseElements/grid';
import { Hideable } from 'components/baseElements/hideable';
import { Loadable } from 'components/baseElements/loadable';
import { Heading, Paragraph } from 'components/baseElements/typography';
import { AbridgedText } from 'components/baseElements/typography/AbridgedText';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { RootState } from 'store';
import { getTastingAttendeesWhoAreGoingWithMax } from 'store/tastingAttendees';
import { ETastingLocationType, Tasting, TastingWithAttendee, toMyTastingVm } from 'store/tastings';
import DetailItem from './DetailItem';

interface TastingDetailProperties {
  isTastingRoom?: boolean;
  isLoading?: boolean;
  tasting?: TastingWithAttendee;
}

function createMarkup(html: string | undefined): any {
  return { __html: html };
}

const renderLocationRow = (tasting?: Tasting): JSX.Element => {
  if (tasting?.locationType === ETastingLocationType.Virtual) {
    return (
      <DetailItem
        data-cy="virtualTastingLocation"
        disabled
        icon={faGlobe}
      >
        <FormattedMessage id="tastingForm.labels.virtualTasting"/>
      </DetailItem>
    );
  }
  const getFullAddress = (): JSX.Element => {
    if (!tasting?.address1 && !tasting?.address2 && !tasting?.city && !tasting?.stateProvince && !tasting?.zipCode) {
      return <>{tasting?.locationType === ETastingLocationType.InPerson ? 'In-Person Tasting' : 'Virtual Tasting'}</>;
    }
    return (
      <>
        {tasting?.address1}<br/>{tasting?.address2 ? <>{tasting?.address2}<br/></> : <></>}
        {tasting?.city}{tasting?.stateProvince ? ', ' + tasting.stateProvince : ''}{tasting?.zipCode ? ' ' + tasting.zipCode : ''}
      </>
    );
  };
  if (tasting?.locationType === ETastingLocationType.InPerson) {
    return (
      <DetailItem
        data-cy="inPersonTastingLocation"
        disabled
        icon={faMapMarkerAlt}
      >
        {getFullAddress()}
      </DetailItem>
    );
  }
  return <></>;
};

const TastingDetail: FunctionComponent<TastingDetailProperties> = (
  props: TastingDetailProperties,
) => {
  const {
    isTastingRoom,
    isLoading,
    tasting,
  } = props;
  const tastingRoomDescription = <span dangerouslySetInnerHTML={createMarkup(tasting?.tasting?.roomDescription)}/>;
  const numberOfHoursUntilEndOfTasting = useSelector((state: RootState): number => state.appSettings.numberOfHoursUntilEndOfTasting);
  const history = useHistory();
  const vm = tasting ? toMyTastingVm(tasting, numberOfHoursUntilEndOfTasting) : tasting;
  const zone = new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2];
  const isHost = !!vm?.attendee?.isHost;

  return (
    <>
      <Loadable
        isLoading={isLoading}
      >
        <Loadable.Loaded>
          <Flex
            flexDirection="column"
            pl={3}
            width={1}
          >
            <Flex alignItems="center" flexDirection="row" width={1}>
              {!isTastingRoom && (
                <Flex flex={1} flexWrap="wrap">
                  {vm?.tasting?.isHappeningNow && (
                    <Paragraph
                      color="var(--ion-color-primary)"
                      data-cy="happeningNowText"
                      $fontWeight="bold"
                      mb={0}
                      whiteSpace="nowrap"
                      width={1}
                    >
                      <FormattedMessage id="myTastings.text.happeningNow" />
                    </Paragraph>
                  )}
                  <Paragraph
                    mt={vm?.tasting?.isHappeningNow && 0}
                    whiteSpace="nowrap"
                    width={1}
                  >
                    {vm?.tasting?.dateTime} {zone}
                  </Paragraph>
                </Flex>
              )}
            </Flex>
            <Box pr={3}>
              <Heading
                data-cy="tastingDetailName"
                headingSize="xl"
                mt={!isTastingRoom && -1}
              >
                {vm?.tasting?.name}
              </Heading>
              <Hideable hide={!!isTastingRoom}>
                <Flex
                  alignItems="flex-start"
                  flexDirection="column"
                  pb={2}
                >
                  {renderLocationRow(vm?.tasting)}
                  <Hideable hide={!vm?.tasting?.showAttendees && !isHost}>
                    <DetailItem
                      icon={faUserFriends}
                      onClick={(): void => history.push(`/tastings/attendees/${vm?.tasting.id}`)}
                    >
                      <FormattedMessage id="tastingDetail.labels.attendees"/>{getTastingAttendeesWhoAreGoingWithMax(vm?.attendeeList, vm?.tasting?.maxAttendeesCount)}
                    </DetailItem>
                  </Hideable>
                </Flex>
              </Hideable>
              <AbridgedText>
                {isTastingRoom ? tastingRoomDescription : vm?.tasting?.description}
              </AbridgedText>
              <Divider $width={1}/>
            </Box>
          </Flex>
        </Loadable.Loaded>
      </Loadable>
    </>
  );
};

export default TastingDetail;
