import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgBlackCherryIcon(props) {
  return (
    <svg width={18} height={18} fill="none" {...props}>
      <path
        d="M8.334 9.963a2.738 2.738 0 01-.088-.625l.003-.1a2.498 2.498 0 00-1.946-.31A1.73 1.73 0 014.681 8.5a2.44 2.44 0 00-1.773-.7c-.666.011-1.287.281-1.75.76a2.47 2.47 0 00-.351.462 5.842 5.842 0 00.481 6.621 3.862 3.862 0 004.42 1.174 5.805 5.805 0 003.661-4.741 6.007 6.007 0 01-1.035-2.112zM3.211 8.699a1.608 1.608 0 00-1.85 1.33c-.02.124-.027.25-.018.377a.106.106 0 01-.098.112l-.008.001a.107.107 0 01-.105-.098 1.813 1.813 0 01.755-1.611 1.82 1.82 0 011.36-.319c.057.01.096.064.086.122a.108.108 0 01-.122.086z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M16.207 5.393a2.476 2.476 0 00-1.72-.692 2.472 2.472 0 00-2.151 1.233 1.71 1.71 0 01-1.446.851A2.494 2.494 0 008.539 9.91a5.843 5.843 0 005.05 4.31 3.868 3.868 0 003.94-2.32 5.842 5.842 0 00-1.322-6.506zm-5.296 2.271c-.43.01-.831.187-1.128.499a1.601 1.601 0 00-.394 1.522.106.106 0 11-.204.054 1.812 1.812 0 01.444-1.722c.337-.352.79-.553 1.277-.564.063.005.107.045.109.104a.106.106 0 01-.104.107z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M5.303 8.655c.134.054.274.088.416.103A11.623 11.623 0 006.102.622a11.203 11.203 0 015.336 5.834c.133-.056.256-.132.368-.224A11.63 11.63 0 005.824.02a.212.212 0 00-.286.266 11.21 11.21 0 01-.235 8.37z"
        fill="var(--ion-color-dark)"
      />
    </svg>
  );
}

export default SvgBlackCherryIcon;
