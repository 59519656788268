import { yupResolver } from '@hookform/resolvers/yup';
import { IonContent, IonItem, IonList } from '@ionic/react';
import HookInput from 'components/baseElements/formControls/HookInput';
import Form from 'components/baseElements/formControls/StyledForm';
import { IonLabel, IonRange } from 'components/ionicComponents';
import SliderStepLabel from 'components/wineScores/SliderStepLabel';
import { StepBaseProperties } from 'components/wineScores/types';
import { ScoreAWineHeader } from 'containers/wineScores/ScoreAWineContainer/ScoreAWineHeader';
import React, { FunctionComponent, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { handleFormDataReset } from 'utils/formValidation';
import StepBottomButtons from '../StepBottomButtons';
import StepHeader from '../StepHeader';
import StepMidHeaderButtons from '../StepMidHeaderButtons';

const ScoreAWinePalateStep: FunctionComponent<StepBaseProperties> = (
  props: StepBaseProperties,
) => {
  const {
    loadingStatus,
    modalId,
    onSaveAndClose,
    stepName,
    nextAction,
    previousAction,
    validationSchema,
    score,
    nextStepName,
    showNextStepButton,
    previousStepName,
    showPreviousStepButton,
    stepNumber,
    totalSteps,
  } = props;

  const form = useForm({
    defaultValues: {
      body: 50,
      complexity: 50,
      finish: 50,
    },
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    handleFormDataReset(score, form.reset);
  }, [score, form.reset]);

  return (
    <>
      <ScoreAWineHeader
        modalId={modalId}
        onSaveAndClose={(): any => onSaveAndClose(form.getValues())}
        stepName={stepName}
      />
      <IonContent>
        <StepMidHeaderButtons
          loadingStatus={loadingStatus}
          nextClickedAction={(): any => nextAction(form.getValues())}
          previousClickedAction={(): any => previousAction(form.getValues())}
          nextStepName={nextStepName}
          showNextStepButton={showNextStepButton}
          previousStepName={previousStepName}
          showPreviousStepButton={showPreviousStepButton}
        />
        <StepHeader
          modalId={modalId}
          stepName="scoreWine.palateStep.title"
          stepNumber={stepNumber}
          totalSteps={totalSteps}
        />
        <Form>
          <IonList lines="none">
            <IonItem>
              <SliderStepLabel field="body" stepName="palateStep" value={form.watch('body')}/>
            </IonItem>
            <HookInput
              form={form}
              name="body"
            >
              <IonRange min={0} max={100} step={25} snaps={true}>
                <IonLabel slot="start">
                  <FormattedMessage id="scoreWine.palateStep.labels.bodyStart"/>
                </IonLabel>
                <IonLabel slot="end">
                  <FormattedMessage id="scoreWine.palateStep.labels.bodyEnd"/>
                </IonLabel>
              </IonRange>
            </HookInput>
            <IonItem>
              <SliderStepLabel field="complexity" stepName="palateStep" value={form.watch('complexity')}/>
            </IonItem>
            <HookInput
              form={form}
              name="complexity"
            >
              <IonRange min={0} max={100} step={25} snaps={true}>
                <IonLabel slot="start">
                  <FormattedMessage id="scoreWine.palateStep.labels.complexityStart"/>
                </IonLabel>
                <IonLabel slot="end">
                  <FormattedMessage id="scoreWine.palateStep.labels.complexityEnd"/>
                </IonLabel>
              </IonRange>
            </HookInput>
            <IonItem>
              <SliderStepLabel field="finish" stepName="palateStep" value={form.watch('finish')}/>
            </IonItem>
            <HookInput
              form={form}
              name="finish"
            >
              <IonRange min={0} max={100} step={25} snaps={true}>
                <IonLabel slot="start">
                  <FormattedMessage id="scoreWine.palateStep.labels.finishStart"/>
                </IonLabel>
                <IonLabel slot="end">
                  <FormattedMessage id="scoreWine.palateStep.labels.finishEnd"/>
                </IonLabel>
              </IonRange>
            </HookInput>
          </IonList>
        </Form>
      </IonContent>
      <StepBottomButtons
        loadingStatus={loadingStatus}
        nextClickedAction={(): any => nextAction(form.getValues())}
        previousClickedAction={(): any => previousAction(form.getValues())}
        nextStepName={nextStepName}
        onSaveAndClose={(): any => onSaveAndClose(form.getValues(), true)}
        showNextStepButton={false}
        previousStepName={previousStepName}
        showPreviousStepButton={false}
      />
    </>
  );
};

export default ScoreAWinePalateStep;
