import { IonCard, IonText, isPlatform } from '@ionic/react';
import TastingBg from 'assets/images/tastingbg-01.jpg';
import { Box, Flex } from 'components/baseElements/grid';
import Image from 'components/baseElements/Image';
import { Loadable } from 'components/baseElements/loadable';
import { Heading, Paragraph } from 'components/baseElements/typography';
import { IonButton } from 'components/ionicComponents';
import { CreateTastingModalId } from 'modals/CreateTastingModal';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { tastingAttendeeIsGoing } from 'store/tastingAttendees';
import { BrowseTastingResponseData, toMyTastingVm } from 'store/tastings';
import { getImageUrl } from 'utils/dataFormat/strings';
import { LoadingStatus } from 'utils/formValidation';
import { showModal } from 'utils/modals';
import AttendeeStatus from '../../attendee/AttendeeStatus';
import ViewMoreTastingsButton from '../ViewMoreTastingsButton';

interface MyTastingsProperties {
  browseTastingLoadingStatus?: LoadingStatus;
  browseTastings?: BrowseTastingResponseData;
  createTastingModalCallback?: (result: any) => any;
}

export default function MyTastings(props: MyTastingsProperties): JSX.Element {
  const {
    browseTastingLoadingStatus,
    browseTastings,
    createTastingModalCallback,
  } = props;
  const isLoading = browseTastingLoadingStatus === LoadingStatus.Loading;
  const numberOfHoursUntilEndOfTasting = useSelector((state: RootState): number => state.appSettings.numberOfHoursUntilEndOfTasting);
  const dispatch = useDispatch();
  const apiBaseUrl = useSelector((state: RootState): string => state.appSettings.apiBaseUrl);
  const cardSize = 190;
  return (
    <Flex
      alignItems="center"
      background="rgba(var(--ion-color-secondary-rgb), 0.21)"
      flexDirection="column"
      minHeight={200}
      pb={1}
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
        pt={1}
        px={3}
        width={1}
      >
        <Heading headingSize="sm" textAlign="center">
          <FormattedMessage id="myTastings.header.caption" />
        </Heading>
        <IonButton
          color="secondary"
          onClick={(): void => showModal(dispatch, CreateTastingModalId, { callback: createTastingModalCallback })}
        >
          <FormattedMessage id="getStarted.button.hostTasting" />
        </IonButton>
      </Flex>

      <Loadable
        isLoading={isLoading}
      >
        <Loadable.Loaded>
          <Box overflow="auto" width={1}>
            <Flex alignItems="flex-start" data-cy="tastingCardContainer">
              {browseTastings ? browseTastings.items.map(item => {
                const { tasting: tastingVm, attendee } = toMyTastingVm(item, numberOfHoursUntilEndOfTasting);
                const viewTastingDetailUrl = tastingAttendeeIsGoing(attendee) && tastingVm?.isHappeningNow ?
                  `/tastings/details/room/${tastingVm.id}` :
                  `/tastings/details/${tastingVm.id}`;
                return (
                  <Box
                    key={tastingVm.id}
                    mx={isPlatform('ios') ? -2 : -1}
                    width={cardSize}
                  >
                    <IonCard
                      data-cy={`tastingCard${tastingVm.name.replace(/\s+/g, '')}`}
                      routerLink={viewTastingDetailUrl}
                    >
                      <Image
                        alt={tastingVm.name}
                        height={cardSize * (1 / 2.2)}
                        objectFit="cover"
                        objectPosition="center"
                        src={getImageUrl(apiBaseUrl, tastingVm?.tastingImageFileId, TastingBg)}
                        width={1}
                      />
                      <Box px={3} py={2}>
                        <Paragraph fontSize={tastingVm.isHappeningNow ? '14px' : '12px'} m={0}>
                          {tastingVm.isHappeningNow ? (
                            <IonText color="primary">
                              <FormattedMessage id="myTastings.text.happeningNow" />
                            </IonText>
                          ) : tastingVm.dateTime}
                        </Paragraph>
                        <Paragraph
                          ellipsis={2}
                          mb={0}
                          mt={1}
                          $fontWeight="600"
                          $width={1}
                        >
                          <IonText
                            data-cy={`tastingCardLabel${tastingVm.name.replace(/\s+/g, '')}`}
                            color="dark"
                          >
                            {tastingVm.name}
                          </IonText>
                        </Paragraph>
                      </Box>
                      <AttendeeStatus attendee={attendee} />
                    </IonCard>
                  </Box>
                );
              }) : undefined}
            </Flex>
          </Box>
        </Loadable.Loaded>
      </Loadable>
      <ViewMoreTastingsButton />
    </Flex>
  );
}
