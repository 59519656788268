import React from 'react';
import {
  IonButtons,
  IonHeader,
  IonToolbar,
} from '@ionic/react';
import { IonTitle } from 'components/ionicComponents';

interface HeaderWithButtonsProperties {
  children?: React.ReactNode;
  color?: string | undefined;
  ionButtonsEnd?: React.ReactNode;
  ionButtonsEndProps?: any;
  ionButtonsStart?: React.ReactNode;
  ionButtonsStartProps?: any;
}

export function HeaderWithButtons(props: HeaderWithButtonsProperties): JSX.Element {
  const {
    children,
    color = 'primary',
    ionButtonsEnd,
    ionButtonsEndProps,
    ionButtonsStart,
    ionButtonsStartProps
  } = props;
  return (
    <IonHeader>
      <IonToolbar color={color}>
        {ionButtonsStart && (
          <IonButtons slot="start" {...ionButtonsStartProps}>
            {ionButtonsStart}
          </IonButtons>
        )}
        <IonTitle data-cy="pageTitle">
          {children}
        </IonTitle>
        {ionButtonsEnd && (
          <IonButtons slot="end" {...ionButtonsEndProps}>
            {ionButtonsEnd}
          </IonButtons>
        )}
      </IonToolbar>
    </IonHeader>
  );
}
