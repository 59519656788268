import { IonItem, IonList } from '@ionic/react';
import { Box, Flex } from 'components/baseElements/grid';
import { Loadable } from 'components/baseElements/loadable';
import { Heading } from 'components/baseElements/typography';
import { AbridgedText } from 'components/baseElements/typography/AbridgedText';
import { IonText } from 'components/ionicComponents';
import WineAvatar from 'components/wine/WineAvatar';
import WineRating from 'components/wine/WineRating';
import dayjs from 'dayjs';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Tasting } from 'store/tastings';
import { Wine, WineStatus } from 'store/wines';
import { FormattedWine } from 'utils/dataFormat/FormattedWine';
import { LoadingStatus } from 'utils/formValidation';
import { SummaryValue } from '../ScoreAWineSteps/SummaryValue';
import ScoreSummaryItem from './ScoreSummaryItem';
import ScoreSummaryStep from './ScoreSummaryStep';

interface WineScoreSummaryProps {
  loadingStatus?: LoadingStatus;
  wine?: Wine;
  tasting?: Tasting;
  editClickHandler?: (wine?: Wine) => void;
  scoreClickHandler: (scoreStep: number) => any;
}

const ScoreSummary: FunctionComponent<WineScoreSummaryProps> = (
  props: WineScoreSummaryProps,
) => {
  const {
    editClickHandler,
    loadingStatus,
    wine,
    tasting,
    scoreClickHandler,
  } = props;

  const renderSummaryItems = (fields: Array<string>, stepName: string): JSX.Element[] => fields.map(field => (
      <ScoreSummaryItem key={field}
        label={`scoreWine.${stepName}.labels.${field}`}
      >
      <AbridgedText>
        {SummaryValue({
          field,
          value: wine?.scoring?.userScore?.[field] as string
        })}
      </AbridgedText>
    </ScoreSummaryItem>
    )
  );

  const isLoading = loadingStatus === LoadingStatus.Loading;
  return (
    <>
      <Loadable
        isLoading={isLoading}
      >
        <Loadable.Loaded>
          <IonList lines="full">
            <IonItem lines="none">
              <Flex
                alignItems="center"
                flexDirection="column"
                width={1}
              >
                <Heading
                  headingSize="xs"
                  $ellipsis={2}
                  mb={0}
                  textAlign="center"
                  width={1}
                >
                  <FormattedWine
                    canEdit={wine?.approvalStatus !== WineStatus.Approved}
                    wine={wine}
                    onEditWineClick={(): void => editClickHandler && editClickHandler(wine)}
                  />
                </Heading>
                <IonText
                  color="primary"
                  $ellipsis
                  $textAlign="center"
                >
                  {tasting?.name}
                </IonText>
                <IonText
                  $ellipsis
                  $textAlign="center"
                >
                  <FormattedMessage id="scoreWine.summary.header.dateCaption" />
                  {dayjs(wine?.scoring?.userScore?.updatedAt).format('MMM D, YYYY')}
                </IonText>
              </Flex>
            </IonItem>
            <ScoreSummaryStep
              label="scoreWine.ratingStep.title"
              scoreClickHandler={scoreClickHandler}
              scoreStep={1}
            >
              <Flex
                alignItems="center"
                justifyContent="space-evenly"
                py={3}
                width={1}
              >
                <WineAvatar
                  size={112}
                  useIonAvatar={false}
                  wine={wine}
                />
                <WineRating
                  score={wine?.scoring?.userScore?.rating}
                  scoreClickHandler={scoreClickHandler}
                  scoreStep={1}
                  size={56}
                />
              </Flex>
              <IonText fontWeight="bold">
                <AbridgedText>
                  {wine?.scoring?.userScore?.['notes']}
                </AbridgedText>
              </IonText>
            </ScoreSummaryStep>
            <ScoreSummaryStep label="scoreWine.visualStep.title" scoreClickHandler={scoreClickHandler} scoreStep={2}>
              <ScoreSummaryItem
                alignItems="center"
                label="scoreWine.visualStep.labels.color"
              >
                <Box
                  backgroundColor={wine?.scoring?.userScore?.color}
                  border="1px solid rgba(var(--ion-color-medium-rgb), 0.2)"
                  borderRadius={7}
                  height={21}
                />
              </ScoreSummaryItem>
              {renderSummaryItems(['brightness', 'clarity'], 'visualStep')}
            </ScoreSummaryStep>
            <ScoreSummaryStep label="scoreWine.aromaStep.title" scoreClickHandler={scoreClickHandler} scoreStep={3}>
              {renderSummaryItems(['aromas'], 'aromaStep')}
            </ScoreSummaryStep>
            <ScoreSummaryStep label="scoreWine.tasteStep.title" scoreClickHandler={scoreClickHandler} scoreStep={4}>
              {renderSummaryItems(['sweetness', 'acidity', 'alcohol', 'tannins'], 'tasteStep')}
            </ScoreSummaryStep>
            <ScoreSummaryStep label="scoreWine.flavorsStep.title" scoreClickHandler={scoreClickHandler} scoreStep={5}>
              {renderSummaryItems(['flavors'], 'flavorsStep')}
            </ScoreSummaryStep>
            <ScoreSummaryStep label="scoreWine.palateStep.title" scoreClickHandler={scoreClickHandler} scoreStep={6}>
              {renderSummaryItems(['body', 'complexity', 'finish'], 'palateStep')}
            </ScoreSummaryStep>
          </IonList>
        </Loadable.Loaded>
      </Loadable>
    </>
  );
}

export default ScoreSummary;
