import { IonAvatar } from '@ionic/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { getImageUrl } from '../../../utils/dataFormat/strings';
import { Flex } from '../grid';
import { Hideable } from '../hideable';
import { Heading } from '../typography';


interface UserAvatarProps {
  avatarId?: string;
  border?: number;
  fullName?: string;
  headingSize?: string;
  size: number;
  slot?: string;
}

export default function UserAvatar(props: UserAvatarProps): JSX.Element {
  const apiBaseUrl = useSelector((state: RootState): string => state.appSettings.apiBaseUrl);
  const { avatarId, border, fullName, headingSize, size, slot } = props;
  const borderCss = border ? `${border}px solid var(--ion-color-primary)` : undefined;
  return (
    <IonAvatar slot={slot}>
      <Hideable hide={!!avatarId}>
        <Flex
          alignItems="center"
          backgroundColor="var(--ion-color-medium)"
          borderRadius="50%"
          border={borderCss}
          justifyContent="center"
          size={size}
        >
          <Heading
            color="var(--ion-color-light)"
            fontFamily="'Open Sans', sans-serif"
            headingSize={headingSize ?? 'xs'}
            m={0}
          >
            {(fullName || '').charAt(0).toUpperCase()}
          </Heading>
        </Flex>
      </Hideable>
      <Hideable hide={!avatarId}>
        <Flex
          alignItems="center"
          backgroundColor="var(--ion-color-medium)"
          backgroundImage={'url(' + getImageUrl(apiBaseUrl || '', avatarId || '') + ')'}
          backgroundSize="cover"
          borderRadius="50%"
          border={borderCss}
          justifyContent="center"
          size={size}
        />
      </Hideable>
    </IonAvatar>
  );
}
