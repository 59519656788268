import { IonPage } from '@ionic/react';
import MyTastingsContainer from 'containers/tastings/MyTastingsContainer';
import React from 'react';

const ViewTastingPage = (): any => {

  return (
    <IonPage>
      <MyTastingsContainer/>
    </IonPage>
  );
};

export default ViewTastingPage;
