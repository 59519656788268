import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgOreganoIcon(props) {
  return (
    <svg width={19} height={20} fill="none" {...props}>
      <path
        d="M9.589 5.783C9.438 7.664 7.767 9.31 5.857 9.458c-2.902.225-5.07-1.909-4.84-4.766l.272-3.407 3.46-.269C7.65.791 9.817 2.925 9.589 5.783zM13.997 8.403c-1.511-.117-2.833-1.42-2.952-2.907-.181-2.26 1.533-3.949 3.828-3.77l2.737.212.215 2.695c.181 2.26-1.533 3.948-3.828 3.77zM6.413 10.98c1.069.273 1.85 1.371 1.743 2.454-.162 1.643-1.61 2.64-3.232 2.225l-1.935-.495.193-1.96c.162-1.643 1.608-2.64 3.231-2.224zM11.858 13.273c-.35-1.032.162-2.274 1.142-2.775 1.488-.761 3.124-.108 3.654 1.458l.633 1.868-1.775.907c-1.488.761-3.124.108-3.654-1.458zM15.516 3.903s-4.171 1.51-5.918 8.625M15.516 13.194s-3.637-2.8-5.706.288M4.774 13.484c1.45-1.612 4.645-2.775 5.147.579M3.613 3.903S10.29 6.3 10.29 19M8.108 8.518S6.754 6.215 7.097 3.613"
        stroke="var(--ion-color-dark)"
        strokeWidth={0.5}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
    </svg>
  );
}

export default SvgOreganoIcon;
