import React from 'react';
import { Box, Flex, Spacer } from '@chakra-ui/react';
import { Hideable } from 'components/baseElements/hideable';
import { IonButton } from 'components/ionicComponents';
import { FormattedMessage } from 'react-intl';
import { WineListItemType } from '../WineListItem';
import { showAvgVsYouTitles, showTotalVsYouTitles } from './utils';
import { IonItem } from '@ionic/react';
import { Heading } from 'components/baseElements/typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faFileImport } from '@fortawesome/pro-light-svg-icons';

interface WineListForTastingActionBar {
  lines?: HTMLIonItemElement['lines'];
  showEditWineButton?: boolean;
  showRequestImportWineListButton?: boolean;
  showRankWinesButton?: boolean;
  tastingId?: string;
  wineListItemType: WineListItemType;
  onRankWinesClick?: () => void;
  onRequestImportWineListClick?: () => void;
}

export default function WineListForTastingActionBar(props: WineListForTastingActionBar): JSX.Element {
  const {
    lines,
    onRankWinesClick,
    onRequestImportWineListClick,
    showEditWineButton = false,
    showRequestImportWineListButton = false,
    showRankWinesButton = false,
    wineListItemType = WineListItemType.Default,
    tastingId
  } = props;

  return (
    <IonItem lines={lines}>
      <Heading headingSize="xs" m={0} textAlign="center" width={showEditWineButton ? 'auto' : '100%'}>
        <FormattedMessage id="wineTastingList.header.caption"/>
      </Heading>
      <Hideable hide={!showEditWineButton}>
        <IonButton
          color="secondary"
          fill="clear"
          routerLink={`/tastings/details/editWineList/${tastingId}`}
          size="md"
        >
          <FontAwesomeIcon icon={faEdit} size="lg" />
        </IonButton>
        <Spacer />
      </Hideable>
      <Hideable hide={!showAvgVsYouTitles(wineListItemType)}>
        <Flex flexDirection="row" pr="8px" textAlign="center">
          <Box fontSize="14px" width="35px" pr="8px">
            <FormattedMessage id="wineTastingList.text.avgScore"/>
          </Box>
          <Box fontSize="14px" width="35px">
            <FormattedMessage id="wineTastingList.text.yourScore"/>
          </Box>
        </Flex>
      </Hideable>
      <Hideable hide={!showTotalVsYouTitles(wineListItemType)}>
        <Flex flexDirection="row" pr="8px" textAlign="center">
          <Box fontSize="14px" width="50px" pr="8px" whiteSpace="nowrap">
            <FormattedMessage id="wineTastingList.text.totalRank"/>
          </Box>
          <Box fontSize="14px" width="30px">
            <FormattedMessage id="wineTastingList.text.yourRank"/>
          </Box>
        </Flex>
      </Hideable>
      <Hideable hide={!showRankWinesButton}>
        <IonButton
          color="secondary"
          onClick={(): void => onRankWinesClick && onRankWinesClick()}
          size="md"
        >
          <FormattedMessage id="rankWinesTastingListButton.title.caption"/>
        </IonButton>
      </Hideable>
      <Hideable hide={!showRequestImportWineListButton}>
        <IonButton
          fill="clear"
          onClick={(): void => onRequestImportWineListClick && onRequestImportWineListClick()}
          size="md"
        >
          <FontAwesomeIcon icon={faFileImport} size="lg" />
        </IonButton>
      </Hideable>
    </IonItem>
  );
}
