import { IonTextarea as IonTextareaBase } from '@ionic/react';
import styled from 'styled-components';
import {
  layout,
  space,
  typography,
} from 'styled-system';
import { ellipsis, transient } from 'utils/styledHelpers';

const IonTextarea = styled(IonTextareaBase)`
  ${transient(layout)}
  ${transient(space)}
  ${transient(typography)}
  ${ellipsis}
  min-height: 25vh;
  textarea {
    min-height: 25vh;
  }
`;

export { IonTextarea };
