import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgSmokeIcon(props) {
  return (
    <svg width={11} height={18} fill="none" {...props}>
      <path
        d="M6.707 8.1c2.084.151 3.819-1.773 3.54-3.846C9.886 1.59 7.655-.136 5.28.008 2.945.15.747 1.735.17 4.47c-.72 3.417.9 5.448 3.527 6.91.944.524 3.108 1.575 3.67 2.58.584 1.046.575 2.817-.865 4.04 2.185-1.01 3.164-2.41 3.311-3.958.144-1.512-.647-2.951-1.655-3.743-1.762-1.385-3.343-1.555-3.597-3.31-.116-.799.374-1.583 1.258-1.383 1.178.266.826 1.734.096 2.324 1.26-.573 1.953-2.052 1.135-3.366-.667-1.07-2.07-1.469-3.227-.394.993-1.654 3.235-1.123 3.807-.416C8.825 5.23 8.08 7.098 6.707 8.1z"
        fill="var(--ion-color-dark)"
      />
    </svg>
  );
}

export default SvgSmokeIcon;
