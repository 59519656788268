export enum ETastingAttendeeFilter {
  Going = 'Going',
  Invited = 'Invited',
  CantGo = 'CantGo',
}

export enum ETastingAttendeeButtons {
  None,
  TastingRoom,
  ChangeStatus,
  NotInvited,
  Invited,
  Ended,
  Full,
}

export interface TastingAttendee {
  attendeeId?: string;
  email?: string;
  fullName?: string;
  id?: string;
  invited?: boolean;
  isGoing?: boolean;
  isHost: boolean;
  photoId?: string;
}

export interface TastingAttendeeStatus {
  attendeeId?: string;
  email: string;
  isGoing: boolean;
}

export type TastingAttendeesState = any;
