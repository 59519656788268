import { yupResolver } from '@hookform/resolvers/yup';
import { IonContent, IonItem, IonList } from '@ionic/react';
import HookInput from 'components/baseElements/formControls/HookInput';
import Form from 'components/baseElements/formControls/StyledForm';
import { IonLabel, IonRange } from 'components/ionicComponents';
import SliderStepLabel from 'components/wineScores/SliderStepLabel';
import { StepBaseProperties } from 'components/wineScores/types';
import { ScoreAWineHeader } from 'containers/wineScores/ScoreAWineContainer/ScoreAWineHeader';
import React, { FunctionComponent, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { handleFormDataReset } from 'utils/formValidation';
import StepBottomButtons from '../StepBottomButtons';
import StepHeader from '../StepHeader';
import StepMidHeaderButtons from '../StepMidHeaderButtons';

const ScoreAWineTasteStep: FunctionComponent<StepBaseProperties> = (
  props: StepBaseProperties,
) => {
  const {
    loadingStatus,
    modalId,
    onSaveAndClose,
    stepName,
    nextAction,
    previousAction,
    validationSchema,
    score,
    nextStepName,
    showNextStepButton,
    previousStepName,
    showPreviousStepButton,
    stepNumber,
    totalSteps
  } = props;

  const form = useForm({
    defaultValues: {
      sweetness: 50,
      acidity: 50,
      alcohol: 50,
      tannins: 60
    },
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    handleFormDataReset(score, form.reset);
  }, [score, form.reset]);

  return (
    <>
      <ScoreAWineHeader
        modalId={modalId}
        onSaveAndClose={(): any => onSaveAndClose(form.getValues())}
        stepName={stepName}
      />
      <IonContent>
        <StepMidHeaderButtons
          loadingStatus={loadingStatus}
          nextClickedAction={(): any => nextAction(form.getValues())}
          previousClickedAction={(): any => previousAction(form.getValues())}
          nextStepName={nextStepName}
          showNextStepButton={showNextStepButton}
          previousStepName={previousStepName}
          showPreviousStepButton={showPreviousStepButton}
        />
        <StepHeader
          modalId={modalId}
          stepName="scoreWine.tasteStep.title"
          stepNumber={stepNumber}
          totalSteps={totalSteps}
        />
        <Form>
          <IonList lines="none">
            <IonItem>
              <SliderStepLabel field="sweetness" stepName="tasteStep" value={form.watch('sweetness')} />
            </IonItem>
            <HookInput
              form={form}
              name="sweetness"
            >
              <IonRange
                min={0}
                max={100}
                step={25}
                snaps={true}
              >
                <IonLabel slot="start"><FormattedMessage id="scoreWine.tasteStep.labels.sweetnessStart" /></IonLabel>
                <IonLabel slot="end"><FormattedMessage id="scoreWine.tasteStep.labels.sweetnessEnd" /></IonLabel>
              </IonRange>
            </HookInput>
            <IonItem>
              <SliderStepLabel field="acidity" stepName="tasteStep" value={form.watch('acidity')} />
            </IonItem>
            <HookInput
              form={form}
              name="acidity"
            >
              <IonRange
                min={0}
                max={100}
                step={25}
                snaps={true}
              >
                <IonLabel slot="start"><FormattedMessage id="scoreWine.tasteStep.labels.acidityStart" /></IonLabel>
                <IonLabel slot="end"><FormattedMessage id="scoreWine.tasteStep.labels.acidityEnd" /></IonLabel>
              </IonRange>
            </HookInput>
            <IonItem>
              <SliderStepLabel field="alcohol" stepName="tasteStep" value={form.watch('alcohol')} />
            </IonItem>
            <HookInput
              form={form}
              name="alcohol"
            >
              <IonRange
                min={0}
                max={100}
                step={25}
                snaps={true}
              >
                <IonLabel slot="start"><FormattedMessage id="scoreWine.tasteStep.labels.alcoholStart" /></IonLabel>
                <IonLabel slot="end"><FormattedMessage id="scoreWine.tasteStep.labels.alcoholEnd" /></IonLabel>
              </IonRange>
            </HookInput>
            <IonItem>
              <SliderStepLabel field="tannins" stepName="tasteStep" value={form.watch('tannins')} />
            </IonItem>
            <HookInput
              form={form}
              name="tannins"
            >
              <IonRange
                min={0}
                max={100}
                step={20}
                snaps={true}
              >
                <IonLabel slot="start"><FormattedMessage id="scoreWine.tasteStep.labels.tanninsStart" /></IonLabel>
                <IonLabel slot="end"><FormattedMessage id="scoreWine.tasteStep.labels.tanninsEnd" /></IonLabel>
              </IonRange>
            </HookInput>
          </IonList>
        </Form>
      </IonContent>
      <StepBottomButtons
        loadingStatus={loadingStatus}
        nextClickedAction={(): any => nextAction(form.getValues())}
        previousClickedAction={(): any => previousAction(form.getValues())}
        nextStepName={nextStepName}
        onSaveAndClose={(): any => onSaveAndClose(form.getValues())}
        showNextStepButton={false}
        previousStepName={previousStepName}
        showPreviousStepButton={false}
      />
    </>
  );
}

export default ScoreAWineTasteStep;
