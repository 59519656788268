import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonToolbar } from '@ionic/react';
import { IonTitle } from 'components/ionicComponents';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import NotFoundContainer from 'containers/authorization/NotFoundContainer';

export default function NotFoundPage(): JSX.Element {
  const location = useLocation();
  const useQuery = (): URLSearchParams => new URLSearchParams(location.search);
  const query = useQuery();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Not Found</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <NotFoundContainer referrer={query.get('referrer') || ''} />
      </IonContent>
    </IonPage>
  );
}
