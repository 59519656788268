import * as React from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';
import { transient } from 'utils/styledHelpers';

const StyledForm = styled('form')`
  ${transient(space)}
`;

interface StyledFormProps extends React.HTMLAttributes<HTMLFormElement> {
  children?: React.ReactNode;
  noValidate?: boolean;
}

export default function Form(props: StyledFormProps): JSX.Element {
  const {
    children,
    ...otherProps
  } = props;

  return (
    <StyledForm{...otherProps}>
      {children}
    </StyledForm>
  );
}
