import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgCheeseIcon(props) {
  return (
    <svg
      width={18} height={18}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      {...props}
    >
      <path d="M6.826 88.758V47.827h3.112v37.638l80.124-4.616V39.632h3.112v44.151z"
        fill="var(--ion-color-dark)"
      />
      <path d="M9.014 49.976l-1.197-2.981 57.182-35.753 28.005 27.57-1.337 2.379-82.653 8.785zm55.552-34.797l-49.64 31.037 73.257-7.785-23.617-23.252zM35.401 78.217c-3.531 0-6.399-2.871-6.399-6.399s2.869-6.399 6.399-6.399c3.525 0 6.4 2.871 6.4 6.399s-2.875 6.399-6.4 6.399zm0-9.688a3.29 3.29 0 000 6.576 3.292 3.292 0 003.288-3.288 3.293 3.293 0 00-3.288-3.288zM48.358 64.039a6.406 6.406 0 01-6.399-6.4 6.405 6.405 0 016.399-6.398c3.524 0 6.399 2.871 6.399 6.398.001 3.528-2.874 6.4-6.399 6.4zm0-9.687a3.288 3.288 0 100 6.575 3.288 3.288 0 000-6.575zM74.291 62.689c-3.531 0-6.4-2.871-6.4-6.399s2.869-6.4 6.4-6.4c3.524 0 6.399 2.872 6.399 6.4s-2.875 6.399-6.399 6.399zm0-9.687a3.289 3.289 0 000 6.576 3.292 3.292 0 003.287-3.288 3.292 3.292 0 00-3.287-3.288zM10.982 71.721c-1.027 0-2.048-.186-3.118-.563l-1.039-.367V53.729l1.039-.368c6.527-2.294 12.581 2.659 12.581 8.9 0 5.218-4.248 9.46-9.463 9.46zm-1.044-3.213c4.029.76 7.396-2.406 7.396-6.247s-3.367-7.001-7.396-6.251v12.498zM28.723 59.195c-2.565 0-4.649-2.088-4.649-4.652s2.084-4.653 4.649-4.653c2.564 0 4.655 2.088 4.655 4.653s-2.091 4.652-4.655 4.652zm0-6.193c-.851 0-1.538.689-1.538 1.541 0 .851.687 1.54 1.538 1.54s1.543-.689 1.543-1.54c0-.852-.693-1.541-1.543-1.541zM55.165 85.975l-.443-.954a11.226 11.226 0 01-1.088-4.792c0-6.26 5.093-11.353 11.353-11.353S76.34 73.969 76.34 80.229c0 1.148-.2 2.318-.62 3.58l-.334 1.002-20.221 1.164zm9.821-13.987c-4.546 0-8.241 3.697-8.241 8.24 0 .845.14 1.689.413 2.516l15.887-.914a7.541 7.541 0 00.182-1.602c0-4.542-3.695-8.24-8.241-8.24z"
        fill="var(--ion-color-dark)"
      />
      <path d="M59.109 37.325c-3.068 0-5.476-1.784-5.476-4.063s2.407-4.063 5.476-4.063 5.476 1.784 5.476 4.063c0 2.28-2.406 4.063-5.476 4.063zm0-5.013c-1.525 0-2.364.714-2.364.951s.839.951 2.364.951 2.364-.714 2.364-.951c.001-.238-.838-.951-2.364-.951z"
        fill="var(--ion-color-dark)"
      />
    </svg>
  );
}

export default SvgCheeseIcon;
