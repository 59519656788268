import { HeaderSubmitButton, HeaderWithButtons } from 'components/baseElements/header';
import React, { useCallback, useState } from 'react';
import ReorderFlights from 'components/wine/ReorderFlights';
import { Flight } from 'store/wines';
import { getDataForFlightReorder } from 'store/wines/utils';
import { IonButton } from 'components/ionicComponents';
import { FormattedMessage } from 'react-intl';
import { GetLoadingStatus, makeApiCall } from 'utils/api';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { LoadingStatus } from 'utils/formValidation';

interface ReorderFlightsContainerProps {
  flights?: Array<Flight>;
  handleCancelAction: () => any;
  handleSaveAction: (data: Array<Flight>) => any;
  tastingId?: string;
}

const ReorderFlightsContainer: React.FC<ReorderFlightsContainerProps> = (props: ReorderFlightsContainerProps) => {
  const { flights, handleCancelAction, handleSaveAction, tastingId } = props;
  const dispatch = useDispatch();
  const REORDER_FLIGHTS_KEY = 'reorderFlights';
  const [flightReorderData, setflightReorderData] = useState(getDataForFlightReorder(flights));

  const auth = useSelector((state: RootState) => state.authorization);
  const apiBaseUrl = useSelector((state: RootState): string => state.appSettings.apiBaseUrl);
  const reorderFlightsApiCall = useSelector((state: RootState) =>
    state.api.callStatuses.find(e => e.id === REORDER_FLIGHTS_KEY),
  );

  const handleFlightMove = (data: Array<Flight>): void => {
    setflightReorderData(data);
  }

  const applyFlightReorder = useCallback((flightsArray: Array<Flight>, reorderData: Array<Flight>): Array<Flight> => {
    const flightsArr: Array<Flight> = [...flightsArray];
    let currentSequenceNumber = 0;
    reorderData.forEach((item: Flight): void => {

      const flight = flightsArr.find((f: Flight) => f.id === item?.id);
      if (!flight) {
        return;
      }
      flight.sequence = currentSequenceNumber;
      currentSequenceNumber++;
    })
    return flightsArr;
  }, []);

  const handleFlightReorderSave = useCallback(async (): Promise<void> => {
    if (!flights) {
      return;
    }
    const orderedFlights = applyFlightReorder([...flights], flightReorderData);
    try {
      await makeApiCall({
        authToken: auth.token,
        dispatch: dispatch,
        callId: REORDER_FLIGHTS_KEY,
        request: {
          url: `${apiBaseUrl}/tastings/${tastingId}/flights/reorder`,
          httpMethod: 'PUT',
          body: orderedFlights
        },
        showSuccessMessage: false,
      });
      handleSaveAction(orderedFlights);
    }
    catch (error) {
      return;
    }

  }, [applyFlightReorder, flightReorderData, flights, handleSaveAction, apiBaseUrl, auth.token, dispatch, tastingId]);

  return (
    <>
      <HeaderWithButtons
        ionButtonsEnd={(
          <HeaderSubmitButton
            isLoading={GetLoadingStatus(reorderFlightsApiCall?.status) === LoadingStatus.Loading}
            onClick={(): void => { handleFlightReorderSave() }}
          />
        )}
        ionButtonsStart={(
          <IonButton onClick={(): void => { handleCancelAction() }}>
            <FormattedMessage id="general.buttons.cancel" />
          </IonButton>
        )}
      >
        <FormattedMessage id="reorderFlights.header.caption" />
      </HeaderWithButtons>
      <ReorderFlights
        data={getDataForFlightReorder(flights)}
        moveHandler={handleFlightMove}
      />
    </>
  )
}

export default ReorderFlightsContainer;