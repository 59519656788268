import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Wine } from '..';

dayjs.extend(utc);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const toRequestInsertWineTastingDto = (item: any): Wine => {
  const { vintage } = item;
  let vintageResult;

  vintageResult = vintage;
  if (!vintage) {
    vintageResult = '';
  }

  return {
    ...item,
    // Needed to ensure inserted sequence order for master wines
    createdAt: new Date().toUTCString(),
    masterWine: null,
    vintage: `${vintageResult}`,
  } as Wine;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const toRequestEditWineTastingDto = (item: any): Wine => {
  const { vintage } = item;
  let vintageResult;

  vintageResult = vintage;
  if (!vintage) {
    vintageResult = '';
  }

  return {
    ...item,
    masterWine: null,
    vintage: `${vintageResult}`,
  } as Wine;
};
