import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import {
  IonContent,
  IonModal,
  IonHeader,
  IonToolbar,
  IonIcon,
  IonButtons,
} from '@ionic/react';
import { close } from 'ionicons/icons';
import { EmptyStateImage } from 'components/baseElements/EmptyStateImage';
import { Flex } from 'components/baseElements/grid';
import { HeaderSubmitButton } from 'components/baseElements/header';
import { Paragraph } from 'components/baseElements/typography';
import { IonButton, IonTitle } from 'components/ionicComponents';
import { PrivatePropertyContainer } from 'containers/authorization/PrivatePropertyContainer';
import CreateTastingContainer from 'containers/tastings/CreateTastingContainer';
import { AuthorizationModalId } from 'modals/AuthorizationModal';
import { RootState } from 'store';
import { hideModal, showModal } from 'utils/modals';
import { LoadingStatus } from 'utils/formValidation';

export const CreateTastingModalId = 'createTasting';

export default function CreateTastingModal(): JSX.Element {
  const dispatch = useDispatch();
  const modalProps = useSelector((state: RootState) =>
    state.gui.modals.find(e => e.id === CreateTastingModalId),
  );

  const [loadingStatus, setLoadingStatus] = React.useState<LoadingStatus>(LoadingStatus.None);

  return (
    <IonModal
      isOpen={modalProps?.show || false}
      onDidDismiss={(): void => hideModal(dispatch, CreateTastingModalId, {})}
    >
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={(): void => hideModal(dispatch, CreateTastingModalId, {})}>
              <IonIcon icon={close} slot="icon-only" />
            </IonButton>
          </IonButtons>
          <IonTitle>
            <FormattedMessage id="createNewTastingModal.header.title" />
          </IonTitle>
          <PrivatePropertyContainer>
            <PrivatePropertyContainer.Authorized>
              <IonButtons slot="end">
                <HeaderSubmitButton
                  data-cy="tastingSubmitButton"
                  form="tastingForm"
                  isLoading={loadingStatus === LoadingStatus.Loading}
                />
              </IonButtons>
            </PrivatePropertyContainer.Authorized>
          </PrivatePropertyContainer>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <PrivatePropertyContainer>
          <PrivatePropertyContainer.Unauthorized>
            <Flex
              alignItems="center"
              flexDirection="column"
              mt={3}
            >
              <EmptyStateImage />
              <Paragraph
                textAlign="center"
                width={2 / 3}
              >
                <FormattedMessage id="createNewTastingModal.text.emptyState" />
              </Paragraph>
              <IonButton
                data-cy="signUpBtnHostTasting" onClick={(): void => showModal(dispatch, AuthorizationModalId, { params: {mode: 'registration'} })}
              >
                <FormattedMessage id="general.nav.registration" />
              </IonButton>
            </Flex>
          </PrivatePropertyContainer.Unauthorized>
          <PrivatePropertyContainer.Authorized>
            <CreateTastingContainer
              loadingStatus={loadingStatus}
              setLoadingStatus={setLoadingStatus}
              successHandler={(tastingId: string): void => hideModal(dispatch, CreateTastingModalId, {}, modalProps?.callback, { createdTasting: true, tastingId: tastingId })}
            />
          </PrivatePropertyContainer.Authorized>
        </PrivatePropertyContainer>
      </IonContent>
    </IonModal>
  );
}
