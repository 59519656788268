import { faArrowLeft, faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonFooter, IonToolbar } from '@ionic/react';
import { SubmitButton } from 'components/baseElements/formControls';
import { Flex } from 'components/baseElements/grid';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { LoadingStatus } from 'utils/formValidation';

interface StepBottomButtonsProps {
  loadingStatus: LoadingStatus;
  nextClickedAction: () => any;
  previousClickedAction: () => any;
  nextStepName: string;
  onSaveAndClose?: (data: any, isScoringCompleted?: boolean) => any;
  showNextStepButton: boolean;
  previousStepName: string;
  showPreviousStepButton: boolean;
}

const StepBottomButtons: FunctionComponent<StepBottomButtonsProps> = (
  props: StepBottomButtonsProps,
) => {
  const {
    loadingStatus,
    nextClickedAction,
    previousClickedAction,
    nextStepName,
    onSaveAndClose,
    showNextStepButton,
    previousStepName,
    showPreviousStepButton,
  } = props;

  return (
    <IonFooter>
      <IonToolbar>
        <Flex
          alignItems="center"
          justifyContent="center"
          width={1}
        >
          {showPreviousStepButton && (
            <SubmitButton
              expand={null}
              fill="outline"
              isLoading={loadingStatus === LoadingStatus.Loading}
              loadingElement={null}
              maxWidth="calc(50% - 4px)"
              onClick={(): any => previousClickedAction()}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
              &nbsp;
              {previousStepName}
            </SubmitButton>)}
          <Flex
            justifyContent="center"
            width={1}
          >
            <SubmitButton
              alignItems="center"
              onClick={onSaveAndClose}
            >
              <FormattedMessage id="scoreWine.header.submitCaption" />
            </SubmitButton>
          </Flex>
          <Flex flex={1} />
          {showNextStepButton && (
            <SubmitButton
              color="primary"
              expand={null}
              isLoading={loadingStatus === LoadingStatus.Loading}
              loadingElement={null}
              maxWidth="calc(50% - 4px)"
              onClick={(): any => nextClickedAction()}
            >
              {nextStepName}
              &nbsp;
              <FontAwesomeIcon icon={faArrowRight} />
            </SubmitButton>
          )}
        </Flex>
      </IonToolbar>
    </IonFooter>
  );
};

export default StepBottomButtons;
