import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgCranberryIcon(props) {
  return (
    <svg width={14} height={18} fill="none" {...props}>
      <path
        d="M9.476 5.216a4.5 4.5 0 001.36-.945c1.26-1.26 1.601-2.967.76-3.807-.84-.841-2.54-.508-3.8.754a4.457 4.457 0 00-.988 1.478 4.457 4.457 0 00-.988-1.47C4.56-.033 2.859-.374 2.018.467c-.84.84-.499 2.545.76 3.807a4.5 4.5 0 001.362.943C1.708 6.202 0 8.497 0 11.172c0 2.86 1.951 5.286 4.655 6.142a.72.72 0 001.36.287l.152.016a.72.72 0 001.273 0l.153-.016a.72.72 0 001.359-.287c2.716-.864 4.66-3.288 4.66-6.149 0-2.673-1.704-4.968-4.136-5.95zm-1.172-3.49c.623-.625 1.417-1.01 2.07-1.01.264-.015.522.075.72.25.48.48.286 1.746-.76 2.795-.622.625-1.416 1.014-2.07 1.014a1.006 1.006 0 01-.72-.252c-.48-.484-.288-1.75.76-2.798zM2.526.963c.197-.175.456-.266.72-.252.653 0 1.44.389 2.07 1.013 1.046 1.048 1.24 2.314.76 2.796a1.006 1.006 0 01-.72.252c-.654 0-1.44-.389-2.07-1.013-1.047-1.05-1.241-2.324-.76-2.796zM8.74 16.625l-.481.153-.01.499-.222-.452-.5.054-.14.014-.392.034-.187.35-.18-.35-.392-.034-.148-.012-.5-.056-.22.452-.018-.504-.48-.152C2.39 15.837.72 13.644.72 11.165c0-3.173 2.73-5.76 6.088-5.76 3.357 0 6.088 2.583 6.088 5.76 0 2.479-1.67 4.672-4.156 5.458v.002z"
        fill="var(--ion-color-dark)"
      />
    </svg>
  );
}

export default SvgCranberryIcon;
