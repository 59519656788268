import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgButterIcon(props) {
  return (
    <svg width={18} height={11} fill="none" {...props}>
      <path
        d="M17.472 1L14.228.032a.723.723 0 00-.424 0l-6.49 1.953a.739.739 0 00-.526.708v.425l-1.709-.531a.742.742 0 00-.742.184l-.616.615a.74.74 0 00-.107.91L4.627 5.94l-.433-.13a.75.75 0 00-.423 0l-3.244.97A.739.739 0 000 7.488v.872c0 .327.214.615.527.709l3.244.968a.728.728 0 00.423 0L7.44 9.07a.739.739 0 00.527-.709v-.056l.63.211a.739.739 0 00.758-.177l.478-.477.727.216a.734.734 0 00.426 0l6.49-1.954A.738.738 0 0018 5.415V1.71A.74.74 0 0017.472 1zM14.017.74l2.601.776-5.846 1.76L8.169 2.5 14.017.74zM9.449 7.197l-.344.345-.43-.8-.99-1.846.338-.34 1.282 2.374.144.267zM4.86 3.292l1.928.6.654.203-.318.317-.336-.104-2.248-.694.32-.322zm-.877 3.226l1.227.367 1.375.412-.836.25-1.766.532-2.601-.782 2.601-.78zM.738 7.873l2.876.867v.48L.738 8.36v-.487zm6.49.487l-2.876.86v-.482l2.592-.779.284-.086v.487zm.738-.837v-.035a.745.745 0 00-.527-.708l-.623-.186-1.125-.336-1.153-1.871 2.25.694.187.058.552 1.027.15.28.493.918.15.28-.354-.12zm2.133-.675L8.49 3.868a.736.736 0 00-.43-.355l-.533-.165v-.27l2.876.86V7.26l-.215-.065a.742.742 0 00-.09-.347zm7.163-1.433L11.14 7.258v-3.32l6.12-1.844v3.32z"
        fill="var(--ion-color-dark)"
      />
    </svg>
  );
}

export default SvgButterIcon;
