import { GuiStateUpdateModalAction } from './types';
import { GuiState } from '..';
import { ModalProperties } from '../types';

const upsertModalProps = (
  existingModals: Array<ModalProperties<any>>,
  modalProps: ModalProperties<any>
): Array<ModalProperties<any>> => {
  const index = existingModals.findIndex(element => element.id === modalProps.id);
  if (index === -1) {
    return [
      ...existingModals,
      modalProps
    ];
  }

  return existingModals.map((modal, i) => {
    if (i === index) {
      return modalProps;
    }

    return modal;
  });
};

export const guiStateUpdateModalReducer = (
  guiState: GuiState,
  action: GuiStateUpdateModalAction,
): GuiState => ({
  ...guiState,
  modals: upsertModalProps(guiState.modals, action.payload.modalProps)
});
