import React, { useEffect, useState } from 'react';
import { RefresherEventDetail } from '@ionic/core';
import { IonRefresher, IonRefresherContent } from '@ionic/react';
import { Loadable, Loaded } from 'components/baseElements/loadable';
import WineListForRanking from 'components/wine/WineListForRanking';
import useTastingFlights from 'hooks/tastings/useTastingFlights';
import useTastingWineRankings from 'hooks/tastings/useTastingWineRankings';
import useTastingWines from 'hooks/tastings/useTastingWines';
import { chevronDownCircleOutline } from 'ionicons/icons';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { WineRank } from 'store/wineRanks';

interface RankWinesContainerProps {
  flightId?: string;
  onChangeIsValid: (isValid: boolean) => void;
  onChangeWineRankings: (wineRanks: WineRank[]) => void;
  onClearWineRanks?: (wineRanks: WineRank[]) => void;
  tastingId?: string;
  refresh?: boolean;
}

export default function RankWinesContainer(props: RankWinesContainerProps): JSX.Element {
  const {
    onClearWineRanks,
    flightId,
    onChangeIsValid,
    onChangeWineRankings,
    tastingId,
    refresh,
  } = props;
  // LOCAL (CONTAINER) STATE SETUP
  const intl = useIntl();
  const auth = useSelector((state: RootState) => state.authorization);
  const userId = auth.tokenData && auth.tokenData['userData/userId'];
  const { data: flightData, isLoading: flightsAreLoading, refetch: refetchFlights } = useTastingFlights(tastingId);
  const { data: wineData, isLoading: winesAreLoading, refetch: refetchWines } = useTastingWines(tastingId);
  const {
    data: userRankings,
    isLoading: wineRankingsAreLoading,
    refetch: refetchWineRankings,
  } = useTastingWineRankings(tastingId, userId);
  const [hideList, setHideList] = useState<boolean>(false);
  const refreshContainer = async (): Promise<void> => {
    setHideList(true);
    try {
      await refetchFlights();
      await refetchWines();
      await refetchWineRankings();
      setHideList(false);
    }
    catch (e) {
      // eslint-disable-next-line no-console
      console.error;
    }

  }
  // CALLBACK FUNCTIONS
  const doRefresh = (event: CustomEvent<RefresherEventDetail>): void => {
    refreshContainer();
    event.detail.complete();
  };

  useEffect(() => {
    refreshContainer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh])
  return (
    <>
      <IonRefresher
        onIonRefresh={doRefresh}
        slot="fixed"
      >
        <IonRefresherContent
          pullingIcon={chevronDownCircleOutline}
          pullingText={intl.formatMessage({ id: 'general.refresher.pullingText' })}
          refreshingSpinner="circles"
          refreshingText={intl.formatMessage({ id: 'general.refresher.refreshingText' })}
        />
      </IonRefresher>
      <Loadable isLoading={hideList || flightsAreLoading || winesAreLoading || wineRankingsAreLoading}>
        <Loaded>
          <WineListForRanking
            flightId={flightId}
            flights={flightData?.items || []}
            tastingId={tastingId}
            userId={userId}
            userRankings={userRankings || []}
            wines={wineData?.items || []}
            onChangeIsValid={onChangeIsValid}
            onChangeWineRankings={onChangeWineRankings}
            onClearWineRanks={onClearWineRanks}
          />
        </Loaded>
      </Loadable>
    </>
  );
}
