import { WineRank } from '../../../store/wineRanks';
import { Wine } from '../../../store/wines';

export interface RankingsValidation {
  gold: number;
  silver: number;
  bronze: number;
  isValid: boolean;
}

export const isFlightWineRank = (wineRank: WineRank): boolean => !!wineRank?.flightId;

export const isOverallWineRank = (wineRank: WineRank): boolean => !wineRank?.flightId;

export const findWineRankByFlightOrOverall = (wineId: string, flightId?: string): any => {
  return (wineRank: WineRank): boolean => {
    if (flightId) {
      return isFlightWineRank(wineRank) && wineRank.wineId === wineId && wineRank.flightId === flightId;
    }
    return isOverallWineRank(wineRank) && wineRank.wineId === wineId;
  };
};

export const filterWineRankByFlightOrOverall = (flightId?: string): any => (wineRank: WineRank): boolean => {
  return flightId ? wineRank.flightId === flightId : !wineRank.flightId;
};

export const filterOutWineRankByFlightOrOverall = (wineId: string, flightId?: string): any => {
  return (wineRank: WineRank): boolean => {
    if (flightId) {
      return !(isFlightWineRank(wineRank) && wineRank.wineId === wineId && wineRank.flightId === flightId);
    }
    return !(isOverallWineRank(wineRank) && wineRank.wineId === wineId);
  };
};

export const rankingsValidator = (wineRanks: WineRank[] = [], flightId?: string): RankingsValidation => {
  if (!wineRanks?.length) {
    return { gold: 0, silver: 0, bronze: 0, isValid: true };
  }
  const filteredWineRanks = wineRanks.filter(filterWineRankByFlightOrOverall(flightId));
  const countMap = filteredWineRanks
    // Get unique rankings
    .reduce((map: any, wineRank: WineRank): any => {
      if (!map[wineRank.points]) {
        map[wineRank.points] = 1;
      } else {
        map[wineRank.points] = map[wineRank.points] + 1;
      }
      return map;
    }, {});
  return {
    gold: countMap[3] || 0,
    silver: countMap[2] || 0,
    bronze: countMap[1] || 0,
    isValid: filteredWineRanks.length > 3
      // Max 3 wines total
      ? false
      // Max 1 of each wine
      : !Object.values<number>(countMap).filter((count: number): boolean => count > 1).length,
  };
}

export const updateUserRankings = (
  points: number,
  prevUserRankings: WineRank[] = [],
  userId: string,
  wine: Wine,
  tastingId?: string,
  flightId?: string,
): WineRank[] => {
  if (!userId || !wine?.id) {
    return [];
  }
  const currentRank = prevUserRankings.find(findWineRankByFlightOrOverall(wine?.id, flightId));
  if (points) {
    if (currentRank) {
      // Update rank
      return prevUserRankings.map((wineRank: WineRank): WineRank => {
        if (flightId) {
          return (wine.id === wineRank.wineId && wineRank.flightId === flightId)
            ? { ...wineRank, flightId, points, tastingId }
            : { ...wineRank };
        }

        // Must search on wineRank.wineId AND !wineRank.flightId to distinguish as an overall rank
        return wine.id === wineRank.wineId && !wineRank.flightId ? { ...wineRank, points, tastingId } : { ...wineRank };
      });
    } else {
      // Add new rank
      return [...prevUserRankings, { flightId, points, tastingId, userId, wineId: wine.id }];
    }
  }
  // Remove rank
  return prevUserRankings.filter(filterOutWineRankByFlightOrOverall(wine?.id, flightId));
}
