import React from 'react';
import { IonBackButton } from '@ionic/react';
import { HeaderWithButtons } from '../HeaderWithButtons';

interface BackHeaderProperties {
  backHref?: string;
  children?: React.ReactNode;
  color?: string | undefined;
  ionButtonsEnd?: React.ReactNode;
  ionButtonsEndProps?: any;
  onClick?: (event: React.MouseEvent<HTMLIonButtonElement>) => void | undefined;
}

export function BackHeader(props: BackHeaderProperties): JSX.Element {
  const {
    backHref,
    children,
    color = 'primary',
    ionButtonsEnd,
    ionButtonsEndProps,
    onClick,
  } = props;
  return (
    <HeaderWithButtons
      color={color}
      ionButtonsEnd={ionButtonsEnd}
      ionButtonsEndProps={ionButtonsEndProps}
      ionButtonsStart={(
        <span onClick={onClick}>
          <IonBackButton
            data-cy="backBtn"
            defaultHref={backHref}
            style={ onClick ? { display: 'block', visibility: 'visible' } : {} }
            type="button"
          />
        </span>
      )}
      ionButtonsStartProps={{
        style: onClick ? { visibility: 'visible' } : {},
      }}
    >
      {children}
    </HeaderWithButtons>
  );
}
