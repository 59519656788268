import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgPeachIcon(props) {
  return (
    <svg width={16} height={18} fill="none" {...props}>
      <path
        d="M15.561 10.22a7.793 7.793 0 00-2.887-6.047 4.214 4.214 0 001.89-2.023L15.39.273 13.354.032A4.205 4.205 0 009 2.512l-.013.029a7.768 7.768 0 00-1.206-.102C3.49 2.439 0 5.929 0 10.219S3.49 18 7.78 18c.89 0 1.744-.152 2.541-.429a4.163 4.163 0 001.794-.895 7.774 7.774 0 003.446-6.456zm-3.557-5.761a7.157 7.157 0 012.922 5.76c0 1.48-.454 2.859-1.23 4.002a7.11 7.11 0 00.34-1.925c.07-2.232-.958-4.785-3.42-5.455-.019-.007-.038-.004-.057-.004-.171-.063-.825-.276-1.877-.31l.274-2.043 1.254.15c.159.018.317.028.48.028h.025c.127 0 .25-.006.378-.02.025-.002.047-.005.07-.005a3.676 3.676 0 00.416-.064c.14-.029.276-.067.412-.108.004-.006.007-.006.013-.006zm-3.728.305L8.13 5.853l-.146-1.09h.292zM12.849.635c.143 0 .286.01.429.026l1.184.142-.482 1.093A3.581 3.581 0 0112 3.786l-.057.022c-.13.047-.264.089-.397.12-.003 0-.006.004-.01.004a3.194 3.194 0 01-.387.07c-.016.003-.035.003-.05.006-.131.012-.261.022-.391.022-.14 0-.283-.01-.423-.025l-.505-.06 2.776-1.766a.318.318 0 00-.34-.537l-2.97 1.89.334-.76A3.574 3.574 0 0112.85.635zM.635 10.22a7.152 7.152 0 018.089-7.082l-.435.99h-1.03l.306 2.287c-.655-.156-1.198-.543-1.547-1.54a.316.316 0 10-.597.21c.651 1.863 1.969 2.045 3.15 2.07 1.137.025 1.776.28 1.791.286a.343.343 0 00.121.025h.003c2.103.594 2.982 2.84 2.919 4.811-.045 1.426-.588 2.96-1.674 3.897a7.073 7.073 0 01-1.597.794c-.01.003-.016.006-.026.006-.01.003-.012.007-.022.01a7.088 7.088 0 01-2.302.38c-3.945 0-7.149-3.203-7.149-7.145z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M2.496 8.571a.316.316 0 00-.216-.394.316.316 0 00-.394.216 6.293 6.293 0 00.108 3.824.317.317 0 10.597-.21 5.697 5.697 0 01-.095-3.436zM3.296 5.951c-.33.347-.619.734-.857 1.15a.316.316 0 00.276.473.32.32 0 00.277-.159c.212-.371.47-.717.765-1.025a.317.317 0 10-.46-.438z"
        fill="var(--ion-color-dark)"
      />
    </svg>
  );
}

export default SvgPeachIcon;
