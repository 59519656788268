import { IonAvatar } from '@ionic/react';
import EmptyStateWineImage from 'components/baseElements/EmptyStateWineImage';
import { Hideable } from 'components/baseElements/hideable';
import Image from 'components/baseElements/Image';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { MasterWine } from 'store/masterWines';
import { Wine } from 'store/wines';
import { LayoutProps } from 'styled-system';
import { getImageUrl, isValidFileId } from 'utils/dataFormat/strings';

interface WineAvatarProperties extends LayoutProps {
  apiBaseUrl?: string;
  onClick?: (event: React.MouseEvent<HTMLIonButtonElement>) => void | undefined;
  slot?: 'start' | 'end';
  src?: string;
  useIonAvatar?: boolean;
  wine?: Wine | MasterWine;
}

interface WrapperProperties {
  children: React.ReactNode;
}

export default function WineAvatar(props: WineAvatarProperties): JSX.Element {
  const {
    maxHeight,
    maxWidth,
    minHeight,
    minWidth,
    onClick,
    size = 40,
    slot = 'start',
    src,
    useIonAvatar = true,
    wine,
  } = props;

  const apiBaseUrl = useSelector((state: RootState): string => state.appSettings.apiBaseUrl);
  const defaultSrc = src || getImageUrl(apiBaseUrl || '', wine?.imageFileId || '');

  const Wrapper = (wrapperProps: WrapperProperties) => {
    const { children } = wrapperProps;
    if (useIonAvatar) {
      return (
        <IonAvatar
          onClick={onClick}
          slot={slot}
        >
          {children}
        </IonAvatar>
      );
    }
    return <>{children}</>;
  };

  return (
    <Wrapper>
      <Hideable hide={isValidFileId(wine?.imageFileId)}>
        <EmptyStateWineImage size={size}/>
      </Hideable>
      <Hideable hide={!isValidFileId(wine?.imageFileId)}>
        <Image
          $bg="white"
          alt={`${wine?.vintage} ${wine?.producer} ${wine?.name}`}
          borderRadius={4}
          maxHeight={maxHeight || size}
          maxWidth={maxWidth || size}
          minHeight={minHeight || size}
          minWidth={minWidth || size}
          objectFit="contain"
          p={1}
          size={size}
          src={defaultSrc}
        />
      </Hideable>
    </Wrapper>
  );
}
