import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgRubberIcon(props) {
  return (
    <svg width={16} height={18} fill="none" {...props}>
      <path
        d="M8.4 18H5.1C2.288 18 0 13.988 0 9c0-4.987 2.288-9 5.138-9h3.3c-1.913 1.387-3.3 4.912-3.3 9s1.35 7.613 3.262 9z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M10.238 0C7.425 0 5.1 4.013 5.1 9c0 4.95 2.288 9 5.138 9 2.812 0 5.137-4.05 5.137-9 0-4.987-2.287-9-5.137-9zm-.188 14.512C8.325 14.512 6.9 12.037 6.9 9c0-3.037 1.425-5.513 3.15-5.513 1.725 0 3.15 2.476 3.15 5.513s-1.425 5.512-3.15 5.512z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M12.713 9c0 2.813-1.313 5.138-2.926 5.138-.525 0-1.012-.263-1.462-.675.862-.9 1.462-2.55 1.462-4.425 0-1.876-.6-3.563-1.462-4.426.412-.45.937-.675 1.462-.675 1.65-.075 2.925 2.25 2.925 5.063z"
        fill="var(--ion-color-dark)"
      />
    </svg>
  );
}

export default SvgRubberIcon;
