import React from 'react';
import { IonPage } from '@ionic/react';
import ViewTastingContainer from 'containers/tastings/ViewTastingContainer';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ViewTastingPage = (props: any): any => {
  const { tastingId } = props.match.params;

  return (
    <IonPage>
      <ViewTastingContainer tastingId={tastingId} />
    </IonPage>
  );
}

export default ViewTastingPage
