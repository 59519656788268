export const getNextWineRankPoints = (p: number): number => (p - 1) < 0 ? 3 : p - 1;

export const getWineRankColorFromPointsForResults = (
  rank?: number,
  points?: number,
  visible?: boolean,
): string | undefined => {
  if (!points || !visible) {
    return 'light';
  }
  return getWineRankColorFromPoints(rank || 0);
};

export const getWineRankColorFromPoints = (p?: number): string => {
  switch (p) {
    case 3:
      return 'gold';
    case 2:
      return 'silver';
    case 1:
      return 'bronze';
    default:
      return 'light';
  }
};

export const getWineRankFromPointsForResults = (
  rank?: number,
  points?: number,
  visible?: boolean,
): number | undefined => {
  if (!points || !visible || !rank) {
    return 0;
  }
  return getWineRankFromPoints(rank || 0);
};

export const getWineRankFromPoints = (p?: number): number | undefined => {
  switch (p) {
    case 3:
      return 1;
    case 2:
      return 2;
    case 1:
      return 3;
    default:
      return;
  }
};
