import { IonIcon } from '@ionic/react';
import { Box } from 'components/baseElements/grid';
import { IonButton } from 'components/ionicComponents';
import React from 'react';
import { useDispatch } from 'react-redux';
import { hideModal } from 'utils/modals';
import { AuthorizationModalId } from '..';

interface CloseButtonProps {
  children: React.ReactNode;
}

export default function CloseButton(props: CloseButtonProps): JSX.Element {
  const { children } = props;
  const dispatch = useDispatch();

  return (
    <Box
      height="100%"
      position="relative"
    >
      <Box
        left="0"
        position="absolute"
        top="0"
        zIndex={1}
      >
        <IonButton
          color="dark"
          fill="clear"
          onClick={(): void => hideModal(dispatch, AuthorizationModalId, {})}
        >
          <IonIcon icon="close" slot="icon-only"/>
        </IonButton>
      </Box>
      {children}
    </Box>
  );
}
