import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgMushroomIcon(props) {
  return (
    <svg width={16} height={18} fill="none" {...props}>
      <path
        d="M15.184 6.435C14.502 2.763 11.247 0 7.613 0S.723 2.763.042 6.435a2.5 2.5 0 001.703 2.84 19.35 19.35 0 003.388.775c-.019.265-.038.511-.038.776a34.06 34.06 0 00.133 4.638c.019.208.076.416.132.624A2.824 2.824 0 008.048 18c.303 0 .625-.057.927-.151a2.835 2.835 0 001.628-1.439 2.849 2.849 0 00.133-2.158 25.74 25.74 0 01-.985-3.747c-.018-.133-.037-.246-.056-.379a18.275 18.275 0 003.823-.833 2.544 2.544 0 001.666-2.858zm-6.7 4.278c.264 1.4.624 2.726 1.04 3.918.133.397.114.814-.075 1.192a1.59 1.59 0 01-.89.795 1.563 1.563 0 01-1.987-.965 1.91 1.91 0 01-.076-.379 30.886 30.886 0 01-.133-4.429c0-.227.02-.454.038-.681.398.019.814.057 1.23.057.266 0 .53 0 .777-.02.019.171.037.342.075.512zm4.618-2.65c-1.136.36-3.161.89-5.49.89-2.327 0-4.353-.53-5.488-.89a1.24 1.24 0 01-.833-1.4c.53-2.878 3.16-5.395 6.322-5.395 3.16 0 5.792 2.517 6.34 5.394a1.241 1.241 0 01-.851 1.401z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M7.613 2.442c-2.612 0-4.43 1.987-5.016 3.842a.606.606 0 00.057.511c.094.17.246.284.416.34.057.02.133.02.19.02a.674.674 0 00.624-.417c.72-1.779 2.12-3.199 3.823-3.88a.217.217 0 00.133-.246c-.038-.076-.133-.17-.227-.17z"
        fill="var(--ion-color-dark)"
      />
    </svg>
  );
}

export default SvgMushroomIcon;
