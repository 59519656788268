
import React, { useState } from 'react';
import { schema } from './validation';
import DateFilterForm from 'components/filters/DateFilterForm';
import { DateFilterState } from 'store/tastings';

interface DateFilterContainerProps {
  successHandler: (dateFilter: DateFilterState, startDate?: string, endDate?: string) => any;
}

function DateFilterContainer(props: DateFilterContainerProps): JSX.Element {
  const {
    successHandler,
  } = props;

  const [showDateRange, setShowDateRange] = useState(false);

  return (
    <DateFilterForm
      onClickFilterSelection={successHandler}
      submitAction={(data: any): void => successHandler(DateFilterState.ChooseDate, data?.startDate, data?.endDate)}
      validationSchema={schema}
      onChooseDate={(): void => setShowDateRange(true)}
      showDateRange={showDateRange}
    />
  );
}

export default DateFilterContainer;
