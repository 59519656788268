import { IonActionSheet } from '@ionic/react';
import { Flex } from 'components/baseElements/grid';
import MyProfileBanner from 'components/users/MyProfileBanner';
import UserProfileForm from 'components/users/UserProfileForm';
import { ApiCall, AuthorizationStore } from 'napa-react-core';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'store';
import { authorizationSetToken } from 'store/authorization/actions/authorizationSetToken';
import { User } from 'store/users';
import { clearApiCallStatus, GetLoadingStatus, makeApiCall } from 'utils/api';
import VinviteVersion from '../../../components/version';
import { guiStateUpdateToast } from '../../../store/gui/guiStateUpdateToast';
import { schema } from './validation';

interface UserProfileContainerProperties {
  setShowActionSheet?: any;
  showActionSheet?: boolean;
}

export default function UserProfileContainer(props: UserProfileContainerProperties): JSX.Element {
  const {
    setShowActionSheet,
    showActionSheet
  } = props;
  // CONSTANT DECLARATION
  const FETCH_PROFILE_KEY = 'fetchUserProfile';
  const SAVE_PROFILE_KEY = 'saveUserProfile';
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const authData = useContext<any>(AuthorizationStore);
  // LOCAL (CONTAINER) STATE SETUP
  const [profileUser, setProfileUser] = useState(undefined as User | undefined);
  const [fetchedUser, setFetchedUser] = useState(undefined as User | undefined);
  // GLOBAL (REDUX) STATE SETUP
  const auth = useSelector((state: RootState) => state.authorization);
  const { apiBaseUrl, cameraApiSettings } = useSelector((state: RootState): any => state.appSettings);
  const saveProfileApiCall = useSelector(
    (state: RootState) =>
      state.api.callStatuses.find((e) => e.id === SAVE_PROFILE_KEY) as ApiCall<User>,
  );
  const fetchProfileApiCall = useSelector(
    (state: RootState) =>
      state.api.callStatuses.find((e) => e.id === FETCH_PROFILE_KEY) as ApiCall<User>,
  );

  // CALLBACK FUNCTIONS
  const profileSubmitAction = (user: User): void => {
    setProfileUser(user);
  };

  const fetchProfileData = useCallback(async (): Promise<void> => {
    try {
      const result = await makeApiCall<User>({
        authToken: auth.token,
        callId: FETCH_PROFILE_KEY,
        dispatch: dispatch,
        request: {
          url: `${apiBaseUrl}/users/${
            auth.tokenData && auth.tokenData['userData/userId']
          }`,
          httpMethod: 'GET',
        },
      });
      setFetchedUser(result);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      // If the user has been logged out (i.e. no token), complete the logout process.
      if (error.message === 'A not found error has occurred') {
        localStorage.removeItem('authToken');
        dispatch(authorizationSetToken({}));
        // New boilerplate way to set auth state
        authData.setState({});
      }
    }
  }, [apiBaseUrl, auth.token, auth.tokenData, authData, dispatch]);

  const saveProfileData = useCallback(async (): Promise<void> => {
    try {
      if (!profileUser) {
        return;
      }
      const result = await makeApiCall<User>({
        authToken: auth.token,
        callId: SAVE_PROFILE_KEY,
        dispatch: dispatch,
        request: {
          url: `${apiBaseUrl}/users/${
            auth.tokenData && auth.tokenData['userData/userId']
          }`,
          httpMethod: 'PUT',
          body: profileUser,
        },
      });
      setFetchedUser(result);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [apiBaseUrl, auth.token, auth.tokenData, dispatch, profileUser]);

  const handleChangePassword = (): void => {
    history.push('/userProfile/changePassword');
  };

  const handleLogout = (): void => {
    localStorage.removeItem('authToken');
    dispatch(authorizationSetToken({}));
    // New boilerplate way to set auth state
    authData.setState({});
    history.push('/tastings');
  };

  const onUploadError = (): void => {
    dispatch(
      guiStateUpdateToast({
        message: 'api.defaults.failureMessage',
      }),
    );
  };
  const onUploadSuccess = (ids: Array<string>): void => {
    setProfileUser({ ...fetchedUser, photoId: ids[0] });
  };

  // EFFECTS SETUP
  useEffect(() => {
    clearApiCallStatus(dispatch, SAVE_PROFILE_KEY);
    clearApiCallStatus(dispatch, FETCH_PROFILE_KEY);
    fetchProfileData();
  }, [dispatch, fetchProfileData]);
  useEffect(() => {
    saveProfileData();
  }, [saveProfileData]);
  return (
    <Flex flexDirection="column" height="100%">
      <MyProfileBanner
        cameraApiSettings={cameraApiSettings}
        onUploadError={onUploadError}
        onUploadSuccess={onUploadSuccess}
        uploadUrl={`${apiBaseUrl}/files/upload`}
        user={fetchedUser}
      />
      <UserProfileForm
        cameraApiSettings={cameraApiSettings}
        createUserProfileLoadingStatus={GetLoadingStatus(
          saveProfileApiCall?.status,
        )}
        fetchUserProfileLoadingStatus={GetLoadingStatus(
          fetchProfileApiCall?.status,
        )}
        onUploadError={onUploadError}
        user={fetchedUser}
        submitAction={profileSubmitAction}
        serverValidation={saveProfileApiCall?.validationErrors}
        uploadUrl={`${apiBaseUrl}/files/upload`}
        validationSchema={schema}
      />
      <VinviteVersion />
      <IonActionSheet
        isOpen={showActionSheet || false}
        onDidDismiss={(): void => setShowActionSheet(false)}
        buttons={[{
          text: intl.formatMessage({ id: 'userRegistrationForm.title.caption' }),
          handler: handleChangePassword,
        }, {
          text: intl.formatMessage({ id: 'userProfile.buttons.logOut' }),
          handler: handleLogout,
        }, {
          text: intl.formatMessage({ id: 'general.buttons.cancel' }),
          role: 'cancel',
        }]}
      >
      </IonActionSheet>
    </Flex>
  );
}
