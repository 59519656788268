import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgBandaidIcon(props) {
  return (
    <svg width={18} height={18} fill="none" {...props}>
      <path
        d="M6.632 9a.474.474 0 100-.947.474.474 0 000 .947zM9.71 10.184a.474.474 0 100-.947.474.474 0 000 .947zM8.526 7.105a.474.474 0 100-.947.474.474 0 000 .947zM8.053 10.421a.474.474 0 100-.947.474.474 0 000 .947zM9.947 8.526a.474.474 0 100-.947.474.474 0 000 .947zM9.474 11.842a.474.474 0 100-.947.474.474 0 000 .947zM11.368 9.948a.474.474 0 100-.948.474.474 0 000 .948zM8.29 8.763a.474.474 0 100-.947.474.474 0 000 .947z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M16.682 1.316a4.505 4.505 0 00-6.364 0l-9 9.004A4.47 4.47 0 000 13.502a4.47 4.47 0 001.318 3.182A4.486 4.486 0 004.5 18a4.486 4.486 0 003.182-1.316l9-9.004A4.47 4.47 0 0018 4.498a4.471 4.471 0 00-1.318-3.182zm-8.16 3.138l5.023 5.024-4.066 4.068-5.024-5.024 4.066-4.068zm-1.51 11.56a3.557 3.557 0 01-5.024 0 3.528 3.528 0 01-1.04-2.512c0-.949.369-1.84 1.04-2.512l1.797-1.798 5.025 5.024-1.798 1.798zm9-9.004l-1.797 1.798L9.19 3.784l1.797-1.798A3.542 3.542 0 0113.5.947c.91 0 1.82.346 2.512 1.039a3.528 3.528 0 011.04 2.512c0 .948-.37 1.84-1.04 2.512z"
        fill="var(--ion-color-dark)"
      />
    </svg>
  );
}

export default SvgBandaidIcon;
