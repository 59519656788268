import { IonListHeader as IonListHeaderBase } from '@ionic/react';
import styled from 'styled-components';
import { typography } from 'styled-system';
import textVariables from 'theme/textVariables.json';
import { ellipsis } from 'utils/styledHelpers';

const IonListHeader = styled(IonListHeaderBase)`
  ${typography}
  ${ellipsis}
`;

IonListHeader.defaultProps = {
  fontSize: textVariables.heading.fontSize.xs,
  fontWeight: 'bold',
};

export { IonListHeader };
