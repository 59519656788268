import * as React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';
import { isAndroid } from 'react-device-detect';

interface TextareaStylesProps extends BoxProps {
  isTextarea?: boolean;
}

export function TextareaStyles(props: TextareaStylesProps): JSX.Element {
  const { children, isTextarea = false, ...otherProps } = props;

  if (isTextarea) {
    return (
      <Box
        _focusWithin={{
          borderColor: 'var(--highlight-background, var(--ion-color-primary))'
        }}
        border="1px solid var(--border-color)"
        borderRadius={4}
        className="textarea-styles"
        my="calc(var(--ion-safe-area-right, 0px) + var(--inner-padding-end))"
        pl={8}
        pt={isAndroid ? 0 : 8}
        width="100%"
        {...otherProps}
      >
        {children}
      </Box>
    )
  }

  return <>{children}</>

}