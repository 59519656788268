import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgStoneIcon(props) {
  return (
    <svg width={18} height={14} fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.217 0a.6.6 0 00-.306.075L4.507 3.08a.6.6 0 00-.275.332l-.54 1.626L1.44 6.724a.599.599 0 00-.217.314l-1.2 4.2a.6.6 0 00.575.767h7.802c.064 0 .13-.01.19-.03l1.799-.603a.6.6 0 00.08-.032l.332-.168v.23a.6.6 0 00.377.558l2.999 1.201a.6.6 0 00.446 0l3-1.201a.6.6 0 00.376-.558v-2.4a.6.6 0 00-.097-.333l-.5-.75V4.204a.602.602 0 00-.085-.31l-1.803-3a.599.599 0 00-.438-.287L10.275.005A.592.592 0 0010.217 0zm.106 1.223l4.316.54 1.102 1.838h-1.94a.599.599 0 00-.332.102l-5.25 3.5H4.201c-.8 0-.8 1.2 0 1.2h4.2a.599.599 0 00.331-.101l5.25-3.5h2.22v1.784l-2.255-.566a.6.6 0 00-.505.102l-2.4 1.8a.6.6 0 00-.24.482v1.428l-.833.414-1.666.558h-6.91l.93-3.243L4.56 5.883a.6.6 0 00.208-.292l.528-1.578 5.027-2.79zm3.611 6.033l1.895.474.976 1.453v1.812l-2.402.962-2.4-.962V9.462l2.09 1.253a.6.6 0 00.576.023l1.2-.598c.775-.337.198-1.49-.535-1.073l-.904.453-1.953-1.171 1.457-1.093z"
        fill="var(--ion-color-dark)"
      />
    </svg>
  );
}

export default SvgStoneIcon;
