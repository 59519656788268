import { IonButtons, IonContent, IonHeader, IonIcon, IonModal, IonToolbar } from '@ionic/react';
import { IonButton, IonTitle } from 'components/ionicComponents';
import { close } from 'ionicons/icons';
import { PrivatePropertyContainer } from 'containers/authorization/PrivatePropertyContainer';
import WineScoreSummaryContainer from 'containers/wineScores/ViewWineScoreSummaryContainer';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { hideModal } from 'utils/modals';
import { faEdit } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const WineScoreSummaryModalId = 'WineScoreSummary';

export default function WineScoreSummaryModal(): JSX.Element {
  const dispatch = useDispatch();
  const modalProps = useSelector((state: RootState) =>
    state.gui.modals.find(e => e.id === WineScoreSummaryModalId),
  );
  const [editToggle, setEditToggle] = useState<boolean | undefined>(undefined);
  const onEditToggle = () => {
    setEditToggle(!editToggle);
  }
  const container = modalProps?.params?.wine
    ? <WineScoreSummaryContainer
      hideBackHeader={modalProps.params?.hideBackHeader}
      wineId={modalProps.params.wine.id}
      editAction={editToggle}
    />
    : <></>;
  return (
    <IonModal
      isOpen={modalProps?.show || false}
      onDidDismiss={(): void => {
        hideModal(dispatch, WineScoreSummaryModalId, {});
        setEditToggle(undefined);
      }}
    >
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={(): void => hideModal(dispatch, WineScoreSummaryModalId, modalProps?.params, modalProps?.callback, { modified: true })} >
              <IonIcon icon={close} slot="icon-only" />
            </IonButton>
          </IonButtons>
          <IonTitle>
            <FormattedMessage id="wineScoreSummary.header.caption" />
          </IonTitle>
          <IonButtons slot="end">
            <IonButton
              fill="clear"
              onClick={(): void => { onEditToggle() }}
              paddingStart="4px"
            >
              <FontAwesomeIcon icon={faEdit} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <PrivatePropertyContainer>
          <PrivatePropertyContainer.Unauthorized>
            This is what the wine score summary modal looks like when you are not logged in.
          </PrivatePropertyContainer.Unauthorized>
          <PrivatePropertyContainer.Authorized>
            {container}
          </PrivatePropertyContainer.Authorized>
        </PrivatePropertyContainer>
      </IonContent>
    </IonModal>
  );
}
