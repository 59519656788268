import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonModal,
  IonToolbar,
} from '@ionic/react';
import { close } from 'ionicons/icons';
import { IonButton, IonTitle } from 'components/ionicComponents';
import { RootState } from 'store';
import { hideModal } from 'utils/modals';
import DateFilterContainer from 'containers/filters/DateFilterContainer';

export const DateFilterModalId = 'DateFilter';

export default function DateFilterModal(): JSX.Element {
  const dispatch = useDispatch();
  const modalProps = useSelector((state: RootState) =>
    state.gui.modals.find(e => e.id === DateFilterModalId),
  );

  return (
    <IonModal
      isOpen={modalProps?.show || false}
      onDidDismiss={(): void => hideModal(dispatch, DateFilterModalId, {})}
    >
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={(): void => hideModal(dispatch, DateFilterModalId, {})}>
              <IonIcon icon={close} slot="icon-only" />
            </IonButton>
          </IonButtons>
          <IonTitle>
            <FormattedMessage id="dateFilterModal.header.title" />
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
            <DateFilterContainer
              successHandler={(...data): void => hideModal(
                dispatch,
                DateFilterModalId,
                modalProps?.params,
                () => modalProps?.callback && modalProps.callback(...data),
              )}
            />
      </IonContent>
    </IonModal>
  );
}
