import React, { FunctionComponent } from 'react';
import { Flight } from 'store/wines';
import { Paragraph } from 'components/baseElements/typography';
import { FormattedMessage } from 'react-intl';
import { Flex } from 'components/baseElements/grid';
import { LoadingStatus } from 'utils/formValidation';
import { SubmitButton } from 'components/baseElements/formControls';

interface DeleteFlightProperties {
  loadingStatus?: LoadingStatus;
  deleteAction: (data: Flight) => void;
  flight?: Flight;
}

const DeleteFlight: FunctionComponent<DeleteFlightProperties> = (
  props: DeleteFlightProperties,
) => {
  const {
    deleteAction,
    flight,
    loadingStatus,
  } = props;

  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      px={3}
      width={1}
    >
      <SubmitButton
        color="danger"
        expand={null}
        isLoading={loadingStatus === LoadingStatus.Loading}
        onClick={(): void => flight && deleteAction(flight)}
      >
        <FormattedMessage id="deleteFlight.labels.deleteButton" />
      </SubmitButton>
      <Paragraph>
        <FormattedMessage id="deleteFlight.text.deleteAssurance" />
      </Paragraph>
    </Flex>
  );
}

export default DeleteFlight;