import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgMetalIcon(props) {
  return (
    <svg width={18} height={10} fill="none" {...props}>
      <path
        d="M17.127 7.468a.282.282 0 00-.252-.156h-.563V1.97h.563c.099 0 .19-.052.24-.137L17.96.426A.282.282 0 0017.718 0H.282a.281.281 0 00-.24.426l.843 1.406c.05.085.142.137.241.137h.563v5.343h-.563a.282.282 0 00-.252.156L.03 9.155a.28.28 0 00.251.407H17.718a.282.282 0 00.252-.407l-.843-1.687zM5.344 7.312H4.78V1.97H15.75v5.343H5.344zm11.372-5.906H5.84l.506-.844h10.875l-.506.844zM.736 9L1.3 7.875h.67a.281.281 0 00.281-.281V1.687a.281.281 0 00-.281-.28h-.685L.778.561h4.913l-.507.844H4.5a.281.281 0 00-.281.281v5.907c0 .155.126.28.281.28h.67L5.733 9H.737zm5.625 0L5.8 7.875H16.7L17.264 9H6.36z"
        fill="var(--ion-color-dark)"
      />
    </svg>
  );
}

export default SvgMetalIcon;
