import { Wine } from '../../../store/wines';
import { WineListItemType } from './index';

export const getPointsText = (points?: number, visible?: boolean): string | undefined => {
  if (!points || !visible) {
    return;
  }
  if (points == 1) {
    return '1 pt';
  }
  return `${points} pts`;
};

export const getUserWineRankingPoints = (wine?: Wine, flightId?: string): number => {
  if (!wine) {
    return 0;
  }
  // Flight Rank
  if (flightId) {
    return wine.wineFlightRanking?.userRank?.points || 0;
  }
  // Overall Rank
  return wine.ranking?.userRank?.points || 0;
};

export const getWineNameEllipsis = (type: WineListItemType, hasDesignation: boolean, hasRanking?: boolean): number => {
  switch (type) {
    case WineListItemType.ActionMenu:
    case WineListItemType.DragOption:
    case WineListItemType.RankWinesModal: {
      return hasDesignation ? 2 : 3;
    }
    case WineListItemType.TastingResultsWineRankAsAttendee:
    case WineListItemType.TastingResultsWineRankAsHost:
    case WineListItemType.TastingResultsWineScoreAsAttendee:
    case WineListItemType.UserRating: {
      return hasDesignation ? 1 : 2;
    }
    case WineListItemType.TastingResultsWineScoreAsHost: {
      return 1;
    }
    case WineListItemType.TastingRoom: {
      if (hasDesignation) {
        return hasRanking ? 1 : 2;
      }
      return hasRanking ? 2 : 3;
    }
    default: {
      return 2;
    }
  }
};

export const hideWineRatingLabel = (type: WineListItemType): boolean => {
  switch (type) {
    case WineListItemType.TastingRoom: {
      return false;
    }
    default: {
      return true;
    }
  }
};

export const showWineListItemIonAvatar = (type: WineListItemType): boolean => {
  switch (type) {
    case WineListItemType.TastingResultsWineRankAsAttendee:
    case WineListItemType.TastingResultsWineRankAsHost:
    case WineListItemType.TastingResultsWineScoreAsAttendee:
    case WineListItemType.TastingResultsWineScoreAsHost: {
      return true;
    }
    default: {
      return false;
    }
  }
};

export const showWineListItemPublishButton = (type: WineListItemType): boolean => {
  switch (type) {
    case WineListItemType.TastingResultsWineScoreAsHost: {
      return true;
    }
    default: {
      return false;
    }
  }
};

export const showWineListItemRanks = (type: WineListItemType): boolean => {
  switch (type) {
    case WineListItemType.TastingResultsWineRankAsAttendee:
    case WineListItemType.TastingResultsWineRankAsHost: {
      return true;
    }
    default: {
      return false;
    }
  }
};

export const showWineListItemScoreAverages = (type: WineListItemType): boolean => {
  switch (type) {
    case WineListItemType.TastingResultsWineScoreAsAttendee:
    case WineListItemType.TastingResultsWineScoreAsHost: {
      return true;
    }
    default: {
      return false;
    }
  }
};

export const showWineListItemScores = (type: WineListItemType): boolean => {
  switch (type) {
    case WineListItemType.TastingRoom:
    case WineListItemType.TastingResultsWineScoreAsAttendee:
    case WineListItemType.TastingResultsWineScoreAsHost:
    case WineListItemType.UserRating: {
      return true;
    }
    default: {
      return false;
    }
  }
};
