import { faEdit, faUserPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonContent, IonFooter, useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react';
import { BackHeader, HeaderSubmitButton } from 'components/baseElements/header';
import TastingBanner from 'components/tastings/TastingBanner';
import TastingDetail from 'components/tastings/TastingDetail';
import WineListForTastingContainer from 'containers/wine/WineListForTastingContainer';
import { EditTastingModalId } from 'modals/EditTastingModal';
import { AuthorizationStore } from 'napa-react-core';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { showModal } from 'utils/modals';
import useTastingWithAttendee from '../../../hooks/tastings/useTastingWithAttendee';
import { InviteAttendeesModalId } from '../../../modals/InviteAttendeesModal';
import { toMyTastingVm } from '../../../store/tastings';
import TastingActionBarContainer from '../TastingActionBarContainer';

interface ViewTastingContainerProps {
  successHandler?: () => any;
  tastingId?: string;
}

const ViewTastingContainer: React.FC<ViewTastingContainerProps> = (props: ViewTastingContainerProps) => {
  const { successHandler, tastingId } = props;
  // LOCAL (CONTAINER) STATE SETUP
  const {
    data: tasting,
    isLoading: tastingIsLoading,
    remove: removeTasting,
    refetch: refetchTasting,
  } = useTastingWithAttendee(tastingId, { successHandler });
  const numberOfHoursUntilEndOfTasting = useSelector((state: RootState): number => state.appSettings.numberOfHoursUntilEndOfTasting);
  const tastingWithAttendeesVm = toMyTastingVm(tasting, numberOfHoursUntilEndOfTasting);

  // GLOBAL (REDUX) STATE SETUP
  const dispatch = useDispatch();
  const authData = useContext(AuthorizationStore);
  const showEditButton = authData?.state?.tokenData && authData.state.tokenData['userData/userId'] === tasting?.tasting?.ownerId && !tastingWithAttendeesVm?.tasting?.hasEnded;

  const editTastingModalProps = useSelector((state: RootState) =>
    state.gui.modals.find(e => e.id === EditTastingModalId),
  );

  // EFFECTS SETUP
  useIonViewWillEnter((): void => {
    refetchTasting()
      // eslint-disable-next-line no-console
      .catch(console.error);
  });
  useIonViewWillLeave((): void => {
    removeTasting();
  });
  useEffect(() => {
    if (!editTastingModalProps?.params?.editedTasting) {
      return;
    }
    refetchTasting()
      // eslint-disable-next-line no-console
      .catch(console.error);
  }, [editTastingModalProps, refetchTasting]);
  useEffect(() => {
    refetchTasting()
      // eslint-disable-next-line no-console
      .catch(console.error);
  }, [authData, refetchTasting]);

  const EditButton = (
    <>
      <HeaderSubmitButton
        color="primary"
        data-cy="editTastingBtn"
        slot="icon-only"
        onClick={(): void => {
          return showModal(dispatch, EditTastingModalId, { params: tastingId, callback: refetchTasting });
        }}
      >
        <FontAwesomeIcon icon={faEdit} />
      </HeaderSubmitButton>
      <HeaderSubmitButton
        color="primary"
        data-cy="inviteAttendeesButton"
        slot="icon-only"
        onClick={(): void => {
          return showModal(dispatch, InviteAttendeesModalId, {
            params: {
              tastingId,
              handleInviteAttendeesModalClose: refetchTasting,
            },
          });
        }}
      >
        <FontAwesomeIcon icon={faUserPlus} />
      </HeaderSubmitButton>
    </>
  );
  return (
    <>
      <BackHeader
        backHref="/tastings"
        ionButtonsEnd={showEditButton ? EditButton : null}
      />
      <IonContent>
        <TastingBanner
          tasting={tasting}
        />
        <TastingDetail
          isLoading={tastingIsLoading}
          tasting={tasting}
        />
        <WineListForTastingContainer
          tasting={tasting}
          tastingId={tastingId}
          allowScoring={false}
        />
      </IonContent>
      <IonFooter>
        <TastingActionBarContainer
          reFetchTastingAction={(): void => {
            // Remove previous value
            removeTasting();
            refetchTasting()
              // eslint-disable-next-line no-console
              .catch(console.error);
          }}
          tasting={tasting}
        />
      </IonFooter>
    </>
  );
};

export default ViewTastingContainer;
