import { IonContent, IonFooter, useIonViewWillEnter } from '@ionic/react';
import { Flex } from 'components/baseElements/grid';
import { BackHeader } from 'components/baseElements/header';
import { IonButton } from 'components/ionicComponents';
import TastingBanner from 'components/tastings/TastingBanner';
import TastingDetail from 'components/tastings/TastingDetail';
import { WineListItemType } from 'components/wine/WineListItem';
import WineListForTastingContainer from 'containers/wine/WineListForTastingContainer';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { RootState } from 'store';
import { TastingWithAttendee } from 'store/tastings';
import { Wine } from 'store/wines';
import { clearApiCallStatus, makeApiCall } from 'utils/api';

interface ViewTastingRoomContainerProps {
  successHandler?: () => any;
  tastingId?: string;
}

const ViewTastingRoomContainer: React.FC<ViewTastingRoomContainerProps> = (props: ViewTastingRoomContainerProps) => {
  const { successHandler, tastingId } = props;
  // CONSTANT DECLARATION
  const VIEW_TASTING_KEY = 'viewTastingRoom';
  const PUBLISH_WINE_KEY = 'PUBLISH_WINE_KEY';
  const UNPUBLISH_WINE_KEY = 'UNPUBLISH_WINE_KEY';
  // LOCAL (CONTAINER) STATE SETUP
  const [refreshData, setRefreshData] = useState<boolean>(false);
  const [winesAndFlightsRefresh, triggerWinesAndFlightsRefresh] = useState<string>('');
  const [tasting, setTasting] = useState(undefined as TastingWithAttendee | undefined);
  // GLOBAL (REDUX) STATE SETUP
  const dispatch = useDispatch();
  const auth = useSelector((state: RootState) => state.authorization);
  const apiBaseUrl = useSelector((state: RootState): string => state.appSettings.apiBaseUrl);
  const history = useHistory();
  const [showRankWinesActions, setShowRankWinesActions] = useState(false);
  // LOCAL FUNCTIONS
  const onRankWinesClick = (toggle: boolean) => {
    setShowRankWinesActions(toggle);
  };
  const fetchTasting = useCallback(async (tastingId?: string): Promise<void> => {
    try {
      if (!tastingId) {
        return;
      }
      const result = await makeApiCall<TastingWithAttendee>({
        authToken: auth.token,
        dispatch: dispatch,
        callId: VIEW_TASTING_KEY,
        request: {
          url: `${apiBaseUrl}/tastings/${tastingId}`,
          httpMethod: 'GET',
        },
      });
      setTasting(result);
      if (successHandler) {
        successHandler();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [apiBaseUrl, auth.token, dispatch, successHandler]);
  const publishClickHandler = async (wine: Wine, publish: boolean): Promise<void> => {
    // Keep this line below to prevent the toggle from flipping back and forth a few times when saving and refreshing.
    wine.areScoresPublished = publish;
    try {
      await makeApiCall({
        authToken: auth.token,
        dispatch: dispatch,
        callId: publish ? PUBLISH_WINE_KEY : UNPUBLISH_WINE_KEY,
        request: {
          url: `${apiBaseUrl}/wines/${wine.id}/${publish ? 'publishScores' : 'unPublishScores'}`,
          httpMethod: 'PUT',
        },
        showSuccessMessage: false,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  // EFFECTS SETUP
  useIonViewWillEnter((): void => {
    setRefreshData(true);
  });
  useEffect(() => {
    if (!refreshData) {
      return;
    }
    clearApiCallStatus(dispatch, VIEW_TASTING_KEY);
    fetchTasting(tastingId)
      // eslint-disable-next-line no-console
      .catch(console.error);
    setRefreshData(false);
  }, [dispatch, fetchTasting, refreshData, tastingId]);
  const tastingResultsUrl = `/tastings/details/results/${tastingId}`;
  return (
    <>
      <BackHeader backHref={`/tastings/details/${tastingId}`}>
        <FormattedMessage id="tastingRoom.header.caption" />
      </BackHeader>
      <IonContent>
        <TastingBanner
          tasting={tasting}
        />
        <TastingDetail
          isTastingRoom
          tasting={tasting}
        />
        <WineListForTastingContainer
          allowScoring
          publishClickHandler={publishClickHandler}
          tastingId={tastingId}
          tasting={tasting}
          setRefreshData={setRefreshData}
          showRankWinesActionSheet={showRankWinesActions}
          triggerWinesAndFlightsRefresh={triggerWinesAndFlightsRefresh}
          wineListItemType={WineListItemType.TastingRoom}
          winesAndFlightsRefresh={winesAndFlightsRefresh}
          rankWinesClickHandler={onRankWinesClick}
        />
      </IonContent>
      <IonFooter>
        <Flex
          alignItems="center"
          borderTop="1px solid var(--ion-color-medium-tint)"
          justifyContent="center"
        >
          <IonButton onClick={(): void => onRankWinesClick && onRankWinesClick(true)}>
            <FormattedMessage id="rankWinesTastingListButton.title.caption" />
          </IonButton>
          <IonButton onClick={(): void => history.push(tastingResultsUrl)}>
            <FormattedMessage id="tastingRoom.buttons.tastingResults" />
          </IonButton>
        </Flex>
      </IonFooter>
    </>
  );
};

export default ViewTastingRoomContainer;
