import React, { useEffect } from 'react';
import { IonList, IonInput, useIonViewWillEnter } from '@ionic/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import HookInput from 'components/baseElements/formControls/HookInput';
import { LoadingStatus, handleServerHookForm } from 'utils/formValidation';
import { SubmitButton } from 'components/baseElements/formControls';
import Form from 'components/baseElements/formControls/StyledForm';

interface ChangePasswordFormProperties {
  submitAction: (data: any) => any;
  changePasswordLoadingStatus?: LoadingStatus;
  serverValidation?: any;
  validationSchema?: any;
}

export default function ChangePasswordForm(
  props: ChangePasswordFormProperties,
): JSX.Element {
  const {
    changePasswordLoadingStatus,
    submitAction,
    serverValidation,
    validationSchema,
  } = props;

  const form = useForm({
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      PasswordConfirm: ''
    },
    resolver: yupResolver(validationSchema)
  })

  useIonViewWillEnter((): void => {
    form.reset();
  });
  useEffect(() => {
    handleServerHookForm(serverValidation, form.setError);
  }, [serverValidation, form.setError])

  return (
    <Form onSubmit={form.handleSubmit(submitAction)}>
      <IonList lines="full">
        <HookInput
          form={form}
          isRequired
          labelText="userRegistrationForm.labels.passwordCurrent"
          name="currentPassword"
        >
          <IonInput
            clearOnEdit={false}
            data-cy="currentPassword"
            type="password"
          />
        </HookInput>
        <HookInput
          assistiveText="userRegistrationForm.assistiveText.password"
          form={form}
          isRequired
          labelText="userRegistrationForm.labels.passwordNew"
          name="newPassword"
        >
          <IonInput
            clearOnEdit={false}
            data-cy="newPassword"
            type="password"
          />
        </HookInput>
        <HookInput
          form={form}
          isRequired
          labelText="userRegistrationForm.labels.passwordConfirmNew"
          name="passwordConfirm"
        >
          <IonInput
            clearOnEdit={false}
            data-cy="confirmPassword"
            type="password"
          />
        </HookInput>
      </IonList>
      <SubmitButton
        isLoading={changePasswordLoadingStatus === LoadingStatus.Loading}
      >
        <FormattedMessage id="changePasswordForm.labels.saveButton" />
      </SubmitButton>
    </Form>
  );
}
