import { AlertOptions } from '@ionic/core';
import { IonAlert } from '@ionic/react';
import { ReactControllerProps } from '@ionic/react/dist/types/components/createControllerComponent';
import React from 'react';
import { useIntl } from 'react-intl';

interface ScoreWineStepAlertProps extends AlertOptions, ReactControllerProps{
  onDiscard: () => void;
}

export function ScoreWineStepAlert(props: ScoreWineStepAlertProps): JSX.Element {
  const { onDiscard, ...alertProps } = props;
  const intl = useIntl();
  const headerCaption = intl.formatMessage({
    id: 'scoreWine.stepDiscardAlert.header'
  });
  const messageCaption = intl.formatMessage({
    id: 'scoreWine.stepDiscardAlert.message'
  });
  const cancelCaption = intl.formatMessage({
    id: 'scoreWine.stepDiscardAlert.cancel'
  });
  const discardCaption = intl.formatMessage({
    id: 'scoreWine.stepDiscardAlert.discard'
  });
  return (
    <IonAlert
      {...alertProps}
      header={headerCaption}
      message={messageCaption}
      buttons={[
        {
          text: cancelCaption,
          role: 'cancel',
          cssClass: 'secondary',
        },
        {
          text: discardCaption,
          handler: (): void => {
            onDiscard();
          }
        }
      ]}
    />
  );
}
