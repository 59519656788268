import FlightForm from 'components/flights/FlightForm';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { Flight } from 'store/wines';
import { clearApiCallStatus, makeApiCall } from 'utils/api';
import { LoadingStatus } from 'utils/formValidation';
import { schema } from './validation';

interface AddFlightToTastingContainerProps {
  flights?: Array<Flight>;
  loadingStatus?: LoadingStatus;
  setLoadingStatus?: (status: LoadingStatus) => void;
  successHandler?: (tastingId: string) => any;
  tastingId: string;
}

function AddFlightToTastingContainer(props: AddFlightToTastingContainerProps): JSX.Element {
  const {
    flights,
    loadingStatus,
    setLoadingStatus,
    successHandler,
    tastingId
  } = props;

  // CONSTANT DECLARATIONS
  const CREATE_FLIGHT_KEY = 'addFlight';
  const dispatch = useDispatch();

  // LOCAL (CONTAINER) STATE SETUP
  const [flight, setFlight] = useState(undefined as Flight | undefined);

  // GLOBAL (REDUX) STATE SETUP
  const auth = useSelector((state: RootState) => state.authorization);
  const apiBaseUrl = useSelector((state: RootState): string => state.appSettings.apiBaseUrl);
  const addFlightApiCall = useSelector((state: RootState) =>
    state.api.callStatuses.find(e => e.id === CREATE_FLIGHT_KEY),
  );

  // LOCAL FUNCTIONS
  const flightSubmitAction = (data: any): void => {
    setFlight({ ...data, sequence: data.sequence || flights?.length || 0 });
  };

  const handleFlightSubmit = useCallback(async (): Promise<void> => {
    if (!flight || loadingStatus === LoadingStatus.Loading) {
      return;
    }
    if (setLoadingStatus) {
      setLoadingStatus(LoadingStatus.Loading);
    }
    try {
      await makeApiCall({
        authToken: auth.token,
        dispatch: dispatch,
        callId: CREATE_FLIGHT_KEY,
        request: {
          url: `${apiBaseUrl}/tastings/${tastingId}/flights`,
          httpMethod: 'POST',
          body: flight,
        },
        showSuccessMessage: false,
      });
      if (successHandler) {
        successHandler(tastingId);
      }
    }
    catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    if (setLoadingStatus) {
      setLoadingStatus(LoadingStatus.Complete);
    }
  }, [apiBaseUrl, auth.token, dispatch, flight, loadingStatus, setLoadingStatus, successHandler, tastingId]);

  // EFFECTS SETUP
  useEffect(() => {
    clearApiCallStatus(dispatch, CREATE_FLIGHT_KEY);
  }, [dispatch]);
  useEffect(() => {
    handleFlightSubmit();
  }, [handleFlightSubmit]);

  return (
    <FlightForm
      submitAction={flightSubmitAction}
      serverValidation={addFlightApiCall?.validationErrors}
      validationSchema={schema}
    />
  );
}

export default AddFlightToTastingContainer;
