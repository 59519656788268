import { IonButtons, IonContent, IonHeader, IonIcon, IonModal, IonToolbar } from '@ionic/react';
import { HeaderSubmitButton } from 'components/baseElements/header';
import { IonButton, IonTitle } from 'components/ionicComponents';
import RankWinesContainer from 'containers/tastings/RankWinesContainer';
import { close } from 'ionicons/icons';
import { useToast } from 'napa-react-core';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { hideModal } from 'utils/modals';
import { Hideable } from '../../components/baseElements/hideable';
import usePostTastingWineRankings from '../../hooks/tastings/usePostTastingWineRankings';
import { WineRank } from '../../store/wineRanks';

export const RankWinesModalId = 'RankWinesModal';

interface RankWinesModalProps {
  callback?: () => void;
  tastingId?: string;
}

export default function RankWinesModal(props: RankWinesModalProps): JSX.Element {
  const { callback, tastingId } = props;
  // LOCAL (CONTAINER) STATE SETUP
  const auth = useSelector((state: RootState) => state.authorization);
  const userId = auth.tokenData && auth.tokenData['userData/userId'];
  const [wineRanks, setWineRanks] = useState<WineRank[] | undefined>();
  const [isValid, setIsValid] = useState<boolean>(false);
  const [toggleRefresh, setToggleRefresh] = useState<boolean>(false);
  const [triggerSaveOnClear, setTriggerSaveOnClear] = useState<boolean>(false);
  const modalProps = useSelector((state: RootState) =>
    state.gui.modals.find(e => e.id === RankWinesModalId)
  );
  // GLOBAL (REDUX) STATE SETUP
  const intl = useIntl();
  const { showToast } = useToast();
  const dispatch = useDispatch();
  const handleHideModal = (): void => hideModal(dispatch, RankWinesModalId, {});
  const {
    isLoading: postTastingWineRankingsIsLoading,
    mutate: mutateTastingWineRankings,
  } = usePostTastingWineRankings(tastingId, userId, {
    errorHandler: (): void => {
      showToast({ message: intl.formatMessage({ id: 'rankWinesModal.toast.rankingsSaveFailure' }) });
    },
    successHandler: () => {
      if (isValid) {
        hideModal(dispatch, RankWinesModalId, {});
        if (callback) {
          callback();
        }
      }
      // a success with isValid = false means we simply cleared the rankings
      else {
        setToggleRefresh(!toggleRefresh);
        setIsValid(true);
      }
    }
  });

  useEffect(() => {
    // ignore effect being triggered on initial load
    if (!wineRanks) {
      return;
    }
    mutateTastingWineRankings(wineRanks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerSaveOnClear]);
  // CALLBACK FUNCTIONS

  const clearWineRankings = (wineRanksToUpdate: WineRank[]): void => {
    setIsValid(false);
    // set/sync latest state of updated wineRanks (from RankWinesContainer) when clearing all
    setWineRanks(wineRanksToUpdate);
    setTriggerSaveOnClear(!triggerSaveOnClear)
  };

  const saveWineRankings = useCallback((): any => {
    if (wineRanks && isValid) {
      const wineRanksToSave = modalProps?.params?.flightId ?
        [...wineRanks] : [...wineRanks.map(wine => ({ ...wine, flightId: null }))];
      mutateTastingWineRankings(wineRanksToSave);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutateTastingWineRankings, isValid, wineRanks]);
  return (
    <Hideable hide={!tastingId}>
      <IonModal isOpen={modalProps?.show || false} onDidDismiss={handleHideModal}>
        <IonHeader>
          <IonToolbar color="primary">
            <IonButtons slot="start">
              <IonButton onClick={handleHideModal}>
                <IonIcon icon={close} slot="icon-only" />
              </IonButton>
            </IonButtons>
            <IonTitle>
              <Hideable hide={!modalProps?.params?.flightId}>
                <FormattedMessage id="rankWinesContainer.header.rank" /> {modalProps?.params?.flightName}
              </Hideable>
              <Hideable hide={!!modalProps?.params?.flightId}>
                <FormattedMessage id="rankWinesContainer.header.title" />
              </Hideable>
            </IonTitle>
            <IonButtons slot="end">
              <HeaderSubmitButton
                form="tastingForm"
                isLoading={postTastingWineRankingsIsLoading}
                onClick={saveWineRankings}
              />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <RankWinesContainer
            flightId={modalProps?.params?.flightId}
            onClearWineRanks={clearWineRankings}
            onChangeWineRankings={setWineRanks}
            onChangeIsValid={setIsValid}
            refresh={toggleRefresh}
            tastingId={tastingId}
          />
        </IonContent>
      </IonModal>
    </Hideable>
  );
}
