import GeoLocationFilterForm from 'components/filters/GeoLocationFilterForm';
import { ApiCallResponse, useApiSearch } from 'napa-react-core';
import React, { useEffect, useState } from 'react';
import { useGeoLocation } from '../../../hooks/geoLocation';
import { GeoLocationFilterState } from '../../../store/geoLocation';
import { User } from '../../../store/users';
import { schema } from './validation';

interface GeoLocationFilterContainerProps {
  distanceFromZipCode?: number;
  userId?: string;
  zipCode?: string;
  successHandler: (geoLocationFilterState?: GeoLocationFilterState) => any;
}

function GeoLocationFilterContainer(props: GeoLocationFilterContainerProps): JSX.Element {
  const { distanceFromZipCode, successHandler, userId, zipCode } = props;
  const [userZipCode, setUserZipCode] = useState<string>('');
  const { geoLocation, getPosition, error: geoLocationError } = useGeoLocation();
  const { search: searchUser } = useApiSearch<ApiCallResponse<User>>();

  // Call to get the user profile and latest zipCode once
  useEffect(() => {
    if (userId) {
      try {
        searchUser(`users/${userId}`).then((response: ApiCallResponse<User>) => {
            setUserZipCode(response.responseData.zipCode ?? '');
          })
          // eslint-disable-next-line no-console
          .catch(console.error);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <GeoLocationFilterForm
      distanceFromZipCode={distanceFromZipCode}
      zipCode={zipCode || userZipCode}
      onCurrentLocationSelection={successHandler}
      geoLocation={geoLocation}
      geoLocationError={geoLocationError}
      getPosition={getPosition}
      submitAction={successHandler}
      validationSchema={schema}
    />
  );
}

export default GeoLocationFilterContainer;
