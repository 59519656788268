import WineForm from 'components/wine/WineForm';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { AppSettingsState } from 'store/appSettings';
import { guiStateUpdateToast } from 'store/gui/guiStateUpdateToast';
import { Wine } from 'store/wines';
import { toRequestInsertWineTastingDto } from 'store/wines/utils';
import { WineScore } from 'store/wineScores';
import { toRequestInsertWineScoreDto } from 'store/wineScores/utils';
import { clearApiCallStatus, makeApiCall } from 'utils/api';
import { LoadingStatus } from 'utils/formValidation';
import { schema } from './validation';

interface AddWineForScoringContainerProps {
  loadingStatus?: LoadingStatus;
  setLoadingStatus?: (status: LoadingStatus) => void;
  successHandler?: (wine: Wine) => any;
}

const AddWineForScoringContainer: React.FC<AddWineForScoringContainerProps> = (props: AddWineForScoringContainerProps) => {
  const {
    setLoadingStatus,
    successHandler,
  } = props;

  // CONSTANT DECLARATIONs
  const CREATE_WINE_FOR_SCORING_KEY = 'addWineForScoring';
  const CREATE_WINE_SCORE_KEY = 'addWineScore';
  const dispatch = useDispatch();

  // LOCAL (CONTAINER) STATE SETUP
  const [wineImageId, setWineImageId] = useState<string>('');

  // GLOBAL (REDUX) STATE SETUP
  const auth = useSelector((state: RootState) => state.authorization);
  const { apiBaseUrl, cameraApiSettings } = useSelector((state: RootState): AppSettingsState => state.appSettings);
  const addWineForScoringApiCall = useSelector((state: RootState) =>
    state.api.callStatuses.find(e => e.id === CREATE_WINE_FOR_SCORING_KEY),
  );

  // LOCAL FUNCTIONS
  const handleWineScoringSubmitAction = useCallback(async (wine: Wine, rating: number): Promise<WineScore> => {
    const wineId = wine.id;
    let wineScore = {} as WineScore;
    try {
      wineScore = await makeApiCall({
        authToken: auth.token,
        dispatch: dispatch,
        callId: CREATE_WINE_SCORE_KEY,
        request: {
          url: `${apiBaseUrl}/wines/${wineId}/scores`,
          httpMethod: 'POST',
          body: toRequestInsertWineScoreDto(wineScore, auth, wine.id || '', rating)
        }
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    return wineScore;
  }, [apiBaseUrl, auth, dispatch]);

  const handleWineSubmitAction = useCallback(async (data: any): Promise<void> => {
    if (setLoadingStatus) {
      setLoadingStatus(LoadingStatus.Loading);
    }
    try {
      const wineResponse = await makeApiCall<Wine>({
        authToken: auth.token,
        dispatch: dispatch,
        callId: CREATE_WINE_SCORE_KEY,
        request: {
          url: `${apiBaseUrl}/wines`,
          httpMethod: 'POST',
          body: toRequestInsertWineTastingDto({ ...data, imageFileId: wineImageId }),
        },
        showSuccessMessage: false
      });
      const userScore = await handleWineScoringSubmitAction(wineResponse, data.rating);
      if (successHandler) {
        const wineScore = {
          userScore,
        } as WineScore;
        successHandler({
          ...wineResponse,
          scoring: wineScore
        } as Wine);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    if (setLoadingStatus) {
      setLoadingStatus(LoadingStatus.Complete);
    }
  }, [apiBaseUrl, auth.token, dispatch, handleWineScoringSubmitAction, setLoadingStatus, successHandler, wineImageId]);

  const onUploadError = (): void => {
    dispatch(
      guiStateUpdateToast({
        message: 'api.defaults.failureMessage',
      })
    );
  };
  const onUploadSuccess = (ids: Array<string>): void => {
    setWineImageId(ids[0]);
  }
  // EFFECTS SETUP
  useEffect(() => {
    clearApiCallStatus(dispatch, CREATE_WINE_FOR_SCORING_KEY);
    clearApiCallStatus(dispatch, CREATE_WINE_SCORE_KEY);
  }, [dispatch]);

  return (
    <WineForm
      apiBaseUrl={apiBaseUrl}
      cameraApiSettings={cameraApiSettings}
      onUploadError={onUploadError}
      onUploadSuccess={onUploadSuccess}
      flights={[]}
      showRating={false}
      serverValidation={addWineForScoringApiCall?.validationErrors}
      submitAction={handleWineSubmitAction}
      uploadUrl={`${apiBaseUrl}/files/upload`}
      uploadedFileId={wineImageId}
      validationSchema={schema}
    />
  )
}

export default AddWineForScoringContainer;
