import { VStack } from '@chakra-ui/react';
import { faEdit } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonList, IonToggle } from '@ionic/react';
import { EmptyStateImage } from 'components/baseElements/EmptyStateImage';
import { Box, Flex } from 'components/baseElements/grid';
import { Hideable } from 'components/baseElements/hideable';
import { Paragraph } from 'components/baseElements/typography';
import { IonButton, IonListHeader, IonText } from 'components/ionicComponents';
import { TastingResultViewState } from 'containers/tastings/ViewTastingResultsContainer';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Flight, Wine } from 'store/wines';
import { getFilteredWines, GetFlightsInOrder, GetSortedWinesInFlight } from 'store/wines/utils';
import { WineListItem, WineListItemType } from '../WineListItem';
import { WineRatingProperties } from '../WineRating';
import { showPublishFlightRanking, showPublishOverallRanking } from './utils';

interface WineListForTastingProperties {
  avgWineRatingProps?: WineRatingProperties;
  flightClickHandler?: (flight: Flight) => any;
  flights?: Array<Flight>;
  manualSort?: boolean;
  onRequestImportWineListClick?: () => void;
  publishClickHandler?: (wine: Wine, publish: boolean) => any;
  publishFlightClickHandler?: (flight: Flight, publish: boolean) => any;
  publishTastingClickHandler?: (publish: boolean) => any;
  refresher?: React.ReactNode;
  wineRemoveClickHandler?: (wine: Wine) => void;
  scoreWineClickHandler?: (wine: Wine) => any;
  searchText?: string;
  showCreateWineButton?: boolean;
  showRequestImportWineListButton?: boolean;
  tastingId?: string;
  wineClickHandler?: (wine: Wine) => any;
  wineEllipsisClickHandler?: (wine: Wine) => void;
  userWineRatingProps?: WineRatingProperties;
  wineListItemType?: WineListItemType;
  wines?: Array<Wine>;
  wineRatingProps?: WineRatingProperties;
  tastingResultViewState?: TastingResultViewState;
}

export default function WineListForTasting(props: WineListForTastingProperties): JSX.Element {
  const {
    avgWineRatingProps,
    flightClickHandler,
    flights,
    manualSort,
    onRequestImportWineListClick,
    publishClickHandler,
    publishFlightClickHandler,
    publishTastingClickHandler,
    scoreWineClickHandler,
    searchText,
    showCreateWineButton = false,
    showRequestImportWineListButton = false,
    userWineRatingProps,
    wineClickHandler,
    wineRemoveClickHandler,
    wineEllipsisClickHandler,
    wineListItemType = WineListItemType.Default,
    wineRatingProps,
    wines,
    refresher,
    tastingId,
    tastingResultViewState,
  } = props;
  const filteredFlights = tastingResultViewState === TastingResultViewState.OverallRanking ? [] : flights;
  const areTastingRanksPublished = !!(wines && wines?.length > 0) ? wines[0].tasting?.areRanksPublished : false;

  const filteredWines = getFilteredWines(wines, tastingResultViewState, searchText);
  return (
    <>
      {refresher}
      <Hideable hide={(!wines || !wines.length) && (!filteredFlights || !filteredFlights.length)}>
        <IonList>
          {filteredFlights && GetFlightsInOrder(filteredFlights).map((item: Flight, index: any) => (
            <React.Fragment key={index}>
              {item.name ? (
                <>
                  <Hideable hide={!flightClickHandler}>
                    <IonListHeader>
                      <Flex alignItems="center" width={1}>
                        <Box
                          flex={1}
                          overflow="hidden"
                          pr={2}
                          style={{
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {item.name}
                        </Box>
                        <IonButton onClick={(): void => flightClickHandler && flightClickHandler(item)} fill="clear">
                          <FontAwesomeIcon icon={faEdit} />
                        </IonButton>
                      </Flex>
                    </IonListHeader>
                  </Hideable>
                  <Hideable hide={!!flightClickHandler}>
                    <IonListHeader fontSize="initial">
                      <IonText ellipsis>
                        {item.name}
                      </IonText>
                    </IonListHeader>
                  </Hideable>
                </>
              ) : undefined}
              {filteredWines && (manualSort ? filteredWines : GetSortedWinesInFlight(filteredWines, item.id, tastingResultViewState)).map((item: Wine, key: any) => (
                <WineListItem
                  avgWineRatingProps={avgWineRatingProps}
                  key={key}
                  flightRank={item?.wineFlightRanking?.flightRank}
                  overallRank={item.ranking.overallRank}
                  type={wineListItemType}
                  userWineRatingProps={userWineRatingProps}
                  wine={item}
                  wineRatingProps={wineRatingProps}
                  onEllipsisWineClick={(): void => wineEllipsisClickHandler && wineEllipsisClickHandler(item)}
                  onPublishWineClick={(_, publish): void => publishClickHandler && publishClickHandler(item, publish)}
                  onScoreWineClick={(): void => scoreWineClickHandler && scoreWineClickHandler(item)}
                  onWineClick={(): void => wineClickHandler && wineClickHandler(item)}
                  onWineRemoveClick={(): void => wineRemoveClickHandler && wineRemoveClickHandler(item)}
                  tastingResultViewState={tastingResultViewState}
                />
              ))}
              <Hideable hide={!showPublishFlightRanking(wineListItemType, tastingResultViewState) || !item.id}>
                <Flex alignItems="center">
                  <Box p={3}>
                    <FormattedMessage id="wineTastingList.text.publishFlight" />
                  </Box>
                  <IonToggle
                    checked={!!(item as Flight)?.areRanksPublished}
                    color="success"
                    onIonChange={(e: any): void => {
                      publishFlightClickHandler && publishFlightClickHandler(item, e.detail.checked);
                    }}
                  />
                </Flex>
              </Hideable>
            </React.Fragment>
          ))}
          <Hideable hide={!showPublishOverallRanking(wineListItemType, tastingResultViewState)}>
            <Flex alignItems="center">
              <Box p={3}>
                <FormattedMessage id="wineTastingList.text.publishOverall" />
              </Box>
              <IonToggle
                checked={areTastingRanksPublished}
                color="success"
                onIonChange={(e: any): void => {
                  publishTastingClickHandler && publishTastingClickHandler(e.detail.checked);
                }}
              />
            </Flex>
          </Hideable>
        </IonList>
      </Hideable>
      <Hideable hide={(wines !== undefined && wines?.length > 0) || (filteredFlights !== undefined && filteredFlights?.length > 0)}>
        <VStack pt={8}>
          <EmptyStateImage />
          <VStack pb={24} px={8}>
            <Paragraph
              data-cy="wineListEmptyStateMsg"
              mx="auto"
              my={showCreateWineButton ? 0 : 'initial'}
              textAlign="center"
              width={210}
            >
              <FormattedMessage id={`wineTastingList.text.${showCreateWineButton ? 'getStarted' : 'emptyCaseDescr'}`} />
              <Hideable hide={!showRequestImportWineListButton}>
                <FormattedMessage id="wineTastingList.text.getStartedContd" />
              </Hideable>
            </Paragraph>
            <Hideable hide={!showCreateWineButton}>
              <IonButton
                color="secondary"
                routerLink={`/tastings/details/editWineList/${tastingId}`}
              >
                <FormattedMessage id="createWineTastingListButton.title.caption"/>
              </IonButton>
            </Hideable>
            <Hideable hide={!showRequestImportWineListButton}>
              <Paragraph m={0} textAlign="center">
                <FormattedMessage id="wineTastingList.text.getStartedContdTwo" />
              </Paragraph>
              <IonButton
                color="secondary"
                fill="outline"
                onClick={(): void => onRequestImportWineListClick && onRequestImportWineListClick()}
              >
                <FormattedMessage id="requestImportWineListButton.title.caption" />
              </IonButton>
            </Hideable>
          </VStack>
        </VStack>
      </Hideable>
    </>
  );
}
