import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgToastIcon(props) {
  return (
    <svg width={14} height={18} fill="none" {...props}>
      <path
        d="M1.596 2.59c.162-1.244 1.657-1.514 2.324-1.226C4.316-.059 6.17.121 6.748.842c.955-1.315 3.404-.99 3.873.288.936.054 1.063.883.972 1.135 1.081.306 1.333 1.207 1.243 1.891-.882-.45-3.08-1.224-5.962-1.224-3.603 0-5.62.846-6.323 1.116C.3 3.31 1.11 2.553 1.596 2.59zm5.71 6.826l-.216-.252-2.035 1.657.216.253 2.035-1.658zm.36-1.801l-.216-.252L5.18 9.2l.217.252 2.27-1.837zm2.504 9.907c-1.783-.54-5.17-.594-6.935 0-1.711.577-3.386.793-3.224-.288.18-1.26.792-2 .792-2.54v-8.79s-.792-1.171.09-1.55C1.92 3.923 4.299 3.329 6.82 3.329c2.45 0 5.17.666 5.944 1.225.577.414-.09 1.35-.09 1.35v8.791c0 .793.469 1.135.72 2.54.199 1.28-2.287.577-3.224.288zm-5.314-5.35l-.162.577 4.954-4.341-.288-.92-.955-.377-.324-1.081c-.234.198-4.612 3.855-4.702 3.927l.559.27-.162.252-.595-.288.415.612-.27.487 1.729.126-.09.306-1.73-.126.343.468 1.278.108zm5.386-3.08L9.9 8.624l-4.935 4.341.342.468 4.935-4.34z"
        fill="var(--ion-color-dark)"
      />
    </svg>
  );
}

export default SvgToastIcon;
