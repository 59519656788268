import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { IonButton, IonText } from 'components/ionicComponents';
import { Box, Flex, Text } from '@chakra-ui/react';

interface DetailItemProperties {
  children?: React.ReactNode;
  'disabled'?: boolean;
  icon: IconProp;
  onClick?: (event: React.MouseEvent<HTMLIonButtonElement>) => void | undefined;
}

export default function DetailItem(props: DetailItemProperties): JSX.Element {
  const {
    children,
    disabled,
    icon,
    ...otherProps
  } = props;

  if (disabled) {
    return (
      <Flex
        alignItems="baseline"
        pt={1}
      >
        <Flex
          alignItems="center"
          background="var(--ion-color-medium)"
          borderRadius="50%"
          justifyContent="center"
          height={28}
          width={28}
        >
          <IonText color="light">
            <FontAwesomeIcon icon={icon} size="xs" />
          </IonText>
        </Flex>
        <Box flex={1} pl={8}>
          <Text
            fontSize={16}
            m={0}
            whiteSpace="pre"
          >
            {children}
          </Text>
        </Box>
      </Flex>
    );
  }

  return (
    <IonButton
      alignItems="baseline"
      fill="clear"
      m={0}
      mb={-2}
      paddingEnd="0"
      paddingStart="0"
      pb={1}
      shape={undefined}
      {...otherProps}
    >
      <Flex
        alignItems="center"
        background="var(--ion-color-medium)"
        borderRadius="50%"
        justifyContent="center"
        height={28}
        width={28}
      >
        <IonText color="light">
          <FontAwesomeIcon icon={icon} size="xs" />
        </IonText>
      </Flex>
      <Box flex={1} pl={8}>
        <Text
          fontSize={16}
          m={0}
          whiteSpace="pre"
        >
          {children}
        </Text>
      </Box>
    </IonButton>
  );
}
