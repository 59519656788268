import { connectRouter } from 'connected-react-router';
import { combineReducers, Reducer } from 'redux';

import history from 'utils/history';
import packageJson from '../../package.json';
import { apiStateReducer } from './api';
import { appSettingsReducer } from './appSettings';
import { authorizationReducer } from './authorization';
import { guiStateReducer } from './gui';
const { version } = packageJson;

const rootReducer: any = combineReducers({
  api: apiStateReducer,
  appSettings: appSettingsReducer,
  authorization: authorizationReducer,
  gui: guiStateReducer,
  router: connectRouter(history),
  version: () => version,
});

export function createReducer(): Reducer<any, any> {
  return rootReducer;
}

export { rootReducer };

export type RootState = ReturnType<typeof rootReducer>;
