import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgPetrolIcon(props) {
  return (
    <svg width={12} height={18} fill="none" {...props}>
      <path
        d="M11.615 0h-3.24a.36.36 0 00-.36.36v1.288c0 .209.17.36.36.36h3.24a.36.36 0 00.36-.36V.36c0-.19-.17-.36-.36-.36zM7.825 10.175H4.206v3.619h3.62v-3.62z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M11.672 2.69H8.26c-.17 0-.36.038-.512.133l-.568.303-.398-.758a2.126 2.126 0 00-1.231-1.023 2.03 2.03 0 00-1.592.152L1.175 2.956A2.184 2.184 0 000 4.888v12.013A1.1 1.1 0 001.099 18h9.758a1.1 1.1 0 001.099-1.099V2.994c0-.17-.133-.304-.284-.304zM1.497 4.889c0-.265.151-.492.379-.606L4.66 2.804a.457.457 0 01.284-.076c.057 0 .114.02.17.038.152.057.285.152.342.284l.398.758-4.358 2.274V4.888zm8.488 10.535a.389.389 0 01-.265.663.455.455 0 01-.284-.113L8.034 14.57H3.979l-1.402 1.403a.386.386 0 01-.55 0 .389.389 0 010-.55l1.384-1.383V9.947L2.027 8.564a.389.389 0 01.55-.55L3.96 9.399h4.074l1.383-1.383a.389.389 0 01.55.55L8.601 9.946v4.074l1.383 1.402z"
        fill="var(--ion-color-dark)"
      />
    </svg>
  );
}

export default SvgPetrolIcon;
