import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgTobaccoIcon(props) {
  return (
    <svg width={18} height={18} fill="none" {...props}>
      <path
        d="M17.42 9.29h-5.227c-.32 0-.58.26-.58.58v.582c0 .32.26.58.58.58v.988a1.336 1.336 0 01-2.52.615L8.14 9.675a10.514 10.514 0 00-6.397-5.247v-.073c0-.32-.26-.58-.58-.58H.58c-.32 0-.581.26-.581.58v2.322c0 .32.26.581.58.581h.581a.58.58 0 00.57-.482 6.091 6.091 0 013.765 3.694l1.43 3.96A5.422 5.422 0 0012.012 18a5.413 5.413 0 005.407-5.407v-1.56c.32 0 .581-.261.581-.581V9.87c0-.32-.26-.58-.58-.58zM.58 6.677V4.355h.581v2.322h-.58zm16.259 4.936h-2.613v.58h2.613v.4a4.832 4.832 0 01-4.827 4.826 4.84 4.84 0 01-4.54-3.187l-1.43-3.96a6.671 6.671 0 00-4.3-4.1V5.033a9.935 9.935 0 015.881 4.908L9.16 12.9c.33.639.982 1.034 1.7 1.034.998 0 1.818-.767 1.906-1.742h.88v-.58h-.87v-.58h4.064v.58zm.58-1.161h-5.226V9.87h5.226v.58z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M16.258 12.593h-.58c0 .146-.01.293-.026.437l.576.069c.02-.167.03-.338.03-.506zM12.012 16.839a4.258 4.258 0 004.093-3.116l-.56-.155a3.676 3.676 0 01-3.533 2.69v.58zM7.839 11.613h-.58v.58h.58v-.58zM8.71 13.355h-.58v.58h.58v-.58zM6.968 9.58h-.58v.581h.58v-.58zM10.965 6.895l1.818.763c.342.143.572.533.572.968v.084h.58v-.084c0-.667-.373-1.271-.928-1.504L11.19 6.36c-.268-.113-.448-.42-.448-.763v-.07c0-.368.204-.688.497-.78l1.793-.565c.532-.167.904-.716.904-1.335 0-.618-.372-1.167-.904-1.334L11.38.993c-.375-.119-.637-.527-.637-.993h-.58c0 .717.428 1.353 1.043 1.546l1.653.52c.292.093.497.414.497.781 0 .368-.205.69-.497.781l-1.793.564c-.532.168-.904.717-.904 1.335v.07c0 .574.323 1.096.804 1.298zM17.071 7.122l-1.817-.762c-.268-.113-.447-.42-.447-.763v-.07c0-.368.204-.688.496-.78l1.793-.565c.532-.167.904-.716.904-1.335 0-.618-.372-1.167-.904-1.334l-1.653-.52c-.375-.119-.636-.527-.636-.993h-.581c0 .717.429 1.353 1.043 1.546l1.653.52c.293.093.497.414.497.781 0 .368-.204.69-.497.781l-1.793.564c-.532.168-.903.717-.903 1.335v.07c0 .574.323 1.096.803 1.298l1.818.763c.342.143.572.533.572.968v.084H18v-.084c0-.667-.373-1.271-.929-1.504z"
        fill="var(--ion-color-dark)"
      />
    </svg>
  );
}

export default SvgTobaccoIcon;
