import { IonModal } from '@ionic/react';
import { PrivatePropertyContainer } from 'containers/authorization/PrivatePropertyContainer';
import ScoreAWineContainer from 'containers/wineScores/ScoreAWineContainer';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { hideModal } from 'utils/modals';

export const ScoreAWineModalId = 'ScoreAWine';

export default function ScoreAWineModal(): JSX.Element {
  const dispatch = useDispatch();
  const modalProps = useSelector((state: RootState) =>
    state.gui.modals.find(e => e.id === ScoreAWineModalId),
  );
  const scoreAWineContainerWithWine = modalProps?.params?.wine
    ? <ScoreAWineContainer
      completionHandler={(data: any, isCompleted: boolean): void => hideModal(
        dispatch,
        ScoreAWineModalId,
        modalProps?.params,
        () => modalProps?.callback && modalProps.callback(data, isCompleted),
        { modified: true },
      )}
      modalId={ScoreAWineModalId}
      stepHandler={(data: any): void => modalProps?.callback && modalProps.callback(data)}
      wine={modalProps.params.wine}
      scoreStep={modalProps?.params?.scoreStep}
    />
    : <></>;
  return (
    <IonModal
      isOpen={modalProps?.show || false}
      onDidDismiss={(): void => hideModal(dispatch, ScoreAWineModalId, {})}
    >
      <PrivatePropertyContainer>
        <PrivatePropertyContainer.Unauthorized>
          This is what the wine scoring modal looks like when you are not logged in.
        </PrivatePropertyContainer.Unauthorized>
        <PrivatePropertyContainer.Authorized>
          {scoreAWineContainerWithWine}
        </PrivatePropertyContainer.Authorized>
      </PrivatePropertyContainer>
    </IonModal>
  );
}
