import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgSaltIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      width={20} height={20} fill="none" {...props}
    >
      <circle cx="82" cy="71.6" r="3.1" />
      <circle cx="84" cy="85" r="3.1" />
      <circle cx="74.9" cy="94.4" r="3.1" />
      <circle cx="71.8" cy="80.1" r="3.1" />
      <path
        d="M56.2 29.3c-4.4-.7-8.7.4-13.7 2.4-5.2 2-11.9 2.3-17.3 1.9l26.3 22.1 14.6-14.6-9.9-11.8z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M75.8 38.8L47.5 5.1c-1.3-1.6-3.3-2.5-5.3-2.6-2.1-.1-4.1.7-5.5 2.1L15.1 26.2c-1.5 1.5-2.2 3.5-2.1 5.5.1 2.1 1 4 2.6 5.3l33.7 28.3c7.4 6.9 19 6.8 26.2-.4 7.1-7.1 7.2-18.7.3-26.1zm-57.4-5.1c-.6-.6-1-1.3-1.1-2.1 0-.8.3-1.6.9-2.2L39.7 7.8c.6-.6 1.3-.9 2.1-.9h.1c.8 0 1.6.4 2.1 1l28.1 33.4-20.3 20.5-33.4-28.1zM63 63.6c-.9.9-2.4.9-3.3 0-.9-.9-.9-2.4 0-3.3.9-.9 2.4-.9 3.3 0 .9.9.9 2.4 0 3.3zm9.2-1.8c-.9.9-2.4.9-3.3 0-.9-.9-.9-2.4 0-3.3.9-.9 2.4-.9 3.3 0 .9.9.9 2.4 0 3.3zm1.8-9.3c-.9.9-2.4.9-3.3 0-.9-.9-.9-2.4 0-3.3.9-.9 2.4-.9 3.3 0 .9 1 .9 2.4 0 3.3z"
        fill="var(--ion-color-dark)"
      />
    </svg>
  );
}

export default SvgSaltIcon;
