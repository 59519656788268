import styled from 'styled-components';
import {
  layout,
  space,
  system,
} from 'styled-system';
import { ReactComponent as LogoText } from './vinvite-logo-text.svg';
import { transient } from 'utils/styledHelpers';

const Logo = styled(LogoText)`
  ${transient(space)}
  ${transient(layout)}
  .gold {
    fill: var(--gold-color-base);
  }
  .light {
    fill: var(--ion-color-light);
  }
  .purple {
    fill: var(--purple-color-base);
  }
  .text {
    ${transient(system({
      fillText: {
        property: 'fill',
      }
    }))}
  }
`;

Logo.defaultProps = {
  $display: 'block',
  $fillText: 'var(--ion-text-color)',
};

export { Logo };
