import UserRegistrationForm from 'components/users/UserRegistrationForm';
import usePostUser from 'hooks/users/usePostUser';
import usePostUserToken from 'hooks/users/usePostUserToken';
import jwtDecode from 'jwt-decode';
import { AuthorizationStore, useToast } from 'napa-react-core';
import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { TokenResponse } from 'store/authorization';
import { authorizationSetToken } from 'store/authorization/actions/authorizationSetToken';
import { User } from 'store/users';
import { schema } from './validation';

interface UserRegistrationContainerProps {
  successHandler?: () => any;
  autoLogin?: boolean;
}

const UserRegistrationContainer: React.FC<UserRegistrationContainerProps> = (props: UserRegistrationContainerProps) => {
  // CONSTANT DECLARATION
  const { successHandler, autoLogin } = props;
  const dispatch = useDispatch();
  const intl = useIntl();
  const { showToast } = useToast();
  const authData = useContext(AuthorizationStore);

  // LOCAL (CONTAINER) STATE SETUP
  const [registrationUser, setRegistrationUser] = useState(undefined as User | undefined);

  // GLOBAL (REDUX) STATE SETUP
  const appSettings = useSelector((state: RootState) => state.appSettings);

  const { isLoading: postUserTokenIsLoading, mutate: mutateUserToken } = usePostUserToken({
    errorHandler: (error: any) => {
      // eslint-disable-next-line no-console
      console.error(error);
      // TODO: Go to login screen
    },
    successHandler: (tokenData: TokenResponse) => {
      const now = new Date();
      const expiration = now.setMinutes(
        now.getMinutes() +
        appSettings.securitySettings.sessionExpirationInMinutes,
      );
      localStorage.setItem(
        'authToken',
        JSON.stringify({
          token: tokenData.token,
          expires: appSettings.securitySettings.sessionExpirationInMinutes > 0 ? new Date(expiration) : undefined,
        }),
      );
      dispatch(
        authorizationSetToken({
          token: tokenData.token,
          tokenData: jwtDecode(tokenData.token),
        }),
      );
      // New boilerplate way to set auth state
      authData.setState({
        token: tokenData.token,
        tokenData: jwtDecode(tokenData.token),
      });
      if (successHandler) {
        successHandler();
      }
    },
  });

  const { error: postUserError, isLoading: postUserIsLoading, mutate: mutateUser } = usePostUser({
    errorHandler: (): void => {
      showToast({ message: intl.formatMessage({ id: 'api.defaults.failureMessage' }) });
    },
    successHandler: () => {
      if (autoLogin) {
        mutateUserToken({ username: registrationUser?.email, password: registrationUser?.password });
      } else {
        if (successHandler) {
          successHandler();
        }
      }
    },
  });

  // CALLBACK FUNCTIONS
  const registrationSubmitAction = (data: User): void => {
    setRegistrationUser(data);
    mutateUser(data);
  };

  return (
    <UserRegistrationForm
      createUserIsLoading={postUserTokenIsLoading || postUserIsLoading}
      submitAction={registrationSubmitAction}
      serverValidation={postUserError?.validationErrors || []}
      validationSchema={schema}
    />
  );
};

export default UserRegistrationContainer;
