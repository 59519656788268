
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { makeApiCall, clearApiCallStatus } from 'utils/api';
import { schema } from './validation';
import { Flight } from 'store/wines';
import FlightForm from 'components/flights/FlightForm';
import DeleteFlightInTastingContainer from '../DeleteFlightInTastingContainer';
import { LoadingStatus } from 'utils/formValidation';

interface EditFlightInTastingContainerProps {
  flight?: Flight;
  loadingStatus?: LoadingStatus;
  setLoadingStatus?: (status: LoadingStatus) => void;
  successHandler?: (tastingId: string) => any;
  tastingId: string;
}

function EditFlightInTastingContainer(props: EditFlightInTastingContainerProps): JSX.Element {
  const {
    loadingStatus,
    setLoadingStatus,
    successHandler,
    tastingId,
  } = props;

  // CONSTANT DECLARATIONs
  const EDIT_FLIGHT_KEY = 'editFlight';
  const dispatch = useDispatch();

  // LOCAL (CONTAINTER) STATE SETUP
  const [flight] = useState(props.flight as Flight | undefined);

  // GLOBAL (REDUX) STATE SETUP
  const auth = useSelector((state: RootState) => state.authorization);
  const apiBaseUrl = useSelector((state: RootState): string => state.appSettings.apiBaseUrl);
  const editFlightApiCall = useSelector((state: RootState) =>
    state.api.callStatuses.find(e => e.id === EDIT_FLIGHT_KEY),
  );

  const saveFlight = async (flightToSave: Flight): Promise<void> => {
    if (!flightToSave || loadingStatus === LoadingStatus.Loading) {
      return;
    }
    if (setLoadingStatus) {
      setLoadingStatus(LoadingStatus.Loading);
    }
    try {
      await makeApiCall({
        authToken: auth.token,
        dispatch: dispatch,
        callId: EDIT_FLIGHT_KEY,
        request: {
          url: `${apiBaseUrl}/flights/${flightToSave.id}`,
          httpMethod: 'PUT',
          body: flightToSave
        }
      });
      if (successHandler) {
        successHandler(tastingId);
      }
    }
    catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    if (setLoadingStatus) {
      setLoadingStatus(LoadingStatus.Complete);
    }
  };

  // LOCAL FUNCTIONS
  const flightSubmitAction = async (data: any): Promise<void> => {
    await saveFlight(data);
  };

  // EFFECTS SETUP
  useEffect(() => {
    clearApiCallStatus(dispatch, EDIT_FLIGHT_KEY);
  }, [dispatch]);
  
  return (
    <>
      <FlightForm
        submitAction={flightSubmitAction}
        serverValidation={editFlightApiCall?.validationErrors}
        validationSchema={schema}
        flight={flight}
      />
      <DeleteFlightInTastingContainer successHandler={successHandler} tastingId={tastingId} flight={flight} />
    </>
  );
}

export default EditFlightInTastingContainer;