/* eslint-disable indent */
import { ToastProperties } from '../types';
import { GUI_STATE_UPDATE_TOAST, GuiStateUpdateToastAction } from './types';

export const guiStateUpdateToast = (
  toastProps: ToastProperties,
): GuiStateUpdateToastAction => ({
  type: GUI_STATE_UPDATE_TOAST,
  payload: {
    toastProps: toastProps,
  },
});
