import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearApiCallStatus, GetLoadingStatus, makeApiCall } from 'utils/api';
import { ChangePasswordRequest } from 'store/users';
import ChangePasswordForm from 'components/users/ChangePasswordForm';
import { RootState } from 'store';
import { schema } from './validation';
import { useHistory } from 'react-router-dom';

export default function ChangePasswordContainer(): JSX.Element {
  // CONSTANT DECLARATION
  const CHANGE_PASSWORD_KEY = 'changePassword';
  const history = useHistory();
  const dispatch = useDispatch();
  // LOCAL (CONTAINER) STATE SETUP
  const [changePasswordRequest, setChangePasswordRequest] = useState(undefined as ChangePasswordRequest | undefined);

  // GLOBAL (REDUX) STATE SETUP
  const auth = useSelector((state: RootState) => state.authorization);
  const apiBaseUrl = useSelector((state: RootState): string => state.appSettings.apiBaseUrl);
  const changePasswordApiCall = useSelector((state: RootState) =>
    state.api.callStatuses.find(e => e.id === CHANGE_PASSWORD_KEY),
  );

  // LOCAL FUNCTIONS
  const changePasswordSubmitAction = (data: any): void => {
    setChangePasswordRequest(data);
  };

  const handleChangePasswordSubmit = useCallback(async (): Promise<void> => {
    if (!changePasswordRequest) {
      return;
    }
    changePasswordRequest.id =
      auth.tokenData && auth.tokenData['userData/userId'];
    try {
      await makeApiCall({
        authToken: auth.token,
        dispatch: dispatch,
        callId: CHANGE_PASSWORD_KEY,
        request: {
          url: `${apiBaseUrl}/users/changePassword`,
          httpMethod: 'PUT',
          body: changePasswordRequest,
        }
      });
      history.goBack();
    }
    catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [apiBaseUrl, auth.token, auth.tokenData, changePasswordRequest, dispatch, history]);

  // EFFECTS SETUP
  useEffect(() => {
    clearApiCallStatus(dispatch, CHANGE_PASSWORD_KEY);
  }, [dispatch]);
  useEffect(() => {
    handleChangePasswordSubmit();
  }, [handleChangePasswordSubmit]);

  return (
    <ChangePasswordForm
      changePasswordLoadingStatus={GetLoadingStatus(
        changePasswordApiCall?.status,
      )}
      submitAction={changePasswordSubmitAction}
      serverValidation={changePasswordApiCall?.validationErrors}
      validationSchema={schema}
    />
  );
}
