import dayjs from 'dayjs';
import { User } from '..';

export const toMyUserVm = (item: User ): User => {
  const { createdAt } = item;
  // get user created At datetime flagged to use UTC time
  // note, when viewing from console, utcDt will show in local time zone but underlying data is still UTC.
  const utcDt = dayjs.utc(createdAt);

  const createdDt = dayjs(utcDt).local(); // flag as local datetime

  return {
    ...item,
    createdAt: createdDt.format('MMM YYYY'),
  } as User
}
