import { IonContent, IonGrid, IonHeader, IonModal, IonRow, IonToolbar } from '@ionic/react';
import { Box } from 'components/baseElements/grid';
import { BackHeader } from 'components/baseElements/header';
import { Paragraph } from 'components/baseElements/typography';
import { IonButton } from 'components/ionicComponents';
import LoginContainer from 'containers/authorization/LoginContainer';
import ForgotPasswordContainer from 'containers/users/ForgotPasswordContainer';
import UserRegistrationContainer from 'containers/users/UserRegistrationContainer';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { hideModal } from 'utils/modals';
import CloseButton from './CloseButton';

export const AuthorizationModalId = 'authorization';

export default function AuthorizationModal(): JSX.Element {
  const dispatch = useDispatch();
  const [mode, setMode] = useState('registration');
  const modalProps = useSelector((state: RootState) =>
    state.gui.modals.find(e => e.id === AuthorizationModalId),
  );

  //When you reset password, we want the authorization modal to popup and direct user to login page instead of signup
  useEffect(() => {
    if (modalProps?.params?.mode)
    {
      setMode(modalProps?.params?.mode);
    }

  }, [modalProps]);

  return (
    <IonModal
      isOpen={modalProps?.show || false}
      onDidDismiss={(): void => hideModal(dispatch, AuthorizationModalId, {})}>
      {
        (mode === 'registration' ? (
        <>
          <Box backgroundColor="rgba(var(--ion-color-secondary-rgb), 0.21)">
            <IonHeader>
              <IonToolbar color="transparent">
                <IonGrid>
                  <IonRow className="ion-align-items-center ion-justify-content-evenly">
                    <Paragraph>
                      <FormattedMessage id="userRegistrationForm.text.alreadyHaveAccount" />
                    </Paragraph>
                    <IonButton
                      data-cy="loginButton"
                      onClick={(): void => setMode('login')}
                    >
                      <FormattedMessage id="general.nav.login" />
                    </IonButton>
                  </IonRow>
                </IonGrid>
              </IonToolbar>
            </IonHeader>
          </Box>
          <IonContent>
            <CloseButton>
              <UserRegistrationContainer
                autoLogin={true}
                successHandler={(): void => hideModal(dispatch, AuthorizationModalId, { params: { loggedIn: true } }, modalProps?.callback)}
              />
            </CloseButton>
          </IonContent>
        </>
        ) : (mode === 'forgotPassword' ? (
          <>
            <BackHeader onClick={(): void => setMode('login')}>
              <FormattedMessage id="forgotPasswordContainer.title.caption" />
            </BackHeader>
            <IonContent>
              <ForgotPasswordContainer />
            </IonContent>
          </>
          ) : (
          <>
            <Box backgroundColor="rgba(var(--ion-color-secondary-rgb), 0.21)">
              <IonHeader>
                <IonToolbar color="transparent">
                  <IonGrid>
                    <IonRow className="ion-align-items-center ion-justify-content-evenly">
                      <Paragraph>
                        <FormattedMessage id="login.text.registationCaption"/>
                      </Paragraph>
                      <IonButton
                        color="secondary"
                        data-cy="signUpButton"
                        onClick={(): void => setMode('registration')}
                      >
                        <FormattedMessage id="general.nav.registration"/>
                      </IonButton>
                    </IonRow>
                  </IonGrid>
                </IonToolbar>
              </IonHeader>
            </Box>
            <IonContent>
              <CloseButton>
                <LoginContainer
                  setModeToForgotPassword={(): void => setMode('forgotPassword')}
                  successHandler={(): void => hideModal(dispatch, AuthorizationModalId, { params: { loggedIn: true } }, modalProps?.callback)}
                />
              </CloseButton>
            </IonContent>
          </>
          )
        )
      )}
    </IonModal>
  );
}
