import * as React from 'react';
import { IonContent, IonHeader, IonPage, IonToolbar } from '@ionic/react';
import { IonTitle } from 'components/ionicComponents';

export default function PrivacyPage(): JSX.Element {

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Privacy Policy</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <iframe
          src="https://storage.googleapis.com/napaqa-cdn/vinvite/privacy.html"
          style={{ border: '0', maxHeight: 'calc(100% - 4px)' }}
          width="100%"
          height="100%"
        />
      </IonContent>
    </IonPage>
  );
}
