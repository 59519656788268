import { ModalProperties } from '../types';

export const GUI_STATE_UPDATE_MODAL = 'GUI_STATE_UPDATE_MODAL';

interface Payload {
  modalProps: ModalProperties<any>;
}

export interface GuiStateUpdateModalAction {
  type: typeof GUI_STATE_UPDATE_MODAL;
  payload: Payload;
}
