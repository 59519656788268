import { GuiState } from './types';
import { Reducer, createSubReducer } from 'utils/store';
import { guiStateUpdateToastReducer, GUI_STATE_UPDATE_TOAST } from './guiStateUpdateToast';
import { GUI_STATE_UPDATE_MODAL, guiStateUpdateModalReducer } from './guiStateUpdateModal';

const initialState = {
  modals: [],
  toast: {
    show: false,
    message: ''
  },
};

const reducers: Array<Reducer<GuiState>> = [
  {
    type: GUI_STATE_UPDATE_TOAST,
    reducer: guiStateUpdateToastReducer,
  },
  {
    type: GUI_STATE_UPDATE_MODAL,
    reducer: guiStateUpdateModalReducer,
  },
];

export const guiStateReducer = createSubReducer<GuiState>(
  initialState,
  reducers,
);
