import { IonButton } from '@ionic/react';
import React from 'react';

interface LoginContainerProps {
  referrer?: string;
}

export default function NotFoundContainer(props: LoginContainerProps): JSX.Element {
  return (
    <div>
      <h1>Oops!</h1>
      <p>We can&apos;t seem to find the page you&apos;re looking for.</p>
      <IonButton routerLink={props?.referrer || '/Users/Profile'}>Go Back</IonButton>
    </div>
  );
}
