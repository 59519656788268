import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgOrangeIcon(props) {
  return (
    <svg width={19} height={20} fill="none" {...props}>
      <path
        d="M17.215 10.931c0-4.974-4.025-9-9-9"
        stroke="var(--ion-color-dark)"
        strokeWidth={0.7}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M10.58 2.238c6.554-1.522 8.914 5.696 6.658 8.693-3.744-5.024-7.743-5.078-6.658-8.693z"
        stroke="var(--ion-color-dark)"
        strokeWidth={0.7}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.759 3.483A2.481 2.481 0 006.276 1"
        stroke="var(--ion-color-dark)"
        strokeWidth={0.7}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M10.42 3.663a7.759 7.759 0 106.097 7.578 7.71 7.71 0 00-.125-1.359c-2.902-3.345-5.715-4.105-5.972-6.219z"
        stroke="var(--ion-color-dark)"
        strokeWidth={0.7}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.553 11.238l-.001.003a6.214 6.214 0 016.207-6.207"
        stroke="var(--ion-color-dark)"
        strokeWidth={0.7}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgOrangeIcon;
