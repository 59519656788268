import { RefresherEventDetail } from '@ionic/core';
import { IonActionSheet, IonRefresher, IonRefresherContent, IonText, useIonViewWillEnter } from '@ionic/react';
import { ActionSheetButton } from '@ionic/react/dist/types/components/IonActionSheet';
import { Box } from 'components/baseElements/grid';
import { Loadable, Loaded } from 'components/baseElements/loadable';
import WineListForTasting from 'components/wine/WineListForTasting';
import WineListForTastingActionBar from 'components/wine/WineListForTastingActionBar';
import { WineListItemType } from 'components/wine/WineListItem';
import { TastingResultViewState } from 'containers/tastings/ViewTastingResultsContainer';
import useRequestTastingWinesUpload from 'hooks/tastings/useRequestTastingWinesUpload';
import useTastingFlights from 'hooks/tastings/useTastingFlights';
import useTastingWines from 'hooks/tastings/useTastingWines';
import { chevronDownCircleOutline } from 'ionicons/icons';
import { ScoreAWineModalId } from 'modals/ScoreAWineModal';
import { Hideable } from 'napa-react-core';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { RootState } from 'store';
import { getTastingStatus, TastingResultInfo, TastingWithAttendee } from 'store/tastings';
import { Flight, Wine } from 'store/wines';
import { defaultWineRating, userHasWineScore } from 'store/wineScores';
import { showModal } from 'utils/modals';
import { v4 as uuidv4 } from 'uuid';
import usePublishTastingRanks from '../../../hooks/tastings/usePublishTastingRanks';
import useUnPublishTastingRanks from '../../../hooks/tastings/useUnPublishTastingRanks';
import RankWinesModal, { RankWinesModalId } from '../../../modals/RankWinesModal';
import { canManageTastingWines } from '../../../store/wines/utils';
import { makeApiCall } from '../../../utils/api';
import { viewTastingResultInfoCaptions } from './utils';


interface WineListForTastingContainer {
  allowScoring: boolean;
  publishClickHandler?: (wine: Wine, publish: boolean) => any;
  rankWinesClickHandler?: (toggle: boolean) => void;
  setRefreshData?: Dispatch<SetStateAction<boolean>>;
  showRankWinesActionSheet?: boolean;
  tastingResultViewState?: TastingResultViewState;
  tastingId?: string;
  tasting?: TastingWithAttendee;
  triggerWinesAndFlightsRefresh?: (id: string) => void;
  wineListItemType?: WineListItemType;
  winesAndFlightsRefresh?: string; // Change the value to trigger refresh of data
  updateTastingResultInfo?: (tastingResultsInfo: TastingResultInfo) => void;
}

const WineListForTastingContainer: React.FC<WineListForTastingContainer> = (props: WineListForTastingContainer) => {
  const {
    allowScoring,
    publishClickHandler,
    rankWinesClickHandler,
    setRefreshData,
    showRankWinesActionSheet = false,
    tastingId,
    tasting,
    tastingResultViewState,
    triggerWinesAndFlightsRefresh,
    updateTastingResultInfo,
    wineListItemType = WineListItemType.Default,
    winesAndFlightsRefresh,
  } = props;
  // GLOBAL (REDUX) STATE SETUP
  const dispatch = useDispatch();
  const history = useHistory();
  const auth = useSelector((state: RootState) => state.authorization);
  const apiBaseUrl = useSelector((state: RootState): string => state.appSettings.apiBaseUrl);
  const numberOfHoursUntilEndOfTasting = useSelector((state: RootState): number => state.appSettings.numberOfHoursUntilEndOfTasting);

  const userId = auth.tokenData && auth.tokenData['userData/userId'];

  const allowPublicEventCreation = auth.tokenData && auth.tokenData['claim/AllowPublicEventCreation'] === 'true';
  // LOCAL (CONTAINER) STATE SETUP
  const [showImportWineListActionSheet, setShowImportWineListActionSheet] = useState(false);
  const { isLoading: requestTastingWinesUploadLoading, mutate: mutateRequestTastingWinesUpload } = useRequestTastingWinesUpload(tastingId);
  const { data: flightData, isLoading: flightsAreLoading, refetch: refetchFlights } = useTastingFlights(tastingId);
  const { data: wineData, isLoading: winesAreLoading, refetch: refetchWines } = useTastingWines(tastingId);
  const { mutate: mutatePublishTastingRanks } = usePublishTastingRanks(tastingId, {
    successHandler: () => {
      setRefreshData && setRefreshData(true);
      doRefresh();
    },
  });
  const { mutate: mutateUnPublishTastingRanks } = useUnPublishTastingRanks(tastingId, {
    successHandler: () => {
      setRefreshData && setRefreshData(true);
      doRefresh();
    },
  });

  const { formatMessage } = useIntl();

  const onRequestImportWinesClickHandler = (show: boolean): void => {
    setShowImportWineListActionSheet(show);
  }
  const canRequestWineListImport = (): boolean => {
    const { hasEnded } = getTastingStatus(tasting, numberOfHoursUntilEndOfTasting);
    const isHost = !!tasting?.attendee?.isHost;
    return isHost && !hasEnded;
  }
  const canEditWineList = (): boolean => {
    const { hasEnded } = getTastingStatus(tasting, numberOfHoursUntilEndOfTasting);
    return canManageTastingWines(tasting, userId) && (wineData?.items?.length || 0) > 0 && !hasEnded;
  };

  const canCreateWineList = (): boolean => {
    const { hasEnded } = getTastingStatus(tasting, numberOfHoursUntilEndOfTasting);
    return canManageTastingWines(tasting, userId) && wineData?.items?.length === 0 && !hasEnded;
  };

  const scoreWineClickHandler = (wine: Wine): void => {
    if (!allowScoring || wineListItemType !== WineListItemType.TastingRoom) {
      return;
    }

    // Now redirects to score summary if wine already scored
    if (!userHasWineScore(wine)) {
      // Add default score to rating to prevent it from being empty
      wine.scoring.userScore = { rating: defaultWineRating };
      showModal(dispatch, ScoreAWineModalId, {
        params: { wine: { ...wine } },
        callback: (data: Wine, isScoringCompleted?: boolean) => updateWineScore(data, isScoringCompleted),
      });
    } else {
      history.push({
        pathname: `/tastings/details/room/wineScore/${wine.id}`,
      });
    }
  };
  const getImportWineListActionSheetButtons = (): ActionSheetButton[] => {
    const buttons = allowPublicEventCreation ?
      [{
        text: formatMessage({ id: 'importWineListActionSheet.confirm' }),
        handler: () => mutateRequestTastingWinesUpload(tastingId),
      }]: [];
    return [
      ...buttons,
      {
        text: 'Cancel',
        handler: () => undefined,
      },
    ];
  }
  const getRankWinesActionSheetButtons = (flights: Flight[]): ActionSheetButton[] => {
    const buttons = flights.sort((a, b) => a.sequence > b.sequence ? 1 : -1).reduce((results: ActionSheetButton[], flight: Flight): ActionSheetButton[] => [
      ...results,
      {
        text: `${formatMessage({ id: 'rankWinesContainer.header.rank' })} ${flight.name}`,
        handler: (): void => showRankWinesModal(flight.id, flight.name),
      },
    ], [{
      text: formatMessage({ id: 'rankWinesContainer.header.title' }),
      handler: showRankWinesModal,
    }]);
    return [
      ...buttons,
      {
        text: 'Cancel',
        handler: () => undefined,
      },
    ];
  };
  const showRankWinesModal = (flightId?: string | null, flightName?: string): void => {
    showModal(dispatch, RankWinesModalId, { params: { flightId, flightName, tastingId } });
  };
  const onRankWinesModalDismiss = (): void => {
    if (setRefreshData) {
      setRefreshData(true);
    }
    if (triggerWinesAndFlightsRefresh) {
      triggerWinesAndFlightsRefresh(uuidv4());
    }
  };

  const handleFlightPublishing = async (flight: Flight, areRanksPublished: boolean): Promise<void> => {
    // Keep this line below to prevent the toggle from flipping back and forth a few times when saving and refreshing.
    flight.areRanksPublished = areRanksPublished;
    await makeApiCall({
      authToken: auth.token,
      dispatch: dispatch,
      callId: areRanksPublished ? 'PUBLISH_PUBLISH_FLIGHT_RANKS' : 'PUBLISH_UN_PUBLISH_FLIGHT_RANKS',
      request: {
        url: `${apiBaseUrl}/flights/${flight.id}/${areRanksPublished ? 'publishRanks' : 'unPublishRanks'}`,
        httpMethod: 'PUT',
      },
      showSuccessMessage: false,
    });
    setRefreshData && setRefreshData(true);
    doRefresh();
  };

  const handleTastingPublishing = (): void => {
    if (tasting?.tasting?.areRanksPublished) {
      mutateUnPublishTastingRanks('');
    } else {
      mutatePublishTastingRanks('');
    }
  };

  const doRefresh = useCallback((event?: CustomEvent<RefresherEventDetail>): void => {
    refetchFlights()
      // eslint-disable-next-line no-console
      .catch(console.error);
    refetchWines()
      // eslint-disable-next-line no-console
      .catch(console.error);
    if (event) {
      event.detail.complete();
    }
  }, [refetchFlights, refetchWines]);

  const updateWineScore = useCallback((wine: Wine, isScoringComplete?: boolean): void => {
    if (isScoringComplete) {
      history.push({
        pathname: `/tastings/details/room/wineScore/${wine.id}`,
      });
    } else {
      doRefresh();
    }
  }, [doRefresh, history]);

  useEffect((): void => {
    if (winesAndFlightsRefresh) {
      doRefresh();
    }
  }, [doRefresh, winesAndFlightsRefresh]);

  // EFFECTS SETUP
  useIonViewWillEnter((): void => {
    doRefresh();
  });
  const infoCaptions = viewTastingResultInfoCaptions(tastingResultViewState, wineData, flightData, tasting);
  const { messageId } = infoCaptions;

  useEffect((): void => {
    if (updateTastingResultInfo) {
      updateTastingResultInfo(infoCaptions)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoCaptions, updateTastingResultInfo]);

  return (
    <>
      <IonRefresher
        onIonRefresh={doRefresh}
        slot="fixed"
      >
        <IonRefresherContent
          pullingIcon={chevronDownCircleOutline}
          pullingText={formatMessage({ id: 'general.refresher.pullingText' })}
          refreshingSpinner="circles"
          refreshingText={formatMessage({ id: 'general.refresher.refreshingText' })}
        />
      </IonRefresher>
      <Hideable hide={!messageId}>
        <Box
          $backgroundColor={'var(--ion-color-secondary-contrast)'}
          $border={'1px solid var(--ion-color-secondary)'}
          $borderRadius="8px"
          $color={'var(--ion-color-secondary)'}
          $margin={'15px 16px'}
          $padding={'8px 16px'}>
          <IonText>
            <FormattedMessage id={messageId} />
          </IonText>
        </Box>
      </Hideable>
      <Hideable hide={!infoCaptions.showWineList}>
        <WineListForTastingActionBar
          lines={wineListItemType !== WineListItemType.TastingRoom && wineData?.items ? 'none' : 'full'}
          onRankWinesClick={(): void => rankWinesClickHandler && rankWinesClickHandler(true)}
          onRequestImportWineListClick={(): void => onRequestImportWinesClickHandler && onRequestImportWinesClickHandler(true)}
          showEditWineButton={wineListItemType === WineListItemType.Default && canEditWineList()}
          showRankWinesButton={false}
          showRequestImportWineListButton={wineListItemType === WineListItemType.Default && canRequestWineListImport() && !canCreateWineList()}
          tastingId={tastingId}
          wineListItemType={wineListItemType}
        />
      </Hideable>
      <Hideable hide={!infoCaptions.showWineList}>
        <Loadable isLoading={flightsAreLoading || winesAreLoading || requestTastingWinesUploadLoading}>
          <Loaded>
            <WineListForTasting
              flights={flightData?.items || []}
              onRequestImportWineListClick={(): void => onRequestImportWinesClickHandler && onRequestImportWinesClickHandler(true)}
              publishClickHandler={publishClickHandler}
              publishFlightClickHandler={handleFlightPublishing}
              publishTastingClickHandler={handleTastingPublishing}
              scoreWineClickHandler={scoreWineClickHandler}
              showCreateWineButton={wineListItemType !== WineListItemType.TastingRoom && canCreateWineList()}
              showRequestImportWineListButton={wineListItemType === WineListItemType.Default && canRequestWineListImport()}
              tastingId={tastingId}
              tastingResultViewState={tastingResultViewState}
              wineClickHandler={scoreWineClickHandler}
              wineListItemType={wineListItemType}
              wines={wineData?.items || []}
            />
          </Loaded>
        </Loadable>
      </Hideable>
      <IonActionSheet
        isOpen={showRankWinesActionSheet}
        onDidDismiss={(): void => rankWinesClickHandler && rankWinesClickHandler(false)}
        cssClass='view-tasting-room-action-sheet'
        buttons={getRankWinesActionSheetButtons(flightData?.items || [])}
      >
      </IonActionSheet>
      <IonActionSheet
        isOpen={showImportWineListActionSheet}
        onDidDismiss={(): void => onRequestImportWinesClickHandler && onRequestImportWinesClickHandler(false)}
        cssClass='import-wine-list-action-sheet'
        buttons={getImportWineListActionSheetButtons()}
        header={formatMessage({ id: 'importWineListActionSheet.header' })}
        subHeader={formatMessage({
          id: allowPublicEventCreation ? 'importWineListActionSheet.subHeader.premiumUser'
          : 'importWineListActionSheet.subHeader.nonPremiumUser' })}
      >
      </IonActionSheet>
      <RankWinesModal tastingId={tastingId} callback={onRankWinesModalDismiss} />
    </>
  );
};

export default WineListForTastingContainer;
