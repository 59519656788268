import { yupResolver } from '@hookform/resolvers/yup';
import { IonContent, IonItem, IonList } from '@ionic/react';
import HookInput from 'components/baseElements/formControls/HookInput';
import Form from 'components/baseElements/formControls/StyledForm';
import { Box } from 'components/baseElements/grid';
import { IonLabel, IonRange } from 'components/ionicComponents';
import SliderStepLabel from 'components/wineScores/SliderStepLabel';
import { StepBaseProperties } from 'components/wineScores/types';
import { ScoreAWineHeader } from 'containers/wineScores/ScoreAWineContainer/ScoreAWineHeader';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { defaultWineColor, getWineColor, getWineColorIndex, WineColors } from 'store/wineScores';
import { handleFormDataReset } from 'utils/formValidation';
import StepBottomButtons from '../StepBottomButtons';
import StepHeader from '../StepHeader';
import StepMidHeaderButtons from '../StepMidHeaderButtons';

const ScoreAWineVisualStep: FunctionComponent<StepBaseProperties> = (
  props: StepBaseProperties,
) => {
  const {
    loadingStatus,
    modalId,
    onSaveAndClose,
    stepName,
    nextAction,
    previousAction,
    validationSchema,
    score,
    nextStepName,
    showNextStepButton,
    previousStepName,
    showPreviousStepButton,
    stepNumber,
    totalSteps
  } = props;

  const [colorIndex, setColorIndex] = useState(getWineColorIndex(score?.color));
  const form = useForm({
    defaultValues: {
      color: score.color || defaultWineColor,
      brightness: 50,
      clarity: 50,
      colorIndex: getWineColorIndex(score.color),
    },
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (score) {
      handleFormDataReset(score, form.reset);
    }
  }, [score, form.reset]);

  // Ensure the form populates with the initial value. For some reason, the form does not use the initial values
  useEffect(() => {
    colorSliderChangeAction({ detail: { value: colorIndex } });
    // eslint-disable-next-line
  }, []);

  const colorSliderChangeAction = (e: any): void => {
    const color = getWineColor(e?.detail?.value);
    setColorIndex(getWineColorIndex(color));
    form.setValue('color', color);
    const wineColorIndex = getWineColorIndex(color);
    const formColorIndex = form.getValues('colorIndex');
    if (!formColorIndex || wineColorIndex?.toString() !== formColorIndex?.toString()) {
      // Use setTimeout to prevent an infinite loop
      setTimeout(() => {
        form.setValue('colorIndex', wineColorIndex);
      });
    }
  }

  return (
    <>
      <ScoreAWineHeader
        modalId={modalId}
        onSaveAndClose={(): any => onSaveAndClose(form.getValues())}
        stepName={stepName}
      />
      <IonContent>
        <StepMidHeaderButtons
          loadingStatus={loadingStatus}
          nextClickedAction={(): any => nextAction(form.getValues())}
          previousClickedAction={(): any => previousAction(form.getValues())}
          nextStepName={nextStepName}
          showNextStepButton={showNextStepButton}
          previousStepName={previousStepName}
          showPreviousStepButton={showPreviousStepButton}
        />
        <StepHeader
          modalId={modalId}
          stepName="scoreWine.visualStep.title"
          stepNumber={stepNumber}
          totalSteps={totalSteps}
        />
        <Form>
          <IonList lines="none">
            <HookInput
              form={form}
              name="color"
              hidden
            />
            <IonItem>
              <IonLabel>
                <FormattedMessage
                  id="scoreWine.visualStep.labels.color"
                />
              </IonLabel>
            </IonItem>
            <div
              style={{
                backgroundColor: WineColors[colorIndex],
                height: '56.25vw',
              }}
            />
            <HookInput
              form={form}
              name="colorIndex"
            >
              <IonRange
                barBackground={`linear-gradient(to right, ${WineColors})`}
                barBackgroundActive="transparent"
                min={0}
                max={WineColors.length - 1}
                onIonChange={colorSliderChangeAction}
                step={1}
              >
                <IonLabel slot="start">
                  <Box
                    backgroundColor={WineColors[0]}
                    borderRadius={7}
                    height={40 * (9 / 16)}
                    width={40}
                  />
                </IonLabel>
                <IonLabel slot="end">
                  <Box
                    backgroundColor={WineColors[WineColors.length - 1]}
                    borderRadius={7}
                    height={40 * (9 / 16)}
                    width={40}
                  />
                </IonLabel>
              </IonRange>
            </HookInput>
            <IonItem>
              <SliderStepLabel field="brightness" stepName="visualStep" value={form.watch('brightness')} />
            </IonItem>
            <HookInput
              form={form}
              name="brightness"
            >
              <IonRange
                min={0}
                max={100}
                step={25}
                snaps={true}
              >
                <IonLabel
                  slot="start"
                >
                  <FormattedMessage id="scoreWine.visualStep.labels.brightnessStart" />
                </IonLabel>
                <IonLabel
                  slot="end"
                >
                  <FormattedMessage id="scoreWine.visualStep.labels.brightnessEnd" />
                </IonLabel>
              </IonRange>
            </HookInput>
            <IonItem>
              <SliderStepLabel field="clarity" stepName="visualStep" value={form.watch('clarity')} />
            </IonItem>
            <HookInput
              form={form}
              name="clarity"
            >
              <IonRange min={0} max={100} step={25} snaps={true}>
                <IonLabel slot="start"><FormattedMessage id="scoreWine.visualStep.labels.clarityStart" /></IonLabel>
                <IonLabel slot="end"><FormattedMessage id="scoreWine.visualStep.labels.clarityEnd" /></IonLabel>
              </IonRange>
            </HookInput>
          </IonList>
        </Form>
      </IonContent>
      <StepBottomButtons
        loadingStatus={loadingStatus}
        nextClickedAction={(): any => nextAction(form.getValues())}
        previousClickedAction={(): any => previousAction(form.getValues())}
        nextStepName={nextStepName}
        onSaveAndClose={(): any => onSaveAndClose(form.getValues())}
        showNextStepButton={false}
        previousStepName={previousStepName}
        showPreviousStepButton={false}
      />
    </>
  );
}

export default ScoreAWineVisualStep;
