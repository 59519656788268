import { ApiCall, CustomHookProps, useBasicQuery } from 'napa-react-core';
import { QueryObserverResult } from 'react-query';
import { WineRank } from '../../store/wineRanks';

export default function useTastingWineRankings(
  tastingId: string | undefined,
  userId: string, props?: CustomHookProps<WineRank[]>,
): QueryObserverResult<WineRank[], ApiCall<WineRank[]>> {
  return useBasicQuery<WineRank[]>({
    apiRoute: `tastings/${tastingId}/rankings/${userId}`,
    httpMethod: 'GET',
    isFormData: false,
    body: undefined,
    queryId: 'useTastingWineRankings',
    params: props
  });
}
