import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgTeaIcon(props) {
  return (
    <svg width={18} height={12} fill="none" {...props}>
      <path
        d="M15.649 1.946h-.514V.486A.484.484 0 0014.65 0H2.432a.484.484 0 00-.486.486v4.055c0 1.864.946 3.54 2.378 4.54H.486A.484.484 0 000 9.568a2.14 2.14 0 002.135 2.135h12.81a2.14 2.14 0 002.136-2.135.484.484 0 00-.486-.487h-3.838c.54-.378 1-.838 1.378-1.378h1.514A2.359 2.359 0 0018 5.324v-1a2.359 2.359 0 00-2.351-2.378zm.324 8.108c-.19.378-.568.649-1.027.649H2.108c-.46 0-.865-.27-1.027-.649h14.892zm-6.351-1H7.459a4.55 4.55 0 01-4.54-4.54V.973H5.27v1.351h-.784a.658.658 0 00-.648.649v2.108c0 .351.297.649.648.649H6.73a.658.658 0 00.648-.649V2.973a.658.658 0 00-.648-.649h-.784V.973h8.19v3.568a4.522 4.522 0 01-4.514 4.513zM5.568 2.973c.027 0 .027 0 0 0H6.702v2.108H4.487V2.973h1.082zm11.027 2.351a.958.958 0 01-.946.946h-.811c.189-.54.27-1.135.27-1.73V3.352h.514c.513 0 .946.433.946.946l.027 1.027z"
        fill="var(--ion-color-dark)"
      />
    </svg>
  );
}

export default SvgTeaIcon;
