import { WineProfile } from '.';
import { CheckboxGroup, CheckboxItem } from 'components/baseElements/formControls/CheckboxList';
import {
  BlackBerryIcon, BlackCherryIcon, BlueberryIcon, GrapeIcon, GrapefruitIcon, LimeIcon, OrangeIcon, FigIcon, PlumIcon,
  RaisinIcon, CherryIcon, CranberryIcon, RaspberryIcon, StrawberryIcon, AppleIcon, ApricotIcon,
  PeachIcon, PearIcon, BubblegumIcon, LycheeIcon, MangoIcon, MelonIcon, PineappleIcon, ChalkIcon,
  MetalIcon, MushroomIcon, PetrolIcon, RocksIcon, SoilIcon, StoneIcon, TruffleIcon, ButterIcon,
  CreamIcon, NutIcon, YeastIcon, JasmineIcon, LemonIcon, LilacIcon, PotpurriIcon, RoseIcon,
  BellPepperIcon, CederIcon, GrassIcon, MentholIcon, OreganoIcon, TeaIcon, TobaccoIcon, GingerIcon,
  HoneyIcon, WheyIcon, BakingsodaIcon, CoconutIcon, DillIcon, SmokeIcon, ToastIcon, CinnamonIcon,
  CloveIcon, LicoriceIcon, PepperIcon, SaffronIcon, BandaidIcon, CardboardIcon, DustIcon, LeatherIcon,
  MeatIcon, OnionIcon, RubberIcon, VinegarIcon, WetDogIcon, VanillaIcon, DriedLeavesIcon, GravelIcon, BreadIcon, ClayIcon, WhiteFlowerIcon,
  CheeseIcon, HazelnutIcon, OysterIcon, SaltIcon, AlmondIcon,
} from 'components/baseElements/icons';

const strWineColors = '#fce3e0#f4c9cc#f7baad#f2adab#f49090#e29d7f#f49a89#f28a75#e57f7f#f46851#dd6952#e84d46#d84435#d72543#c62c4a#b51f40#a01d4f#8c2245#801747#6d122d#701013#560a12#4c071c#42091a#3d040f#42091a#3f0618#3d0606#300303#23000b#160008#0a0000#261004#3f1703#603111#7c390b#894619#994913#ba5c1c#ad6029#bb6a2f#d96929#ee7f34#ce8d2e#e29930#e88d2a#ef953c#dd9852#e2a561#dbab5e#e2bd60#eac66c#ead373#edd87c#e8d68b#e2d996#efe284#f6de8a#f6ed80#efe79a#f6f3b7#e2d9b5#eae6ce#f3f2dd';

const arrWineColors = strWineColors ? strWineColors.split('#').map(s => `#${s}`) : [];
arrWineColors.shift();
export const WineColors = arrWineColors;

export const defaultWineColorIndex = Math.floor(WineColors.length / 2);
export const defaultWineColor = WineColors[defaultWineColorIndex];
export const defaultWineRating = 75;
export const maxWineRating = 75;
export const minWineRating = 75;

export const WineProfiles: Array<WineProfile> = [
  {
    name: 'blackCherry',
    primaryGroup: 'fruit',
    secondaryGroup: 'blackBlueRedFruit',
    icon: BlackCherryIcon
  },
  {
    name: 'blackberry',
    primaryGroup: 'fruit',
    secondaryGroup: 'blackBlueRedFruit',
    icon: BlackBerryIcon
  },
  {
    name: 'blueberry',
    primaryGroup: 'fruit',
    secondaryGroup: 'blackBlueRedFruit',
    icon: BlueberryIcon
  },
  {
    name: 'cranberry',
    primaryGroup: 'fruit',
    secondaryGroup: 'blackBlueRedFruit',
    icon: CranberryIcon
  },
  {
    name: 'grape',
    primaryGroup: 'fruit',
    secondaryGroup: 'blackBlueRedFruit',
    icon: GrapeIcon
  },
  {
    name: 'plum',
    primaryGroup: 'fruit',
    secondaryGroup: 'blackBlueRedFruit',
    icon: PlumIcon
  },
  {
    name: 'raspberry',
    primaryGroup: 'fruit',
    secondaryGroup: 'blackBlueRedFruit',
    icon: RaspberryIcon
  },
  {
    name: 'redCherry',
    primaryGroup: 'fruit',
    secondaryGroup: 'blackBlueRedFruit',
    icon: CherryIcon
  },
  {
    name: 'strawberry',
    primaryGroup: 'fruit',
    secondaryGroup: 'blackBlueRedFruit',
    icon: StrawberryIcon
  },
  {
    name: 'apple',
    primaryGroup: 'fruit',
    secondaryGroup: 'orchardFruit',
    icon: AppleIcon
  },
  {
    name: 'apricot',
    primaryGroup: 'fruit',
    secondaryGroup: 'orchardFruit',
    icon: ApricotIcon
  },
  {
    name: 'peach',
    primaryGroup: 'fruit',
    secondaryGroup: 'orchardFruit',
    icon: PeachIcon
  },
  {
    name: 'pear',
    primaryGroup: 'fruit',
    secondaryGroup: 'orchardFruit',
    icon: PearIcon
  },
  {
    name: 'grapefruit',
    primaryGroup: 'fruit',
    secondaryGroup: 'citrusFruit',
    icon: GrapefruitIcon
  },
  {
    name: 'lemon',
    primaryGroup: 'fruit',
    secondaryGroup: 'citrusFruit',
    icon: LemonIcon
  },
  {
    name: 'lime',
    primaryGroup: 'fruit',
    secondaryGroup: 'citrusFruit',
    icon: LimeIcon
  },
  {
    name: 'orange',
    primaryGroup: 'fruit',
    secondaryGroup: 'citrusFruit',
    icon: OrangeIcon
  },
  {
    name: 'bubblegum',
    primaryGroup: 'fruit',
    secondaryGroup: 'tropicalFruit',
    icon: BubblegumIcon
  },
  {
    name: 'lychee',
    primaryGroup: 'fruit',
    secondaryGroup: 'tropicalFruit',
    icon: LycheeIcon
  },
  {
    name: 'mango',
    primaryGroup: 'fruit',
    secondaryGroup: 'tropicalFruit',
    icon: MangoIcon
  },
  {
    name: 'melon',
    primaryGroup: 'fruit',
    secondaryGroup: 'tropicalFruit',
    icon: MelonIcon
  },
  {
    name: 'pineapple',
    primaryGroup: 'fruit',
    secondaryGroup: 'tropicalFruit',
    icon: PineappleIcon
  },
  {
    name: 'fig',
    primaryGroup: 'fruit',
    secondaryGroup: 'dryFruit',
    icon: FigIcon
  },
  {
    name: 'raisin',
    primaryGroup: 'fruit',
    secondaryGroup: 'dryFruit',
    icon: RaisinIcon
  },
  {
    name: 'chalk',
    primaryGroup: 'nonFruit',
    secondaryGroup: 'earth',
    icon: ChalkIcon
  },
  {
    name: 'clay',
    primaryGroup: 'nonFruit',
    secondaryGroup: 'earth',
    icon: ClayIcon
  },
  {
    name: 'driedLeaves',
    primaryGroup: 'nonFruit',
    secondaryGroup: 'earth',
    icon: DriedLeavesIcon
  },
  {
    name: 'gravel',
    primaryGroup: 'nonFruit',
    secondaryGroup: 'earth',
    icon: GravelIcon
  },
  {
    name: 'iron',
    primaryGroup: 'nonFruit',
    secondaryGroup: 'earth',
    icon: MetalIcon
  },
  {
    name: 'mushroom',
    primaryGroup: 'nonFruit',
    secondaryGroup: 'earth',
    icon: MushroomIcon
  },
  {
    name: 'petrol',
    primaryGroup: 'nonFruit',
    secondaryGroup: 'earth',
    icon: PetrolIcon
  },
  {
    name: 'rocks',
    primaryGroup: 'nonFruit',
    secondaryGroup: 'earth',
    icon: RocksIcon
  },
  {
    name: 'soil',
    primaryGroup: 'nonFruit',
    secondaryGroup: 'earth',
    icon: SoilIcon
  },
  {
    name: 'stone',
    primaryGroup: 'nonFruit',
    secondaryGroup: 'earth',
    icon: StoneIcon
  },
  {
    name: 'truffle',
    primaryGroup: 'nonFruit',
    secondaryGroup: 'earth',
    icon: TruffleIcon
  },
  {
    name: 'cinnamon',
    primaryGroup: 'nonFruit',
    secondaryGroup: 'spice',
    icon: CinnamonIcon
  },
  {
    name: 'clove',
    primaryGroup: 'nonFruit',
    secondaryGroup: 'spice',
    icon: CloveIcon
  },
  {
    name: 'licorice',
    primaryGroup: 'nonFruit',
    secondaryGroup: 'spice',
    icon: LicoriceIcon
  },
  {
    name: 'pepper',
    primaryGroup: 'nonFruit',
    secondaryGroup: 'spice',
    icon: PepperIcon
  },
  {
    name: 'cheese',
    primaryGroup: 'nonFruit',
    secondaryGroup: 'ferment',
    icon: CheeseIcon
  },
  {
    name: 'bread',
    primaryGroup: 'nonFruit',
    secondaryGroup: 'ferment',
    icon: BreadIcon
  },
  {
    name: 'butter',
    primaryGroup: 'nonFruit',
    secondaryGroup: 'ferment',
    icon: ButterIcon
  },
  {
    name: 'cream',
    primaryGroup: 'nonFruit',
    secondaryGroup: 'ferment',
    icon: CreamIcon
  },
  {
    name: 'nut',
    primaryGroup: 'nonFruit',
    secondaryGroup: 'ferment',
    icon: NutIcon
  },
  {
    name: 'yeast',
    primaryGroup: 'nonFruit',
    secondaryGroup: 'ferment',
    icon: YeastIcon
  },
  {
    name: 'jasmine',
    primaryGroup: 'nonFruit',
    secondaryGroup: 'floral',
    icon: JasmineIcon
  },
  {
    name: 'lilac',
    primaryGroup: 'nonFruit',
    secondaryGroup: 'floral',
    icon: LilacIcon
  },
  {
    name: 'potpurri',
    primaryGroup: 'nonFruit',
    secondaryGroup: 'floral',
    icon: PotpurriIcon
  },
  {
    name: 'rose',
    primaryGroup: 'nonFruit',
    secondaryGroup: 'floral',
    icon: RoseIcon
  },
  {
    name: 'whiteFlowers',
    primaryGroup: 'nonFruit',
    secondaryGroup: 'floral',
    icon: WhiteFlowerIcon,
  },
  {
    name: 'bellPepper',
    primaryGroup: 'nonFruit',
    secondaryGroup: 'herbal',
    icon: BellPepperIcon
  },
  {
    name: 'ceder',
    primaryGroup: 'nonFruit',
    secondaryGroup: 'herbal',
    icon: CederIcon
  },
  {
    name: 'grass',
    primaryGroup: 'nonFruit',
    secondaryGroup: 'herbal',
    icon: GrassIcon
  },
  {
    name: 'menthol',
    primaryGroup: 'nonFruit',
    secondaryGroup: 'herbal',
    icon: MentholIcon
  },
  {
    name: 'oregano',
    primaryGroup: 'nonFruit',
    secondaryGroup: 'herbal',
    icon: OreganoIcon
  },
  {
    name: 'tea',
    primaryGroup: 'nonFruit',
    secondaryGroup: 'herbal',
    icon: TeaIcon
  },
  {
    name: 'tobacco',
    primaryGroup: 'nonFruit',
    secondaryGroup: 'herbal',
    icon: TobaccoIcon
  },
  {
    name: 'ginger',
    primaryGroup: 'nonFruit',
    secondaryGroup: 'noble',
    icon: GingerIcon
  },
  {
    name: 'honey',
    primaryGroup: 'nonFruit',
    secondaryGroup: 'noble',
    icon: HoneyIcon
  },
  {
    name: 'saffron',
    primaryGroup: 'nonFruit',
    secondaryGroup: 'noble',
    icon: SaffronIcon
  },
  {
    name: 'whey',
    primaryGroup: 'nonFruit',
    secondaryGroup: 'noble',
    icon: WheyIcon
  },
  {
    name: 'coconut',
    primaryGroup: 'nonFruit',
    secondaryGroup: 'oak',
    icon: CoconutIcon
  },
  {
    name: 'dill',
    primaryGroup: 'nonFruit',
    secondaryGroup: 'oak',
    icon: DillIcon
  },
  {
    name: 'smoke',
    primaryGroup: 'nonFruit',
    secondaryGroup: 'oak',
    icon: SmokeIcon
  },
  {
    name: 'toast',
    primaryGroup: 'nonFruit',
    secondaryGroup: 'oak',
    icon: ToastIcon
  },
  {
    name: 'vanilla',
    primaryGroup: 'nonFruit',
    secondaryGroup: 'oak',
    icon: VanillaIcon
  },
  {
    name: 'almond',
    primaryGroup: 'nonFruit',
    secondaryGroup: 'other',
    icon: AlmondIcon
  },
  {
    name: 'bakingSoda',
    primaryGroup: 'nonFruit',
    secondaryGroup: 'other',
    icon: BakingsodaIcon
  },
  {
    name: 'bandaid',
    primaryGroup: 'nonFruit',
    secondaryGroup: 'other',
    icon: BandaidIcon
  },
  {
    name: 'cardboard',
    primaryGroup: 'nonFruit',
    secondaryGroup: 'other',
    icon: CardboardIcon
  },
  {
    name: 'dust',
    primaryGroup: 'nonFruit',
    secondaryGroup: 'other',
    icon: DustIcon
  },
  {
    name: 'hazelnut',
    primaryGroup: 'nonFruit',
    secondaryGroup: 'other',
    icon: HazelnutIcon
  },
  {
    name: 'leather',
    primaryGroup: 'nonFruit',
    secondaryGroup: 'other',
    icon: LeatherIcon
  },
  {
    name: 'meat',
    primaryGroup: 'nonFruit',
    secondaryGroup: 'other',
    icon: MeatIcon
  },
  {
    name: 'onion',
    primaryGroup: 'nonFruit',
    secondaryGroup: 'other',
    icon: OnionIcon
  },
  {
    name: 'oyster',
    primaryGroup: 'nonFruit',
    secondaryGroup: 'other',
    icon: OysterIcon
  },
  {
    name: 'rubber',
    primaryGroup: 'nonFruit',
    secondaryGroup: 'other',
    icon: RubberIcon
  },
  {
    name: 'saline',
    primaryGroup: 'nonFruit',
    secondaryGroup: 'other',
    icon: SaltIcon
  },
  {
    name: 'vinegar',
    primaryGroup: 'nonFruit',
    secondaryGroup: 'other',
    icon: VinegarIcon
  },
  {
    name: 'wetDog',
    primaryGroup: 'nonFruit',
    secondaryGroup: 'other',
    icon: WetDogIcon
  }
];

export function getWineProfilesAsCheckboxGroups(selected?: string): Array<CheckboxGroup> {
  const checkboxGroups: Array<CheckboxGroup> = [];
  const distinctPrimaryGroupNames: Array<string> = [];

  WineProfiles.forEach((profile: WineProfile) => {
    if (!profile.primaryGroup || distinctPrimaryGroupNames.findIndex(p => p === profile.primaryGroup) > -1) {
      return;
    }
    distinctPrimaryGroupNames.push(profile.primaryGroup);
  })


  distinctPrimaryGroupNames.forEach((pgName: string) => {
    const checkboxGroup: CheckboxGroup = { name: pgName, groups: [], checkboxes: [] };
    const primaryGroupItems = WineProfiles.filter((profile: WineProfile) => profile.primaryGroup === pgName);
    const distinctSecondaryGroupNames: Array<string> = [];

    primaryGroupItems.forEach((profile: WineProfile) => {
      if (!profile.secondaryGroup || distinctSecondaryGroupNames.findIndex(p => p === profile.secondaryGroup) > -1) {
        return;
      }
      distinctSecondaryGroupNames.push(profile.secondaryGroup);
    });

    distinctSecondaryGroupNames.forEach((sgName: string) => {
      const secondaryGroup: CheckboxGroup = { name: sgName };
      const itemsInGroup = WineProfiles.filter((profile: WineProfile) => profile.primaryGroup === pgName && profile.secondaryGroup === sgName);
      itemsInGroup.forEach((wp: WineProfile) => {
        const cbItem: CheckboxItem = { label: wp.name, value: wp.name, icon: wp.icon, checked: !!selected && selected.indexOf(`|${wp.name}|`) > -1 }
        if (!secondaryGroup.checkboxes) {
          secondaryGroup.checkboxes = [];
        }
        secondaryGroup.checkboxes.push(cbItem);
      });
      if (!checkboxGroup.groups) {
        checkboxGroup.groups = [];
      }
      checkboxGroup.groups.push(secondaryGroup);
    });
    checkboxGroups.push(checkboxGroup);
  });

  return checkboxGroups;
}

export function getWineColor(index: number | null | undefined): string {
  if (index === 0) {
    return WineColors[0];
  }
  if (!index || isNaN(index) || index < 0 || index > WineColors.length - 1) {
    return defaultWineColor;
  }
  return WineColors[index];
}

export function getWineColorIndex(color: string | null | undefined): number {
  if (!color) {
    return defaultWineColorIndex;
  }
  const n = WineColors.findIndex(c => c === color);
  return n > -1 ? n : defaultWineColorIndex;
}
