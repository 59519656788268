import WineForm from 'components/wine/WineForm';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { AppSettingsState } from 'store/appSettings';
import { guiStateUpdateToast } from 'store/gui/guiStateUpdateToast';
import { Flight, Wine } from 'store/wines';
import { GetSortedFlights, toRequestInsertWineTastingDto } from 'store/wines/utils';
import { clearApiCallStatus, makeApiCall } from 'utils/api';
import { LoadingStatus } from 'utils/formValidation';
import { schema } from './validation';

interface AddWineToTastingContainerProps {
  loadingStatus?: LoadingStatus;
  setLoadingStatus?: (status: LoadingStatus) => void;
  successHandler?: (tastingId: string) => any;
  tastingId: string;
  flights?: Array<Flight>;
  wines?: Array<Wine>;
}

const AddWineToTastingContainer: React.FC<AddWineToTastingContainerProps> = (props: AddWineToTastingContainerProps) => {
  const {
    loadingStatus,
    flights,
    setLoadingStatus,
    successHandler,
    tastingId,
    wines,
  } = props;
  // CONSTANT DECLARATIONs
  const CREATE_WINE_TASTING_KEY = 'addWineTasting';
  const dispatch = useDispatch();

  // LOCAL (CONTAINER) STATE SETUP
  const [wineTasting, setWineTasting] = useState(undefined as Wine | undefined);
  const [uploadedFileId, setUploadedFileId] = useState<string>('');

  // GLOBAL (REDUX) STATE SETUP
  const auth = useSelector((state: RootState) => state.authorization);
  const { apiBaseUrl, cameraApiSettings } = useSelector((state: RootState): AppSettingsState => state.appSettings);
  const addWineTastingApiCall = useSelector((state: RootState) =>
    state.api.callStatuses.find(e => e.id === CREATE_WINE_TASTING_KEY),
  );

  // LOCAL FUNCTIONS
  const wineTastingSubmitAction = (data: any): void => {
    // Coerce null, undefined and empty string to the No Flight case
    const flightWines = wines?.length
      ? wines.filter((wine: Wine): boolean => (!wine.flightId && !data.flightId) || (wine.flightId === data.flightId))
      : [];
    setWineTasting({ ...data, sequence: data.sequence || flightWines?.length || 0 });
  };

  const handleWineTastingSubmit = useCallback(async (): Promise<void> => {
    if (!wineTasting || loadingStatus === LoadingStatus.Loading) {
      return;
    }
    if (setLoadingStatus) {
      setLoadingStatus(LoadingStatus.Loading);
    }
    try {
      wineTasting.tastingId = tastingId
      await makeApiCall({
        authToken: auth.token,
        dispatch: dispatch,
        callId: CREATE_WINE_TASTING_KEY,
        request: {
          url: `${apiBaseUrl}/wines`,
          httpMethod: 'POST',
          body: toRequestInsertWineTastingDto({ ...wineTasting, imageFileId: uploadedFileId }),
        },
      });
      if (successHandler) {
        successHandler(tastingId);
      }
    }
    catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    if (setLoadingStatus) {
      setLoadingStatus(LoadingStatus.Complete);
    }
  // }, [wineTasting]);
  }, [apiBaseUrl, auth.token, dispatch, loadingStatus, setLoadingStatus, successHandler, tastingId, uploadedFileId, wineTasting]);

  const onUploadError = (): void => {
    dispatch(
      guiStateUpdateToast({
        message: 'api.defaults.failureMessage',
      })
    );
  };
  const onUploadSuccess = (ids: Array<string>): void => {
    setUploadedFileId(ids[0]);
  }
  // EFFECTS SETUP
  useEffect(() => {
    clearApiCallStatus(dispatch, CREATE_WINE_TASTING_KEY);
    handleWineTastingSubmit()
      // eslint-disable-next-line no-console
      .catch(console.error);
  }, [dispatch, handleWineTastingSubmit]);
  return (
    <WineForm
      apiBaseUrl={apiBaseUrl}
      cameraApiSettings={cameraApiSettings}
      onUploadError={onUploadError}
      onUploadSuccess={onUploadSuccess}
      submitAction={wineTastingSubmitAction}
      serverValidation={addWineTastingApiCall?.validationErrors}
      uploadUrl={`${apiBaseUrl}/files/upload`}
      uploadedFileId={uploadedFileId}
      validationSchema={schema}
      flights={GetSortedFlights(flights)}
    />
  )
}

export default AddWineToTastingContainer;
