import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonModal,
  IonToolbar,
} from '@ionic/react';
import { close } from 'ionicons/icons';
import { HeaderSubmitButton } from 'components/baseElements/header';
import { IonButton, IonTitle } from 'components/ionicComponents';
import { PrivatePropertyContainer } from 'containers/authorization/PrivatePropertyContainer';
import EditTastingContainer from 'containers/tastings/EditTastingContainer';
import { RootState } from 'store';
import { hideModal } from 'utils/modals';
import { LoadingStatus } from 'utils/formValidation';

export const EditTastingModalId = 'editTasting';

export default function EditTastingModal(): JSX.Element {
  const dispatch = useDispatch();
  const modalProps = useSelector((state: RootState) =>
    state.gui.modals.find(e => e.id === EditTastingModalId),
  );
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.None);
  return (
    <IonModal
      isOpen={modalProps?.show || false}
      onDidDismiss={(): void => hideModal(dispatch, EditTastingModalId, {})}
    >
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={(): void => hideModal(dispatch, EditTastingModalId, {})}>
              <IonIcon icon={close} slot="icon-only" />
            </IonButton>
          </IonButtons>
          <IonTitle>
            <FormattedMessage id="editTastingModal.header.title" />
          </IonTitle>
          <IonButtons slot="end">
            <HeaderSubmitButton
              data-cy="tastingSubmitButton"
              form="tastingForm"
              isLoading={loadingStatus === LoadingStatus.Loading}
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <PrivatePropertyContainer modalCustomMessageComponent={(<div>This is a custom message in the register popup for the edit tasting page</div>)}>
          <PrivatePropertyContainer.Unauthorized>
            This is what the edit tasting modal looks like when you are not logged in.
          </PrivatePropertyContainer.Unauthorized>
          <PrivatePropertyContainer.Authorized>
            <EditTastingContainer
              loadingStatus={loadingStatus}
              setLoadingStatus={setLoadingStatus}
              tastingId={modalProps?.params}
              successHandler={(): void => {
                hideModal(dispatch, EditTastingModalId, { params: { editedTasting: true }});
                setLoadingStatus(LoadingStatus.Complete);
              }}
            />
          </PrivatePropertyContainer.Authorized>
        </PrivatePropertyContainer>
      </IonContent>
    </IonModal>
  );
}
