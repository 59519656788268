import React, { useContext, useEffect } from 'react';
import LoginForm from 'components/authorization/LoginForm';
import { Flex } from 'components/baseElements/grid';
import jwtDecode from 'jwt-decode';
import { AuthorizationStore } from 'napa-react-core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'store';
import { TokenRequest, TokenResponse } from 'store/authorization';
import { authorizationSetToken } from 'store/authorization/actions/authorizationSetToken';
import { clearApiCallStatus } from 'utils/api';
import usePostUserToken from '../../../hooks/users/usePostUserToken';
import { schema } from './validation';

interface LoginContainerProps {
  referrer?: string;
  setModeToForgotPassword?: any;
  successHandler?: () => any;
}

export default function LoginContainer(props: LoginContainerProps): JSX.Element {
  // CONSTANT DECLARATION
  const LOGIN_TOKEN_KEY = 'loginToken';
  const dispatch = useDispatch();
  const history = useHistory();
  const authData = useContext(AuthorizationStore);
  const { referrer, successHandler, setModeToForgotPassword } = props;
  // LOCAL (CONTAINER) STATE SETUP
  const {
    error: postUserTokenError,
    isLoading: postUserTokenIsLoading,
    mutate: mutateUserToken,
  } = usePostUserToken({
    errorHandler: (error: any) => {
      // eslint-disable-next-line no-console
      console.error(error);
      // TODO: Go to login screen
    },
    successHandler: (tokenData: TokenResponse) => {
      const now = new Date();
      const expiration = now.setMinutes(
        now.getMinutes() +
        appSettings.securitySettings.sessionExpirationInMinutes,
      );
      localStorage.setItem('authToken', JSON.stringify({
        token: tokenData.token,
        expires: appSettings.securitySettings.sessionExpirationInMinutes > 0 ? new Date(expiration) : undefined,
      }));
      dispatch(
        authorizationSetToken({
          token: tokenData.token,
          tokenData: jwtDecode(tokenData.token),
        }),
      );
      // New boilerplate way to set auth state
      authData.setState({
        token: tokenData.token,
        tokenData: jwtDecode(tokenData.token),
      });
      if (successHandler) {
        successHandler();
      }
      if (referrer) {
        history.push(referrer);
      }
    },
  });
  // GLOBAL (REDUX) STATE SETUP
  const appSettings = useSelector((state: RootState) => state.appSettings);

  // CALLBACK FUNCTIONS
  const loginSubmitAction = (request: TokenRequest): void => {
    mutateUserToken(request);
  };

  // EFFECTS SETUP
  useEffect(() => {
    clearApiCallStatus(dispatch, LOGIN_TOKEN_KEY);
  }, [dispatch]);

  return (
    <Flex
      flexDirection="column"
      height="100%"
      justifyContent="space-between"
    >
      <LoginForm
        isLoading={postUserTokenIsLoading}
        serverValidation={postUserTokenError?.validationErrors}
        setModeToForgotPassword={setModeToForgotPassword}
        submitAction={loginSubmitAction}
        validationSchema={schema}
      />
    </Flex>
  );
}
