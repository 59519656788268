import { App, URLOpenListenerEvent } from '@capacitor/app';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

const AppUrlListener: React.FC<any> = () => {
  const history = useHistory();

  useEffect(() => {
    App.addListener('appUrlOpen', (data: URLOpenListenerEvent) => {
      // Remove the domain from the link to route in React
      const slug = data.url.split('vinvite.co')[1];

      if (slug) {
        // eslint-disable-next-line no-console
        console.log(`[AppUrlListener] Detected appUrlOpen route: ${slug}`);
        setTimeout(() => {
          // eslint-disable-next-line no-console
          console.log(`[AppUrlListener] Push to route: ${slug}`);
          // Push to the route now, updating the share URL
          history.push(slug.replaceAll('api/mvc/tastings', 'tastings/details'));
        }, 1000); // Helps cold start
      }
    });
  }, [history]);

  return null;
};

export default AppUrlListener;
