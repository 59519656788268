import * as React from 'react';
import { IonContent, IonPage } from '@ionic/react';
import ChangePasswordContainer from 'containers/users/ChangePasswordContainer';
import { BackHeader } from 'components/baseElements/header';
import { FormattedMessage } from 'react-intl';

export default function ChangePasswordPage(): JSX.Element {
  return (
    <IonPage>
      <BackHeader backHref={'/userProfile'}>
        <FormattedMessage id="userRegistrationForm.title.caption" />
      </BackHeader>
      <IonContent>
        <ChangePasswordContainer />
      </IonContent>
    </IonPage>
  );
}
