import { Device, DeviceInfo } from '@capacitor/device';
import { getPlatforms } from '@ionic/react';
import { Box, Flex } from 'components/baseElements/grid';
import { IonButton } from 'components/ionicComponents';
import { detect } from 'detect-browser';
import { PrivacyModalId } from 'modals/PrivacyModal';
import { Heading, Hideable, Paragraph, showModal } from 'napa-react-core';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import packageJson from '../../../package.json';

export default function VinviteVersion(): JSX.Element {

  const [showDebugInfo, setShowDebugInfo] = useState(0);
  const [deviceInfo, setDeviceInfo] = useState<DeviceInfo>();
  const getDeviceInfo = async (): Promise<void> => {
    setDeviceInfo(await Device.getInfo());
  };
  const apiBaseUrl = useSelector((state: RootState): string => state.appSettings.apiBaseUrl);
  const browser = detect();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!deviceInfo) {
      getDeviceInfo();
    }
  // eslint-disable-next-line
  }, []);
return (
  <>
    <Flex
      alignItems="center"
      flexDirection="column"
      fontSize="small"
      justifyContent="center"
      my={3}
      width={1}
    >
      <Box m={1} onClick={(): void => setShowDebugInfo(showDebugInfo + 1)}>
        VinVite v{packageJson.version}
      </Box>
      <Box mx={1} mb={1} onClick={(): void => setShowDebugInfo(showDebugInfo + 1)}>
        <FormattedMessage id="userProfile.text.versionDate"/>
        {packageJson.versionDate}
      </Box>
      <IonButton
        fill="clear"
        onClick={(): void => showModal(dispatch, PrivacyModalId, { params: {} })}
        size="small"
      >
        Privacy Policy
      </IonButton>
    </Flex>
    <Hideable hide={showDebugInfo < 7}>
      <Flex flexDirection="column" px={3} py={1} width={1}>
        <Heading headingSize="xs">Device Info</Heading>
        <Paragraph>
          Current platforms the app is running on: {getPlatforms().join(', ')}
        </Paragraph>
        {deviceInfo && Object.keys(deviceInfo).map((info, key: any) =>
          <Flex key={key} width={1}>
            <Flex
              flex={1}
              justifyContent="flex-end"
              p={1}
            >
              {info}
            </Flex>
            <Flex
              flex={2}
              p={1}
            >
              {deviceInfo[info]}
            </Flex>
          </Flex>
        )}
        <Paragraph>
          {browser?.name} {browser?.version} {browser?.os}
        </Paragraph>
        <Paragraph>
          API base Url: {apiBaseUrl}
        </Paragraph>
      </Flex>
    </Hideable>
  </>
)}
