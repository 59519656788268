import { TastingAttendeesState } from './types';
import { createSubReducer, Reducer } from 'utils/store';

const initialState = {};

const reducers: Array<Reducer<TastingAttendeesState>> = [];

export const usersReducer = createSubReducer<TastingAttendeesState>(
  initialState,
  reducers,
);
