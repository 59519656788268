import { IonText as IonTextBase } from '@ionic/react';
import styled from 'styled-components';
import { layout, space, system, typography } from 'styled-system';
import { ellipsis, transient } from 'utils/styledHelpers';

const IonText = styled(IonTextBase)`
  ${ transient(layout) }
  ${ transient(space) }
  ${ transient(system({ whiteSpace: true })) }
  ${ transient(system({ wordWrap: true })) }
  ${ transient(typography) }
  ${ ellipsis }
  .highlight {
    background: transparent;
    color: var(--ion-color-primary);
  }
`;

const IonHighlightBoldText = styled(IonText)`
  .highlight {
    color: var(--ion-color-dark);
    font-weight: bold;
  }
`;

export { IonHighlightBoldText, IonText };
