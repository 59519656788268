import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgBlueberryIcon(props) {
  return (
    <svg width={17} height={18} fill="none" {...props}>
      <g clipPath="url(#clip0)" fill="var(--ion-color-dark)">
        <path d="M1.494 7.527a4.367 4.367 0 010-6.169c1.701-1.7 4.469-1.7 6.17 0 1.7 1.701 1.7 4.468 0 6.17a4.367 4.367 0 01-6.17 0zm5.81-5.808a3.857 3.857 0 00-5.45 0 3.857 3.857 0 000 5.448 3.857 3.857 0 005.449 0 3.857 3.857 0 000-5.448z"/>
        <path d="M4.22 3.544a.254.254 0 01-.02-.338 17.6 17.6 0 01.526-.63 7.274 7.274 0 00-.463-.993.255.255 0 01.34-.351l.163.085c.324.168.658.342 1.009.536a8.263 8.263 0 011.024-.281.254.254 0 01.305.222c.038.357.05.72.035 1.079a15.4 15.4 0 011.036 1.12.255.255 0 01-.287.402 6.256 6.256 0 00-1.178-.326c-.16.21-.329.41-.502.592a.254.254 0 01-.395-.031 17.248 17.248 0 01-.522-.823 23.155 23.155 0 00-.744-.154l-.198-.04a.253.253 0 01-.13-.069zm.84-1.499c.07.162.136.33.197.5a.255.255 0 01-.049.255c-.117.13-.231.264-.343.397.2.04.416.084.647.14a.254.254 0 01.16.115c.129.214.256.423.382.62.116-.137.23-.28.338-.43a.254.254 0 01.244-.101c.185.028.369.063.549.105a16.695 16.695 0 00-.483-.489.275.275 0 01-.076-.196 6.883 6.883 0 00-.004-.827 7.612 7.612 0 00-.784.237.255.255 0 01-.211-.017 26.7 26.7 0 00-.567-.309z"/>
        <path d="M1.564 7.596a4.367 4.367 0 01-.139-6.167 4.367 4.367 0 016.168-.14 4.367 4.367 0 01.138 6.168 4.367 4.367 0 01-6.167.139zm5.677-5.938a3.857 3.857 0 00-5.447.122 3.857 3.857 0 00.122 5.448 3.857 3.857 0 005.447-.123 3.857 3.857 0 00-.122-5.447z"/>
        <path d="M8.41 15.563a4.367 4.367 0 01-5.926 1.711 4.367 4.367 0 01-1.712-5.927A4.367 4.367 0 016.7 9.637a4.367 4.367 0 011.711 5.926zm-7.192-3.97a3.857 3.857 0 001.511 5.235 3.857 3.857 0 005.235-1.511 3.857 3.857 0 00-1.511-5.235 3.857 3.857 0 00-5.235 1.511z"/>
        <path d="M3.828 14.05a.254.254 0 01-.32.112c-.248-.103-.5-.214-.75-.331-.303.227-.58.469-.827.72a.255.255 0 01-.431-.229l.036-.18c.072-.358.146-.728.235-1.118a8.394 8.394 0 01-.554-.906.255.255 0 01.13-.355 7.485 7.485 0 011.025-.333c.247-.457.514-.896.79-1.306a.255.255 0 01.465.165 6.2 6.2 0 00.014 1.222c.247.095.485.202.708.318a.256.256 0 01.08.388c-.195.237-.416.48-.646.73.016.278.039.533.059.758l.017.2c.004.051-.007.1-.031.144zm-1.674-.393c.137-.112.279-.221.425-.327a.256.256 0 01.26-.024c.157.076.317.149.475.22a17.873 17.873 0 01-.045-.66.256.256 0 01.067-.186c.17-.184.336-.364.489-.54a6.85 6.85 0 00-.506-.205.254.254 0 01-.165-.207 7.062 7.062 0 01-.051-.556c-.115.196-.228.397-.336.6a.276.276 0 01-.168.128c-.268.061-.534.14-.793.232.138.24.288.47.445.688.044.06.06.136.042.208-.05.215-.095.425-.139.63zM12.401 13.353A4.368 4.368 0 018.04 8.991 4.368 4.368 0 0112.4 4.628a4.367 4.367 0 014.363 4.363 4.368 4.368 0 01-4.363 4.362zm0-8.215A3.857 3.857 0 008.548 8.99a3.857 3.857 0 003.853 3.853 3.857 3.857 0 003.853-3.853 3.857 3.857 0 00-3.853-3.853z"/>
        <path d="M11.51 8.61a.253.253 0 01-.252-.226 17.97 17.97 0 01-.074-.817 7.311 7.311 0 00-1.029-.376.255.255 0 01-.008-.488l.175-.055c.348-.11.707-.224 1.092-.334a8.28 8.28 0 01.527-.924.254.254 0 01.373-.058c.28.226.544.473.788.737.514-.004 1.032.017 1.525.06a.254.254 0 01.08.487 6.25 6.25 0 00-1.064.603c.036.262.058.522.065.774a.254.254 0 01-.302.257 17.09 17.09 0 01-.95-.213c-.237.149-.45.292-.64.42l-.163.11a.257.257 0 01-.142.042zm-.465-1.655c.165.065.329.136.493.213.084.04.14.123.145.216.01.174.023.348.039.522.168-.113.353-.234.556-.359a.258.258 0 01.195-.03c.242.06.48.118.708.167a6.816 6.816 0 00-.064-.541.254.254 0 01.1-.246c.151-.11.306-.215.463-.313a16.211 16.211 0 00-.686-.004.27.27 0 01-.194-.085c-.183-.205-.38-.4-.587-.582-.144.238-.274.48-.387.722a.255.255 0 01-.162.137c-.213.06-.418.122-.619.183z"/>
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="var(--ion-color-light)" d="M0 0h16.956v18H0z"/>
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgBlueberryIcon;
