import { faCalendarCheck, faCalendarTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonActionSheet } from '@ionic/react';
import { SubmitButton } from 'components/baseElements/formControls';
import { Box, Flex } from 'components/baseElements/grid';
import { Hideable } from 'components/baseElements/hideable';
import { IonButton } from 'components/ionicComponents';
import { PrivatePropertyContainer } from 'containers/authorization/PrivatePropertyContainer';
import React, { useState } from 'react';
import { Highlight } from 'react-highlighter-ts';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'store';
import { TastingWithAttendee, toMyTastingVm } from 'store/tastings';
import { LoadingStatus } from 'utils/formValidation';
import {
  ETastingAttendeeButtons,
  getTastingAttendeeButtons,
  tastingAttendeeIsGoing,
} from '../../../store/tastingAttendees';
import { IonHighlightBoldText } from '../../ionicComponents';

interface TastingActionBarProperties {
  registerLoadingStatus: LoadingStatus;
  tasting: TastingWithAttendee | undefined;
  tastingRoomUrl: string;
  attendeeGoingAction: (isGoing: boolean) => void;
  unauthorizedButtonAction: () => any;
  registerForTastingButtonAction: () => any;
}

export default function TastingActionBar(
  props: TastingActionBarProperties
): JSX.Element {
  const {
    attendeeGoingAction,
    registerLoadingStatus,
    tasting,
    tastingRoomUrl,
    unauthorizedButtonAction,
    registerForTastingButtonAction,
  } = props;
  const intl = useIntl();
  const tastingResultsUrl = `/tastings/details/results/${tasting?.tasting?.id}`;
  const goingText = intl.formatMessage({ id: 'tastingActionBar.highlight.going' });
  const notGoingText = intl.formatMessage({ id: 'tastingActionBar.highlight.notGoing' });
  const youAreGoingText = intl.formatMessage({ id: 'tastingActionBar.toasts.youAreGoingToThisTasting' });
  const youAreNotGoingText = intl.formatMessage({ id: 'tastingActionBar.toasts.youAreNotGoingToThisTasting' });

  const [showActionSheet, setShowActionSheet] = useState(false);
  const [showChangeStatusActionSheet, setShowChangeStatusActionSheet] = useState(false);
  const [showInviteeActionSheet, setShowInviteeActionSheet] = useState(false);
  const numberOfHoursUntilEndOfTasting = useSelector(
    (state: RootState): number =>
      state.appSettings.numberOfHoursUntilEndOfTasting,
  );
  const vm = tasting
    ? toMyTastingVm(tasting, numberOfHoursUntilEndOfTasting)
    : tasting;
  const history = useHistory();

  const buttonStatus = getTastingAttendeeButtons(vm);
  // Return fragment if not meant to be shown
  if (buttonStatus === ETastingAttendeeButtons.None) {
    return <></>;
  }

  const zone = new Date()
    .toLocaleTimeString('en-us', { timeZoneName: 'short' })
    .split(' ')[2];
  const actionSheetHeader = `${tasting?.tasting?.name} ${vm?.tasting?.dateTime} ${zone}`;
  return (
    <Flex
      alignItems="center"
      borderTop="1px solid var(--ion-color-medium-tint)"
      justifyContent="center"
    >
      <PrivatePropertyContainer>
        <PrivatePropertyContainer.Unauthorized>
          {/* Sign Up to RSVP */}
          <Hideable hide={buttonStatus === ETastingAttendeeButtons.Ended || buttonStatus === ETastingAttendeeButtons.Full}>
            <IonButton
              color="secondary"
              onClick={(): void => unauthorizedButtonAction()}
            >
              <FormattedMessage id="tastingActionBar.buttons.unauthorized"/>
            </IonButton>
          </Hideable>
          {/* Tasting is full */}
          <Hideable hide={buttonStatus !== ETastingAttendeeButtons.Full}>
            <Box height="2.25em" style={{lineHeight:'2.25em',margin:'4px 2px'}}>
              <FormattedMessage id="tastingActionBar.buttons.tastingIsFull"/>
            </Box>
          </Hideable>
          {/* Tasting has ended */}
          <Hideable hide={buttonStatus !== ETastingAttendeeButtons.Ended}>
            <IonButton onClick={(): void => history.push(tastingResultsUrl)}>
              <FormattedMessage id="tastingRoom.buttons.tastingResults"/>
            </IonButton>
          </Hideable>
        </PrivatePropertyContainer.Unauthorized>
        <PrivatePropertyContainer.Authorized>
          <Hideable hide={buttonStatus !== ETastingAttendeeButtons.Full}>
            <Box height="2.25em" style={{lineHeight:'2.25em',margin:'4px 2px'}}>
              <FormattedMessage id="tastingActionBar.buttons.tastingIsFull"/>
            </Box>
          </Hideable>
          <Hideable hide={buttonStatus !== ETastingAttendeeButtons.TastingRoom}>
            <IonButton onClick={(): void => history.push(tastingRoomUrl)}>
              <FormattedMessage id="tastingActionBar.buttons.tastingRoom"/>
            </IonButton>
          </Hideable>
          <Hideable hide={buttonStatus !== ETastingAttendeeButtons.Ended}>
            <IonButton onClick={(): void => history.push(tastingResultsUrl)}>
              <FormattedMessage id="tastingRoom.buttons.tastingResults"/>
            </IonButton>
          </Hideable>
          <Hideable hide={buttonStatus !== ETastingAttendeeButtons.NotInvited}>
            <IonActionSheet
              buttons={[
                {
                  text: intl.formatMessage({
                    id: 'tastingActionBar.buttons.imGoing',
                  }),
                  handler: (): void => registerForTastingButtonAction(),
                },
                {
                  text: intl.formatMessage({
                    id: 'tastingActionBar.buttons.cancel',
                  }),
                  role: 'cancel',
                  handler: (): void => setShowActionSheet(false),
                },
              ]}
              header={actionSheetHeader}
              isOpen={showActionSheet}
              onDidDismiss={(): void => setShowActionSheet(false)}
            />
            <SubmitButton
              color="secondary"
              expand={null}
              fill="outline"
              isLoading={registerLoadingStatus === LoadingStatus.Loading}
              onClick={(): void => setShowActionSheet(true)}
            >
              <FontAwesomeIcon icon={faCalendarCheck} />&nbsp;
              <FormattedMessage id="tastingActionBar.buttons.rsvp" />
            </SubmitButton>
          </Hideable>
          <Hideable hide={buttonStatus !== ETastingAttendeeButtons.ChangeStatus}>
            <Flex alignItems="center" justifyContent="space-apart">
              <Hideable hide={tastingAttendeeIsGoing(tasting?.attendee)}>
                <Box px={1}>
                  <FontAwesomeIcon icon={faCalendarTimes} size="lg" />
                </Box>
                <Box px={1} textAlign="center">
                  <IonHighlightBoldText>
                    <Highlight
                      ignoreDiacritics
                      search={notGoingText}
                    >
                      {youAreNotGoingText}
                    </Highlight>
                  </IonHighlightBoldText>
                </Box>
              </Hideable>
              <Hideable hide={!tastingAttendeeIsGoing(tasting?.attendee)}>
                <Box px={1}>
                  <FontAwesomeIcon icon={faCalendarCheck} size="lg" />
                </Box>
                <Box px={1} textAlign="center">
                  <IonHighlightBoldText>
                    <Highlight
                      ignoreDiacritics
                      search={goingText}
                    >
                      {youAreGoingText}
                    </Highlight>
                  </IonHighlightBoldText>
                </Box>
              </Hideable>
              <IonActionSheet
                buttons={[
                  {
                    text: intl.formatMessage({
                      id: 'tastingActionBar.buttons.imGoing',
                    }),
                    handler: (): void => {
                      setShowChangeStatusActionSheet(false);
                      attendeeGoingAction(true);
                    },
                  },
                  {
                    text: intl.formatMessage({
                      id: 'tastingActionBar.buttons.notGoing',
                    }),
                    handler: (): void => {
                      setShowChangeStatusActionSheet(false);
                      attendeeGoingAction(false);
                    },
                  },
                  {
                    text: intl.formatMessage({
                      id: 'tastingActionBar.buttons.cancel',
                    }),
                    role: 'cancel',
                    handler: (): void => setShowChangeStatusActionSheet(false),
                  },
                ]}
                isOpen={showChangeStatusActionSheet}
                onDidDismiss={(): void => setShowChangeStatusActionSheet(false)}
              />
              <IonButton
                color="secondary"
                fill="outline"
                mx={1}
                onClick={(): void => setShowChangeStatusActionSheet(true)}
              >
                <FormattedMessage id="tastingActionBar.buttons.changeStatus" />
              </IonButton>
            </Flex>
          </Hideable>
          <Hideable hide={buttonStatus !== ETastingAttendeeButtons.Invited}>
            <IonActionSheet
              buttons={[
                {
                  text: intl.formatMessage({
                    id: 'tastingActionBar.buttons.imGoing',
                  }),
                  handler: (): void => {
                    setShowInviteeActionSheet(false);
                    attendeeGoingAction(true);
                  },
                },
                {
                  text: intl.formatMessage({
                    id: 'tastingActionBar.buttons.cancel',
                  }),
                  role: 'cancel',
                  handler: (): void => setShowInviteeActionSheet(false),
                },
              ]}
              isOpen={showInviteeActionSheet}
              onDidDismiss={(): void => setShowInviteeActionSheet(false)}
            />
            <IonButton
              color="secondary"
              fill="outline"
              onClick={(): void => setShowInviteeActionSheet(true)}
            >
              <FontAwesomeIcon icon={faCalendarCheck} />&nbsp;
              <FormattedMessage id="tastingActionBar.buttons.rsvp" />
            </IonButton>
            <IonButton
              color="secondary"
              fill="outline"
              onClick={(): void => attendeeGoingAction(false)}
            >
              <FontAwesomeIcon icon={faCalendarTimes} />&nbsp;
              <FormattedMessage id="tastingActionBar.buttons.iCantGo" />
            </IonButton>
          </Hideable>
        </PrivatePropertyContainer.Authorized>
      </PrivatePropertyContainer>
    </Flex>
  );
}
