import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgClayIcon(props) {
  return (
    <svg width={18} height={18} fill="none" {...props}>
      <path
        d="M2.106.34h-.562v.639h.562v-.64zM2.106 1.763h-.562v.64h.562v-.64zM1.292 1.09H.653v.562h.64V1.09zM2.998 1.09h-.64v.562h.64V1.09zM16.515 3.093h-.562v.64h.562v-.64zM16.515 4.516h-.562v.64h.562v-.64zM15.701 3.843h-.639v.563h.64v-.563zM17.407 3.843h-.64v.563h.64v-.563zM8.81 18c4.27 0 8.809-1.008 8.809-2.875v-1.703l-.001-.004c-.003-.85-1.005-1.603-2.83-2.135a6.251 6.251 0 00.499-3.415 6.457 6.457 0 00-1.76-3.618c-.181-.188-.274-.446-.274-.767V1.956c0-.012 0-.024-.002-.036l.002-.068C13.253.692 11.593 0 8.811 0 6.03 0 4.37.692 4.37 1.852l.002.067a.296.296 0 00-.002.037V3.48c0 .355-.114.604-.275.77a6.51 6.51 0 00-1.819 4.457c0 .918.2 1.79.555 2.576C1.004 11.815.002 12.568 0 13.42v1.705C0 16.992 4.539 18 8.81 18zm8.246-2.875c0 1.094-3.387 2.313-8.247 2.313-4.86 0-8.246-1.219-8.246-2.313v-.636c1.655 1.454 6.034 1.808 8.246 1.808 2.19 0 6.55-.357 8.22-1.787l.027-.02v.635zM4.932 1.852c0-.76 1.595-1.29 3.88-1.29 2.283 0 3.879.53 3.879 1.29 0 .766-1.594 1.62-3.88 1.62-2.286 0-3.88-.854-3.88-1.62zm-.434 2.79c.069-.071.13-.15.182-.232.645.924 2.229 1.564 4.131 1.564v-.562c-2.099 0-3.872-.881-3.88-1.926v-.558c.753.665 2.192 1.107 3.88 1.107 1.689 0 3.127-.442 3.88-1.107v.555c0 .466.15.866.432 1.157a5.894 5.894 0 011.606 3.301c.035.274.052.524.052.766 0 3.13-2.537 5.718-5.719 5.718-.102-.022-3.737.403-5.559-3.047a5.673 5.673 0 01-.665-2.668 5.946 5.946 0 011.66-4.068zm-1.41 7.152c1.941 3.447 5.859 3.17 5.973 3.194a6.272 6.272 0 005.47-3.194c1.564.442 2.524 1.058 2.524 1.627 0 1.094-3.386 2.312-8.246 2.312S.563 14.515.563 13.421c0-.57.961-1.185 2.525-1.627z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M11.253 5.572l-.192-.528a6.162 6.162 0 01-1.402.32l.064.559a6.728 6.728 0 001.53-.35zM12.093 12.155a4.571 4.571 0 01-3.03 1.145v.563a5.134 5.134 0 003.402-1.287l-.372-.421zM14.218 8.708h-.563a4.55 4.55 0 01-.75 2.515l.47.31a5.11 5.11 0 00.843-2.825zM2.206 4.36a7.855 7.855 0 00-1.23 3.118l.556.092a7.295 7.295 0 011.142-2.897l-.468-.313zM.87 8.708c0 .593.068 1.185.203 1.759l.547-.129a7.131 7.131 0 01-.188-1.63H.87z"
        fill="var(--ion-color-dark)"
      />
    </svg>
  );
}

export default SvgClayIcon;
