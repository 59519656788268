// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const transient = category => props => {
  const unprefixedProps = Object.entries(props).reduce((acc, [key, value]) => {
      let unprefixedKey = key;
      if (key.startsWith('$')) unprefixedKey = key.substring(1);
      acc[unprefixedKey] = value;
      return acc;
  }, {});
  return category(unprefixedProps);
};

export const ellipsis = transient(
  props => props.ellipsis > 1 ? `
    display: -webkit-box !important;
    -webkit-line-clamp: ${props.ellipsis};
    -webkit-box-orient: vertical;
    white-space: normal !important;
    overflow: hidden;
  ` : !props.ellipsis ? '' : `
    display: block;
    width: 100%;
    max-width: 100vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap !important;
  `
);
