import { ApiCall, CustomHookProps, useBasicMutation } from 'napa-react-core';
import { UseMutationResult } from 'react-query/types/react/types';
import { WineScore } from 'store/wineScores';

export default function usePostWineScore(
  wineId?: string,
  props?: CustomHookProps<WineScore>,
): UseMutationResult<WineScore, ApiCall<WineScore>> {
  return useBasicMutation<WineScore>({
    apiRoute: `wines/${wineId}/scores`,
    httpMethod: 'POST',
    isFormData: false,
    params: props,
  });
}
