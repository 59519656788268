import { ItemReorderEventDetail } from '@ionic/core';
import { IonContent, IonList, IonReorderGroup } from '@ionic/react';
import arrayMove from 'array-move';
import { IonListHeader } from 'components/ionicComponents';
import React, { useState } from 'react';
import { Wine } from 'store/wines';
import { WineAndFlightOrder } from 'store/wines/utils';
import { WineListItem, WineListItemType } from '../WineListItem';

interface ReorderWinesProperties {
  data: Array<WineAndFlightOrder>;
  moveHandler: (data: Array<WineAndFlightOrder>) => any;
}

export default function ReorderWines(props: ReorderWinesProperties): JSX.Element {
  const {
    data,
    moveHandler,
  } = props;

  const [orderedData, setOrderedData] = useState(data);

  const doReorder = (event: CustomEvent<ItemReorderEventDetail>): void => {
    const arr = [...orderedData];
    const orderedArr = arrayMove(arr, event.detail.from, event.detail.to);
    setOrderedData(orderedArr);
    moveHandler(orderedArr);
    // Finish the reorder and position the item in the DOM based on
    // where the gesture ended. This method can also be called directly
    // by the reorder group
    event.detail.complete();
  };
  return (
    <IonContent>
      <IonList>
        <IonReorderGroup
          disabled={false}
          id="reorder"
          onIonItemReorder={doReorder}
        >
          {data && data.map((item: WineAndFlightOrder, key: any) => item.flight ?
            (
              <IonListHeader key={key}>{item.flight.name}</IonListHeader>
            ) :
            (
              <WineListItem
                key={key}
                type={WineListItemType.DragOption}
                wine={item?.wine as Wine}
              />
            ),
          )}
        </IonReorderGroup>
      </IonList>
    </IonContent>
  );
}
