import React from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Box, Flex } from 'components/baseElements/grid';
import { Paragraph } from 'components/baseElements/typography';
import { IonButton } from 'components/ionicComponents';
import { showModal } from 'utils/modals';
import { AuthorizationModalId } from 'modals/AuthorizationModal';
import GetStartedBg from 'assets/images/get-started-bg.jpg';

export default function GetStartedNoSession(): JSX.Element {
  const dispatch = useDispatch();
  const borderRadius = 8;
  return (
    <Flex
      backgroundImage={`url(${GetStartedBg})`}
      backgroundSize="cover"
      minHeight={200}
    >
      <Flex
        backgroundColor="rgba(var(--ion-color-secondary-rgb), 0.21)"
        height="100%"
        justifyContent="center"
        px={4}
        py={3}
        width={1}
      >
        <Flex
          background="var(--ion-color-light)"
          borderRadius={borderRadius}
        >
          <Flex
            alignItems="center"
            background="rgba(var(--ion-color-secondary-rgb), 0.1)"
            borderRadius={borderRadius}
            flexDirection={['column', 'row']}
            maxWidth={[230, 345]}
            p={3}
            width={1}
          >
            <Box flex={2}>
              <Paragraph
                textAlign="center"
              >
                <FormattedMessage id="getStarted.text.noSessionDescription" />
              </Paragraph>
            </Box>
            <Box
              flex={1}
              pl={[null, 2]}
              width={1}
            >
              <IonButton
                color="secondary"
                expand="block"
                onClick={(): void =>
                  showModal(dispatch, AuthorizationModalId, { params: {mode: 'registration'} })
                }
              >
                <FormattedMessage id="general.nav.registration" />
              </IonButton>
              <IonButton
                color="primary"
                expand="block"
                onClick={(): void =>
                  showModal(dispatch, AuthorizationModalId, { params: {mode: 'login'} })
                }
              >
                <FormattedMessage id="general.nav.login" />
              </IonButton>
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
