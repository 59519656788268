/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { IonButton as IonButtonBase } from '@ionic/react';
import styled from 'styled-components';
import {
  layout,
  position,
  space,
  system,
  typography,
} from 'styled-system';
import { transient } from 'utils/styledHelpers';

const IonButton = styled(IonButtonBase).attrs(props => ({
  dataCy: props.type === 'submit' ? 'submitButton' : props.dataCy,
}))`
  ${props => props.paddingEnd ? `--padding-end: ${props.paddingEnd};` : ''};
  ${props => props.paddingStart ? `--padding-start: ${props.paddingStart};` : ''};
  ${transient(layout)}
  ${transient(position)}
  ${transient(space)}
  ${transient(system({ textTransform: true }))}
  ${transient(typography)}
`;

IonButton.defaultProps = {
  $fontWeight: 'bold',
  $textTransform: 'none',
};

export { IonButton };
