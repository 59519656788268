import React from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Flex } from 'components/baseElements/grid';
import { Heading, Paragraph } from 'components/baseElements/typography';
import { IonButton } from 'components/ionicComponents';
import { CreateTastingModalId } from 'modals/CreateTastingModal';
import { showModal } from 'utils/modals';
import ViewMoreTastingsButton from '../ViewMoreTastingsButton';

interface GetStartedProps {
  createTastingModalCallback?: (result: any) => any;
}

export default function GetStarted(props: GetStartedProps): JSX.Element {
  const dispatch = useDispatch();
  return (
    <Flex
      alignItems="center"
      background="rgba(var(--ion-color-secondary-rgb), 0.21)"
      flexDirection="column"
      pb={3}
    >
      <Heading
        headingSize="sm"
        textAlign="center"
      >
        <FormattedMessage id="getStarted.header.caption" />
      </Heading>
      <Paragraph
        maxWidth={210}
        textAlign="center"
      >
        <FormattedMessage id="getStarted.text.description" />
      </Paragraph>
      <IonButton
        color="secondary"
        data-cy="hostTastingBtn"
        onClick={(): void => showModal(dispatch, CreateTastingModalId, { callback: props.createTastingModalCallback })}
      >
        <FormattedMessage id="getStarted.button.hostTasting" />
      </IonButton>
      <ViewMoreTastingsButton />
    </Flex>
  );
}
