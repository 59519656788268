import { AuthorizationState } from 'store/authorization/types';
import { AuthorizationSetTokenAction } from './types';

export const AuthorizationSetTokenReducer = (
  authorizationState: AuthorizationState,
  action: AuthorizationSetTokenAction,
): AuthorizationState => ({
  ...authorizationState,
  token: action.payload.token,
  tokenData: action.payload.tokenData,
});
