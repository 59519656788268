import ResetPasswordForm from 'components/users/ResetPasswordForm';
import { AuthorizationModalId } from 'modals/AuthorizationModal';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { RootState } from 'store';
import { ChangePasswordRequest } from 'store/users';
import { clearApiCallStatus, GetLoadingStatus, makeApiCall } from 'utils/api';
import { showModal } from 'utils/modals';
import { schema } from './validation';

interface ResetPasswordQueryString {
  resetToken?: string;
  email?: string;
}

export default function ResetPasswordContainer(
  props: ResetPasswordQueryString,
): JSX.Element {
  // PROPS
  const { resetToken, email } = props;

  // CONSTANT DECLARATION
  const history = useHistory();
  const CHANGE_PASSWORD_UN_AUTH_KEY = 'changePasswordUnauth';
  const dispatch = useDispatch();

  // LOCAL (CONTAINER) STATE SETUP
  const [changePasswordUnauth, setChangePasswordUnauth] = useState(undefined as ChangePasswordRequest | undefined);

  // GLOBAL (REDUX) STATE SETUP
  // const auth = useSelector((state: RootState) => state.authorization);
  const apiBaseUrl = useSelector((state: RootState): string => state.appSettings.apiBaseUrl);
  const changePasswordApiCall = useSelector((state: RootState) =>
    state.api.callStatuses.find(e => e.id === CHANGE_PASSWORD_UN_AUTH_KEY),
  );

  //LOCAL FUNCTIONS
  const changePasswordSubmitAction = (data: any): void => {
    setChangePasswordUnauth(data);
  };

  const handleChangePasswordSubmit = useCallback(async (): Promise<void> => {
    const changePasswordSuccess = (): void => {
      showModal(dispatch, AuthorizationModalId, { params: { mode: 'login' } });
      history.push('/tastings');
    };
    if (!changePasswordUnauth) {
      return;
    }

    // On mobile, the device can convert plus ("+") characters to space characters (" "). This replace statement
    // will ensure they are converted back when calling the API
    changePasswordUnauth.token = resetToken ? resetToken.replaceAll(' ', '+') : '';
    changePasswordUnauth.email = email;
    changePasswordUnauth.currentPassword = undefined;
    try {
      await makeApiCall({
        dispatch: dispatch,
        callId: CHANGE_PASSWORD_UN_AUTH_KEY,
        request: {
          url: `${apiBaseUrl}/users/changePassword`,
          httpMethod: 'PUT',
          body: changePasswordUnauth,
        },
      });
      changePasswordSuccess();
    }
    catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [apiBaseUrl, changePasswordUnauth, dispatch, email, history, resetToken]);

  // EFFECTS SETUP
  useEffect(() => {
    clearApiCallStatus(dispatch, CHANGE_PASSWORD_UN_AUTH_KEY);
  }, [dispatch]);
  useEffect(() => {
    handleChangePasswordSubmit();
  // handle callback should only invoke on change of changePasswordUnauth; do not include callback fn as part of useEffect dependency
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changePasswordUnauth]);

  return (
    <ResetPasswordForm
      loadingStatus={GetLoadingStatus(changePasswordApiCall?.status)}
      submitAction={changePasswordSubmitAction}
      serverValidation={changePasswordApiCall?.validationErrors}
      validationSchema={schema}
    />
  );
}
