import { ApiCall, CustomHookProps, PaginatedData, useBasicQuery } from 'napa-react-core';
import { QueryObserverResult } from 'react-query';
import { Wine } from '../../store/wines';

export default function useTastingWines(
  tastingId: string | undefined,
  props?: CustomHookProps<PaginatedData<Wine>>,
): QueryObserverResult<PaginatedData<Wine>, ApiCall<Wine[]>> {
  return useBasicQuery<PaginatedData<Wine>>({
    apiRoute: `tastings/${tastingId}/wines`,
    httpMethod: 'GET',
    isFormData: false,
    body: undefined,
    queryId: 'useTastingWines',
    params: props
  });
}
