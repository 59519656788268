import * as React from 'react';
import { IonPage } from '@ionic/react';
import ViewWineScoreSummaryContainer from 'containers/wineScores/ViewWineScoreSummaryContainer';
import { useParams } from 'react-router-dom';

interface ScoreSummaryPageParamTypes {
  wineId: string;
}
const WineScoreSummaryPage = (): any => {
  const { wineId } = useParams<ScoreSummaryPageParamTypes>();

  return (
    <IonPage>
      <ViewWineScoreSummaryContainer wineId={wineId} />
    </IonPage>
  );
};

export default WineScoreSummaryPage;
