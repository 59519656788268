// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const loadSmartAppBanner = (environment?: any): void => {
  try {
    if (environment?.smartAppBanner) {
      new window['SmartBanner']({
        daysHidden: 15,   // days to hide banner after close button is clicked (defaults to 15)
        daysReminder: 90, // days to hide banner after "VIEW" button is clicked (defaults to 90)
        appStoreLanguage: 'us', // language code for the App Store (defaults to user's browser language)
        title: 'VinVite',
        author: 'VinVite LLC',
        button: 'VIEW',
        store: {
          ios: 'On the App Store',
          android: 'In Google Play',
        },
        price: {
          ios: 'FREE',
          android: 'FREE',
        },
        theme: 'ios' // put platform type ('ios', 'android', etc.) here to force single theme on all device
        // , icon: '' // full path to icon image if not using website icon image
        // , force: 'ios' // Uncomment for platform emulation
      });
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};
