
import * as React from 'react';
import { IonContent, IonHeader, IonPage, IonToolbar } from '@ionic/react';
import { IonTitle } from 'components/ionicComponents';
import BrowseTastingsContainer from 'containers/tastings/BrowseTastingsContainer';
import { FormattedMessage } from 'react-intl';

const BrowseTastingPage = (): any => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>
            <FormattedMessage id="browseTastings.header.title" />
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <BrowseTastingsContainer />
      </IonContent>
    </IonPage>
  );
}

export default BrowseTastingPage
