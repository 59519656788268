import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgMangoIcon(props) {
  return (
    <svg width={16} height={19} fill="none" {...props}>
      <path
        d="M8.508 2.897c.847.669 1.769 1.009 2.746 1.009 1.966 0 3.603-1.393 4.141-1.85l.433-.366-.408-.253C14.218.691 13.01.313 11.83.313c-1.545 0-2.734.668-3.353 1.226-.03-.287-.075-.514-.141-.627-.331-.563-1.551 0-1.551 0 .887.982.953 2.73.942 3.425l-.067-.002c-1.976 0-3.66 1.091-4.503 2.92-.567 1.232-.593 2.517-.615 3.65-.027 1.324-.05 2.467-.895 3.196C.69 14.925.26 15.905.495 16.72c.126.438.568 1.207 2.112 1.44.667.101 1.318.152 1.935.152 2.584 0 4.75-.908 6.261-2.625 1.8-2.045 2.359-4.886 2.115-6.794-.346-2.705-2.464-4.24-4.582-4.512.088-.268.151-.86.172-1.485zm3.718 6.085c.224 1.753-.29 4.363-1.947 6.244-1.395 1.585-3.325 2.39-5.737 2.39-.582 0-1.198-.05-1.831-.145-.858-.13-1.407-.465-1.545-.943-.155-.54.203-1.267.936-1.899 1.082-.932 1.11-2.281 1.137-3.71.022-1.117.045-2.272.552-3.372.737-1.598 2.147-2.515 3.87-2.515 2.017 0 4.233 1.357 4.565 3.95zM9.07 2.39a.21.21 0 01-.015.021l-.005.004.008-.01.007-.01.005-.005zm5.56-.604c-.679.548-1.93 1.423-3.376 1.423-.775 0-1.515-.261-2.206-.776l.006-.003c.094-.04.25-.098.44-.16.381-.124.905-.27 1.434-.39.53-.12 1.066-.21 1.472-.24.407-.037.68.011.68.01 0 0-.263-.09-.68-.12-.413-.036-.968-.03-1.521.005-.521.033-1.036.09-1.443.151a4.53 4.53 0 012.395-.674c.912 0 1.853.26 2.8.774z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M10.786 10.707c.177.367.616-1.042.425-1.963-.21-1.013-.842-1.89-1.296-1.657-.448.23.271.763.555 1.835.232.874.165 1.472.316 1.785z"
        fill="var(--ion-color-dark)"
      />
    </svg>
  );
}

export default SvgMangoIcon;
