import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function SvgDriedLeavesIcon(props) {
  return (
    <svg width={18} height={19} fill="none" {...props}>
      <path
        d="M17.03 7.277a.208.208 0 00-.205-.081l-1.817.343.045-1.28a.214.214 0 00-.113-.197.217.217 0 00-.225.02l-2.622 1.997.323-2.293.009-.03.385-2.733a.21.21 0 00-.11-.213.214.214 0 00-.239.025l-1.108.965L8.933.1c-.078-.12-.275-.118-.351 0l-2.42 3.7-1.108-.965a.214.214 0 00-.24-.025.21.21 0 00-.11.213l.718 5.056L2.8 6.082a.217.217 0 00-.225-.02.209.209 0 00-.113.197l.046 1.28L.69 7.196a.208.208 0 00-.205.081.206.206 0 00-.02.223l1.525 3.004-1.283.583a.205.205 0 00-.124.2.21.21 0 00.14.19l3.526 1.219-3.387 2.569a.211.211 0 00-.07.239c.03.087.115.143.208.14l3.187-.129-.21 1.23a.22.22 0 00.086.208c.037.025.08.04.121.04a.193.193 0 00.104-.029l3.596-2 .661.616v2.22a.21.21 0 00.422 0v-2.217l.664-.62 3.596 2.001a.21.21 0 00.225-.011.213.213 0 00.084-.208l-.208-1.23 3.188.13a.216.216 0 00.208-.141.211.211 0 00-.07-.24l-3.39-2.568 3.527-1.218a.21.21 0 00.141-.191.205.205 0 00-.124-.2l-1.283-.583L17.05 7.5a.206.206 0 00-.02-.223zm-1.98 3.233a.201.201 0 00-.011.166.202.202 0 00.11.121l1.004.453-3.084 1.066-.315.11a.218.218 0 00-.14.166.217.217 0 00.081.202l3.168 2.4-2.777-.112a.207.207 0 00-.217.245l.178 1.055L9.7 14.519a.22.22 0 00-.248.031l-.486.453v-2.127l5.025-3.123a.21.21 0 10-.222-.357l-4.803 2.982V5.961a.21.21 0 10-.422 0v6.412L3.627 9.199a.215.215 0 00-.292.062.213.213 0 00.064.292l5.146 3.32v2.13l-.484-.453a.213.213 0 00-.247-.03l-3.346 1.862.178-1.055a.208.208 0 00-.217-.245l-2.777.112 3.168-2.4a.21.21 0 00.079-.202.21.21 0 00-.138-.166l-3.399-1.173 1.002-.456a.207.207 0 00.113-.121.2.2 0 00-.012-.166L1.033 7.691l1.657.312a.208.208 0 00.175-.048.205.205 0 00.073-.168l-.04-1.098 2.676 2.04c.068.05.16.056.234.014a.215.215 0 00.104-.214l-.71-5.005.865.751c.047.043.106.06.168.051a.213.213 0 00.147-.093L8.756.601l2.377 3.632a.21.21 0 00.315.042l.864-.75-.3 2.143c-.006.011-.006.023-.01.037l-.399 2.824a.215.215 0 00.104.214.214.214 0 00.234-.014l2.675-2.04-.042 1.098c0 .064.026.126.074.168.05.043.115.06.177.048l1.657-.312-1.432 2.819z"
        fill="var(--ion-color-dark)"
      />
      <path
        d="M8.757 2.832a.211.211 0 00-.21.21v1.626a.211.211 0 00.421 0V3.043a.211.211 0 00-.21-.211z"
        fill="var(--ion-color-dark)"
      />
    </svg>
  );
}

export default SvgDriedLeavesIcon;
